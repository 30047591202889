import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
// import { Helmet } from "react-helmet";

const AssureInsuranceHighlights = ({ classes }) => {
    return (
        <GridContainer className={classes.containerDisplay}>
      {/* {process.env.ENABLE_HELMET == `true` && (
       <Helmet>
     <script type="application/ld+json">
{`{ "@context": "http://schema.org",
  "@type": "Product",
  "name": "Star Health Assure Insurance Policy",
  "description": "Affordable health policy with vast benefits. Cover your entire family under a single roof.",
"offers":{ 
          "@type": "AggregateOffer",
          "offerCount": "5%"
        }
      }`}
    </script>
       </Helmet>)} */}
    </GridContainer>
  );
};
AssureInsuranceHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(AssureInsuranceHighlights);