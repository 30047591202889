var md5 = require(`blueimp-md5`);

export const formatReqAndRes = requestBody => {
  const elementsTobeChanged = [
    { current: `policy_type`, updateTo: `policyType` },
    { current: `product_id`, updateTo: `productId` },
    { current: `policy_cover`, updateTo: `policyCover` },
    { current: `policyQuote`, updateTo: `policy_quote` },
    { current: `pincode`, updateTo: `pinCode` },
    { current: `policy_plan`, updateTo: `policyPlan` },
    { current: `life_cover`, updateTo: `lifeCover` },
    { current: `lifeQuote`, updateTo: `life_policy_quote` },
    { current: `healthQuote`, updateTo: `health_policy_quote` },
    { current: `policy_option`, updateTo: `policyOption` },
    { current: `coverage_plan`, updateTo: `coveragePlan` }
  ];
  elementsTobeChanged.map(obj => {
    if (requestBody.hasOwnProperty(obj.current)) {
      requestBody[obj.updateTo] = requestBody[obj.current];
      delete requestBody[obj.current];
    }
  });
  return requestBody;
};

export function isNullOrUndefined(str) {
  if (str === null || str === `undefined` || str === undefined || str.length <= 0) {
    return ``;
  }
  return str;
}

export function getRequestData(body) {
  const productId = isNullOrUndefined(body.productId) ? body.productId : ``,
    dob = isNullOrUndefined(body.dob) ? body.dob : ``,
    policyCover = isNullOrUndefined(body.policyCover) ? body.policyCover : ``,
    policyPlan = isNullOrUndefined(body.policyPlan) ? body.policyPlan : ``,
    pinCode = isNullOrUndefined(body.pinCode) ? body.pinCode : ``,
    policyType = isNullOrUndefined(body.policyType) ? body.policyType : ``;
  var checkSumRequest = md5(
    `${productId}|${dob}|${policyType}|${policyCover}|${policyPlan}|${pinCode}|quote`,
    `73q&*cvm`,
  );
  body.checkSum = checkSumRequest;
  // console.log(
  //   'checksum response',
  //   JSON.stringify(body),
  //   `${productId}|${dob}|${policyType}|${policyCover}|${policyPlan}|${pinCode}|quote`,
  // );
  return body;
}