import React from "react";
// nodejs library that concatenates classes
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class StarAssureBenefits extends React.Component {
// const StarAssureBenefits = ({ classes }) => {
  render() {
    let {classes } = this.props;
    let props = this.props
    return ( 
        <GridContainer>
            <ul>
                <li>Room, Boarding, Nursing Expenses all inclusive as provided by the Hospital / Nursing Home as per the limits given below:-
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Sum Insured in lakhs (Rs.)</b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>5</b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>10/15/20/25</b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>50/75/100/200</b>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>Room Rent Criteria</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Up to 1% of Sum Insured per day</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Any Room(Except suite or above category)</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Any room</TableCell>
                        </TableRow>
                        </TableHead>
                     
                  </Table>
                </Paper>
                </li>
                <li>Surgeon, Anesthetist, Medical Practitioner, Consultants, Specialist Fees.</li>
                <li>All day care treatments are covered</li>
                <li><b>Coverage for Non-medical Items (Consumables)</b> available <u><a target="blank" href="/sites/default/files/CoveredNon-MedicalItems-StarHealthAssureInsurancePolicy.pdf">click here</a></u> to view details</li>
                <li>Road Ambulance charges for transportation of Insured Person covered subject to an admissible Hospitalisation Claim.</li>
                <li><b>Air Ambulance :</b> up to 10% of the Sum Insured per year is payable</li>
                <li>Pre-Hospitalization expenses up to 60 days prior to admission in the hospital.</li>
                <li>Post- Hospitalization expenses incurred up to 180 days after discharge from the hospital.</li>
                <li><b>Domiciliary Hospitalization:</b> Coverage for medical treatment (Including AYUSH) for a period exceeding three days.</li>
                <li><b>Organ Donor Expenses</b> In patient hospitalization expenses incurred for organ transplantation from the Donor to the Recipient Insured Person are payable.</li>
                <li><b>Health Checkup Assure:</b> Expenses incurred towards cost of health check-up up to the limits for each policy year (irrespective of claim).</li>
                <li><b>Home care treatment:</b> Payable up to 10% of the sum insured subject to maximum of Rs.5 lakhs in a policy year, for treatment availed by the Insured Person at home, only for the specified conditions </li>
                <li><b>Delivery Expenses :</b> Expenses for a Delivery including Delivery by Caesarean section (including pre-natal and post natal expenses) up-to 10% of the Sum Insured is payable.</li>
                <li><b>In Utero Fetal Surgery/Intervention  :</b> The Company will pay  the expenses incurred for listed In Utero Fetal Surgeries and Procedures after the waiting period of 24 months from the date of inception of this policy 
                <br/>
                <b>Note :</b> The above mentioned waiting periods will not apply for treatment related to congenital Internal disease / defects for the Unborn</li>
                <li><b>Assisted Reproduction Treatment:</b> The Company will reimburse medical expenses incurred on Assisted Reproduction Treatment upto the limits in a policy year, for sub-fertility</li>
                <li><b>Hospitalization expenses for treatment of New Born Baby:</b> Cover New born baby from Day 1 of its birth subject to limits. However, the sublimit will not apply for treatment related to congenital disease/defects for the new born.</li>
                <li><b>Treatment for Chronic Severe Refractory Asthma :</b> cover is payable up to 10% of sum insured not exceeding Rs.5 lakhs per policy period.</li>
                <li><b>Compassionate travel:</b> The Company will reimburse the transportation expenses by air incurred upto Rs.10,000/- for one immediate family member(other than the travel companion) for travel towards the place where hospital is located. </li>
                <li><b>Repatriation of Mortal Remains</b> The Company shall reimburse up to Rs.15,000/- in a policy year </li>
                <li><b>Treatment in Valuable service providers network:</b> An amount calculated at 1% of Sum Insured subject to a maximum of Rs.5,000/- per policy period is payable as lump sum.</li>
                <li><b>Shared accommodation:</b> An amount of Rs.1,000/- per day will be payable for each continuous and completed period of 24 hours of stay in such shared accommodation.</li>
                <li><b>AYUSH Treatment:</b> AYUSH treatment is payable up to the sum insured.</li>
                <li><b>Second Medical Opinion:</b> The Insured Person can obtain a Medical Second Opinion from a Doctor in the Company’s network of Medical Practitioners</li>
                <li><b>Coverage for Modern Treatment:</b> Please <u><a target="blank" href="/sites/default/files/modern-treatment/Modern-Treatment-StarHealthAssureInsurancePolicy.pdf">click here</a></u></li>
                <li>Benefit/Premium illustration for Individual and Floater basis please <u><a target="blank" href="/sites/default/files/Benefit-Illustration/BenefitIllustration-StarHealthAssureInsurancePolicy.pdf">Click Here</a></u></li>
                <li><b>Cumulative Bonus:</b> The insured person will be eligible for Cumulative bonus calculated at 25% of sum insured for each claim free year and maximum upto 100% of the sum insured</li>
                <li><b>Automatic Restoration of Sum Insured:</b> Unlimited number of times and maximum up to 100% each time, which can be utilized for a subsequent hospitalization</li>
                <li><b>Rehabilitation and Pain Management:</b> Payable up to the sub-limit (or) maximum up to 20% of the sum insured whichever is less, per policy year.</li>
                <li><b>Optional Cover to choose deductible:</b> If the insured person chooses any of the following deductible, the Company will provide a discount on premium as per the table given below
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Sum Insured</b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Aggregate Deductible Option</b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Discount offered</b>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowspan={2} align="center" className={classes.tableCell}>Up to Rs. 20 lakhs</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Rs. 50,000/-</TableCell>
                            <TableCell align="center" className={classes.tableCell}>45%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>Rs. 1,00,000/-</TableCell>
                            <TableCell align="center" className={classes.tableCell}>55%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowspan={2} align="center" className={classes.tableCell}>Above Rs. 20 lakhs</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Rs. 50,000/-</TableCell>
                            <TableCell align="center" className={classes.tableCell}>35%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>Rs. 1,00,000/-</TableCell>
                            <TableCell align="center" className={classes.tableCell}>50%</TableCell>
                        </TableRow>
                        </TableHead>
                     
                  </Table>
                </Paper>
                <br /><b>Note:</b> This deductible is applicable for every policy year. (on Aggregate basis)
                </li>
                <li><u><b>Waiting Period:</b></u>
                    <ol>
                        <li><b>Pre-Existing Diseases:</b>
                            <ul>
                                <li>30 months in case of 3 years term </li>
                                <li>36 months in case of 1 year and 2 years term</li>
                            </ul>
                        </li>
                        <li><b>Specified disease / procedure waiting period:</b> 24 months</li>
                        <li><b>Initial waiting period:</b> 30 days</li>
                    </ol>
                </li>
                {props && props.redirectUrl ? 
                (<>
                <li><u><b>Instalment Facility:</b></u> Premium can be paid <u><a target="blank" href={`/customerportal/${props && props.redirectUrl}&paymentOption=Quarterly`}>Quarterly</a></u> and <u><a target="blank" href={`/customerportal/${props && props.redirectUrl}&paymentOption=HalfYearly`}>Half Yearly</a></u>. Premium can also be paid Annual, Biennial (once in 2 years) and Triennial (once in 3 years). Note: Instalment facility is not available for long term (2 year and 3 year) policies</li>
                </>) :
                (<>
                <li><u><b>Instalment Facility:</b></u> Premium can be paid Quarterly and Half Yearly. Premium can also be paid Annual, Biennial (once in 2 years) and Triennial (once in 3 years). Note: Instalment facility is not available for long term (2 year and 3 year) policies</li>
                </>)}

            </ul>
        </GridContainer>
    );}
};
StarAssureBenefits.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
  redirectUrl: PropTypes.string
};
export default withStyles(WellnessBenefitsStyle)(StarAssureBenefits);