import React from "react";
// @material-ui/core components
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import wizardStyle from "ps-assets/jss/wizardStyle.jsx";
// import CustomStepper from "ps-components/CustomStepper/CustomStepperGroup";
// import FormGenerator from "ps-components/FormGenerator/FormGenerator";
import CustomInput from "components/CustomInput/CustomInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import { wizardLMSCall } from "util/lmsRequest";
import {  browserWindow } from "util/localStorageHelper";
import Button from "components/CustomButtons/Button";
import root from "window-or-global";
import { Helmet } from "react-helmet";


const initialState = {
    name: ``,
    mobile: ``,
    email: ``,
    companyName: ``,
    formResponse: null,
    productId: null,
    formArr: null,
    activeStep: 0,
    dndConsent: true,
    website: ``,
    company: ``,
    industry: ``,
    employees: ``,
    urlInfo: ``
};

class AccidentGroup extends React.Component {
    state = initialState;

    handleInputChange = (e) => {
        if ((e.target.id === `name`) || (e.target.id === 'companyName') ) {
            if ((e.target.value[0] != " ") && (e.target.value.search(`^[a-zA-Z. ']*$`) === 0)) {
              this.setState({ [e.target.id]: e.target.value });
            }
          } else if (e.target.id === `mobile`) {
            if (!isNaN(e.target.value) && e.target.value.length <= 10) {
              this.setState({ [e.target.id]: e.target.value });
            }
          }
          else if (e.target.id === `dndConsent`) {
            this.setState({
              dndConsent:this.state.dndConsent
            })
          } else this.setState({ [e.target.id]: e.target.value });
    };

    handleSubmit = async () => {
        try {
          let queryParams = decodeURIComponent(browserWindow.location.search);
          queryParams = queryParams.split(`?`)[1];
          let response = await wizardLMSCall(
            this.state.name,
                this.state.mobile,
                this.state.email,
                this.props.productName,
                this.props.productId,
                this.state.urlInfo,
                null,
                null,
                {
                    keyword: this.state.companyName
                }
          );
          response = await response.json();
          sessionStorage.setItem(`prevLandingPage`, `complete-health-insurance`);
          root.open(
            `${browserWindow.location.pathname}/thank-you?userId=${response.zohoEntityId}${
              queryParams ? `&${queryParams}` : ``
            }`,
            `_self`
          );
        } catch (exception) {
          console.error(`except - `, exception);
        }
      };

    componentDidMount() {
        let name = ``,
            email = ``,
            mobile = ``,
            firstName = ``,
            formResponse = ``;
        let user = localStorage.getItem(`user`);
        let authToken = ``;
        if (user) {
            user = JSON.parse(user);
            authToken = user.token;
            if (authToken) {
                let logOutToken = localStorage.getItem(`loggedOut${authToken}`);
                if (logOutToken) {
                    authToken = ``;
                }
            }
        }
        var base64Url = authToken.split(`.`)[1];
        if (base64Url != undefined) {
            var decodedValue = JSON.parse(browserWindow.atob(base64Url));
            let userObj = decodedValue.jti;
            userObj = JSON.parse(userObj);
            email = userObj.email;
            firstName = userObj.firstName;
            mobile = userObj.mobileNo;
        }
        
        this.setState({
            name: firstName ? firstName : name,
            email: email,
            mobile: mobile,
            formResponse: formResponse,
        });
        if(this.state.urlInfo === ``) {
            this.setState({
              urlInfo:browserWindow.location.href
            })
          }
    }


    render() {
        const { classes } = this.props;

        var userDetails = (
            <div style={{marginTop: '7%'}} className={classes.GMCform} >
               <CustomInput
          autoFocus
          labelText='Your Name'
          id='name'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.formControl
          }}
          inputProps={{
            required: true,
            pattern: `^[a-zA-Z. ']*$`
          }}
          error={this.state.name.search(`^[a-zA-Z. ']*$`) !== 0}
          value={this.state.name}
          onChange={this.handleInputChange}
        />
        {this.state.name.search(`^[a-zA-Z. ']*$`) !== 0 && (
          <FormHelperText id='my-helper-text' error>
            Name is not in the required format
          </FormHelperText>
        )}
                <CustomInput
                    labelText="Mobile Number"
                    id="mobile"
                    formControlProps={{
                        fullWidth: true,
                        required: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        required: true,
                        pattern: `[0-9]{10}`,
                        maxLength: `10`,
                        inputMode: `tel`,
                    }}
                    error={
                        this.state.mobile.length != 10 && this.state.mobile.length != 0
                    }
                    value={this.state.mobile}
                    onChange={this.handleInputChange}
                />
                {this.state.mobile.length != 10 && this.state.mobile.length != 0 && (
                    <FormHelperText id="my-helper-text" error>
                        Mobile Number must be 10 digits{` `}
                    </FormHelperText>
                )}
                <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                        required: true,
                        fullWidth: true,
                    }}
                    inputProps={{
                        required: true,
                        pattern: `[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$`,
                        inputMode: `email`,
                    }}
                    onChange={this.handleInputChange}
                    value={this.state.email}
                    error={
                        this.state.email !== `` &&
                        this.state.email.search(/^\S+@\S+\.\S+$/) !== 0
                    }
                />
                {this.state.email !== `` &&
                    this.state.email.search(/^\S+@\S+\.\S+$/) !== 0 && (
                        <FormHelperText id="my-helper-text" error>
                            Please enter valid email
                        </FormHelperText>
                    )}
                    <CustomInput
                        autoFocus
                        labelText='Company Name'
                        id='companyName'
                        formControlProps={{
                            fullWidth: true,
                            required: true,
                            className: classes.formControl
                        }}
                        inputProps={{
                            required: true,
                            pattern: `^[a-zA-Z. ']*$`
                        }}
                        error={this.state.companyName.search(`^[a-zA-Z. ']*$`) !== 0}
                        value={this.state.companyName}
                        onChange={this.handleInputChange}
                        />
                        {this.state.companyName.search(`^[a-zA-Z. ']*$`) !== 0 && (
                        <FormHelperText id='my-helper-text' error>
                            Company Name is not in the required format
                        </FormHelperText>
                        )}

                {/* <div className={classes.checkboxspan}>
                    <Checkbox
                        id="dndConsent"
                        checked={this.state.dndConsent}
                        onChange={this.handleInputChange}
                        style={{ color: '#35488a', padding: '0px' }}
                    />
                    <span className={classes.authorize}>
                        I authorize Star Health to contact me and I understand that this
                        will override the DND status on my mobile number
            </span>
                </div> */}
                <div className={classes.GMCbutton}>
                    <Button style={{borderRadius: '30px',backgroundColor: '#35488a',marginLeft:'38%'}} onClick={this.handleSubmit}>
                    Get Quote
                    </Button>
                </div>
            </div>
        );

        return (
            <>
            {process.env.ENABLE_HELMET == `true` && (
                <Helmet key='helmetTags'>
                    <link rel='canonical' href={'https://www.starhealth.in/group-health-insurance/'} />
                </Helmet>)}
                <span key={`customStepper`}>
                    {userDetails}
                </span>
            </>
        );
    }
}
AccidentGroup.propTypes = {
    ctaLabel: PropTypes.string,
    classes: PropTypes.object,
    buyNowUrl: PropTypes.string,
    formArr: PropTypes.object,
    query: PropTypes.string,
    planType: PropTypes.string,
    productId: PropTypes.number,
    type: PropTypes.array,
    productName: PropTypes.string,
};
export default withStyles(wizardStyle)(AccidentGroup);
