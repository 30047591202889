import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "components/Accordion/Accordion.jsx";


const table1 = [
    ['1.','Eligibility', '18-65 years'],
    ['2.','Dependent children', '91 days to 25 years'],
    ['3.','Policy term', '1 / 2 years'],
    ['4.','Plan options', 'Silver / Gold plan'],
]
const table1_2 = [
    ['6.','Product type', 'Individual / Floater'],
    ['7.','Installment facility', 'Quarterly / Half-yearly'],
    ['8.','Discounts', '5 percent discount only if the entire two-year premium is paid in advance'],
    ['9.','Renewals', 'Life-long renewal option'],
    ['10.','Pre-insurance medical screening', 'Not required'],
]
const faq=[
    {
        title:'What is the waiting period for the Super Surplus policy?',
        description:<h5>The waiting period for pre-existing diseases under the Super Surplus silver plan is 36 months, and for specific treatments, it is 24 months. The waiting period for pre-existing diseases and specific treatments under the Super Surplus gold plan is 12 months.</h5>,
        content:false
    },
    {
        title:'Can all health policies get top-up with this plan?',
        description:<h5>Yes, all health insurance plans are eligible for a top-up with the Super Surplus policy.</h5>,
        content:true
    },
    {
        title:'What is the entry age limit of this policy?',
        description:
            <h5>People between the ages of 18 and 65 years can take this policy. Dependent children can be covered from 91 days -25 years.</h5>,
        content:false
    },
    {
        title:'Will this plan cover pre-existing diseases?',
        description:
            <h5>Yes, the policy covers pre-existing diseases after a waiting period of 36 months (silver plan) and 12 months (gold plan).</h5>,
        content:false
    },
    {
        title:'Are there any pre-insurance medical screening required to get this plan?',
        description:
            <h5>No, pre-insurance medical screening is not required for the Super Surplus Insurance policy.</h5>,
        content:false
    },
    {
        title:'What is the co-pay for this policy?',
        description:
            <h5>There is no co-pay applicable for the Super Surplus policy.</h5>,
        content:false
    },
    {
        title:'Can I get a top-up plan for my existing Covid Insurance policy?',
        description:
            <h5>Yes, you can get a top-up plan for your Covid Insurance policy. If the claim exceeds the threshold limit, the top-up cover will come into use.</h5>,
        content:false
    },
    ] 

const SuperSurplusHighlights = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });
    return (
      <GridContainer className={classes.containerDisplay}>
        <GridItem
          md={11}
          sm={11}
          xs={11}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.infoArea,
            classes.handleMarginRight
          )}
        >
          <GridItem style={{ paddingLeft: "0px" }}>
            <h2 className={classes.h2hTitle}>
              Super Surplus Insurance Policy / Star Super Surplus (Floater)
              Insurance Policy
            </h2>
          </GridItem>
          <h5>
            “Insurance for Health is Wealth” has become a prominent term in our
            fast-pacing world today. No matter how conscious we are with our
            mind and body, a health emergency may come knocking at any point in
            life. You must ensure that you are well-prepared for such
            circumstances.{" "}
          </h5>
          <h5>
            A health insurance policy helps you to get timely treatment while
            facing an illness/injury. Even if you have a health insurance policy
            cover. At times when your medical bills take a huge spike, a sum
            insured of 5-10 lakhs might not be sufficient. In such cases, a
            top-up health insurance plan works as a supplement to your regular
            health insurance cover. This plan adds additional coverage to your
            existing health insurance policy. In simple words, a top-up health
            insurance plan offers additional protection after your existing
            policy has reached the threshold limit.{" "}
          </h5>
          <GridItem style={{ paddingLeft: "0px" }}>
            <h2 className={classes.h2hTitle}>Top-up Health Insurance Plan</h2>
          </GridItem>
          <h5>
            The Super Surplus Insurance policy by Star Health is a top-up health
            insurance plan providing up to one crore sum insured at an
            affordable premium. It comes with broad protection than that of the
            other basic plans. The policy is available for age group from three
            months to 65 years on both an individual and floater basis.{" "}
          </h5>
          <h5>
            The policy is available in options as Gold and Silver plans. The
            waiting period under this policy is 12 months and 36 months,
            respectively. The policy terms are of one year/2 years. On the
            policy purchase, a lifelong renewability option is available.{" "}
          </h5>
          <GridItem style={{ paddingLeft: "0px" }}>
            <h2 className={classes.h2hTitle}>
              Features of Super Surplus Health Insurance Policy
            </h2>
          </GridItem>
          <div>
            <Paper className={classes.root}>
              <Table
                className={classes.table}
                fixedHeader={false}
                style={{ tableLayout: "auto" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      className={
                        classes.tableCellwidth + ` ` + classes.headCell
                      }
                      // style={{minWidth:100, maxWidth:100}}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      className={
                        classes.tableCellwidth + ` ` + classes.headCell
                      }
                      // style={{minWidth:100, maxWidth:100}}
                    >
                      Subject
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      align="center"
                      className={
                        classes.tableCellwidth + ` ` + classes.headCell
                      }
                    >
                      Criteria
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table1.map((row) => (
                    <TableRow key={row[0]}>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeftcustomWidth}
                      >
                        {row[0]}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeftcustomWidth}
                      >
                        {row[1]}
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={3}
                        className={classes.tableCellLeftcustomWidth}
                      >
                        {row[2]}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      rowSpan={6}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      5.
                    </TableCell>
                    <TableCell
                      rowSpan={3}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      The Company will pay in excess of the deductible limit on
                      every claim under Silver plan
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      <b>Silver</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      <b>Sum insured</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      <b>Deductible limit</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      Individual
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      7 lakhs / 10 lakhs
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      3 lakhs
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      Floater
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      10 in lakhs
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      3 & 5 lakhs
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={3}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      The Company will pay the aggregate of all claims amount in
                      excess of defined limit in the policy year under Gold plan
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      <b>Gold</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      <b>Sum insured</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      <b>Defined limit</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      Individual
                    </TableCell>
                    <TableCell
                      rowSpan={2}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      5 / 10 / 15 / 20 / 25 /50 / 75/ 100 lakhs
                    </TableCell>
                    <TableCell
                      rowSpan={2}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      3 / 5 /10 / 15 / 20 /25 lakhs
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCellLeftcustomWidth}
                    >
                      Floater
                    </TableCell>
                  </TableRow>
                  {table1_2.map((row) => (
                    <TableRow key={row[0]}>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeftcustomWidth}
                      >
                        {row[0]}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeftcustomWidth}
                      >
                        {row[1]}
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={3}
                        className={classes.tableCellLeftcustomWidth}
                      >
                        {row[2]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </div>
          <GridItem style={{ paddingLeft: "0px" }}>
            <h2 className={classes.h2hTitle}>
              Coverage under Super Surplus Insurance Policy / Star Super Surplus
              (Floater) Insurance Policy
            </h2>
          </GridItem>
          <h5>
            The policy offers extensive coverage for both individual and floater
            under two plan options namely Gold and Silver.{" "}
          </h5>
          <h5>This benefit is elaborated as follows:</h5>
          <GridItem style={{ textAlign: "center" }}>
            <h5>
              <b>Individual Plan (Silver)</b>
            </h5>
          </GridItem>
          <GridItem style={{ paddingLeft: "20px" }}>
            <ol>
              <li>
                <b>In-hospitalisation Expenses-</b> Expenses such as room,
                boarding and nursing expenses are subject to a maximum of Rs.
                4000 per day. Other expenses like surgeon’s fee, anaesthetist,
                medical practitioner, consultants, specialist fee, blood,
                oxygen, ICU charges, operation theatre charges, medicines and
                drugs, diagnostic materials and lab tests are also covered.
              </li>
              <li>
                <b>Pre-hospitalisation Expenses-</b> Expenses incurred up to 30
                days before getting hospitalised are covered.
              </li>
              <li>
                <b>Post-hospitalisation Expenses-</b> Expenses incurred up to 60
                days after discharge are covered.
              </li>
              <li>
                <b>Coverage for Modern Treatments -</b> Modern/Advanced
                treatments are covered under the policy for the specific illness
                up to the sub-limit mentioned in the policy clause.
              </li>
            </ol>
          </GridItem>
          <GridItem style={{ textAlign: "center" }}>
            <h5>
              <b>Individual Plan (Gold)</b>
            </h5>
          </GridItem>
          <GridItem style={{ paddingLeft: "20px" }}>
            <ol>
              <li>
                <b>In-hospitalisation Expenses - </b> Expenses like room (Single
                Private AC room), boarding and nursing as provided by the
                hospital are covered. Other expenses like surgeon’s fee,
                anaesthetist, medical practitioner, consultants, specialist fee,
                blood, oxygen, ICU charges, operation theatre charges, medicines
                and drugs, diagnostic materials and lab tests are also covered.
              </li>
              <li>
                <b>Pre-hospitalisation Expenses -</b> Expenses incurred up to 60
                days before getting hospitalised is covered.
              </li>
              <li>
                <b>Post-hospitalisation Expenses-</b> Expenses incurred up to 90
                days after discharge is covered.
              </li>
              <li>
                <b>Coverage for Modern Treatments -</b> Modern/Advanced
                treatments are covered under the policy for the specific illness
                up to the sub-limit mentioned in the policy clause.
              </li>
              <li>
                <b>Emergency ambulance charges -</b> Emergency ambulance charges
                up to Rs. Three thousand per policy period for the
                transportation of the insured to the hospital is applicable.
              </li>
              <li>
                <b>Air ambulance charges -</b> Air ambulance expenses are
                covered up to 10 percent of the sum insured per policy period
                (only applicable for sum insured Rs. 700000 and above).
              </li>
            </ol>
          </GridItem>
          <GridItem style={{ textAlign: "center" }}>
            <h5>
              <b>Floater Plan (Silver)</b>
            </h5>
          </GridItem>
          <GridItem style={{ paddingLeft: "20px" }}>
            <ol>
              <li>
                <b>In-hospitalisation Expenses -</b> Room, boarding and nursing
                expenses are subject to a maximum of Rs. 4000 per day. Other
                expenses like surgeon’s fee, anaesthetist, medical practitioner,
                consultants, specialist fee, blood, oxygen, ICU charges,
                operation theatre charges, medicines and drugs, diagnostic
                materials and lab tests are also covered.
              </li>
              <li>
                <b>Pre-hospitalisation Expenses-</b> Expenses incurred up to 30
                days before getting hospitalised are covered.
              </li>
              <li>
                <b>Post-hospitalisation Expenses-</b> Expenses incurred up to 60
                days after discharge are covered.
              </li>
              <li>
                <b>Coverage for Modern Treatments -</b> Modern/Advanced
                treatments are covered under the policy for the specific illness
                up to the sub-limit mentioned in the policy clause.
              </li>
            </ol>
          </GridItem>
          <GridItem style={{ textAlign: "center" }}>
            <h5>
              <b>Floater Plan (Gold)</b>
            </h5>
          </GridItem>
          <GridItem style={{ paddingLeft: "20px" }}>
            <ol>
              <li>
                <b>In-hospitalisation Expenses - </b> Expenses like room (Single
                Private AC room), boarding and nursing as provided by the
                hospital are covered. Other expenses like surgeon’s fee,
                anaesthetist, medical practitioner, consultants, specialist fee,
                blood, oxygen, ICU charges, operation theatre charges, medicines
                and drugs, diagnostic materials and lab tests are also covered.
              </li>
              <li>
                <b>Pre-hospitalisation Expenses -</b> Expenses incurred up to 60
                days before getting hospitalised is covered.
              </li>
              <li>
                <b>Post-hospitalisation Expenses-</b> Expenses incurred up to 90
                days after discharge is covered.
              </li>
              <li>
                <b>Coverage for Modern Treatments -</b> Modern/Advanced
                treatments are covered under the policy for the specific illness
                up to the sub-limit mentioned in the policy clause.
              </li>
              <li>
                <b>Emergency ambulance charges -</b> Emergency ambulance charges
                up to Rs. Three thousand per policy period for the
                transportation of the insured to the hospital is applicable.
              </li>
              <li>
                <b>Air ambulance charges -</b> Air ambulance expenses are
                covered up to 10 percent of the sum insured per policy period
                (only applicable for sum insured Rs. 1000000 and above).
              </li>
            </ol>
          </GridItem>
          <h2 className={classes.h2hTitle}>
            Why should you buy a top-up health insurance plan?
          </h2>
          <h5>
            Health insurance specialist says the top-up plan is a must to have.
            For instance, the current pandemic has caused a major financial
            hardship during hospitalisation. However, buying a top-up plan is
            much better than exceeding your existing basic health insurance
            cover at a nominal cost. Top-up health insurance plans are crafted
            with an idea to provide an additional cover in case your existing
            policy gets exhausted.{" "}
          </h5>
          <h2 className={classes.h2hTitle}>
            What are the inclusions and exclusions of the policy?
          </h2>
          <h5>
            The Super Surplus insurance policy comes with certain inclusions
            (covered) and exclusions (not covered). They are as follows:
          </h5>
          <h5>
            <b>Inclusions</b>
          </h5>
          <GridItem style={{ paddingLeft: "20px" }}>
            <ol>
              <li>
                The Super Surplus Gold plan offers coverage for maternity up to
                specified limits.
              </li>
              <li>
                Provides air ambulance cover for up to 10% of the sum insured
                for more than Rs. 7 lakhs (individual) and 10 lakh (floater).
              </li>
              <li>Coverage for organ donor expenses.</li>
              <li>Coverage for inpatient hospitalisation expenses.</li>
              <li>Pre- and post-hospitalisation expenses are also covered.</li>
              <li>Cover for all day-care procedures or treatments (under gold plan).</li>
              <li>
                Coverage for pre-existing diseases under the Super Surplus
                silver plan is 36 months, and specific treatments have 24 months
                waiting period.
              </li>
              <li>
                Coverage for pre-existing diseases under the Super Surplus gold
                plan is 12 months, and specific treatments have 12 months
                waiting period.
              </li>
            </ol>
          </GridItem>
          <h5>
            <b>Exclusions</b>
          </h5>
          <h5>
            The following is a partial listing of policy exclusions. A detailed
            list of all exclusions is included in the policy document.
          </h5>
          <GridItem style={{ paddingLeft: "20px" }}>
            <ol>
              <li>
                Any ailment/injury caused due to war, civil war, biological war,
                etc.
              </li>
              <li>Self-intentional injuries like suicide attempts.</li>
              <li>Costs of Walkers / Wheelchairs.</li>
              <li>Expenses related to treatment for weight loss surgeries.</li>
              <li>
                Cosmetic or plastic surgery expenses unless subjected to an
                accident.
              </li>
              <li>
                Expenses related to injuries caused by hazardous sports or
                adventurous activities.
              </li>
              <li>
                Expenses related to change of gender treatments and sexually
                transmitted diseases.
              </li>
            </ol>
          </GridItem>
          <GridItem style={{ paddingLeft: "0px" }}>
            <h2 className={classes.h2hTitle}>
              What are the add-on benefits of the Super Surplus policy?
            </h2>
          </GridItem>
          <ol>
            <li>
              <h5>
                <b>Additional coverage</b>
              </h5>
              <h5>
                The Super Surplus Insurance policy acts as a top-up plan that
                offers additional coverage when your basic health plan gets
                exhausted. The plan comes with a higher sum insured at an
                affordable premium.
              </h5>
            </li>
            <li>
              <h5>
                <b>No pre-insurance medical screening</b>
              </h5>
              <h5>
                Generally, while purchasing a health insurance policy, a
                pre-medical examination is requested by the insurer (health
                insurance company) before the policy is provided to the person.
                The Super Surplus policy doesn’t require any pre-insurance
                medical screening.
              </h5>
            </li>
            <li>
              <h5>
                <b>Free E-medical consultation</b>
              </h5>
              <h5>
                The exclusive feature “Talk to Star” offered by Star Health is a
                free consultation line for customers all over India. You can get
                in touch with our in-house doctors for a free medical
                consultation over the phone regarding your health conditions.
              </h5>
            </li>
            <li>
              <h5>
                <b>Tax Benefits</b>
              </h5>
              <h5>
                Under Super Surplus, the premium paid by any mode other than
                cash is eligible for tax benefit under Section 80D of the Income
                Tax Act 1961.
              </h5>
            </li>
            <li>
              <h5>
                <b>Free-look period</b>
              </h5>
              <h5>
                The policy offers a free-look period of 15 days from the date of
                receipt of the policy. However, this feature is not applicable
                for portability, migration and renewals.
              </h5>
            </li>
          </ol>
          <h5>
            <b>How to register a claim for the Super Surplus Insurance plan?</b>
          </h5>
          <h5>
            Star Health offers hassle-free claim settlement for all its
            customers. There are two ways through which you can file a claim at
            your convenience.
          </h5>
          <GridItem style={{ paddingLeft: "0px" }}>
            <h2 className={classes.h2hTitle}>
              Frequently Asked Questions
            </h2>
          </GridItem>
          <div>
            <div className={classes.section}>
              <div className={classes.container}>
                <GridContainer
                  className={`${classes.container} ${classes.textCenter}`}
                >
                  <GridItem
                    md={12}
                    style={{ paddingLeft: "0px" }}
                    className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                  ></GridItem>
                  <GridItem
                    style={{ paddingLeft: "0px" }}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Accordion active={0} collapses={AccordionArray} />
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          {process.env.ENABLE_HELMET == `true` && (
            <Helmet>
              <meta
                name="title"
                content="Top-Up Health Insurance Plans, Super Surplus Medical Policy | Star Health"
              />
            </Helmet>
          )}
        </GridItem>
        {/* {process.env.ENABLE_HELMET == `true` && (
    <Helmet>
<script type="application/ld+json">
{`{ "@context": "http://schema.org",
  "@type": "Product",
  "name": "Super Surplus Insurance Policy",
  "description": "Buy Star Super Surplus Health Insurance Policy that provides medical expenses incurred during hospitalization. Click to Explore Top Up Plans, Individual and Floater Basis Plans.",
"offers":{ 
          "@type": "AggregateOffer",
          "offerCount": "5%"
        }

}`}
</script>
</Helmet>)}  */}
      </GridContainer>
    );
};
SuperSurplusHighlights.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    pageContext: PropTypes.object,
  };
export default withStyles(WellnessBenefitsStyle)(SuperSurplusHighlights);