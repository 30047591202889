import React from 'react';
import { graphql } from 'gatsby';
import Wizard from 'ps-components/Wizard/Wizard';
import Accident from 'ps-components/Wizard/AccidentInd';
import CancerCare from 'ps-components/Wizard/CancerCare';
import Healthpremier from 'ps-components/Wizard/StarHealthpremier';
import StarAssure from 'ps-components/Wizard/StarAssure';
import Extraprotect from 'ps-components/Wizard/Extraprotect';
import YoungStarExtraProtectAddon from 'ps-components/Wizard/YoungStarExtraProtectAddon';
import accident_classic from 'forms/react-form-accident/basic_accidentInd';
import payloadAccidentInd from 'forms/react-form-accident/payloadAccidentInd';
import payloadhealthpremier from 'forms/react-form-accident/payloadhealthpremier';
import payloadstarassure from 'forms/react-form-accident/payloadstarassure'
import saralBhima from 'forms/react-form-accident/saral_bhima';
import ComprehensiveWellnessBenefits from './ComprehensiveWellnessBenefits';
import YoungStarWellnessBenefits from './YoungStarWellnessBenefits';
import AssureWellnessBenefits from './AssureWellnessBenefits';
import CriticalillnessWellnessBenefits from './criticalillnessWellnessBenefits';
import payloadSaralBhima from 'forms/react-form-accident/payloadSaralBhima';
import payloadCancerCare from 'forms/react-form-accident/payloadCancerCare';
import PropTypes from 'prop-types';
import AccidentcareprePageSEO from './seo/AccidentcareprePageSEO';
import CallComponent from "components/CallComponent";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Hidden from '@material-ui/core/Hidden';
import productListStyle from 'ps-assets/jss/productListStyle.jsx';
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles';
import 'assets/scss/material-kit-pro-react.scss?v=1.3.0';
import GetApp from '@material-ui/icons/GetApp';
import breadcrumbsjson from 'jsons/breadcrumbs'
import { browserDocument, browserWindow, browserLocalStorage } from 'util/localStorageHelper';
// react component for creating beautiful carousel
import Carousel from "react-slick";
import VerticalCard from "ps-components/Card/VerticalCard.jsx";
import Verticalcard1 from 'ps-components/Card/verticalcard1';
import Accordion from "components/Accordion/Accordion.jsx";
import classNames from "classnames";
import NavPills from "components/NavPills/NavPills.jsx";
import Layout from "ps-components/Layout/Layout";
import FormatQuote from "@material-ui/icons/FormatQuote";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import MUIDataTable from "mui-datatables";
import { Helmet } from "react-helmet";
import Markdown from "markdown-to-jsx";
import ConversationalQuote from "templates/ConversationalQuote";
import accidentData from "forms/accident/family-accident-care.json";
import arogyaSanjeevaniJson from "forms/health/aroghya-sanjeevani-new.json";
import cancerCareJson from "forms/health/cancercare.json";
import healthpremierJson from "forms/health/healthpremier.json"
import starAssureJson from "forms/health/starAssureJson.json"
import familyHealthOptima from "forms/health/star-family-health-optima.json";
import coronaKavachJson from "forms/health/corona-kavach.json";
import groupAccident from "forms/accident/group-accident-care.json";
import groupHealth from "forms/health/group-health-care.json";
import superSurplus from "forms/health/star-super-surplus.json"
import AccidentGroup from "../ps-components/Wizard/AccidentGroup"
import GroupHealthInsurance from "../ps-components/Wizard/GroupHealthInsurance"
// import CoronaRakshackHighlights from './seo/CoronaRakshackHighlights';
// import CoronaKavachHighlights from './seo/CoronaKavachHighlights';
import ComprehensiveHighlights from './seo/ComprehensiveHighlights';
import ArogyaSanjeeviniHighlights from './seo/ArogyaSanjeeviniHighlights';
import MediclassicHighlights from './seo/MediclassicHighlights';
import OutpatientcareHighlights from './seo/OutpatientcareHighlights';
import YoungstarHighlights from './seo/YoungstarHighlights';
import HospitalCashHighlights from './seo/HospitalCashHighlights';
import MicroRuralFarmersHighlights from './seo/MicroRuralFarmersHighlights';
import SeniorCitizenHighlights from './seo/SeniorCitizenHighlights';
import SuperSurplusHighlights from './seo/SuperSurplusHighlights';
import CriticcareplusHighlights from './seo/CriticcareplusHighlights';
import CardiaccareHighlights from './seo/CardiaccareHighlights';
import DiabetrssafeHighlights from './seo/DiabetrssafeHighlights';
import FamilyhealthoptimaHighlights from './seo/FamilyhealthoptimaHighlights';
import Groupmediclaimhighlights from './seo/Groupmediclaimhighlights';
import CancercareHighlights from './seo/CancercareHighlights';
import WomencareHighlights from './seo/WomencareHighlights';
// import HealthgainHighlights from './seo/HealthgainHighlights';
import PremierInsuranceHighlights from './seo/PremierInsuranceHighlights';
import AssureInsuranceHighlights from './seo/AssureInsuranceHighlights';
import CriticalillnessBenefits from './seo/CriticalillnessBenefits';
import CorporatetravelBenefits from './seo/CorporatetravelBenefits';
import StarAssureBenefits from './seo/StarAssureBenefits';
import Womencarebenefits from './seo/Womencarebenefits';
import HealthPremierWellnessBenefits from './HealthPremierWellnessBenefits';
import WomencareWellnessBenefits from './WomencareWellnessBenefits';
import ExtraprotectBenefits from './seo/ExtraprotectBenefits';
import YoungStarExtraProtectAddonBenifits from './seo/YoungStarExtraProtectAddonBenifits';
import InstalmentFacility from './seo/InstalmentFacility';
import { sevaApp } from 'util/LayoutUtil.jsx';
import Button from "components/CustomButtons/Button";
import { Link } from "gatsby";
const HOSPITALCOUNT = process.env.HOSPITALCOUNT;
const STRHOSPITALCOUNT = process.env.STRHOSPITALCOUNT;
const CUSTOMERPORTAL_URL = process.env.CUSTOMERPORTAL_URL
const searchRegExp = /<networkHospitalCount \/>/g
const stringSearchRegExp = /<stringNetworkHospitalCount \/>/g
const FHOP_productId = 3;

const SCIP_productId = 4;

const SCRCIP_productId = 7;

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
        // initialSlide: 0
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const settings1 = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true
};

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: `classic`,
      enableConversation: false,
      isAgent: this.props.location.hash === `#agent`,
      instalment: false,
      redirectUrl: '',
      forallpolicies: true
         };
  }
  
  addLdScript=(script)=>{
    let ldjsonScript = browserDocument.createElement(`script`)
    ldjsonScript.type=`application/ld+json`
    ldjsonScript.id=`ld-json-script/bradcrumbs`
    ldjsonScript.innerHTML = `${JSON.stringify(script.content)}`;
    if(!browserDocument.getElementById('ld-json-script') && script.content){
      browserDocument.body.appendChild(ldjsonScript);
    }
  }

  removeLdScript=()=>{
    const ldjsonScript = browserDocument.getElementById(`ld-json-script/bradcrumbs`)
    if(ldjsonScript){
      ldjsonScript.remove()
    }
  }

  handleChoiceToggle = () => {
    const type =
      this.state.choice === `conversational` ? `classic` : `conversational`;
    this.setState({ choice: type });
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search)
    if(queryParams.has('sevaapp') && queryParams.get('sevaapp') == 'true'){
      sessionStorage.seva= true
      // const nonMobileElements = browserDocument.querySelectorAll('[sevaapp="true"]')
      
    }
    let params = new URLSearchParams(browserDocument.location.search)
    let redirectUrl 
    if(params.get("redirectPath") == 'bancs/renewal')
    {
      redirectUrl = `bancs/renewal?policyQNo=${params.get("policyQNo")}`
    }  
    if (params.get("redirectPath") == 'renewal') {
      redirectUrl = `renewal?sysId=${params.get("sysId")}`
    }
    let user = browserLocalStorage.getItem(`user`);
    if (user) {
        user = JSON.parse(user);
        const authToken = user.token;
        redirectUrl += `&token=${authToken}`
    }

    if(params.get("title") == "renewal")
    {
      this.setState({ 
        instalment: true,
        redirectUrl: redirectUrl
      });
      var benefitsButton = "//h2[text()='Benefits']";
      benefitsButton && browserDocument.evaluate(benefitsButton, browserDocument, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue.click();
      setTimeout(
        function() {
          var instalmentElement = "//strong[contains(text(),'Instalment Facility')] | //b[contains(text(),'Instalment Facility')]";
          var instalmentPosition = instalmentElement && browserDocument.evaluate(instalmentElement, browserDocument, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
          var rect = instalmentPosition.getBoundingClientRect();
          browserWindow.scrollTo(0, rect.top);
      }, 500);
    }
    const { data } = this.props;
    if (
      data.strapiInsuranceplans.productId === FHOP_productId ||
      data.strapiInsuranceplans.productId === SCIP_productId ||
      data.strapiInsuranceplans.productId === SCRCIP_productId 
    ) {
      this.setState({ enableConversation: true });
    }
    this.addLdScript({type: 'ld/json', content: breadcrumbsjson[data.strapiInsuranceplans.productId] })

    // let queryParams = decodeURIComponent(browserWindow.location.search);
    // if(queryParams){
    //   queryParams = queryParams && queryParams.split(`?`)[1];
    //   queryParams = queryParams && queryParams.split(`&`);
    //   if(queryParams[0] == "title=renewal"){
    //     let redirectUrl = queryParams[1].split(`=`)[0]
    //     this.setState({ 
    //       instalment: true,
    //       redirectUrl: redirectUrl
    //      });
    //   }
    // }
    const ids = [1,2,3,4,11,22,25,26,33,34,37,42]
    ids && ids.map((items) => {
    if (data.strapiInsuranceplans.productId == items){
      this.setState({ 
        forallpolicies: false,
      });
        }
    });
  }

  componentWillUnmount() {
    this.removeLdScript()
  }

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true
      },
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: `red`
          },
          paper: {
            boxShadow: `none`,
            paddingBottom: `20px`
          },
          responsiveScroll: { maxHeight: `auto !important` }
        },
        MuiToolbar: {
          root: {
            display: `none !important`
          }
        },
        MuiTableCell: {
          root: {
            padding: `35px 0px 10px 28px !important`
          },
          head: {
            fontWeight: `bold !important`,
            fontSize: `0.9em`
          },
          body: {
            fontSize: `0.9em`,
            fontWeight: `300`
          }
        },
        MuiTableSortLabel: {
          icon: {
            marginRight: `0px`
          }
        },
        MUIDataTableBodyCell: {
          root: {
            width: `${
              this.props.data.strapiInsuranceplans.name.includes(`Travel`) ||
              this.props.data.strapiInsuranceplans.name.includes(`Cash`)
                ? `auto`
                : this.props.data.strapiInsuranceplans.name ===
                  `Star Health Gain Insurance Policy`
                ? `20%`
                : `50%`
            }`,
            paddingRight: `20px`
          }
        }
      }
    });

  getTables(documents, options) {
    return documents.tables.map((ele, idx) => {
      var sortedTable =
        ele.tablerows &&
        ele.tablerows.sort((a, b) => {
          return a.sequence - b.sequence;
        });
      var heading = sortedTable[0].tablecolumns
        .sort((a, b) => {
          if (a.sequence && b.sequence) return a.sequence - b.sequence;
        })
        .map(data => {
          return data.details;
        });
      var tablerows = sortedTable.slice(1);
      let tableHead = [],
        tableData = [],
        tableTitle = ele.title;

      tablerows.map(item => {
        if (item.isHeader) {
          item.tablecolumns
            .sort((a, b) => {
              if (a.sequence && b.sequence) return a.sequence - b.sequence;
            })
            .map(data => {
              if (data.details) tableHead.push(data.details);
              else tableHead.push(``);
            });
        } else {
          var dataArr = item.tablecolumns
            .sort((a, b) => {
              if (a.sequence && b.sequence) return a.sequence - b.sequence;
            })
            .map(data => (data.details ? data.details.trim() : ``));
          tableData.push(dataArr);
        }
      });
      return (
        <div key={idx}>
          <GridContainer item md={12} lg={12}>
            <GridItem md={12} lg={12} xs={12} sm={12}>
              <h4
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {tableTitle}
              </h4>
              <div style={{ maxWidth: `100 % `, whiteSpace: `pre-wrap` }}>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <MUIDataTable
                    title={
                      tableHead.length > 0
                        ? heading.length > 0
                          ? heading
                          : []
                        : []
                    }
                    data={tableData.length > 0 ? tableData : [tableHead]}
                    columns={
                      tableData.length > 0
                        ? tableHead.length > 0
                          ? tableHead
                          : heading
                        : [``, ``, ``, ``, ``]
                    }
                    options={options}
                  />
                </MuiThemeProvider>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      );
    });
  }

  render() {
    const { data, classes, location, pageContext } = this.props;
    const { isAgent } = this.state;
    const options = {
      selectableRows: false,
      sort: false,
      filter: false,
      search: false,
      download: false,
      print: false,
      viewColumns: false,
      responsive: `scroll`,
      pagination: false,
      textLabels: {
        body: {
          noMatch: ``
        }
      }
    };

    // let url = process.env.BASE_URL;
    let urlold = process.env.BASE_URL_OLD;
    const cart_images = data.allStrapiEntities.edges;
    var brochureUrl = [],
      typeArr = [];
    let details;
    // let urlPath = data.strapiInsuranceplans.urlPath;
    switch(data.strapiInsuranceplans.productId){
      case 26: details = arogyaSanjeevaniJson;break;
      case 29: details = coronaKavachJson;break;
      case 31: details = accidentData; break;
      case 34: details = cancerCareJson; break;
      case 8: details = superSurplus; break;
      case 3: details = familyHealthOptima; break
      case 222: details = groupAccident; break;
      case 38: details = groupHealth; break;
      case 42: details = healthpremierJson; break;
      case 43: details = starAssureJson; break;
      default: details = (data.strapiInsuranceplans.forms[0] &&
        data.strapiInsuranceplans.forms[0].details &&
        JSON.parse(
          JSON.stringify(data.strapiInsuranceplans.forms[0].details)
        )) ||
      null;
      break;
    };
    let Highlights;
    switch(data.strapiInsuranceplans.productId){
      // case 28: Highlights = <CoronaRakshackHighlights />;break;
      // case 29: Highlights = <CoronaKavachHighlights />;break;
      case 4: Highlights = <ComprehensiveHighlights />;break;
      case 26: Highlights = <ArogyaSanjeeviniHighlights />;break;
      case 2: Highlights = <MediclassicHighlights/>;break;
      case 23: Highlights = <OutpatientcareHighlights/>;break;
      case 25: Highlights = <YoungstarHighlights/>;break;
      case 5: Highlights = <HospitalCashHighlights/>;break;
      case 6: Highlights = <MicroRuralFarmersHighlights/>;break;
      case 7: Highlights = <SeniorCitizenHighlights/>;break;
      case 8: Highlights = <SuperSurplusHighlights/>;break;
      case 11: Highlights = <CardiaccareHighlights/>;break;
      case 14: Highlights = <DiabetrssafeHighlights/>;break;
      case 3: Highlights = <FamilyhealthoptimaHighlights/>;break;
      case 10: Highlights = <CriticcareplusHighlights/>; break;
      case 8445: Highlights = <CancercareHighlights/>; break;
      case 41: Highlights = <WomencareHighlights/> ; break;
      // case 9: Highlights = <HealthgainHighlights/> ; break;
      case 43: Highlights= <AssureInsuranceHighlights/> ; break;
      case 42: Highlights= <PremierInsuranceHighlights/> ; break;
      default: Highlights = '';
      break;
    };
    let WellnessBenefits;
    switch(data.strapiInsuranceplans.productId){
      case 4:WellnessBenefits = <ComprehensiveWellnessBenefits />;break;
      case 43:WellnessBenefits = <AssureWellnessBenefits />;break;
      case 40: WellnessBenefits = <CriticalillnessWellnessBenefits/>; break;
      case 42: WellnessBenefits = <HealthPremierWellnessBenefits/>;break;
      case 41: WellnessBenefits = <WomencareWellnessBenefits/>; break;
      default:WellnessBenefits = <YoungStarWellnessBenefits />
        break;
    };
    var colorCode =
      data.strapiInsuranceplans.insuranceplancategory &&
      data.strapiInsuranceplans.insuranceplancategory.colorCode;
    var category =
      data.strapiInsuranceplans.insuranceplancategory &&
      data.strapiInsuranceplans.insuranceplancategory.category;
    var imageArr =
      category &&
      data.allStrapiInsuranceplancategories.edges.filter(document => {
        if (document.node.category === category) return true;
      });
    var bannerImage2 = data.strapiInsuranceplans.isVisiblelist ? 
    data.strapiInsuranceplans.cartImages3.childImageSharp.fluid : 
      imageArr &&
      imageArr.length > 0 &&
      imageArr[0].node.bannerImage2.childImageSharp.fluid;
    // Grouping cards in downloads section of Star Super Surplus (productID:8) and Student car (productId:16)
    var reOrderArray = [];
    switch (data.strapiInsuranceplans.productId) {
      case 8:
        data.strapiInsuranceplans.documents.map(data => {
          if (
            !data.link[0].url.toLowerCase().includes(`individual`) &&
            !data.link[0].url.toLowerCase().includes(`floater`)
          )
            reOrderArray.push(data);
        });
        data.strapiInsuranceplans.documents.map(data => {
          if (data.link[0].url.toLowerCase().includes(`individual`))
            reOrderArray.push(data);
        });
        data.strapiInsuranceplans.documents.map(data => {
          if (data.link[0].url.toLowerCase().includes(`floater`))
            reOrderArray.push(data);
        });
        data.strapiInsuranceplans.documents = reOrderArray;
        break;
      case 16:
        data.strapiInsuranceplans.documents.map(data => {
          if (
            data.link[0].url.toLowerCase().includes(`school`) &&
            data.link[0].url.toLowerCase().includes(`college`)
          ) {
            reOrderArray.push(data);
          }
        });
        data.strapiInsuranceplans.documents.map(data => {
          if (
            data.link[0].url.toLowerCase().includes(`school`) &&
            !data.link[0].url.toLowerCase().includes(`college`)
          ) {
            reOrderArray.push(data);
          }
        });
        data.strapiInsuranceplans.documents.map(data => {
          if (
            data.link[0].url.toLowerCase().includes(`college`) &&
            !data.link[0].url.toLowerCase().includes(`school`)
          ) {
            reOrderArray.push(data);
          }
        });
        data.strapiInsuranceplans.documents = reOrderArray;
        break;
    }
    var downloads =
      data.strapiInsuranceplans.documents &&
      data.strapiInsuranceplans.documents
        .filter(res => {
          if (
            res.Category === `brochures` ||
            res.Category === `proposal` ||
            res.Category === `policy_clause` ||
            res.Category === `prospectus`
          ) {
            return true;
          }
        })
        .map((res, index) => {
          var tabContent = [];
          let formatted_category = res && res.Category && res.Category.split(`_`)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(` `);
          if (res.Category === `brochures`) {
            typeArr.push(res.type);
            brochureUrl.push(res.link[0].url);
          }
          if (res.type)
            formatted_category = res.type + ` ` + formatted_category;
          tabContent.push(
            <GridItem md={3} sm={4} xs={12} key={`download-` + index}>
              <a
                href={res.link[0].url}
                target='_blank'
                rel='noopener noreferrer'>
                <Card className={classes.card}>
                  <CardBody style={{ textAlign: `center` }}>
                    <GetApp />
                    <br />
                    {formatted_category}
                  </CardBody>
                </Card>
              </a>
            </GridItem>
          );
          return tabContent;
        });
    var navPillsortedArray=[]
    switch(data.strapiInsuranceplans.productId){
      case 4:
      case 40:
      case 43:
      case 25:
      case 41:
      case 42:
        navPillsortedArray=data.strapiInsuranceplans.insuranceplansections.sort((a, b) => {
          if (a.Sequence && b.Sequence) return a.Sequence - b.Sequence;
        });
        break;
      default:
        navPillsortedArray=data.strapiInsuranceplans.insuranceplansections;
        break;
    }
    var navPillArr = navPillsortedArray.map(
      document => {
        var obj = {};
        obj.tabButton = document.category.includes("_") ? document.category.replace("_", " ") : document.category;
        var arr = data.allStrapiImages.edges.filter(item => {
          if (
            item.node.category ===
            `plan_` + document.category.replace(` `, `_`).toLowerCase()
          )
            return item;
        });
        var imageUrl =
          arr.length > 0 &&
          arr[0].node.content &&
          arr[0].node.content.publicURL;
        if (imageUrl) obj.tabImage = imageUrl;
        else obj.tabImage = imageUrl;
        if (document.category === `Highlights`) {
          obj.tabContent = (
            <div
              key={document.category}
              className={`${classes.main} ${classes.mainRaised}`}
              style={{
                marginTop: `60px`,
                background: `transparent`,
                boxShadow: `none`
              }}>
              <h2
                style={{
                  textAlign: `center`,
                  color: `rgba(68, 68, 68, 0.8)`,
                  fontSize: '20px'
                }}>
                {data.strapiInsuranceplans.irdaiId &&
                  data.strapiInsuranceplans.irdaiId.split(`\\n`).map(data => {
                    return (
                      <>
                      {/* {data == 'IRDAI UIN:  SHAHLIP23037V072223' ? <>
                      <div>
                        <h5 style={{fontSize: '1.25rem',textAlign: 'left', marginTop: '-5%'}} >Rationale for revision in price - <u><a target="blank" href="/sites/default/files/Rationale-for-revision-in-price.pdf" >click here</a></u>  </h5>
                        </div>
                       </> : <> </>} */}
                        {data}
                        <br />
                      </>
                    );
                  })}
              </h2>
              {data.strapiInsuranceplans.productId == 8 && (
                <h3 style={{ textAlign: `center` }}>
                  Below are the highlights for Gold Plan
                </h3>
              )}
              <GridContainer key={document.category}>
                {document.insuranceplansectiondetails.map((ele, index) => {
                  var imageObj = data.allStrapiInsuranceplansectiondetails.edges.filter(
                    item => {
                      return item.node.title === ele.title;
                    }
                  );
                  let imageUrl =
                    imageObj &&
                    imageObj.length > 0 &&
                    imageObj[0].node.images &&
                    imageObj[0].node.images.publicURL;
                  return (
                    <GridItem
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ marginLeft: `auto`, marginRight: `auto` }}
                      key={`${document.category}-${index}`}>
                        {(ele.title == 'Benefits available' || ele.title == 'Who can avail' || ele.title == "Sum Insured Options SHIPIP" || ele.title == "Sum Insured Options SHAP") ? (
                          <Verticalcard1
                          title= {(ele.title == 'Sum Insured Options SHIPIP' || ele.title == 'Sum Insured Options SHAP') ? 'Sum Insured Options' : ele.title}
                          image={
                            <span>
                              <a
                                rel='noopener noreferrer'
                                href='#pablito'
                                onClick={e => e.preventDefault()}>
                              <img
                                  src={imageUrl}
                                  width='50'
                                  height='50'
                                  style={{ boxShadow: `none` , marginTop: '-40%'}}
                                />
                              </a>
                              <div
                                style={{
                                  backgroundImage: `url(${imageUrl})`,
                                  opacity: `1`
                                }}
                              />
                            </span>
                          }
                          description={ele.summary}
                          noMarginHeader
                          alignItems='center'
                          onHoverEffect
                          style={{
                            marginTop: `5px`,
                            marginBottom: `0px`,
                            boxShadow: `none`
                          }}
                          height='187px'
                          wrap={false}
                        />) :
                        
                      <>
                      {(ele.title !== 'Benefits available' || ele.title !== 'Who can avail' || ele.title !== "Sum Insured Options SHIPIP" || ele.title !== "Sum Insured Options SHAP") && 
                      (<VerticalCard
                        title={ele.title}
                        image={
                          <span>
                            <a
                              rel='noopener noreferrer'
                              href='#pablito'
                              onClick={e => e.preventDefault()}>
                              {ele.title == 'Indemnity cover' ? 
                              <img
                              src={imageUrl}
                              width='40'
                              height='40'
                              style={{ boxShadow: `none` }}
                            />
                            :
                            <img
                                src={imageUrl}
                                width='50'
                                height='50'
                                style={{ boxShadow: `none` }}
                              />
                              }
                              {/* <img
                                src={imageUrl}
                                width='50'
                                height='50'
                                style={{ boxShadow: `none` }}
                              /> */}
                            </a>
                            <div
                              style={{
                                backgroundImage: `url(${imageUrl})`,
                                opacity: `1`
                              }}
                            />
                          </span>
                        }
                        description={ele.summary}
                        noMarginHeader
                        alignItems='center'
                        onHoverEffect
                        style={{
                          marginTop: `15px`,
                          marginBottom: `15px`,
                          boxShadow: `none`
                        }}
                        height='100px'
                        wrap={false}
                      />)}
                      </>}
                        {/* {(ele.title == "Sum Insured Options SHIPIP" || ele.title == "Sum Insured Options SHAP") && (
                        <Verticalcard1
                        title={'Sum Insured Options'}
                        image={
                          <span>
                            <a
                              rel='noopener noreferrer'
                              href='#pablito'
                              onClick={e => e.preventDefault()}>
                            <img
                                src={imageUrl}
                                width='50'
                                height='50'
                                style={{ boxShadow: `none` , marginTop: '-40%'}}
                              />
                            </a>
                            <div
                              style={{
                                backgroundImage: `url(${imageUrl})`,
                                opacity: `1`
                              }}
                            />
                          </span>
                        }
                        description={ele.summary}
                        noMarginHeader
                        alignItems='center'
                        onHoverEffect
                        style={{
                          marginTop: `15px`,
                          marginBottom: `15px`,
                          boxShadow: `none`
                        }}
                        height='147px'
                        wrap={false}
                      />)} */}
                    </GridItem>
                  );
                })}
                {Highlights}
              </GridContainer>
            </div>
          );
        }else if (document.category === `Wellness_Benefits`) {
          obj.tabContent = (
            <div
              key={document.category}
              className={`${classes.main} ${classes.mainRaised}`}
              style={{
                marginTop: `60px`,
                background: `transparent`,
                boxShadow: `none`
              }}>
              <GridContainer key={document.category}>
                {WellnessBenefits}
                {/* {data.strapiInsuranceplans.productId == 4 ? <ComprehensiveWellnessBenefits /> :  <YoungStarWellnessBenefits /> } */}
              </GridContainer>
            </div>
          );
        } else if (data.strapiInsuranceplans.productId == 222) {
          obj.tabContent = (
            <div>
              <h2
                style={{
                  textAlign: `center`,
                  color: `rgba(68, 68, 68, 0.8)`,
                  fontSize: '20px'
                }}>
                {data.strapiInsuranceplans.irdaiId &&
                  data.strapiInsuranceplans.irdaiId.split(`\\n`).map(data => {
                    return (
                      <>
                        {data}
                        <br />
                      </>
                    );
                  })}
              </h2>
              <div
                key={document.category}
                className={`${classes.customListFlexFree} ${classes.main} ${classes.mainRaised}`}
                style={{ boxShadow: `none` }}>
                <div
                  classes={classes.container}
                  style={{
                    paddingBottom: `40px`,
                    paddingTop: `40px`,
                    maxWidth: `970px`,
                    marginLeft: `auto`,
                    marginRight: `auto`,
                    paddingLeft: `15px`,
                    paddingRight: `15px`
                  }}>
                  {document.insuranceplansectiondetails.map((ele, index) => {
                    return (
                      <div key={`${document.category}-${index}`}>
                        <div className={classes.flexItem}>
                          <div className={classes.markdownContainer}>
                            {ele.description != null && (
                              <Markdown>{ele.description}</Markdown>
                            )}
                          </div>
                        </div>
                        {ele.tables.length != 0 && this.getTables(ele, options)}
                      </div>
                    );
                  })}
                  {document.tables && this.getTables(document, options)}
                </div>
              </div>
            </div>
          );
        }else if (data.strapiInsuranceplans.productId == 40) {
          obj.tabContent = (
            <div
              key={document.category}
              className={`${classes.customListFlexFree} ${classes.main} ${classes.mainRaised}`}
              style={{ boxShadow: `none` }}>
              <div
                classes={classes.container}
                style={{
                  paddingBottom: `40px`,
                  paddingTop: `40px`,
                  maxWidth: `970px`,
                  marginLeft: `auto`,
                  marginRight: `auto`,
                  paddingLeft: `15px`,
                  paddingRight: `15px`
                }}>
                {document.insuranceplansectiondetails.map((ele, index) => {
                  return (
                    <div key={`${document.category}-${index}`}>
                      <div className={classes.flexItem}>
                        <div className={classes.markdownContainer}>
                          {(ele.title == "SCIMP Table") && <CriticalillnessBenefits/>}
                          {ele.description != null && (
                            <Markdown>{(ele.description).replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT)}</Markdown>
                          )}
                        </div>
                      </div>
                      {ele.tables.length != 0 && this.getTables(ele, options)}
                    </div>
                  );
                })}
                {document.tables && this.getTables(document, options)}
              </div>
            </div>
          );
        }
        else if (data.strapiInsuranceplans.productId == 22) {
          obj.tabContent = (
            <div
              key={document.category}
              className={`${classes.customListFlexFree} ${classes.main} ${classes.mainRaised}`}
              style={{ boxShadow: `none` }}>
              <div
                classes={classes.container}
                style={{
                  paddingBottom: `40px`,
                  paddingTop: `40px`,
                  maxWidth: `970px`,
                  marginLeft: `auto`,
                  marginRight: `auto`,
                  paddingLeft: `15px`,
                  paddingRight: `15px`
                }}>
                {document.insuranceplansectiondetails.map((ele, index) => {
                  return (
                    <div key={`${document.category}-${index}`}>
                      <div className={classes.flexItem}>
                        <div className={classes.markdownContainer}>
                          {(ele.title == "SCTPIP General Terms1") && <CorporatetravelBenefits/> }
                          {ele.description != null && (
                            <Markdown>{(ele.description).replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT)}</Markdown>
                          )}
                        </div>
                      </div>
                      {ele.tables.length != 0 && this.getTables(ele, options)}
                    </div>
                  );
                })}
                {document.tables && this.getTables(document, options)}
              </div>
            </div>
          );
        }
        else if (data.strapiInsuranceplans.productId == 41) {
          obj.tabContent = (
            <div
              key={document.category}
              className={`${classes.customListFlexFree} ${classes.main} ${classes.mainRaised}`}
              style={{ boxShadow: `none` }}>
              <div
                classes={classes.container}
                style={{
                  paddingBottom: `40px`,
                  paddingTop: `40px`,
                  maxWidth: `970px`,
                  marginLeft: `auto`,
                  marginRight: `auto`,
                  paddingLeft: `15px`,
                  paddingRight: `15px`
                }}>
                {document.insuranceplansectiondetails.map((ele, index) => {
                  return (
                    <div key={`${document.category}-${index}`}>
                      <div className={classes.flexItem}>
                        <div className={classes.markdownContainer}>
                        {this.state.instalment == true ? (ele.title == "SWCP Benefits2") && <Womencarebenefits redirectUrl={this.state.redirectUrl}/>  : (ele.title == "SWCP Benefits2") && <Womencarebenefits/> }  
                          {ele.description != null && (
                            <Markdown>{(ele.description).replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT)}</Markdown>
                          )}
                        </div>
                      </div>
                      {ele.tables.length != 0 && this.getTables(ele, options)}
                    </div>
                  );
                })}
                {document.tables && this.getTables(document, options)}
              </div>
              <div style={{textAlign: 'center'}}>
              {this.state.instalment == true &&
              <Link to={`${CUSTOMERPORTAL_URL}/customerportal/${this.state.redirectUrl}&paymentOption=fullpayment`}>
            <Button
            round
            color="primary"
            >
                Cancel
            </Button>
            </Link>}
              </div>
            </div>
          );
        }
        else if (data.strapiInsuranceplans.productId == 43) {
          obj.tabContent = (
            <div
              key={document.category}
              className={`${classes.customListFlexFree} ${classes.main} ${classes.mainRaised}`}
              style={{ boxShadow: `none` }}>
              <div
                classes={classes.container}
                style={{
                  paddingBottom: `40px`,
                  paddingTop: `40px`,
                  maxWidth: `970px`,
                  marginLeft: `auto`,
                  marginRight: `auto`,
                  paddingLeft: `15px`,
                  paddingRight: `15px`
                }}>
                {document.insuranceplansectiondetails.map((ele, index) => {
                  return (
                    <div key={`${document.category}-${index}`}>
                      <div className={classes.flexItem}>
                        <div className={classes.markdownContainer}>
                        {this.state.instalment == true ? (ele.title == "SHAP Benefits 1") && <StarAssureBenefits redirectUrl={this.state.redirectUrl}/>  : (ele.title == "SHAP Benefits 1") && <StarAssureBenefits/> }
                          {ele.description != null && (
                            <Markdown>{(ele.description).replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT)}</Markdown>
                          )}
                        </div>
                      </div>
                      {ele.tables.length != 0 && this.getTables(ele, options)}
                    </div>
                  );
                })}             
                {document.tables && this.getTables(document, options)}
              </div>
              <div style={{textAlign: 'center'}}>
              {this.state.instalment == true &&
              <Link to={`${CUSTOMERPORTAL_URL}/customerportal/${this.state.redirectUrl}&paymentOption=fullpayment`}>
            <Button
            round
            color="primary"
            >
                Cancel
            </Button>
            </Link>}
            </div>
            </div>
          );
        }
        else if (data.strapiInsuranceplans.name == 'Young Star Extra Protect–Add on Cover' ) {
          obj.tabContent = (
            <div
              key={document.category}
              className={`${classes.customListFlexFree} ${classes.main} ${classes.mainRaised}`}
              style={{ boxShadow: `none` }}>
              <div
                classes={classes.container}
                style={{
                  paddingBottom: `40px`,
                  paddingTop: `40px`,
                  maxWidth: `970px`,
                  marginLeft: `auto`,
                  marginRight: `auto`,
                  paddingLeft: `15px`,
                  paddingRight: `15px`
                }}>
                {document.insuranceplansectiondetails.map((ele, index) => {
                  return (
                    <div key={`${document.category}-${index}`}>
                      <div className={classes.flexItem}>
                        <div className={classes.markdownContainer}>
                          {(ele.title == "YSEP Benefits") && <YoungStarExtraProtectAddonBenifits/> }
                          {ele.description != null && (
                            <Markdown>{(ele.description).replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT)}</Markdown>
                          )}
                        </div>
                      </div>
                      {ele.tables.length != 0 && this.getTables(ele, options)}
                    </div>
                  );
                })}
                {document.tables && this.getTables(document, options)}
              </div>
            </div>
          );
        }

        else if (data.strapiInsuranceplans.name == 'Star Extra Protect - Add on Cover' ) {
          obj.tabContent = (
            <div
              key={document.category}
              className={`${classes.customListFlexFree} ${classes.main} ${classes.mainRaised}`}
              style={{ boxShadow: `none` }}>
              <div
                classes={classes.container}
                style={{
                  paddingBottom: `40px`,
                  paddingTop: `40px`,
                  maxWidth: `970px`,
                  marginLeft: `auto`,
                  marginRight: `auto`,
                  paddingLeft: `15px`,
                  paddingRight: `15px`
                }}>
                {document.insuranceplansectiondetails.map((ele, index) => {
                  return (
                    <div key={`${document.category}-${index}`}>
                      <div className={classes.flexItem}>
                        <div className={classes.markdownContainer}>
                          {(ele.title == "SEP Benefits") && <ExtraprotectBenefits/> }
                          {ele.description != null && (
                            <Markdown>{(ele.description).replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT)}</Markdown>
                          )}
                        </div>
                      </div>
                      {ele.tables.length != 0 && this.getTables(ele, options)}
                    </div>
                  );
                })}
                {document.tables && this.getTables(document, options)}
              </div>
            </div>
          );
        }
         else {
          obj.tabContent = (
            <div
              key={document.category}
              className={`${classes.customListFlexFree} ${classes.main} ${classes.mainRaised}`}
              style={{ boxShadow: `none` }}>
              <div
                classes={classes.container}
                style={{
                  paddingBottom: `40px`,
                  paddingTop: `40px`,
                  maxWidth: `970px`,
                  marginLeft: `auto`,
                  marginRight: `auto`,
                  paddingLeft: `15px`,
                  paddingRight: `15px`
                }}>
                {document.insuranceplansectiondetails.map((ele, index) => {
                  let title = false;
                  if (ele.title == "Instalment Facility" && this.state.instalment){
                    ele.description = " "
                    title = true
                  }
                  return (
                    <div key={`${document.category}-${index}`}>
                      <div className={classes.flexItem}>
                        <div className={classes.markdownContainer}>                          
                        {this.state.instalment == true && title && (
                         <InstalmentFacility 
                         productId={data.strapiInsuranceplans.productId}
                         redirectUrl={this.state.redirectUrl} /> ) }
                          {ele.description != null && (
                            <Markdown>{(ele.description).replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT)}</Markdown>
                          )}
                        </div>
                      </div>
                      {ele.tables.length != 0 && this.getTables(ele, options)}
                    </div>
                  );
                })}
                {document.tables && this.getTables(document, options)}
              </div>
              <div style={{textAlign: 'center'}}>
              {this.state.instalment == true &&
              <Link to={`${CUSTOMERPORTAL_URL}/customerportal/${this.state.redirectUrl}&paymentOption=fullpayment`}>
            <Button
            round
            color="primary"
            >
                Cancel
            </Button>
            </Link>}
            </div>
            </div>
          );
        }

        return obj;
      }
    );

    if (downloads.length) {
      let obj = {};
      let arr = data.allStrapiImages.edges.filter(item => {
        if (item.node.category === `plan_downloads`) return item;
      });
      let imageUrl =
        arr.length > 0 && arr[0].node.content && arr[0].node.content.publicURL;
      if (imageUrl) obj.tabImage = imageUrl;
      obj.tabButton = `Downloads`;
      obj.tabContent = (
        <GridContainer
          item
          md={8}
          style={{ marginLeft: `auto`, marginRight: `auto` }}
          key={`download`}>
          {downloads}
        </GridContainer>
      );
      navPillArr.push(obj);
    }

    const getWidthForPremiumChart = function(productId) {
      switch (productId) {
        case 2:
          return `80%`;
        case 4:
        case 25:
        case 26:
        case 7:
        case 3:
        case 6:
        case 11:
        case 14:
        case 43:
          case 8:
          return `100%`;
        default:
          return `auto`;
      }
    };

    if (
      ((process.env.BASE_URL === `https://web.starhealth.in/` &&
        data.strapiInsuranceplans.displayPremiumChart) ||
        process.env.BASE_URL !== `https://web.starhealth.in/`) &&
      (data.strapiInsuranceplans.premiumChartImage ||
        data.allStrapiSections.edges.filter(
          edge => edge.node.category === `premium_chart`
        ).length != 0)
    ) {
      let obj = {};
      let arr = data.allStrapiImages.edges.filter(item => {
        if (item.node.category === `plan_premium_chart`) return item;
      });
      let imageUrl =
        arr.length > 0 && arr[0].node.content && arr[0].node.content.publicURL;
      if (imageUrl) obj.tabImage = imageUrl;
      obj.tabButton = `Premium Chart`;
      obj.tabContent = (
        <>
          <div className={classes.premiumChart}>
            {data.strapiInsuranceplans.premiumChartImage && (
              <div style={{ textAlign: `center` }}>
                <img
                  onContextMenu={event => event.preventDefault()}
                  src={data.strapiInsuranceplans.premiumChartImage.publicURL}
                  width={getWidthForPremiumChart(
                    data.strapiInsuranceplans.productId
                  )}
                />
              </div>
            )}
            {data.allStrapiSections.edges && (
              <>
              {data.strapiInsuranceplans.name == 'Accident Care Individual Insurance Policy' && <AccidentcareprePageSEO/>}
              {data.strapiInsuranceplans.name != 'Accident Care Individual Insurance Policy' && <Accordion
                activeColor='primary'
                collapses={data.allStrapiSections.edges
                  .filter(edge => edge.node.category === `premium_chart`)
                  .sort((a, b) => a.node.sequence - b.node.sequence)
                  .map((edge, index) => {
                    let obj = {};
                    obj.title = edge.node.title;
                    obj.content = (
                      <img
                        key={index + 1}
                        onContextMenu={event => event.preventDefault()}
                        src={
                          edge &&
                          edge.node &&
                          edge.node.image &&
                          edge.node.image.publicURL
                        }
                        width={getWidthForPremiumChart(
                          data.strapiInsuranceplans.productId
                        )}
                      />
                    );
                    return obj;
                  })}
              />}
              </>
            )}
          </div>
          {data.strapiInsuranceplans.productId == 23 && (
            <div className={classes.premiumChartText}>
              <h4>
                *If the policy type is individual and if you select more than 1
                member, then the premium will be individual premium x no of
                members.
                <br />
                *If the policy type is floater then the premium will be based on
                the applicable 2,3,4,5,6 members premium.
              </h4>
            </div>
          )}
        </>
      );
      navPillArr.push(obj);
    }

    var testimonials = data.strapiInsuranceplans.entities
      .filter(res => {
        if (res.category === `testimonials`) return true;
      })
      .map((document, index) => {
        return (
          <div key={`testimonial - ` + index}>
            <GridItem xs={12} sm={12} md={12} style={{ marginBottom: `50px` }}>
              <VerticalCard
                title={document.title}
                type='testimonial'
                description={document.summary}
                height={`150px`}
                wrap={false}
                alignItems={`center`}
                quote={
                  <div className={classes.icon}>
                    <FormatQuote color='primary' />
                  </div>
                }
                onHoverEffect
                noShadow
                italic
              />
            </GridItem>
          </div>
        );
      });
    console.log(data.strapiInsuranceplans.name);
    return (
      <Layout
        key={`detail - layout`}
        image2={bannerImage2}
        title={data.strapiInsuranceplans.name}
        summary={data.strapiInsuranceplans.summary}
        small={true}
        >
        <Helmet key='helmetTags'>
          <title>{pageContext.titleTag}</title>
        </Helmet>
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key='helmetTags'>
            {data.strapiInsuranceplans.CanonicalUrl && (<link rel='canonical' href={urlold + data.strapiInsuranceplans.CanonicalUrl} />)}
            <meta name={`title`} content={`${pageContext.metaTitle}`} />
            <meta
              name={`description`}
              content={`${pageContext.metaDescription}`}
            />
            <meta name={`keywords`} content={`${pageContext.metaKeywords}`} />
            <meta property='og:title' content={pageContext.metaTitle} />
            <meta
              property='og:description'
              content={pageContext.metaDescription}
            />
            <meta
              property='og:url'
              content={`${process.env.BASE_URL}${pageContext.urlPath}`}
            />
            <meta name={pageContext.metaTitle} />
            <meta property='twitter:title' content={pageContext.metaTitle} />
            <meta
              name='twitter:description'
              content={pageContext.metaDescription}
            />
            <meta
              property='twitter:url'
              content={`${process.env.BASE_URL}${pageContext.urlPath}`}
            />
          </Helmet>
        )}
        {data.strapiInsuranceplans.hasForm && (
          <GridContainer
            item
            md={10}
            className={classNames(
              classes.main,
              classes.mainRaised,
              classes.mlAuto,
              classes.mrAuto
            )}>
            <Hidden mdUp>
              <h1
                className={classes.pageTitle}
                style={{
                  marginTop: `50px`,
                  width: `100%`,
                  textAlign: `center`
                }}>
                {data.strapiInsuranceplans.name}
              </h1>
              {data.strapiInsuranceplans.name == "Senior Citizens Red Carpet Health Insurance Policy" && 
              <h3
              className={`${classes.pageTitle} ${classes.mobileNo}`}
              >
                For queries call {` `}
                <CallComponent color={'#3C4858'}
                contactNumber="044 4674 5800"/> </h3>}
              <p
                className={classes.summary}
                style={{
                  padding: `10px`,
                  width: `100%`,
                  textAlign: `center`,
                  marginTop: '8px',
                  fontSize: '18px'
                }}>
                {data.strapiInsuranceplans.summary}
              </p>
            </Hidden>
            {this.state.choice === `classic` && (
              <Hidden smDown>
                <GridItem md={4} lg={4} style={{ paddingLeft: `0` }}>
                  <Carousel {...settings1} className={classes.customCarousel}>
                    {cart_images.map((cart_image, k) => (
                      <div
                        key={`cart-image-${k}`}
                        className={classes.cartImage}>
                        <p
                          style={{
                            color: `white`,
                            fontSize: `25px`,
                            fontWeight: `bold`,
                            fontFamily: `Roboto Slab`,
                            lineHeight: `30px`
                          }}>
                          {(cart_image.node.description).replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT)}
                        </p>
                        <br />
                        <img
                          src={
                            cart_image.node.icon &&
                            cart_image.node.icon.publicURL
                          }
                          className='slick-image'
                          style={{
                            maxWidth: `fit-content`,
                            position: `absolute`,
                            bottom: 140
                          }}
                        />
                      </div>
                    ))}
                  </Carousel>
                </GridItem>
              </Hidden>
            )}
            {this.state.choice === `classic` && (
              <GridItem md={8} lg={8} sm={12}>
                {this.state.enableConversation && (
                  <div style={{ padding: `20px` }}>
                    <GridContainer justify={`flex-end`}>
                      {!isAgent && (
                        <GridItem
                          md={4}
                          xs={8}
                          sm={8}
                          lg={4}
                          className={`${classes.choice}`}
                          onClick={this.handleChoiceToggle}>
                          {this.state.choice === `classic`
                            ? `Try Conversational`
                            : `Go back to Classic`}
                        </GridItem>
                      )}
                    </GridContainer>
                  </div>
                )}
                {(data.strapiInsuranceplans.name ===
                  `Saral Suraksha Bima, Star Health And Allied Insurance Co Ltd` && (
                  <Accident
                    productName={data.strapiInsuranceplans.name}
                    productId={data.strapiInsuranceplans.productId}
                    planType={data.strapiInsuranceplans.planType}
                    ctaLabel={data.strapiInsuranceplans.ctaLabel}
                    buyNowUrl={data.strapiInsuranceplans.buyNowUrl}
                    colorCode={colorCode}
                    query={location.search}
                    brochure={data.strapiInsuranceplans.entities.filter(
                      ent => ent.title === `Brochure`
                    )}
                    brochureUrl={brochureUrl}
                    type={typeArr}
                    key={`wizard`}
                    formJson={saralBhima}
                    formPayload={payloadSaralBhima}
                  />
                )) ||
                  (data.strapiInsuranceplans.name ===
                    `Accident Care Individual Insurance Policy` && (
                    <Accident
                      productName={data.strapiInsuranceplans.name}
                      productId={data.strapiInsuranceplans.productId}
                      planType={data.strapiInsuranceplans.planType}
                      ctaLabel={data.strapiInsuranceplans.ctaLabel}
                      buyNowUrl={data.strapiInsuranceplans.buyNowUrl}
                      colorCode={colorCode}
                      query={location.search}
                      brochure={data.strapiInsuranceplans.entities.filter(
                        ent => ent.title === `Brochure`
                      )}
                      brochureUrl={brochureUrl}
                      type={typeArr}
                      key={`wizard`}
                      formJson={accident_classic}
                      formPayload={payloadAccidentInd}
                    />
                  )) ||
                  (data.strapiInsuranceplans.name ===
                    `Group Accident Insurance Policy` && (
                    <AccidentGroup
                      formArr={details}
                      productName={data.strapiInsuranceplans.name}
                      productId={data.strapiInsuranceplans.productId}
                      planType={data.strapiInsuranceplans.planType}
                      ctaLabel={data.strapiInsuranceplans.ctaLabel}
                      buyNowUrl={data.strapiInsuranceplans.buyNowUrl}
                      colorCode={colorCode}
                      query={location.search}
                      type={typeArr}
                      key={`wizard`}
                    />
                  )) ||
                  (data.strapiInsuranceplans.isVisiblelist && (
                    <GroupHealthInsurance
                      productName={data.strapiInsuranceplans.name}
                      productId={data.strapiInsuranceplans.productId}
                      key={`wizard`}
                    />
                  )) ||
                  (data.strapiInsuranceplans.name ===
                    `Star Cancer Care Platinum Insurance Policy` && (
                      <>
                     <CancerCare
                     productName={data.strapiInsuranceplans.name}
                     productId={data.strapiInsuranceplans.productId}
                     planType={data.strapiInsuranceplans.planType}
                     ctaLabel={data.strapiInsuranceplans.ctaLabel}
                     buyNowUrl={data.strapiInsuranceplans.buyNowUrl}
                     colorCode={colorCode}
                     query={location.search}
                     type={typeArr}
                     brochure={data.strapiInsuranceplans.entities.filter(
                      ent => ent.title === `Brochure`
                    )}
                    brochureUrl={brochureUrl}
                     key={`wizard`}
                     formJson={cancerCareJson}
                     formPayload={payloadCancerCare}
                    />
                    </>
                  )) || 
                  (data.strapiInsuranceplans.name ===
                    `Star Health Premier Insurance Policy` && (
                      <>
                     <Healthpremier
                     productName={data.strapiInsuranceplans.name}
                     productId={data.strapiInsuranceplans.productId}
                     planType={data.strapiInsuranceplans.planType}
                     ctaLabel={data.strapiInsuranceplans.ctaLabel}
                     buyNowUrl={data.strapiInsuranceplans.buyNowUrl}
                     colorCode={colorCode}
                     query={location.search}
                     type={typeArr}
                     brochure={data.strapiInsuranceplans.entities.filter(
                      ent => ent.title === `Brochure`
                    )}
                    brochureUrl={brochureUrl}
                     key={`wizard`}
                     formJson={healthpremierJson}
                     formPayload={payloadhealthpremier}
                    />
                    </>
                  )) || 
                  (data.strapiInsuranceplans.name ===
                    `Star Health Assure Insurance Policy` && (
                      <>
                     <StarAssure
                     productName={data.strapiInsuranceplans.name}
                     productId={data.strapiInsuranceplans.productId}
                     planType={data.strapiInsuranceplans.planType}
                     ctaLabel={data.strapiInsuranceplans.ctaLabel}
                     buyNowUrl={data.strapiInsuranceplans.buyNowUrl}
                     colorCode={colorCode}
                     query={location.search}
                     type={typeArr}
                     brochure={data.strapiInsuranceplans.entities.filter(
                      ent => ent.title === `Brochure`
                    )}
                    brochureUrl={brochureUrl}
                     key={`wizard`}
                     formJson={starAssureJson}
                     formPayload={payloadstarassure}
                    />
                    </>
                  )) || 
                  (data.strapiInsuranceplans.name ===
                    `Star Extra Protect - Add on Cover` && (
                      <>
                     <Extraprotect
                     productName={data.strapiInsuranceplans.name}
                     productId={data.strapiInsuranceplans.productId}
                     planType={data.strapiInsuranceplans.planType}
                     ctaLabel={data.strapiInsuranceplans.ctaLabel}
                     buyNowUrl={data.strapiInsuranceplans.buyNowUrl}
                     colorCode={colorCode}
                     query={location.search}
                     type={typeArr}
                     brochure={data.strapiInsuranceplans.entities.filter(
                      ent => ent.title === `Brochure`
                    )}
                    brochureUrl={brochureUrl}
                     key={`wizard`}
                    //  formJson={starAssureJson}
                    //  formPayload={payloadstarassure}
                    />
                    </>
                  )) ||
                  (data.strapiInsuranceplans.name ===
                    `Young Star Extra Protect–Add on Cover` && (
                      <>
                     <YoungStarExtraProtectAddon
                     productName={data.strapiInsuranceplans.name}
                     productId={data.strapiInsuranceplans.productId}
                     planType={data.strapiInsuranceplans.planType}
                     ctaLabel={data.strapiInsuranceplans.ctaLabel}
                     buyNowUrl={data.strapiInsuranceplans.buyNowUrl}
                     colorCode={colorCode}
                     query={location.search}
                     type={typeArr}
                     brochure={data.strapiInsuranceplans.entities.filter(
                      ent => ent.title === `Brochure`
                    )}
                    brochureUrl={brochureUrl}
                     key={`wizard`}
                    //  formJson={starAssureJson}
                    //  formPayload={payloadstarassure}
                    />
                    </>
                  )) ||
                  (data.strapiInsuranceplans.name !==
                    `Accident Care Individual Insurance Policy` &&
                    data.strapiInsuranceplans.name !==
                      `Group Accident Insurance Policy` && (
                      <Wizard
                        formArr={details}
                        productName={data.strapiInsuranceplans.name}
                        productId={data.strapiInsuranceplans.productId}
                        planType={data.strapiInsuranceplans.planType}
                        ctaLabel={data.strapiInsuranceplans.ctaLabel}
                        buyNowUrl={data.strapiInsuranceplans.buyNowUrl}
                        colorCode={colorCode}
                        query={location.search}
                        brochure={data.strapiInsuranceplans.entities.filter(
                          ent => ent.title === `Brochure`
                        )}
                        brochureUrl={brochureUrl}
                        type={typeArr}
                        key={`wizard`}
                      />
                    ))}
              </GridItem>
            )}
            {this.state.choice === `conversational` && (
              <GridItem md={12} lg={12} sm={12}>
                <div style={{ padding: `20px` }}>
                  <GridContainer justify={`flex-end`}>
                    <GridItem
                      md={3}
                      sm={8}
                      xs={8}
                      lg={3}
                      className={`${classes.choice}`}
                      onClick={this.handleChoiceToggle}>
                      {this.state.choice === `classic`
                        ? `Try Conversational`
                        : `Go back to Classic`}
                    </GridItem>
                  </GridContainer>
                </div>
                <GridItem  md={10} sm={10} xs={12}  className={classes.FormPosition}>
                  <ConversationalQuote
                  data={data}
                  classes={classes}
                  location={this.props.location}
                  pageContext={this.props.pageContext}
                  buyNowUrl={data.strapiInsuranceplans.buyNowUrl}
                  planType={data.strapiInsuranceplans.planType}
                />
                </GridItem>
              </GridItem>
            )}
          </GridContainer>
        )}

        {!sevaApp() && <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <h4
                  style={{
                    textAlign: `center`,
                    color: `rgba(68, 68, 68, 0.8)`
                  }}>
                  {data &&
                    data.strapiInsuranceplans &&
                    data.strapiInsuranceplans.id === `Insuranceplans_74` &&
                    data.strapiInsuranceplans.irdaiId &&
                    data.strapiInsuranceplans.irdaiId.split(`\\n`).map(data => {
                      return (
                        <>
                          {data}
                          <br />
                        </>
                      );
                    })}
                </h4>
                {
                  !data.strapiInsuranceplans.isVisiblelist && (<NavPills
                    tabs={navPillArr}
                    color='primary'
                    alignCenter
                    variant
                  />)
                }
                
              </GridItem>
            </GridContainer>
          </div>
          {data.strapiInsuranceplans.productId ==`38`? (
            <>
            <Groupmediclaimhighlights/>
             </>
          ):(
            <></>
          )}
        </div>}

        <div
          className={`${classes.testimonials} ${classes.sectionDark} ${classes.testimonial2}`}
          style={{ marginBottom: `100px` }}>
          {testimonials.length !== 0 && !sevaApp() && (
            <div className={classes.container} key={`testimonial`}>
              <h2
                className={classes.cardTitle}
                style={{ textAlign: `center`, fontSize: '25px' }}>
                {data.strapiInsuranceplans.testimonialHeading}
              </h2>
              <p style={{ textAlign: `center`, fontSize: '17px' }}>
                {data.strapiInsuranceplans.testimonialDescription}
              </p>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Carousel {...settings}>{testimonials}</Carousel>
                </GridItem>
              </GridContainer>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}

ProductDetails.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object
};

export default withStyles(productListStyle)(ProductDetails);

export const query1 = graphql`
  query ProductDetails($id: String!, $metaContent: String!, $strapiId: Int!) {
    strapiInsuranceplans(id: { eq: $id }) {
      id
      productId
      planType
      ctaLabel
      buyNowUrl
      urlPath
      hasForm
      urlPath
      displayPremiumChart
      name
      irdaiId
      isVisiblelist
      CanonicalUrl
      premiumChartImage {
        publicURL
      }
      summary
      entities {
        title
        description
        summary
        category
        url
      }
      testimonialHeading
      testimonialDescription
      thumbnailImages {
        publicURL
      }
      cartImages {
        publicURL
      }
      insuranceplancategory {
        category
        colorCode
      }
      cartImages3{
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      insuranceplansections {
        category
        description
        Sequence
        insuranceplansectiondetails {
          id
          title
          summary
          description
          tables {
            tablerows {
              tablecolumns {
                details
              }
            }
          }
        }
        tables {
          title
          tablerows {
            isHeader
            sequence
            tablecolumns {
              sequence
              details
            }
          }
        }
      }
      documents {
        Category
        Title
        type
        link {
          url
        }
      }
      forms {
        details {
          formSteps {
            fields {
              id
              name
              label
              type
              description
              placeholder
              minValue
              maxValue
              defaultValue
              options {
                heading
                items {
                  label
                  value
                }
              }
              visibleWhen {
                field
                is {
                  value
                }
              }
              visibleWhenAll {
                field
                is {
                  value
                }
              }
              validWhen {
                lengthIsLessThan {
                  length
                  message
                }
                lengthIsGreaterThan {
                  length
                  message
                }
              }
              visible
              required
              disabled
              omitWhenHidden
              maximumLength
            }
          }
        }
      }
    }
    allStrapiInsuranceplancategories {
      edges {
        node {
          category
          bannerImage2 {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allStrapiImages {
      edges {
        node {
          category
          content {
            publicURL
          }
        }
      }
    }
    allStrapiInsuranceplansectiondetails(
      filter: { insuranceplansection: { insuranceplan: { eq: $strapiId } } }
    ) {
      edges {
        node {
          id
          title
          images {
            publicURL
          }
        }
      }
    }
    allStrapiEntities(
      filter: {
        category: { eq: "cart_image" }
        insuranceplancategory: { category: { eq: $metaContent } }
      }
    ) {
      edges {
        node {
          description
          sequence
          icon {
            publicURL
          }
        }
      }
    }
    allStrapiSections(filter: { insuranceplan: { id: { eq: $strapiId } } }) {
      edges {
        node {
          title
          category
          image {
            publicURL
          }
          sequence
          insuranceplan {
            id
            name
          }
        }
      }
    }
  }
`;
