import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
// import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "components/Accordion/Accordion.jsx";
import { Helmet } from "react-helmet";

const table2 = [
    ['Basic Plan','Rs. 1000, Rs. 2000, Rs. 3000','30/60/90/120/180 days'],
    ['Enhanced Plan','Rs. 3000, Rs. 4000, Rs. 5000','90/120/180 days'],
    ]
        

const HospitalCashHighlights = ({ classes }) => {
      const faq=[
        {
            title:'What is the age limit to buy hospital cash insurance?',
            description:<h5>Adults between 18 years and 65 years can purchase this policy. Dependent children from 91 days to 25 years can also be covered.</h5>,
            content:false
        },
        {
            title:'Is there any deductible at the time of claims for Star Hospital Cash Insurance Policy?',
            description:<h5>Yes. Star Hospital Cash Insurance Policy’s Basic Plan has a deductible of one day for sickness hospital daily cash benefit. The deductible represents the amount that the insurer deducts before starting to pay the holder the hospitalisation cash benefit amount. E.g. a deductible of 1 day means that the provided daily cash benefit for a day from your chosen total number of days in the hospital will be subtracted. Besides, if you spend just one day in the hospital - you will get nothing from your hospital cash plan. But if you spend five days in the hospital, then after applying a 1- day deductible, you are eligible to receive the benefit for four days.</h5>,
            content:false
        },
        {
            title:'What can I use daily hospital cash benefits for?',
            description:<h5>Hospital Cash Amount (per day) chosen by the insured as lump-sum amount for the expenses incurred by the insured during hospitalisation due to Illness / Sickness / Injury Sickness Hospital Cash / Accident Hospital Cash(150% ) / ICU Hospital - Cash due to Sickness and/or Injury (200%) / Worldwide Hospital Cash ( 200%) / Convalescence Hospital Cash / Child Birth Hospital Cash.</h5>,
            content:false
        },
        {
            title:'How long does the plan require the holder to be hospitalised to be eligible for the coverage?',
            description:<h5>The Hospital Cash Insurance Policy requires the insured to be hospitalised for a minimum period of 24 hours to be eligible for the fixed daily cash benefit. This exception is for sickness hospital daily cash benefit in the Star Hospital Cash Insurance Policy’s Basic Plan.</h5>,
            content:false
        },
        {
            title:'What is the maximum per day benefit under Hospital Cash?',
            description:<h5>
                <h5>
                Hospital Cash Amount (per day) is chosen by the insured as a lump-sum amount for the expenses incurred by the insured during hospitalisation due to Illness / Sickness / Injury.
                </h5>
                <h5>
                Hospital Cash Amount (per day) options:
                </h5>
                <h5>
                Basic Plan Rs :1,000/- ; 2,000/- ; 3,000/-
                </h5>
                <h5>
                Enhanced Plan Rs :3,000/- ; 4,000/- ; 5,000/-
                </h5>
            </h5>,
            content:false
        },
        {
            title:'Are original bills required for Hospital Cash claims?',
            description:<h5>
                List of claim documents you need:
                <ol type='a'>
                <li>
                Copy of Discharge Summary / Discharge Certificate
                </li>
                <li>
                Copy of Final Hospital Bill
                </li>
                <li>
                Aadhar card & PAN card Copies
                </li>
                <li>
                NEFT details
                </li>
                </ol>
                </h5>,
            content:true
        },
        {
            title:'Are daycare procedures covered under the Star Hospital Cash Insurance Policy?',
            description:<h5>Yes. All daycare procedures are eligible to get daily cash benefits under this policy.</h5>,
            content:false
        },
        {
            title:'Are Pre-Existing Diseases covered under the plan?',
            description:<h5>Yes. Pre Existing Diseases (PED’s) are eligible to receive daily cash benefits after a waiting period of 36 months in the Star Hospital Cash Insurance Policy’s Basic Plan. Star Hospital Cash Insurance Policy’s Enhanced Plan covers PED’s after a waiting period of 24 months.</h5>,
            content:false
        },
        {
            title:'Can the Star Hospital Cash Insurance Policy be an add-on to my existing health insurance plan?',
            description:<h5>Certainly. The Hospital Cash Health Insurance Policy can be an add-on to any of your existing health insurance plans.</h5>,
            content:false
        },
        {
            title:'Will the Hospital Cash Insurance Policy cover maternity?',
            description:<h5>Unfortunately, no. The Hospital Cash Insurance Policy does not cover maternity expenses. We would suggest some of our best-selling policies for your maternity needs. Star Comprehensive Insurance Policy, Family Health Optima Insurance Policy and Young Star Insurance Policy top our list for maternity needs.</h5>,
            content:false
        }
    ]
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });
    return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Star Hospital Cash Insurance Policy
            </h2>
            </GridItem>
                <h5>
                There is no doubt that health care costs are high today, and they are only rising. Vector-borne, air-borne and water-borne infections could easily cost a person’s entire monthly savings. You can, of course, get your regular health coverage to cover your hospital expense. But once you land in a hospital, you may encounter many other miscellaneous costs and unavoidable expenses.
                </h5>

             <h5>
             A hospital cash insurance policy can offer some relief here, and one of the best features of the hospital cash policy is that even the little things are covered for you. With this plan in place, your day-to-day needs be taken care of. The Star Hospital Cash Policy can be an additional benefit plan along with your existing health insurance policy. To cover you with a fixed amount of money for each day of hospitalisation. This payment can be utilised for miscellaneous expenses during your hospital stay. Besides, this policy will also be beneficial for the insured to cover any other out-of-pocket expenses like petrol, food for the attendant, etc. incurred outside the hospital. For example, the coverage in your hospital cash policy is Rs. 1000 per day, the insurer will pay you Rs. 1000 for each day during the entire period of your hospitalisation. However, it is entirely up to you how to spend the funds.
             </h5>
             <h5>
             They also work very well as an add-on to your existing health medical policy. You simply have to choose the daily cash limit and the number of days covered as available in the policy.
             </h5>
             <h5>
             Star Hospital Cash Insurance Policy provides daily hospital cash during the insured’s confinement in the hospital in addition to any in and out expenses. The policy can be an add-on to all the medical plans offered by Star Health.
             </h5>
             <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Features of Star Hospital Cash Insurance Policy
            </h2>
            </GridItem>
            <h5>
            <h5>
                <b>
                Eligibility
                </b>
            </h5>
            <h5>
            Star Hospital Cash Insurance Policy can be purchased by individuals and families between the age of 18 years and 65 years, including your spouse and up to 3 dependent children between the age of 3 months and 25 years.
            </h5>
            </h5>

            <h5>
            <h5>
                <b>
                Product type
                </b>
            </h5>
            <h5>
            Two plans are available - a Basic Plan and an Enhanced Plan - on both individual and floater basis. Under both basic and enhanced plans, the policy holder can choose a combination of hospital cash amounts per day and the number of hospital cash days. The table below illustrates the options of hospital cash amount and number of hospital cash days.
            </h5>
            </h5>
           
        <div >
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Plan type
                                  </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Hospital cash amount
                                </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                >
                                   Number of hospital cash days
                         </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {table2.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[2]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                        </Table>
                </Paper>
                </div>
            
            <h5>
            <h5>
                <b>
                Policy term
                </b>
            </h5>
            <h5>
            Available for - 1 year / 2 years / 3 years
            </h5>
            </h5>

            <h5>
            <h5>
                <b>
                Waiting periods
                </b>
            </h5>
            <h5>
            Illness contacted within 30 days of the policy inception date is not covered. The exception is in case of an accident. Coverage for specified illnesses/ surgeries becomes effective after 24 months. Pre-existing diseases are covered after a waiting period of 36 months (basic plan) and 24 months (enhanced plan) from the policy inception date.
            </h5>
            </h5>

            <h5>
            <h5>
                <b>
                Portability
                </b>
            </h5>
            <h5>
            As per IRDAI guidelines on portability, the insured person has the provision to port the policy to another insurer by applying to that insurer to port the entire policy. All family members can port at least 45 days before, but not earlier than 60 days before, the renewal date.
            </h5>
            </h5>

            <h5>
            <h5>
                <b>
                Free-look period
                </b>
            </h5>
            <h5>
            A free-look period of 15 days from the date of receipt of the policy is available for reviewing the policy’s terms and conditions. If the insured is not satisfied with the policy, they will have an option of cancelling the policy within the stipulated period. However, this feature is not applicable for policy renewals.
            </h5>
            </h5>

            <h5>
            <h5>
                <b>
                What is covered in the Star Hospital Cash Insurance Policy (basic and enhanced plan)?
                </b>
            </h5>
            <h5>
            The Star Hospital Cash Insurance Policy will provide daily cash benefits when the insured person(s) are required to be hospitalised for treatment for at least 24 hours for the maximum number of days chosen by the insured. The plan will cover expenses incurred towards the following as per the terms of the policy:
            </h5>
            </h5>
            <ol>
                <li>
                <h5>
                <b>Sickness hospital cash - </b>
                Hospital Cash Amount (per day) for the maximum number of days chosen by the insured. Applicable for both basic and enhanced plans.
                </h5>
                </li>
                <li>
                <h5>
                <b>Accident hospital cash - </b>
                150% of the Hospital Cash Amount (per day) for the maximum number of days chosen by the insured
                </h5>
                </li>
                <li>
                <h5>
                <b>ICU hospital cash  - </b>
                200% of the Hospital Cash Amount (per day) chosen by the insured. Where the policy is issued on Individual Basis, ICU Hospital Cash is payable for a maximum of 30 days only in a policy year. Where the policy is issued on Floater Basis, ICU Hospital Cash is payable for a maximum of 90days only in a policy year.
                </h5>
                </li>
                <li>
                <h5>
                <b>Convalescence hospital cash - </b>
                If Hospitalization is beyond 5 consecutive days one-day additional Hospital Cash amount is given as Convalescence Cash Benefit. This is not applicable for basic plan.
                </h5>
                </li>
                <li>
                <h5>
                <b>Child Birth hospital cash - </b>
                This benefit is subject to a waiting period of 2 years from the first commencement of this Star Hospital Cash Insurance Policy. Only female insured persons are eligible for this benefit. This does not apply to the basic plan.
                </h5>
                </li>
                <li>
                <h5>
                <b>Worldwide hospital cash - </b>
                If the Insured Person is Hospitalized outside India for treatment of Illness or Injury, 200% Hospital Cash Amount (per day) chosen by the insured person is payable. Not applicable to the basic plan.
                </h5>
                </li>
                <li>
                <h5>
                <b>Daycare procedures - </b>
                Fractures (other than hairline fractures), Cataract, Dilatation and curettage, Hemodialysis, Parenteral Chemotherapy, Radio Therapy, Coronary Angiography, Lithotripsy, Manipulation for Dislocation under General Anaesthesia, Cystoscopy under General Anesthesia. The above mentioned covers 1, 2, 3 and 6 are applicable for these daycare treatments. Insured person is eligible for a claim in respect of the above said daycare treatments only five times in a policy year.
                </h5>
                </li>
            </ol>


                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Why choose Hospital Cash Health Policy from Star Health Insurance?
            </h2>
            </GridItem>
            <ol>
            <li>
                <b>Tax benefits</b>
                <br />
                <h5>
                Individual and family members below 60 years can get Rs. 25,000 deductions from taxable income, and the deductible amount can extend up to Rs. 50,000 for senior citizens on premiums paid as per Section 80D of Income Tax Act, 1961. If two family members above the age of 60 are insured under one policy, then the deductible amount extends to Rs 1 lakh. In contrast, a family member below the age of 60 and his parents are insured too under the same policy, then they can avail of a deduction of Rs 75,000.
                </h5>
                </li>
            </ol>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            How to register a claim for the Star Hospital Cash Insurance plan?
            </h2>
            </GridItem>
            <h5>
            Star Health offers hassle-free claim settlement for all its customers. You can get a reimbursement for Star Hospital Cash Insurance Plan.
            </h5>

            <h5>
                <b> Reimbursement claim</b>
                <br />
                A reimbursement claim means you settle the hospital bill from your own pockets and then apply for reimbursement from the insurance company. The process of reimbursement is as follows:
            </h5>
            <ol>
                <li>
                Claim should be intimated within 24 hours of hospitalisation. Treatment can be from either a network or a non-network hospital.
                </li>
                <li>
                You can avail treatment, settle all the required documents and then file a claim for the reimbursement.
                </li>
                <li>
                After successful verification, your insurer will process the claim, and you will receive the amount in your registered bank account.
                </li>
            </ol>

            <h5>
                <b>
                Documents required for reimbursement:
                </b>
            </h5>
            <ul>
                <li>
                Discharge Summary from the hospital
                </li>
                <li>
                Copy of Final hospital bill
                </li>
                <li>
                Copy of PAN card, Cancelled cheque or NEFT Details
                </li>
            </ul>


            <h5>
                <b>
                Reasons why claims could be rejected
                </b>
            </h5>
            <ol>
                <li>
                A claim for Time-Bound / Permanent exclusions in a health insurance plan
                </li>
                <li>
                If Material Facts, are Non-disclosed / Mis-Represented / Fraudulent claims
                </li>
                <li>
                Claims from Excluded Provider (only Payable during a Life-threatening situation/accident up to the stage of stabilization )
                </li>
                <li>
                If you fail to submit the documents required for the claim.
                </li>
            </ol>


            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            FAQ’s on Star Hospital Cash Insurance Policy
            </h2>
            </GridItem>
            <div>
            <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer
                className={`${classes.container} ${classes.textCenter}`}
                >
                <GridItem
                    md={12}
                    style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                ></GridItem>
                <GridItem
                style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <Accordion active={0} collapses={AccordionArray} />
                </GridItem>
                </GridContainer>
            </div>
            </div>
        </div>

      </GridItem>
      {process.env.ENABLE_HELMET == `true` && (
            <Helmet>
                <script type="application/ld+json">
                {`{
                    "@context":"https://schema.org",
                    "@type":"FAQPage",
                    "mainEntity":[
                        {
                            "@type":"Question",
                            "name":"What is the age limit to buy hospital cash insurance?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"Adults between 18 years and 65 years can purchase this policy. Dependent children from 91 days to 25 years can also be covered."
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"Is there any deductible at the time of claims for Star Hospital Cash Insurance Policy?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"Yes. Star Hospital Cash Insurance Policy’s Basic Plan has a deductible of one day for sickness hospital daily cash benefit. The deductible represents the amount that the insurer deducts before starting to pay the holder the hospitalisation cash benefit amount. E.g. a deductible of 1 day means that the provided daily cash benefit for a day from your chosen total number of days in the hospital will be subtracted. Besides, if you spend just one day in the hospital - you will get nothing from your hospital cash plan. But if you spend five days in the hospital, then after applying a 1- day deductible, you are eligible to receive the benefit for four days."
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"What can I use daily hospital cash benefits for?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"Hospital Cash Amount (per day) chosen by the insured as lump-sum amount for the expenses incurred by the insured during hospitalisation due to Illness / Sickness / Injury Sickness Hospital Cash / Accident Hospital Cash(150% ) / ICU Hospital - Cash due to Sickness and/or Injury (200%) / Worldwide Hospital Cash ( 200%) / Convalescence Hospital Cash / Child Birth Hospital Cash."
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"How long does the plan require the holder to be hospitalised to be eligible for the coverage?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"The Hospital Cash Insurance Policy requires the insured to be hospitalised for a minimum period of 24 hours to be eligible for the fixed daily cash benefit. This exception is for sickness hospital daily cash benefit in the Star Hospital Cash Insurance Policy’s Basic Plan."
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"What is the maximum per day benefit under Hospital Cash?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"Hospital Cash Amount (per day) is chosen by the insured as a lump-sum amount for the expenses incurred by the insured during hospitalisation due to Illness / Sickness / Injury.\n \nHospital Cash Amount (per day) options:\nBasic Plan Rs :1,000/- ; 2,000/- ; 3,000/-\nEnhanced Plan Rs :3,000/- ; 4,000/- ; 5,000/-"
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"6.Are original bills required for Hospital Cash claims?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"List of claim documents you need:\na.Copy of Discharge Summary / Discharge Certificate\nb.Copy of Final Hospital Bill \nc.Aadhar card & PAN card Copies, \nd.NEFT details"
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"Are daycare procedures covered under the Star Hospital Cash Insurance Policy?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"Yes. All daycare procedures are eligible to get daily cash benefits under this policy."
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"Are Pre-Existing Diseases covered under the plan?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"Yes. Pre Existing Diseases (PED’s) are eligible to receive daily cash benefits after a waiting period of 36 months in the Star Hospital Cash Insurance Policy’s Basic Plan. Star Hospital Cash Insurance Policy’s Enhanced Plan covers PED's after a waiting period of 24 months."
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"Can the Star Hospital Cash Insurance Policy be an add-on to my existing health insurance plan?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"Certainly. The Hospital Cash Health Insurance Policy can be an add-on to any of your existing health insurance plans."
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"Will the Hospital Cash Insurance Policy cover maternity?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"Unfortunately, no. The Hospital Cash Insurance Policy does not cover maternity expenses. We would suggest some of our best-selling policies for your maternity needs. Star Comprehensive Insurance Policy, Family Health Optima Insurance Policy and Young Star Insurance Policy top our list for maternity needs."
                            }
                        }
                    ]
                 }
                `}
                </script>
                {/* <script type="application/ld+json">
                 {`{ "@context": "http://schema.org",
               "@type": "Product",
               "name": "Star Hospital Cash Insurance Policy",
               "description": "Star Hospital Cash Insurance policy provides a lump sum amount to the insured in case of hospitalization. The insurer can use this money to meet additional expenses incurred during hospitalisation such as traveling, food, etc.",
                "offers":{ 
                    "@type": "AggregateOffer",
                    "offerCount": "5%"
                }

}`}
</script> */}
                </Helmet>
        )}
    </GridContainer>
  );
};
HospitalCashHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(HospitalCashHighlights);