import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const table1 = [
  ["200 to 350", "2%"],
  ["351 to 600", "5%"],
  ["601 to 750", "7%"],
  ["751 to 1000", "10%"],
];
const table2 = [
  ["Self, Spouse", "1:1"],
  ["Self, Spouse and Dependent Children (up to 18 years)", "1:1:0:0:0"],
  ["Self, Spouse and Dependent Children (aged above 18 years)", "2:2:1:1:1"],
];
const table4 = [
  "Complete Haemogram Test",
  "Blood Sugar (Fasting Blood Sugar (FBS) + Postprandial (PP) [or] HbA1c)",
  "Lipid profile (Total cholesterol, HDL, LDL, Triglycerides, Total Cholesterol / HDL Cholesterol Ratio)",
  "Serum Creatinine",
];
const table9 = [
  ["Completed Online Health Risk Assessment (HRA)", "50"],
  [
    "Submitted Health Check-Up Report (two test results are not within normal values)",
    "100",
  ],
  ["Participated in Walkathon ", "100"],
  ["Attended to Gym", "100"],
  [
    "Achieved 10,000 average number of steps per day during the policy year",
    "200",
  ],
  ["Shared his fitness success story", "50"],
  ["Managed Diabetes through Chronic Condition Management Program", "250"],
];
const table10 = [
  ["Completed Online Health Risk Assessment (HRA)", "50", "50"],
  ["Submitted Health Check-Up Report", "200", "200"],
  ["Participation in Marathon", "100", "0"],
  ["Attended to Gym", "100", "100"],
  [
    "Achieved 10,000 average number of steps per day during the policy year",
    "200",
    "200",
  ],
  [
    "Suresh accepted the Weight management program and reached 27 BMI <br /> <br /> Lakshmi accepted the Weight management program and reached 23 BMI",
    "100",
    "100",
  ],
  [
    "Suresh Managed Diabetes & Hypertension through Chronic Condition Management Program; <br /><br /> <br /><br /> Lakshmi has completed De-stress & Mind Body Healing Program",
    "250",
    "125",
  ],
];

const table11 = [
  ["Completed Online Health Risk Assessment (HRA)", "50", "50"],
  ["Submitted Health Check-Up Report", "200", "200"],
  ["Participated in Walkathon", "100", "100"],
  ["Attended to Yoga Classes", "100", "100"],
  [
    "Achieved 10,000 average number of steps per day during the policy year",
    "200",
    "200",
  ],
  ["Submitted his fitness success story", "50", "50"],
  ["Completed De-stress & Mind Body Healing Program", "125", "125"],
];

const ComprehensiveWellnessBenefits = ({ classes }) => {
  return (
    <GridContainer>
      <GridItem
        md={8}
        sm={8}
        xs={8}
        className={classNames(
          classes.mrAuto,
          classes.mlAuto,
          classes.infoArea,
          classes.handleMarginLeft
        )}
      >
        <GridItem style={{ textAlign: "center" }}>
          <h2>Star Wellness Program</h2>
        </GridItem>
        <h5>
          This program intends to promote, incentivize and to reward the Insured
          Persons’ healthy life style through various wellness activities. The
          wellness activities as mentioned below are designed to help the
          Insured person to earn wellness reward points which will be tracked
          and monitored by the Company. The wellness points earned by the
          Insured Person(s) under the wellness program, can be utilized to get
          discount in premium.
        </h5>
        <h5>
          This Wellness Program is enabled and administered online through Star
          Wellness Platform (digital platform)
        </h5>
        <h5>
          Note: The Wellness Activities mentioned in the table below (from
          Serial Number 1 to 5) are applicable for the Insured person(s) aged 18
          years and above only.
        </h5>
        <h5>
          <b>
            The following table shows the discount on renewal premium available
            under the Wellness Program:
          </b>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    rowSpan={1}
                    className={classes.tableCell + ` ` + classes.headCell}
                  >
                    Wellness Points Earned
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    align="center"
                    className={classes.tableCell + ` ` + classes.headCell}
                  >
                    Discount in Premium
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table1.map((row) => (
                  <TableRow key={row[0]}>
                    <TableCell align="center" className={classes.tableCell}>
                      {row[0]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row[1]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </h5>
        <h5>
          *In case of floater policy the weightage is given as per the following
          table :
        </h5>
        <div>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    rowSpan={1}
                    className={classes.tableCell + ` ` + classes.headCell}
                  >
                    Family Size
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    align="center"
                    className={classes.tableCell + ` ` + classes.headCell}
                  >
                    Weightage
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table2.map((row) => (
                  <TableRow key={row[0]}>
                    <TableCell align="center" className={classes.tableCellLeft}>
                      {row[0]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row[1]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
        <div style={{ paddingLeft: `2%` }}>
          <div>
            <h5>
              Note: In case of two year policy, total number of wellness points
              earned in two year period will be divided by two.
            </h5>
            <h5>
              Each Insured Person will be given an Individual log-in facility,
              which will be linked to his/ her policy.
            </h5>
            <h5>
              *Please refer the Illustrations to understand the calculation of
              discount in premium, weightage and the calculation in case of two
              year policy
            </h5>
            <h5>
              The wellness services and activities are categorized as below:
            </h5>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      className={classes.tableCell + ` ` + classes.headCell}
                    >
                      Sr. No.
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      align="center"
                      className={classes.tableCell + ` ` + classes.headCell}
                    >
                      Activity
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      align="center"
                      className={classes.tableCell + ` ` + classes.headCell}
                    >
                      Maximum number of Wellness Points that can be earned under
                      each policy in a policy year
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* row1 */}
                  <TableRow>
                    <TableCell
                      rowSpan={3}
                      align="center"
                      className={classes.tableCell}
                    >
                      1.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      Manage and Track Health
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      a) Online Health Risk Assessment (HRA)
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      50
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      b) Preventive Risk Assessment
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      200
                    </TableCell>
                  </TableRow>
                  {/* row2 */}
                  <TableRow>
                    <TableCell
                      rowSpan={3}
                      align="center"
                      className={classes.tableCell}
                    >
                      2.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      Affinity to Wellness
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      a) Participating in Walkathon, Marathon, Cyclothon and
                      similar activities
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      100
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      b) Membership in a health club (for 1 year or more)
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      100
                    </TableCell>
                  </TableRow>
                  {/* row3 */}
                  <TableRow>
                    <TableCell align="center" className={classes.tableCell}>
                      3.
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellLeft}>
                      Stay Active – If the Insured member achieves the step
                      count target on mobile app
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      200
                    </TableCell>
                  </TableRow>
                  {/* row4 */}
                  <TableRow>
                    <TableCell
                      rowSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                      4.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      a) Weight Management Program (for the Insured who is
                      Overweight / Obese)
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      100
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      b) Sharing Insured Fitness Success Story through adoption
                      of Star Wellness Program (for the Insured who is not
                      Overweight / Obese)
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      50
                    </TableCell>
                  </TableRow>
                  {/* row5 */}
                  <TableRow>
                    <TableCell
                      rowSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                      5.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      a) Chronic Condition Management Program (for the Insured
                      who is suffering from Chronic Condition/s - Diabetes,
                      Hypertension, Cardiovascular Disease or Asthma)
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      250
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      b) On Completion of De-Stress & Mind Body Healing Program
                      (for the Insured who is not suffering from Chronic
                      Condition/s - Diabetes, Hypertension, Cardiovascular
                      Disease or Asthma)
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      125
                    </TableCell>
                  </TableRow>
                  {/* row */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    ></TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      Additional Wellness Services
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    ></TableCell>
                  </TableRow>
                  {/* row6 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      6.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      Online Chat with Doctor
                    </TableCell>
                    <TableCell
                      rowSpan={8}
                      align="center"
                      className={classes.tableCell}
                    ></TableCell>
                  </TableRow>
                  {/* row7 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      7.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      Medical Concierge Services
                    </TableCell>
                  </TableRow>
                  {/* row8 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      8.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      Period & Fertility Tracker
                    </TableCell>
                  </TableRow>
                  {/* row9 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      9.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      Digital Health Vault
                    </TableCell>
                  </TableRow>
                  {/* row10 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      10.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      Wellness Content
                    </TableCell>
                  </TableRow>
                  {/* row11 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      11.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      Health Quiz & Gamification
                    </TableCell>
                  </TableRow>
                  {/* row12 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      12.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      Post-Operative Care
                    </TableCell>
                  </TableRow>
                  {/* row13 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      13.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      Discounts from Network Providers
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </div>
        </div>
        <ol>
          <li>
            <div style={{ paddingLeft: `2%` }}>
              <h5 style={{ fontWeight: "bold" }}>Manage and Track Health:</h5>
            </div>
            <div>
              <div>
                <p>a) Completion of Health Risk Assessment (HRA):</p>
                The Health Risk Assessment (HRA) questionnaire is an online tool
                for evaluation of health and quality of life of the Insured. It
                helps the Insured to introspect his/ her personal lifestyle. The
                Insured can log into his/her account on the website
                www.starhealth.in and complete the HRA questionnaire. The
                Insured can undertake this once per policy year. On Completion
                of online HRA questionnaire, the Insured earns 50 wellness
                points. Note: To get the wellness points mentioned under HRA,
                the Insured has to complete the entire HRA within one month from
                the time he/she started HRA Activity.
              </div>
            </div>
            <div>
              <div>
                <p>b) Preventive Risk Assessment:</p>
                The Insured can also earn wellness points by undergoing
                diagnostic / preventive tests during the policy year. These
                tests should include the five mandatory tests mentioned below.
                Insured can take these tests at any diagnostic centre at
                Insured’s own expenses.
                <ul style={{ listStyle: "none" }}>
                  <li>
                    - If all the results of the submitted test reports are
                    within the normal range, Insured earns 200 wellness points.
                  </li>
                  <li>
                    - If the result of any one test is not within the normal
                    range as specified in the lab report, Insured earns 150
                    wellness points.
                  </li>
                  <li>
                    - If two or more test results are not within the normal
                    range, Insured earns 100 wellness points only.
                  </li>
                </ul>
                Note: These tests reports should be submitted together and
                within 30 days from the date of undergoing such Health Check-Up.
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={1}
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          List of mandatory tests under Preventive Risk
                          Assessment
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {table4.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            className={classes.tableCellLeft}
                          >
                            <span style={{ paddingLeft: "3%" }}>
                              {index + 1}.{" "}
                            </span>
                            {` `}{" "}
                            <span style={{ paddingLeft: "3%" }}>{row}</span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            </div>
          </li>
          <li>
            <div style={{ paddingLeft: `2%` }}>
              <h5 style={{ fontWeight: "bold" }}>
                Affinity towards wellness: Insured earns wellness points for
                undertaking any of the fitness and health related activities as
                given below.List of Fitness Initiatives and Wellness points:
              </h5>
            </div>
            <div>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableCell + ` ` + classes.headCell}
                      ></TableCell>
                      <TableCell
                        className1={classes.tableCell + ` ` + classes.headCell}
                        style={{
                          border: "1px solid black",
                          color: "black",
                          fontWeight: "800",
                          fontSize: "18px",
                        }}
                      >
                        Initiative
                      </TableCell>
                      <TableCell
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Wellness Points
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        rowSpan={2}
                        align="center"
                        className={classes.tableCell}
                      >
                        a.
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        Participating in Walkathon, Marathon, Cyclothon and
                        similar activities
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        align="center"
                        className={classes.tableCell}
                      >
                        100
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        - On submission of BIB Number along with the details of
                        the entry ticket taken to 100 participate in the event.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        rowSpan={1}
                        align="center"
                        className={classes.tableCell}
                      >
                        b.
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        Membership in a health club (for 1 year or more) - In a
                        Gym / Yoga Centre / Zumba Classes / Aerobic Exercise/
                        Sports Club/ Pilates Classes/ Swimming / Tai Chi/
                        Martial Arts / Gymnastics/ Dance Classes
                      </TableCell>
                      <TableCell
                        rowSpan={1}
                        align="center"
                        className={classes.tableCell}
                      >
                        100
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        <span style={{ paddingLeft: "1%" }}> Note:</span>{" "}
                        <span style={{ paddingLeft: "2%" }}>
                          In case if Insured is not a member of any health club,
                          he/she should join into club within 3 months from the
                          date of the policy risk commencement date. Insured
                          person should submit the health club membership.
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </li>
          <li>
            <div style={{ paddingLeft: `2%` }}>
              <h5 style={{ fontWeight: "bold" }}>
                Stay Active: Insured earns wellness points on achieving the step
                count target on star mobile application as mentioned below:
              </h5>
            </div>
            <div>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className1={classes.tableCell + ` ` + classes.headCell}
                        style={{
                          border: "1px solid black",
                          color: "black",
                          fontWeight: "800",
                          fontSize: "18px",
                        }}
                      >
                        Average number of steps per day in a policy year
                      </TableCell>
                      <TableCell
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Wellness Points
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        <ul style={{ listStyle: "disc" }}>
                          <li>
                            If the average number of steps per day in a policy
                            year are between - 5000 and 7999
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        100
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        <ul style={{ listStyle: "disc" }}>
                          <li>
                            If the average number of steps per day in a policy
                            year are between - 8000 and 9999
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        150
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        <ul style={{ listStyle: "disc" }}>
                          <li>
                            If the average number of steps per day in a policy
                            year are - 10000 and above
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        200
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        <p>Note:</p>
                        <ul style={{ listStyle: "disc" }}>
                          <li>
                            First month and last month in each policy year will
                            not be taken into consideration for calculation of
                            average number of steps per day under Stay Active
                          </li>
                          <li>
                            The mobile app must be downloaded within 30 days of
                            the policy risk start date to avail this benefit.
                          </li>
                          <li>
                            The average step count completed by an Insured
                            member would be tracked on star wellness mobile
                            application.
                          </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </li>
          <li>
            <div style={{ paddingLeft: `2%` }}>
              <h5 style={{ fontWeight: "bold" }}>Weight Management Program:</h5>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                a) This Program will help the Insured persons with Over Weight
                and Obesity to manage their Body Mass Index (BMI) through the
                empanelled wellness experts who will guide the Insured in losing
                excess weight and maintain their BMI.
                <ul style={{ listStyle: "disc" }}>
                  <li>
                    On acceptance of the Weight Management Program, Insured
                    earns 50 wellness points.
                  </li>
                  <li>
                    An additional 50 wellness points will be awarded in case if
                    the results are achieved and maintained as mentioned below.
                  </li>
                </ul>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          Sr. No.
                        </TableCell>
                        <TableCell
                          className1={
                            classes.tableCell + ` ` + classes.headCellLeft
                          }
                          style={{
                            border: "1px solid black",
                            color: "black",
                            fontWeight: "800",
                            fontSize: "18px",
                          }}
                        >
                          Name of the Ailment
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + ` ` + classes.headCellLeft
                          }
                        >
                          Values to submitted
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + ` ` + classes.headCellLeft
                          }
                        >
                          Criteria to get the Wellness points
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" className={classes.tableCell}>
                          1.
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Obesity (If BMI is above 29)
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Height & Weight (to calculate BMI)
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Achieving and maintaining the BMI between 18 and 29
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className={classes.tableCell}>
                          2.
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Overweight (If BMI is between 25 and 29)
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Height & Weight (to calculate BMI)
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Reducing BMI by two points and maintaining the same
                          BMI in the policy year
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          - Values (for BMI) shall be submitted for every 2
                          months (up to 5 times in each policy year)
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </li>
              <li>
                b) Incase if the Insured is not Overweight / Obese, the Insured
                can submit his/her Fitness Success Story through adoption of
                Star Wellness Activities with us. On submission of the Fitness
                Success Story through adoption of Star Wellness Activities,
                Insured earns 50 wellness points.
              </li>
            </ul>
          </li>
          <li>
            <div style={{ paddingLeft: `2%` }}>
              <h5 style={{ fontWeight: "bold" }}>
                Chronic Condition Management Program:
              </h5>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                a) This Program will help the Insured suffering from Diabetes,
                Hypertension, Cardiovascular Disease or Asthma to track their
                health through the empanelled wellness experts who will guide
                the insured in maintaining/ improving the health condition.
                <ul style={{ listStyle: "disc" }}>
                  <li>
                    On acceptance of the Chronic Condition Management Program,
                    Insured earns 100 wellness points
                  </li>
                  <li>
                    The Insured has to submit the test result values for every 3
                    months maximum up to 3 times in a policy year
                  </li>
                  <li>
                    If the test result values are within +/- 10% range of the
                    values given below, for at least 2 times in a policy year,
                    an additional 150 wellness points will be awarded.
                  </li>
                  <li>
                    These tests reports to be submitted within 1 month from the
                    date of undergoing the Health Check-Up
                  </li>
                </ul>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          Sr. No.
                        </TableCell>
                        <TableCell
                          className1={
                            classes.tableCell + ` ` + classes.headCell
                          }
                          style={{
                            border: "1px solid black",
                            color: "black",
                            fontWeight: "800",
                            fontSize: "18px",
                          }}
                        >
                          Name of the Ailment
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          Test to be submitted
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          Values Criteria to get the additional Wellness points
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          rowSpan={2}
                          align="center"
                          className={classes.tableCell}
                        >
                          1.
                        </TableCell>
                        <TableCell
                          rowSpan={2}
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Diabetes(Insured can submit either HbA1c test value
                          (or) Fasting Blood Sugar (FBS) Range and Postprandial
                          test value)
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          HbA1c
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          ≤ 6.5
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Fasting Blood Sugar (FBS) Range and Postprandial test
                          value
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          100 to 125 mg/dl below 160 mg/dl
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          rowSpan={1}
                          align="center"
                          className={classes.tableCell}
                        >
                          2.
                        </TableCell>
                        <TableCell
                          rowSpan={1}
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Hypertension
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Measured with - BP apparatus
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Systolic Range - 110 to 140 mmHg Diastolic Range - 70
                          to 90 mmHg
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          rowSpan={1}
                          align="center"
                          className={classes.tableCell}
                        >
                          3.
                        </TableCell>
                        <TableCell
                          rowSpan={1}
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Cardiovascular Disease
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          LDL Cholesterol and Total Cholesterol / HDL
                          Cholesterol Ratio
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          100 to 159 mg/dl ≤ 4.0
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          rowSpan={1}
                          align="center"
                          className={classes.tableCell}
                        >
                          4.
                        </TableCell>
                        <TableCell
                          rowSpan={1}
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Asthma
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          PFT (Pulmonary Function Test)
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          FEV1 (PFC) is 75% or more FEV1/ FVC is 70% or more
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </li>
              <li>
                b) In case if the Insured is not suffering from Chronic
                Condition/s (Diabetes, Hypertension, Cardiovascular Disease or
                Asthma) he/she can opt for “De-Stress & Mind Body Healing
                Program”. This program helps the Insured to reduce stress caused
                due to internal (self-generated) & external factors and
                increases the ability to handle stress.
                <ul style={{ listStyle: "none" }}>
                  <li>
                    - On acceptance of De-stress & Mind Body Healing Program
                    Insured earns 50 wellness points.
                  </li>
                  <li>
                    - On completion of De-stress & Mind Body Healing Program
                    Insured earns an additional 75 wellness points.
                  </li>
                </ul>
                <p>
                  Note: This is a 10 weeks program which insured needs to
                  complete without any break.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <h5 style={{ fontWeight: "bold" }}>Online Chat with Doctor:</h5>{" "}
            Insured can consult qualified healthcare professionals at their
            convenience. The Doctor Chat feature allows Insured to “Chat” with
            qualified Doctors, available from Monday to Friday between 9.00 AM
            and 6.00 PM to help Insured with advice and quick consultations
            including on Diet & Nutrition and Second Medical Opinion. They do
            not prescribe any medications or diagnose any health issues.
          </li>
          <li>
            <h5 style={{ fontWeight: "bold" }}>Medical Concierge Services:</h5>{" "}
            The Insured can also contact Star Health to avail the following
            services:- Emergency assistance information such as nearest
            ambulance / hospital / blood bank etc.
          </li>
          <li>
            <h5 style={{ fontWeight: "bold" }}>Period & Fertility Tracker:</h5>{" "}
            The online easy tracking program helps every woman with their period
            health and fertility care. The program gives access to trackers for
            period and ovulation which maps out cycles for months. This helps in
            planning for conception prevention and tracks peak ovulation if
            planning pregnancy.
          </li>
          <li>
            <h5 style={{ fontWeight: "bold" }}>Digital Health Vault:</h5> A
            secured Personal Health records system for Insured to store/access
            and share health data with trusted recipients. Using this portal,
            Insured can store their health documents (prescriptions, lab
            reports, discharge summaries etc.), track health data add family
            members.
          </li>
          <li>
            <h5 style={{ fontWeight: "bold" }}>Wellness Content:</h5> The
            wellness portal provides rich collection of health articles, blogs,
            tips and other health and wellness content. The contents have been
            written by experts drawn from various fields. Insured will benefit
            from having one single and reliable source for learning about
            various health aspects and incorporating positive health changes.
          </li>
          <li>
            <div style={{ paddingLeft: `0%` }}>
              <h5 style={{ fontWeight: "bold" }}>
                Health Quiz & Gamification:
              </h5>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                - The wellness portal provides a host of Health & Wellness
                Quizzes. The wellness quizzes are geared towards helping the
                Insured to be more aware of various health choices.
              </li>
              <li>
                - Gamification helps in creating fun and engaging health &
                wellness experiences. It helps to create a sense of achievement
                in users and increases motivation levels.
              </li>
            </ul>
          </li>
          <li>
            <h5 style={{ fontWeight: "bold" }}>Post Operative Care:</h5> It is
            done through follow up phone calls (primarily for surgical cases)
            for resolving their medical queries.
          </li>
          <li>
            <h5 style={{ fontWeight: "bold" }}>
              Discounts from Network Providers:
            </h5>{" "}
            The Insured can avail discounts on the services offered by our `
            network providers which will be displayed in our website.
          </li>
        </ol>
        <div style={{ paddingLeft: `2%` }}>
          <h5 style={{ fontWeight: "bold" }} className={classes.exclusionFont}>
            Terms and conditions under wellness activity
          </h5>
        </div>
        <div>
          <ul>
            <li>
              Any information provided by the Insured in this regard shall be
              kept confidential.
            </li>
            <li>
              There will not be any cash redemption against the wellness reward
              points.
            </li>
            <li>
              Insured should notify and submit relevant documents, reports,
              receipts etc for various wellness activities within 1 month of
              undertaking such activity/test.
            </li>
            <li>
              No activity, report, document, receipt can be submitted in the
              last month of each policy year.
            </li>
            <li>
              For services that are provided through empanelled service
              provider, Star Health is only acting as a facilitator; hence would
              not be liable for any incremental costs or the services.
            </li>
            <li>
              All medical services are being provided by empanelled health care
              service provider. We ensure full due diligence before empanelment.
              However Insured should consult his/her doctor before
              availing/taking the medical advices/services. The decision to
              utilize these advices/services is solely at Insured person’s
              discretion.
            </li>
            <li>
              We reserve the right to remove the wellness reward points if found
              to be achieved in unfair manner.
            </li>
            <li>
              Star Health, its group entities, or affiliates, their respective
              directors, officers, employees, agents, vendors, are not
              responsible or liable for, anyactions, claims, demands, losses,
              damages, costs, charges and expenses which a Member claims to have
              suffered, sustained or incurred, by way ofand / or on account of
              the Wellness Program.
            </li>
            <li>
              Services offered are subject to guidelines issued by IRDA from
              time to time.
            </li>
          </ul>
        </div>
        <div>
          <div>
            <p>
              <b>
                <u>ILLUSTRATION OF BENEFITS:</u>
              </b>
            </p>
          </div>
          <div style={{ paddingLeft: `2%` }}>
            <div>
              <h5>
                <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                  <i>
                    {" "}
                    Lets look how the Insured can avail discount on premium
                    through the “Star Wellness Program”
                  </i>
                </p>
              </h5>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableRow}>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        className={
                          classes.tableCell + ` ` + classes.headCellLeft
                        }
                        style={{ fontWeight: "normal" }}
                      >
                        <b>Scenario – 1</b>
                        <br />
                        <br />
                        <br />
                        <p>
                          A 40 year old Individual Ramesh buys Star
                          Comprehensive Insurance Policy (Individual Sum
                          Insured) on <b>15th March, 2019</b> on payment of
                          Rs.17,615/- per year (excluding taxes), with Sum
                          Insured 25 Lacs, let’s understand how he can earn{" "}
                          <b>Wellness Points</b> by doing different wellness
                          activities. Ramesh has declared that his Body Mass
                          Index (BMI) as 24 and he is a Diabetic. Ramesh
                          enrolled under the Star Wellness Program and completed
                          the following <b>wellness activities.</b>
                        </p>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Sr.No
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Name of the wellness activity taken up during the policy
                        year
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Wellness Points Earned
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table9.map((row, index) => (
                      <TableRow key={row[0]}>
                        <TableCell align="left" className={classes.tableCell}>
                          {index + 1}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[0]}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[1]}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                      ></TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        Total Number of Wellness Points earned
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        850
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        align="left"
                        className={classes.tableCellLeft}
                      >
                        Based on the number of Wellness Points earned Ramesh is
                        eligible to get 10% discount on renewal premium.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </div>
          <div style={{ marginTop: "2%" }}>
            <p>
              <b>
                <u>ILLUSTRATION OF BENEFITS:</u>
              </b>
            </p>
          </div>
          <div style={{ paddingLeft: `2%` }}>
            <div>
              <h5>
                <p
                  className={classes.root}
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  <i>
                    {" "}
                    Lets look how the Insured can avail discount on premium
                    through the “Star Wellness Program”
                  </i>
                </p>
              </h5>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableRow}>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        className={
                          classes.tableCell + ` ` + classes.headCellLeft
                        }
                        style={{ fontWeight: "normal" }}
                      >
                        <b>Scenario – 2</b>
                        <br />
                        <br />
                        <br />
                        <p>
                          A 42 year old Individual Suresh and his wife Lakshmi
                          along with their two dependent children (aged below 18
                          yrs) buy a Star Comprehensive Insurance Policy
                          (Floater Sum Insured) on 20th, March, 2019 on payment
                          of Rs.34,220/- per year (excluding taxes), with Sum
                          Insured 25 Lacs, let’s understand how they can earn
                          Wellness Points under the Floater Policy. Suresh has
                          declared that he is suffering from Diabetes &
                          Hypertension. Suresh has declared his Body Mass Index
                          (BMI) as 30 & Lakshmi has declared her BMI as 25
                        </p>
                        <p>
                          Suresh and Lakshmi enrolled under the Star wellness
                          program and completed the following{" "}
                          <b>wellness activities.</b>
                        </p>
                        <br />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Sr.No
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Name of the wellness activity taken up during the policy
                        year
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Wellness Points Earned by Ramesh
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Wellness Points Earned by Lakshmi
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table10.map((row, index) => (
                      <TableRow key={row[0]}>
                        <TableCell align="left" className={classes.tableCell}>
                          {index + 1}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[0]}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[1]}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[2]}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                      ></TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        Total Number of Wellness Points earned
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        1000
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        775
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                      ></TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        No of wellness points based upon weightage - 1:1
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        500 <br /> <br />
                        (1000X1/2)
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        388 <br /> <br />
                        (775X1/2)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        align="left"
                        className={classes.tableCellLeft}
                      >
                        Total Number of Wellness Points earned by Suresh and
                        Lakshmi = 888 (500+388)
                        <br /> <br />
                        Based on the no of Wellness Points earned, Suresh &
                        Lakshmi are eligible to get 10% discount on renewal
                        premium
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </div>
          <div style={{ marginTop: "2%" }}>
            <p>
              <b>
                <u>ILLUSTRATION OF BENEFITS:</u>
              </b>
            </p>
          </div>
          <div style={{ paddingLeft: `2%` }}>
            <div>
              <h5>
                <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                  <i>
                    {" "}
                    Lets look how the Insured can avail discount on premium
                    through the “Star Wellness Program”
                  </i>
                </p>
              </h5>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableRow}>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        className={
                          classes.tableCell + ` ` + classes.headCellLeft
                        }
                        style={{ fontWeight: "normal" }}
                      >
                        <b>Scenario – 3</b>
                        <br />
                        <br />
                        <br />
                        <p>
                          A 27 year old Individual Umesh buys Star Comprehensive
                          Insurance Policy (Individual Sum Insured) for two year
                          period, with Sum Insured 25 Lacs, let’s understand how
                          he can earn <b>Wellness Points</b> by doing different
                          wellness activities. Umesh has declared that his Body
                          Mass Index (BMI) is 24 and he is not suffering with
                          any Chronic Condition. Umesh enrolled under the Star
                          Wellness Program and completed the following{" "}
                          <b>wellness activities.</b>
                        </p>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Sr.No
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Name of the wellness activity taken up during the policy
                        year
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Wellness Points Earned in the First Year
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Wellness Points Earned in the Second Year
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table11.map((row, index) => (
                      <TableRow key={row[0]}>
                        <TableCell align="left" className={classes.tableCell}>
                          {index + 1}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[0]}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[1]}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[2]}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                      ></TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        Total Number of Wellness Points earned
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        825
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        825
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        align="left"
                        className={classes.tableCellLeft}
                      >
                        Total Number of Wellness Points earned by Umesh = 2000
                        (1000+1000)
                        <br /> <br />
                        Calculation of Wellness Points as per two year policy
                        condition = 1000 (2000/2)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        align="left"
                        className={classes.tableCellLeft}
                      >
                        Based on the number of Wellness Points earned, Umesh is
                        eligible to get 10% discount on renewal premium.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </div>
        </div>
      </GridItem>
    </GridContainer>
  );
};
ComprehensiveWellnessBenefits.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(ComprehensiveWellnessBenefits);
