"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _Input = require("@material-ui/core/Input");

var _Input2 = _interopRequireDefault(_Input);

var _InputLabel = require("@material-ui/core/InputLabel");

var _InputLabel2 = _interopRequireDefault(_InputLabel);

var _MenuItem = require("@material-ui/core/MenuItem");

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _FormControl = require("@material-ui/core/FormControl");

var _FormControl2 = _interopRequireDefault(_FormControl);

var _Select = require("@material-ui/core/Select");

var _Select2 = _interopRequireDefault(_Select);

var _Chip = require("@material-ui/core/Chip");

var _Chip2 = _interopRequireDefault(_Chip);

var _index = require("../../../../core/dist/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
  return {
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    formControl: {
      // margin: theme.spacing.unit,
      minWidth: 120,
      maxWidth: 300
    },
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      margin: theme.spacing.unit / 4
    }
  };
};

var MaterialUiMultiSelect = function (_React$Component) {
  _inherits(MaterialUiMultiSelect, _React$Component);

  function MaterialUiMultiSelect() {
    _classCallCheck(this, MaterialUiMultiSelect);

    return _possibleConstructorReturn(this, (MaterialUiMultiSelect.__proto__ || Object.getPrototypeOf(MaterialUiMultiSelect)).apply(this, arguments));
  }

  _createClass(MaterialUiMultiSelect, [{
    key: "render",
    value: function render() {
      // $FlowFixMe - HOC adds this class
      var classes = this.props.classes;
      var _props = this.props,
          disabled = _props.disabled,
          id = _props.id,
          isValid = _props.isValid,
          name = _props.name,
          _props$options = _props.options,
          options = _props$options === undefined ? [] : _props$options,
          placeholder = _props.placeholder,
          required = _props.required,
          _props$value = _props.value,
          value = _props$value === undefined ? [] : _props$value,
          label = _props.label,
          onFieldChange = _props.onFieldChange,
          valueDelimiter = _props.valueDelimiter;


      var items = options.reduce(function (itemsSoFar, option) {
        return itemsSoFar.concat(option.items.map(function (item) {
          if (typeof item === "string") {
            return _react2.default.createElement(
              _MenuItem2.default,
              { value: item },
              item
            );
          } else {
            return _react2.default.createElement(
              _MenuItem2.default,
              { value: item.value },
              item.label || item.value
            );
          }
        }));
      }, []);

      var processedValue = value;
      if (valueDelimiter && typeof value === "string") {
        processedValue = value.split(valueDelimiter);
      }

      if (processedValue && !Array.isArray(processedValue)) {
        processedValue = [processedValue];
      }

      return _react2.default.createElement(
        "div",
        { key: id },
        _react2.default.createElement(
          _FormControl2.default,
          {
            key: id,
            disabled: disabled,
            className: classes.formControl
          },
          _react2.default.createElement(
            _InputLabel2.default,
            { htmlFor: id },
            label
          ),
          _react2.default.createElement(
            _Select2.default,
            {
              multiple: true,
              value: processedValue,
              onChange: function onChange(evt) {
                onFieldChange(id, evt.target.value);
              },
              input: _react2.default.createElement(_Input2.default, { name: name, id: id }),
              renderValue: function renderValue(selected) {
                return _react2.default.createElement(
                  "div",
                  { className: classes.chips },
                  selected && typeof selected.map === "function" && selected.map(function (value) {
                    return _react2.default.createElement(_Chip2.default, { key: value, label: value, className: classes.chip });
                  })
                );
              }
            },
            items
          )
        )
      );
    }
  }]);

  return MaterialUiMultiSelect;
}(_react2.default.Component);

var StyledMaterialUiMultiSelect = (0, _styles.withStyles)(styles)(MaterialUiMultiSelect);

exports.default = function (props) {
  return _react2.default.createElement(
    _index.FieldWrapper,
    props,
    _react2.default.createElement(StyledMaterialUiMultiSelect, null)
  );
};