import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import customStepperStyle from 'ps-assets/jss/customStepperStyle.jsx';
import root from 'window-or-global';
import { browserDocument, browserWindow } from "util/localStorageHelper";

function CustomStep(props) {
  return (
    <GridItem
      md={3}
      lg={3}
      sm={3}
      xs={12}
      className={props.data.connectorfixpadding}>
      <Step {...props} />
    </GridItem>
  );
}

class CustomStepperAcci extends React.Component {
  state = {
    disabled: false,
    error: ``
  };

  handleNext = e => {
    e.preventDefault();

    var s = root.document.getElementById(`submitButton`);
    if (s) {
      s.click();
    }
    var error = null;
    if (error) {
      this.setState({ error: `*Please enter all values` });
      error.focus();
    } else if (s && s.disabled) {
      this.setState({ error: `*Please enter all values` });
    } else {
      this.setState({ error: `` });
      this.props.handleFormState(this.props.activeStep + 1);
    }
  };

  handleBack = () => {
    this.props.handleFormState(this.props.activeStep - 1);
  };

  handleCallback = () => {
    browserDocument.getElementsByClassName(`wbf-screen`)[0].classList.add(`active`);
    browserDocument.getElementsByClassName(`wbf-container`)[0].classList.add(`active`);
  };

  render() {
    const { classes, steps, content, activeStep } = this.props;
    const connector = (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          completed: classes.connectorCompleted,
          disabled: classes.connectorDisabled,
          line: classes.connectorLine
        }}
        className={classes.connectorfix}
        style={{ left: `calc(-50% + -10px)` }}
      />
    );
    return (
      <div className={classes.container}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={connector}
          className={classes.root}>
          {steps.map(label => (
            <CustomStep data={classes} key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.icon,
                    active: classes.active,
                    completed: classes.active
                  }
                }}
                classes={{
                  labelContainer: classes.label
                }}>
                {label}
              </StepLabel>
            </CustomStep>
          ))}
        </Stepper>

        <div style={{ padding: `24px`, margin: `0 auto` }}>
          <div>
            <form onSubmit={this.handleNext}>
              {content && content[activeStep]}
              <div className={classes.errorMsg}>{this.state.error}</div>
              <div style={{ paddingTop: `30px`, minHeight: `80px` }}>
                {activeStep !== 0 && (
                  <Button
                    round
                    variant='contained'
                    color='primary'
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.button}>
                    Back
                  </Button>
                )}
                {activeStep === steps.length - 1
                  ? !browserWindow.location.href.includes(`#agent`) && (
                    <Button
                      round
                      variant='contained'
                      color='primary'
                      className={`${classes.button} sumInsuredBuyNowGA`}
                      style={{ float: `right` }}
                      disabled={
                        !this.props.premiumAmount ||
                          this.props.premiumAmount < 0
                      }
                      onClick={this.props.handleBuyNow}>
                        <span className={`sumInsuredBuyNowGA`}> Buy Now</span>
                    </Button>
                  )
                  : activeStep !== 1 && (
                    <Button
                      disabled={this.state.disabled}
                      round
                      variant='contained'
                      color='primary'
                      className={`${classes.button} ${activeStep==0 ? `contactDetailNextGa` :`basicInfoNextGA`}`}
                      style={{ float: `right` }}
                      type='submit'>
                       <span className={activeStep==0 ? `contactDetailNextGa` :`basicInfoNextGA`}> Next</span>
                    </Button>
                  )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CustomStepperAcci.propTypes = {
  classes: PropTypes.object,
  handleFormState: PropTypes.func,
  activeStep: PropTypes.number,
  steps: PropTypes.array,
  content: PropTypes.array,
  ctaLabel: PropTypes.string,
  premiumAmount: PropTypes.object,
  handleBuyNow: PropTypes.func,
  handleCallNow: PropTypes.func,
  buyNowUrl: PropTypes.string
};

CustomStep.propTypes = {
  data: PropTypes.object
};

export default withStyles(customStepperStyle)(CustomStepperAcci);
