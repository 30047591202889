import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
// import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "components/Accordion/Accordion.jsx";
import { Helmet } from "react-helmet";


const table1 = [
    ['Renewal', 'Lifelong'],
    ['Policy period', '1 year'],
    ['Sum Insured in Rs', '0.5 lakh to 10 lakhs (Multiples of 50,000)'],
    ['Co-payment ', '5% to all admissible claims'],
    ['Discounts in premium', '20% rural discount'],
]

const table2 = [
    ['In-patient Hospitalisation','Up to S.I and bonus (if any)','Covers all in-patient hospitalisation expenses.'],
    ['Pre-Hospitalisation','Up to S.I.','Covers related pre-hospitalisation expenses for fixed period of 30 days prior to the date of admissible hospitalization.'],
    ['Post-Hospitalisation','Up to S.I.','Covers post-hospitalisation expenses for a fixed period of 60 days from the date of discharge from the hospital.'],
    ['Daycare Treatments/Procedures','Covered','Covers all daycare procedures, surgeries, and treatments that require less than 24 hours of hospitalisation.'],
    ['Covid-19 treatments','Covered','Covers treatment for Covid-19 along with other illnesses. However, an initial waiting period of 30 days is applicable for COVID-19 related expenses under this policy.'],
    ['Modern Treatment','Covered',<h5 key="modern_treatment">
        <h5 key="modern_treatment1" style={{fontSize:'14px',fontWeight:'400'}}>Twelve modern treatments, including balloon sinuplasty, immunotherapy, and stem cell therapy, are covered.</h5>
    <h5 key="modern_treatment2" style={{fontSize:'14px',fontWeight:'400'}}>
    Up to 50% of sum insured
        </h5></h5>],
    ['Cataract Surgery','Covered','The policy covers expenses incurred for cataract treatment up to 25% of the sum insured or Rs.40,000/-, whichever is lesser. This is applicable for each eye in a policy year.'],
]

const faq=[
    {
        title:'Does Arogya Sanjeevani Policy cover Critical Illness?',
        description:<h5>Arogya Sanjeevani is a common product designed by the insurance regulator. But few insurers are offering optional cover of Critical illness by demanding additional Premium. But Star health offers all features designed by the regulator, but the policy holders can avail good cashless facility across india.</h5>,
        content:false
    },
    {
        title:'What is the policy tenure of Arogya Sanjeevani Policy?',
        description:<h5>Arogya Sanjeevani has a policy term of one year. The Premiums can be paid quarterly, half-yearly & annually</h5>,
        content:false
    },
    {
        title:'Do I have to pay any amount while claiming under Arogya Sanjeevani Policy?',
        description:<h5>5% copay to all admissible claims.</h5>,
        content:false
    },
    {
        title:'What is the sum insured option provided under Arogya Sanjeevani Policy?',
        description:<h5>The sum insured options provided under this policy ranges from ₹50000 to ₹10,00,000 in multiples of fifty thousand.</h5>,
        content:false
    }
]
        

const ArogyaSanjeeviniHighlights = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });
    return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Arogya Sanjeevani Policy, Star Health and Allied Insurance Co Ltd.
            </h2>
            </GridItem>
                <h5>
                Although we know the importance of staying healthy and safe, health insurance plans are often at the bottom of our priority list. We are frequently plagued with questions regarding the significance of health insurance. “Does it serve its purpose?”, “Why should I get one when I am healthy?” “Can I spend this premium on something more worthwhile?”. While these are very valid questions, but if there is anything that 2020 has taught us, unpredictability can lurk in corners unknown to us.
                </h5>

             <h5>
             Medical treatment is expensive, especially in the private sector, therefore purchasing health insurance coverage for yourself and your family is essential. And as we know, hospitalisation might drain your bank account and throw your finances off course. We would recommend that you obtain a health insurance policy for yourself and your family so that you can mitigate the cost of any medical emergencies. 
             </h5>
             <h5>
             The Arogya Sanjeevani Policy is introduced by the Insurance Regulatory and Development Authority of India (IRDAI). The policy has been developed to cater to the needs of those who are looking for complete coverage at an affordable price. While medical costs have been rising for decades and are expected to keep increasing, purchasing the Arogya Sanjeevani Insurance Policy might help the insured stay financially secure in the face of medical emergencies.
             </h5>
             <h5>
             Arogya Sanjeevani Policy is an indemnity-based health insurance policy offering coverage up to Rs. 10 lakhs on individual and floater basis for in-patient hospitalisation expenses, daycare treatments/procedures, COVID-19 treatment, AYUSH treatment, and more. 
             </h5>
             <h5>
             Arogya Sanjeevani Policy allows you to opt for a family floater plan that will cover Self, spouse and dependant children between 3 months and 25 years. Additionally, you can buy this plan for parents and parents-in-law as well. 
             </h5>
             <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Coverage under Arogya Sanjeevani Policy includes:
            </h2>
            </GridItem>
             <ul>
                <li>
                In-patient hospitalisation expenses
                </li>
                <li>
                Pre- and post-hospitalisation expenses 
                </li>
                <li>
                Cataract treatment
                </li>
                <li>
                AYUSH treatment
                </li>
                <li>
                Daycare treatments
                </li>
                <li>
                Ambulance expenses
                </li>
                <li>
                Modern treatments
                </li>
                <li>
                ICU/ ICCU expenses
                </li>
                <li>
                Covid-19 treatment cover
                </li>
                <li>
                Tele Medicine Service – talk to Star
                </li>
                </ul>
        <div>
        <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Product Features of the Arogya Sanjeevani Health Insurance Policy
            </h2>
            </GridItem>
        <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={2}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                18 years to 65 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Dependent Children – 3 months to 25 years
                            </TableCell>
                        </TableRow>
                        {table1.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting Periods
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-48 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24/48 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>
        <div style={{marginTop:'5%'}}>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Benefits
                                  </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Coverage limit
                                </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                >
                                   Description of cover
                         </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {table2.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[2]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                        </Table>
                </Paper>
                </div>
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Features of the Arogya Sanjeevani Policy
            </h2>
            </GridItem>
            <ul>
                <li>
                <b>Eligibility</b>
                <br />
                <h5>
                Arogya Sanjeevani Health Insurance Policy can be purchased by individuals between 18 and 65 years of age. The Arogya Sanjeevani family floater plan allows the insurer to cover up to two adults and three dependent children between 3 months and 25 years and parents and parents in law.
                </h5>
                </li>
                <li>
                <b>Sum insured</b>
                <br />
                <h5>
                The plan provides sum insured options ranging from a minimum of Rs. 50,000 to a maximum of Rs. 10 lakhs under the individual and floater basis.
                </h5>
                </li>
                <li>
                <b>Policy term</b>
                <br />
                <h5>
                Available for 1 year.
                </h5>
                </li>
                <li>
                <b>Co-payment</b>
                <br />
                <h5>
                The policy has a co-payment of 5% on admissible claim, which means that the Policy holder (insured) to pay 5% and 95% would be paid by the insurance company (insurer) of the eligible claim amount. This means that every time a claim is filed with the company, the insured will be required to pay a fixed 5% of the claim, and then Star Health Insurance will process the rest of the claim.
                </h5>
                </li>
                <li>
                <b>Cumulative bonus</b>
                <br />
                <h5>
                Arogya Sanjeevani Policy offers its policy holders a 5% of the sum insured as bonus for every claim-free year subject to a maximum of 50% of the sum insured.
                </h5>
                </li>
                <li>
                <b>Waiting period</b>
                <br />
                <h5>
                <ol>
                    <li>
                    Any illness contracted within 30 days of the policy inception date is not covered. The exception is in case of an accident.
                    </li>
                    <li>
                    Coverage for specified illnesses/ surgeries becomes effective after 24/48 months.
                    </li>
                    <li>
                    Pre-existing diseases are covered after a waiting period of 48 months from the policy inception date..
                    </li>
                </ol>
                </h5>
                </li>
                <li>
                <b>Portability</b>
                <br />
                <h5>
                This policy can be ported. Such option should be exercised at least 45 days before, but not earlier than 60 days from the policy renewal date.
                </h5>
                </li>
                <li>
                <b>Pre-medical screening</b>
                <br />
                <h5>
                Persons above 50 years of age will have to undergo pre-acceptance health screening at the company&apos;s nominated centers.    
                </h5>
                </li>
                <li>
                <b>Lifelong renewability</b>
                <br />
                <h5>
                While the eligibility for the purchase of this policy is limited to those between the ages of 18 to 65 years, this policy provides lifelong renewability as long as it is renewed on time. By doing so, the insured can enjoy continuous benefits like on coverage of PED’, cumulative bonus, etc.
                </h5>
                </li>
            </ul>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Why should you choose the Arogya Sanjeevani Policy?
            </h2>
            </GridItem>
            <h5>
            With almost the entire world experiencing some form of financial complications in this period of a global medical emergency, we are on the cusp of something inexpensive and highly defensive to meet our medical emergencies.
            </h5>
            <h5>
            The Arogya Sanjeevani policy is sufficient to cover health insurance needs providing extensive coverage including a wide range of medical expenses such as hospitalisation expenses, daycare treatments, and modern treatments.
            </h5>
            <h5>
            This is a standard health insurance policy launched by the Insurance Regulatory and Development Authority of India (IRDAI). The policy attracts first-time buyers as it is designed to be comprehensive yet simple.
            </h5>
            <h5>
            The Arogya Sanjeevani policy is more beneficial for younger people looking at a health insurance cover for themselves starting from Rs 50,000. Arogya Sanjeevani’s plan is a perfect fit for families with a sum insured option of up to 10 lakhs. Arogya Sanjeevani will help you work out the cover you need without having to stretch your budget too much. Accidents are covered from the day of purchase of the policy.
            </h5>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Inclusions of Arogya Sanjeevani Policy
            </h2>
            </GridItem>
            <h5>
            Here are various expenses that are covered under the Arogya Sanjeevani Policy
            </h5>
            <ul>
            <li>
                <b>Hospitalisation expenses</b>
                <br />
                <ol>
                    <li>
                    Room rent, boarding, and nursing expenses are covered up to 2% of the sum insured subject to a maximum of Rs.5000 per day.
                    </li>
                    <li>
                    Intensive Care Unit (ICU) and Intensive Cardiac Care Unit (ICCU) expenses are covered up to 5% of the sum insured subject to a maximum of Rs.10000 per day.
                    </li>
                    <li>
                    Surgeons, anesthetists, medical practitioners, consultants, and specialist fees are covered.
                    </li>
                    <li>
                    Costs incurred on anesthesia, blood, oxygen, operation theatre charges, surgical appliances, medicines and drugs, costs towards diagnostics, diagnostic imaging modalities.
                    </li>
                </ol>
                </li>
                <li>
                <b>AYUSH treatment</b>
                <br />
                <h5>
                Inpatient care treatment taken in AYUSH Hospital  under Ayurveda,Yoga and Naturopathy, Unani, Siddha and Homeopathy systems of medicines during each Policy Year up to the limit of sum insured. Also mention it in the table itself
                </h5>
                </li>
                <li>
                <b>Ambulance expenses</b>
                <br />
                <h5>
                The policy covers road ambulance expenses charges up to a limit of Rs. 2000 per hospitalisation.
                </h5>
                </li>
                <li>
                <b>Accident cover</b>
                <br />
                <h5>
                All in-patient hospitalisation expenses related to accidental injury are covered under the Arogya Sanjeevani Insurance Policy.
                </h5>
                </li>
                <li>
                <b>Modern treatments</b>
                <br />
                <h5>
                Twelve modern treatments are covered up to 50% of the sum Insured.
                </h5>
                <GridItem  style={{ paddingLeft:'0px'}}>
                    <h2 className={classes.h2hTitle}>
                    Modern Treatments covered under the Arogya Sanjeevani Policy
                    </h2>
                    </GridItem>
                    <ol>
                        <li>
                        Uterine artery embolization and HIFU (High intensity focused ultrasound)
                        </li>
                        <li>
                        Balloon sinuplasty
                        </li>
                        <li>
                        Deep brain stimulation
                        </li>
                        <li>
                        Oral chemotherapy
                        </li>
                        <li>
                        Immunotherapies - monoclonal antibody to be given as an injection
                        </li>
                        <li>
                        Intra vitreal injections
                        </li>
                        <li>
                        Robotic surgeries
                        </li>
                        <li>
                        Stereotactic radio surgeries
                        </li>
                        <li>
                        Bronchial Thermoplasty
                        </li>
                        <li>
                        Vaporisation of the prostrate
                        </li>
                        <li>
                        ION M Intra Operative Neuro Monitoring
                        </li>
                        <li>
                        Stem cell therapy
                        </li>
                    <li>
                    <b>ICU/ICCU expenses</b>
                    <br />
                    <h5>
                    The policy covers expenses incurred due to admission to the ICU / ICCU up to the policy’s sub-limits. This covers the reimbursement / payment of all other hospital expenses, excluding medicine costs, in accordance with the permissible rate per day to the actual rate per day of ICU/ICCU charges.
                    </h5>
                    </li>
                    <li>
                    <b>COVID-19 treatment cover</b>
                    <br />
                    <h5>
                    Arogya Sanjeevani Mediclaim Policy covers treatment for Covid-19 along with other illnesses. However, an initial waiting period of 30 days is applicable for COVID-19 related expenses under this policy. 
                    </h5>
                    </li>
                    </ol>
                </li>
            </ul>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Key Benefits of Arogya Sanjeevani Policy
            </h2>
            </GridItem>
            <h5>
                <h5>
                <b>
                Lower premiums
                </b>
                </h5>
                <h5>
                Keeping in mind the rising medical costs in India, the Arogya Sanjeevani policy’s premiums are comparatively lower than other health insurance policies in the market.
                </h5>
            </h5>
            <h5>
                <h5>
                <b>
                Low co-payment
                </b>
                </h5>
                <h5>
                There is a minimum co-payment of 5% required to be paid by the insured during an admissible claim in this policy.
                </h5>
            </h5>
            <h5>
                <h5>
                <b>
                Instalment premium options
                </b>
                </h5>
                <h5>
                The insured person can choose to pay their premiums in instalments, such as half-yearly or quarterly.
                </h5>
            </h5>

            <h5>
                <h5>
                <b>
                Tax benefits
                </b>
                </h5>
                <h5>
                As per Section 80D of the Income Tax Act of 1961, an income tax assessee, either individual or family members under the age of 60 can get Rs. 25,000 rebate on health insurance premium payment, and the amount can reach up to Rs. 50,000 for senior citizens above 60 years. The deductible amount would increase to Rs 1 lakh if two family members over 60 were insured under one policy. Conversely, if one family member is under 60 years of age and his parents are enrolled under the same policy, they can get a deduction of Rs 75,000.
                </h5>
            </h5>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            What are the exclusions of the Arogya Sanjeevani Policy
            </h2>
            </GridItem>
            <h5>
            The following list of policy exclusions is partial and not a complete list. Make sure you read through all the exclusions in the policy clauses.
            </h5>
            <ul>
                <li>
                Expenses related to the treatment of pre-existing diseases (PED) and their direct complications before the expiry of the waiting period as intended in the policy clauses.
                </li>
                <li>
                Treatments, surgeries, and diseases prescribed under the insurance policy clauses will not be covered before the waiting period of either 24 months or 48 months, as applicable.
                </li>
                <li>
                The policy will not cover medical bills arising due to illness within the first 30 days of policy inception under the Arogya Sanjeevani Policy except for Accidental medical expenses, which will be covered from the day of purchase of the policy.
                </li>
                <li>
                Any treatments related to gender change
                </li>
                <li>
                Home treatments and OPD treatments
                </li>
            </ul>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            How to buy this policy? 
            </h2>
            </GridItem>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h3 className={classes.h3_customstyle}>Buying Arogya Sanjeevani policy online is very simple:</h3>
            </GridItem>
            <h5>
            Step 1: Visit the Star Health website or mobile app and find the Arogya Sanjeevani Policy. Provide your name, mobile number and e-mail address.
            </h5>
            <h5>
            Step 2: Enter a few key details, the number of people you want to take the policy for (if buying for family), birth date, policy term.
            </h5>
            <h5>
            Step 3: Upon sharing this information, you will get a final quote on your premium on your sum insured opted and you can proceed with the payment. Now, you will have the policy in just a few minutes in your inbox.
            </h5>
            <h5>
            Renewing your policy is even simpler. Just sign in with your registered mobile number (or policy details), confirm your details, and make the payment. There you go!
            </h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            FAQ’S
            </h2>
            </GridItem>
            <div>
            <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer
                className={`${classes.container} ${classes.textCenter}`}
                >
                <GridItem
                    md={12}
                    style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                ></GridItem>
                <GridItem
                style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <Accordion active={0} collapses={AccordionArray} />
                </GridItem>
                </GridContainer>
            </div>
            </div>
        </div>

      </GridItem>
      {process.env.ENABLE_HELMET == `true` && (
            <Helmet>
                <script type="application/ld+json">
                    {`{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                    "@type":"Question",
                    "name":"Does Arogya Sanjeevani Policy cover Critical Illness?",
                    "acceptedAnswer":{
                       "@type":"Answer",
                       "text":"Arogya Sanjeevani is a common product designed by the insurance regulator. But few insurers are offering optional cover of Critical illness by demanding additional Premium. But Star health offers all features designed by the regulator, but the policy holders can avail good cashless facility across India."
                    }
                 },
                 {
                    "@type":"Question",
                    "name":"What is the policy tenure of Arogya Sanjeevani Policy?",
                    "acceptedAnswer":{
                       "@type":"Answer",
                       "text":"Arogya Sanjeevani has a policy term of one year. The Premiums can be paid quarterly, half-yearly & annually"
                    }
                 },
                 {
                    "@type":"Question",
                    "name":"Do I have to pay any amount while claiming under Arogya Sanjeevani Policy?",
                    "acceptedAnswer":{
                       "@type":"Answer",
                       "text":"5% copay to all admissible claims."
                    }
                 },
                 {
                    "@type":"Question",
                    "name":"What is the sum insured option provided under Arogya Sanjeevani Policy?",
                    "acceptedAnswer":{
                       "@type":"Answer",
                       "text":"The sum insured options provided under this policy ranges from ₹50000 to ₹10,00,000 in multiples of fifty thousand."
                    }
                 }
              ]
            }`}</script>
           {/* <script type="application/ld+json">
            {`{ "@context": "http://schema.org",
            "@type": "Product",
            "name": "Arogya Sanjeevani Policy",
            "description": "Arogya Sanjeevani is a health coverage policy that offers both individual and family floater plans. It covers AYUSH treatments as well as modern treatments. Another insurance benefit is that all daycare procedures are included making it the perfect mediclaim plan."
            }`}
            </script> */}
            </Helmet>
            )}
    </GridContainer>
  );
};
ArogyaSanjeeviniHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(ArogyaSanjeeviniHighlights);