import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
// import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Accordion from "components/Accordion/Accordion.jsx";    

const AccidentcareprePageSEO = ({ classes }) => {
    return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
             </GridItem>
             <div>
             For the purpose of rating, persons proposed for insurance are classified under three risk groups
             <br></br>
             <b>Risk Group I</b>- Persons engaged primarily in administrative functions<br></br>
             <b>Risk Group II</b> – Persons engaged in manual work other than what is specifically provided for under Risk Group III<br></br>
             <b>Risk Group III</b> – Persons working in explosives industry, mine and /or Magazine workers, high tension electric supply, horse racing including jockeys, athletes and occupations of similar hazard.
             <h5><b>Premium Excluding Tax</b></h5>
             <h5><b>1 Year policy:</b></h5>
             </div>

             <div>
                <Paper className={classes.root} style={{marginTop: '15px'}}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" rowSpan={2} colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Coverage/ Risk group
                            </TableCell>
                            <TableCell align="center"  colSpan={3} className={classes.SEP2tableCellLeftcustomWidth}>
                            Premium (in Rs.) per mille sum insured
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Group I
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Group II
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Group III
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Table A
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            0.43
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            0.60
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            0.80
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Table B
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            0.70
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            1.30
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            1.75
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Table C
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            1.25
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            1.75
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            2.00
                            </TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
               </Paper>
                <br></br>
            <h5><b>2 Year policy:</b></h5>
            <Paper className={classes.root} style={{marginTop: '15px'}}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" rowSpan={2} colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Coverage/ Risk group
                            </TableCell>
                            <TableCell align="center"  colSpan={3} className={classes.SEP2tableCellLeftcustomWidth}>
                            Premium (in Rs.) per mille sum insured
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Group I
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Group II
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Group III
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Table A
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            0.83
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            1.16
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            1.54
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Table B
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            1.35
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            2.51
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            3.38
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Table C
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            2.41
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            3.38
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            3.86
                            </TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
               </Paper>
               <br></br>
               <h5><b>3 Year policy:</b></h5>
               <Paper className={classes.root} style={{marginTop: '15px'}}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" rowSpan={2} colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Coverage/ Risk group
                            </TableCell>
                            <TableCell align="center"  colSpan={3} className={classes.SEP2tableCellLeftcustomWidth}>
                            Premium (in Rs.) per mille sum insured
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Group I
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Group II
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Group III
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Table A
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            1.20
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            1.68
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            2.24
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Table B
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            1.96
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            3.63
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            4.89
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            Table C
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            3.49
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            4.89
                            </TableCell>
                            <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                            5.59
                            </TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
               </Paper>
               <br></br>
               <h5>*per mille means per thousand sum insured</h5>
               <Paper className={classes.root} style={{marginTop: '15px'}}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                    <TableBody>
                        <TableRow>
                        <TableCell align="center" style={{textAlign:"center"}} colSpan={2} className={classes.SEP2tableCellLeftcustomWidth}>
                        <b>Premium for Optional Benefits</b>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                          Medical Expenses Extension
                       </TableCell>
                       <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                       10% of the final premium per year per person
                       </TableCell>
                       </TableRow>
                       <TableRow>
                       <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                       Winter Sports & Rallies
                       </TableCell>
                       <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                       100% of the policy premium per person
                       </TableCell>
                       </TableRow>
                       <TableRow>
                       <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                       Hospital Cash 
                       </TableCell>
                       <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                       Rs.40/- per year per person
                       </TableCell>
                       </TableRow>
                       <TableRow>
                       <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                       Home Convalescence
                       </TableCell>
                       <TableCell align="center" colSpan={1} className={classes.SEP2tableCellLeftcustomWidth}>
                       Rs.15/- per year per person
                       </TableCell>
                       </TableRow>
                     </TableBody>
                </Table>
             </Paper>
             <br></br>
             <h5><b>Final Premium means:</b> Basic premium + premium for other extensions, if any</h5>
             <h5><b>Policy Premium means:</b> Basic premium + premium of opted optional benefits</h5>
            </div>  
        </GridContainer>)}
           
    AccidentcareprePageSEO.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    pageContext: PropTypes.object,
  };
  export default withStyles(WellnessBenefitsStyle)(AccidentcareprePageSEO);          
