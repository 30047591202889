import React, { Component } from "react";
import { Form } from "lib/core/dist/index";
import { FormButton } from "lib/atlaskit/dist/index";
import { renderer } from "lib/material-ui/dist/index";
import root from "window-or-global";
import PropTypes from "prop-types";
class FormGenerator extends Component {
  constructor() {
    super();
  }

  handleClick = values => {
    this.props.handleFormSubmit(values);
  };

  componentDidMount() {
    const submitButton = root.document.getElementById(`submitButton`);
    if (submitButton) submitButton.style.display = `none`;
  }

  render() {
    const { fields } = this.props;
    return (
      <div id="main-panel">
        <Form renderer={renderer} defaultFields={fields}>
          <FormButton id="submitButton" onClick={this.handleClick} />
        </Form>
      </div>
    );
  }
}
FormGenerator.propTypes = {
  handleFormSubmit: PropTypes.func,
  fields: PropTypes.array
};
export default FormGenerator;
