import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import FormProcessor from '@ps/react-forms/processor/FormProcessor';
import wizardStyle from 'ps-assets/jss/wizardStyle.jsx';
import CustomStepper from 'ps-components/CustomStepper/CustomStepperAcci';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import loadingGIF from "ps-assets/img/star2.gif";
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import GridItem from 'components/Grid/GridItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import root, { console } from 'window-or-global';
import ReactSVG from 'react-svg';
import { withPrefix } from 'gatsby';
import { getSecuredLocalStorageItem } from 'util/localStorageHelper.js';
import { wizardLMSCall, dndCall } from 'util/lmsRequest';
import { formatReqAndRes , getRequestData, isNullOrUndefined} from "util/RequestBodyFormatter.jsx";
import GridContainer from 'components/Grid/GridContainer';
import classNames from 'classnames';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from '@material-ui/core/Radio';
import Card from 'components/Card/Card';
// import Dialog from "@material-ui/core/Dialog";
// import Close from "@material-ui/icons/Close";
import { browserLocalStorage, browserWindow  } from "util/localStorageHelper";
import CardBody from 'components/Card/CardBody';
import moment from 'moment';
import { sevaApp } from 'util/LayoutUtil.jsx';
import Cookies from "js-cookie";

const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;
var md5 = require(`blueimp-md5`);
const CUSTOMERPORTAL_URL = process.env.CUSTOMERPORTAL_URL

const emiOptionsArr = [
  {
    display: `Half yearly EMI Plan`,
    value: `04`
  },
  {
    display: `Quarterly EMI Plan`,
    value: `03`
  }
];

const initialState = {
  name: ``,
  mobile: ``,
  email: ``,
  quoteReceived:true,
  formResponse: null,
  productId: null,
  dob:``,
  formArr: null,
  activeStep: 0,
  policy_cover: null,
  policyType: null,
  quoteResponse: ``,
  premiumAmount: null,
  errorPremiumAmount: null,
  errorLifePremiumAmount: null,
  lifePremiumAmount: null,
  policyQuote: ``,
  formEditDataVal: {},
  sumInsured: [],
  errorDup: ``,
  formPayload: null,
  starLoading: true,
  dndConsent: true,
  emiPlan: `FP`,
  paymentMode: `FP`,
  displayEMIinfo: false,
  urlInfo: ``,
  adultDob1: ``,
  adultDob2: ``,
  policycover_options: [],
  data: []
};

class Accident extends React.Component {
  state = initialState;

  showEMIinfo = () => {
    this.setState({
      displayEMIinfo : true,
    });
  }

// getEmiDebitCardBanks = function(){
//   try {
//     return <Dialog open={this.state.displayEMIinfo}>
//       <GridItem>
//         <GridItem style={{textAlign:`end`}}>
//           <Close style={{cursor:`pointer`}} onClick={() => this.setState({
//             displayEMIinfo:false
//           })}/></GridItem>
//         <GridItem style={{
//           fontWeight: `bold`,
//           textAlign: `center`,
//           minWidth: `550px`,
//           fontSize: `20px`,
//           marginTop: `2%`,
//           marginBottom: `1%`
//         }}>{this.emiINFO.emiContent[0].title}</GridItem>
//         <GridItem>
//           <ul>
//             {this.emiINFO.emiContent[0].content.split(`|`).map((bank,index) => <li key={index}>{bank}</li>)}
//           </ul>
//         </GridItem>
//       </GridItem>
//     </Dialog>;
//   }
//   catch(exception){
//     console.log(`exception - `,exception);
//   }
// };
  changePaymentMode = async event => {
    try {
      event.persist();
      let url = `${CUSTOMER_PORTAL_API_URL}/cp/authentication/fetch/forms?formId=banklist&formType=formData`;
      let res = await fetch(url);
      res = await res.json();
      this.emiINFO = res;
      if (event.target.value === `FP`)
        this.setState({
          paymentMode: event.target.value,
          emiPlan: `FP`,
          premiumAmount: this.applyCommasForAmount(this.state.policyQuote && this.state.policyQuote)
        });
      else
        this.setState({
          paymentMode: event.target.value,
          emiPlan: `04`,
          premiumAmount: this.applyCommasForAmount(this.response.hlflyTotalPremium && this.response.hlflyTotalPremium)
        });
    } catch (exception) {
      console.log(exception);
    }
  };

  applyCommasForAmount = amount => {
    amount = amount.toString();
    amount = amount.split(`,`).join(``);
    if (amount.length > 3) {
      const newAmount = amount.split(``);
      newAmount.splice(-3, 0, `,`);
      amount = newAmount.join(``);
      if (amount.length > 6) {
        const newAmount = amount.split(``);
        newAmount.splice(-6, 0, `,`);
        amount = newAmount.join(``);
      }
      return amount;
    }
    return amount;
  };

  handleInputChange = e => {
    if (e.target.id === `name`) {
      if ((e.target.value.search(`^[a-zA-Z. ']*$`) === 0) && (e.target.value[0] != " ")) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else if (e.target.id === `mobile`) {
      if (!isNaN(e.target.value) && e.target.value.length <= 10) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else if (e.target.id === `dndConsent`) {
      this.setState({
        dndConsent: this.state.dndConsent
      });
    } else this.setState({ [e.target.id]: e.target.value });
  };

  handleChangeEnabled = (input, value) => {
    this.setState({
      starLoading:true
    });
    let policy_cover = this.state.policy_cover;
    this.setState({ policy_cover: value });

    if (input === `policy_cover`) policy_cover = value;
    this.getQuoteSumInsuredChange(policy_cover);
  };

  getQuoteSumInsuredChange = (policy_cover) => {
    if (policy_cover){
      this.setState({ policy_cover: policy_cover });
      let dob = this.state.dob ? this.state.dob : (this.state.data.adultDob1.value && this.state.data.adultDob1.value) ? this.state.data.adultDob1.value: this.state.data.dob.value;
      let adultDob2 = this.state.adultDob2 ? this.state.adultDob2 : this.state.data.adultDob2.value
      let year = this.state.data.PolicyPeriod.value;
      let adults = this.state.data.adults.value ? this.state.data.adults.value : ``;
      let kids = this.state.data.kids.value ? this.state.data.kids.value : this.state.data.kids1.value ? this.state.data.kids1.value : this.state.data.kids2.value ? this.state.data.kids2.value : ``;
      let policyType = this.state.data.policy_type.value ? this.state.data.policy_type.value : ``;
      let pincode = this.state.data.pinCode.value ? this.state.data.pinCode.value : ``;
      let parents = this.state.data.parents.value ? this.state.data.parents.value : this.state.data.parents1.value ? this.state.data.parents1.value : ``;
      let parentDob1 = this.state.data.parentDob1.value ? this.state.data.parentDob1.value : "";
      let parentDob2 = this.state.data.parentDob2.value ? this.state.data.parentDob2.value  : ``;
      let parentDob3 = this.state.data.parentDob3.value ? this.state.data.parentDob3.value  : ``;
      let parentDob4 = this.state.data.parentDob4.value ? this.state.data.parentDob4.value  : ``;
      var requestBody = {};
      requestBody.productId = this.props.productId;
      requestBody.policyCover = policy_cover ? parseInt(policy_cover) : ``;
      requestBody.name = this.state.name;
      requestBody.mobNo = this.state.mobile;
      requestBody.email = this.state.email;
      requestBody.dob = dob;
      requestBody.adultDob1 = dob;
      requestBody.adultDob2 =adultDob2;
      requestBody.year = year;
      requestBody.adults =adults;
      requestBody.kids =kids;
      requestBody.policyType = policyType;
      requestBody.pinCode = pincode;
      requestBody.parents = parents;
      requestBody.parentDob1 = parentDob1;
      requestBody.parentDob2 = parentDob2;
      requestBody.parentDob3 = parentDob3;
      requestBody.parentDob4 = parentDob4;
      let quoteApi = ``,
        headers = {};
      requestBody = formatReqAndRes(requestBody);
      quoteApi = `${CUSTOMER_PORTAL_API_URL}/cp/quote/calculate/product/quote`;
      headers = {
        clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`,
        "Content-Type": `application/json`,
      };
      fetch(quoteApi, {
        method: `POST`,
        headers: headers,
        body: JSON.stringify(getRequestData(requestBody)),
      })
        .then((res) => res.json())
        .then((res) => {
          var checksum = md5(
            `${isNullOrUndefined(res.response.policyQuote)}|${isNullOrUndefined(res.response.totalPremium)}|quote`,
            `73q&*cvm`,
          );
          if (res.response && (checksum === res.response.checkSum)) {
            this.setState({
              starLoading:false
            });
            res.response = formatReqAndRes(res.response);
            this.response = res.response;
            let premiumAmount = res.response.policy_quote || null,
              errorPremiumAmount = res.response.error || null,
              lifePremiumAmount = res.response.life_policy_quote || null;
            // if (
            //   requestBody.productId == 43
            // ) {
            //   premiumAmount = convQuoteToString(
            //     convQuoteToInt(premiumAmount) * parseInt(requestBody.adults)).split(`₹`)[1];
            // }
            this.setState({
              premiumAmount: premiumAmount,
              lifePremiumAmount: lifePremiumAmount,
              errorPremiumAmount: errorPremiumAmount,
              quoteReceived: true,
              emiPlan:`FP`,
              paymentMode:`FP`
            });
          } else
            this.setState({
              premiumAmount: null,
              lifePremiumAmount: null,
              errorPremiumAmount: `Sorry could not fetch the premium amount`,
              errorLifePremiumAmount: `Sorry could not fetch the  premium amount and life premium amount`,
              quoteReceived: true,
              emiPlan:`FP`,
              paymentMode:`FP`
            });
        })
        .catch(() => {
          this.setState({
            errorPremiumAmount: `Sorry could not fetch the premium amount`,
          });
          
        });
    }
  };

  handleBuyNow = () => {
    try {
      let { buyNowUrl, planType } = this.props;
      let name = this.state.name;
      let mobNo = this.state.mobile;
      let email = this.state.email;
      let adults = this.state.data.adults.value ? this.state.data.adults.value : `1`;
      let kids = this.state.data.kids.value ? this.state.data.kids.value : this.state.data.kids1.value ? this.state.data.kids1.value : this.state.data.kids2.value ? this.state.data.kids2.value : `0`;
      let parents = this.state.data.parents.value ? this.state.data.parents.value : this.state.data.parents1.value ? this.state.data.parents1.value :  `0`;
      let year = this.state.data.PolicyPeriod.value ? this.state.data.PolicyPeriod.value : ``;
      let pinCode = this.state.data.pinCode.value ? this.state.data.pinCode.value : ``;
      let policyCoverOptions = JSON.stringify(this.policyCoverOptions);
      let suminsured = this.state.policy_cover ? this.state.policy_cover : this.props.formJson.formSteps[0].fields[0].defaultValue;
      let redirectUrl = buyNowUrl;
      let user = browserLocalStorage.getItem(`user`);
      let authToken = ``;
      if (user) {
        user = JSON.parse(user);
        authToken = user.token;
        if (authToken) {
          let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
          if (logOutToken) {
            authToken = ``;
          }
        }
      }
      let dob = this.state.dob && this.state.dob.replace(/\//g, `-`);
      let normalBuyUrl = `${CUSTOMERPORTAL_URL}/customerportal`;
      normalBuyUrl += `/normalbuy`;
      normalBuyUrl += `?${planType ? `type=${planType}` : ``}${suminsured ? `&policyCover=${suminsured}` : ``}${dob ? `&dob=${dob.split(`-`).join(`/`)}` : ``}${year ? `&year=${year}` : ``}${name ? `&name=${name}` : ``}${mobNo ? `&mobNo=${mobNo}` : ``}${email ? `&email=${email}` : ``}${adults ? `&adults=${adults}` : `1`}${kids ? `&kids=${kids}` : `0`}${parents ? `&parents=${parents}` : `1`}${pinCode ? `&pinCode=${pinCode}` : ``}${policyCoverOptions ? `&policyCoverOptions=${policyCoverOptions}` : ``}${this.state.productId ? `&productId=${this.state.productId}` : ``}`;
      normalBuyUrl += `&emiValue=${this.state.emiPlan}&emiOptions=${JSON.stringify(emiOptionsArr)}`;
      redirectUrl = normalBuyUrl;
      let target = `_self`;
      if(sevaApp()){
        redirectUrl= redirectUrl + '&sevaapp=true'
      }
      if(authToken !== ``) localStorage.setItem(`sessionalive`, true);
      root.open(redirectUrl, target);
    }
    catch(exception){
      console.log(exception);
    }
  };


  handleFormState = activeStep => {
    this.setState({ activeStep: activeStep });
  };

  handleFormState = activeStep => {
    if (activeStep === 1 && !browserWindow.location.href.includes(`#agent`)) {
      wizardLMSCall(
        this.state.name,
        this.state.mobile,
        this.state.email,
        this.props.productName,
        this.props.productId,
        this.state.urlInfo
      );
    }
    if (!this.state.dndConsent) {
      dndCall(this.state.name, this.state.mobile);
    }
    this.setState({ activeStep: activeStep });
  };

  getPremiumComponent = () => {
    const {classes} = this.props;
    return <>{this.state.premiumAmount && !this.state.lifePremiumAmount && (
      <div style={{ textAlign: `center` }}>
        <h4 className={classes.cardTitle}>
          {this.state.productId == 9
            ? `Out Patient Benfit`
            : `Premium Amount`}
        </h4>
        <h2 className={classes.amount}>₹ {this.state.premiumAmount}</h2>
        <h4 className={classes.cardTitle}>(Including Tax)</h4>
        <h6
          className={classes.cardTitle}
          style={{ textTransform: `capitalize` }}
        >
          {this.state.productId == 6
            ? `This policy is applicable only for rural segment`
            : ``}
        </h6>
      </div>
    )}</>;
  }
  handleNext = (submitformData, formData) => {
    this.setState({
      data: formData
    });
    this.getRequestBodyObject(formData, submitformData);
    const formEditData = {};
    let fieldsArr = [];
    for (const subSection of this.props.formJson.pages[0].sections[0].subSections) {
      const dialogues = subSection.fields;
      for (const dialogue of dialogues) {
        fieldsArr.push(dialogue);
      }
    }
    for (const field of fieldsArr) {
      if(submitformData[field.name]){
        formEditData[field.name] = {};
        formEditData[field.name][`value`]=submitformData[field.name];
        formEditData[field.name][`isVisible`] = true;
        formEditData[field.name][`isDisplayed`] = true;
        formEditData[field.name][`isVisited`] = true;
        formEditData[field.name][`isValid`] = true;
      }else {
        formEditData[field.name] = {};
        formEditData[field.name][`value`] = null;
        formEditData[field.name][`isVisible`] = false;
        formEditData[field.name][`isDisplayed`] = false;
        formEditData[field.name][`isVisited`] = true;
        formEditData[field.name][`isValid`] = false;
      }
    }
    this.setState({
      error: ``,
      formEditDataVal: formEditData,
    });
    this.handleFormState(this.state.activeStep + 1);
    this.SIUpdateforStarHealthPremier(formEditData);
    // browserWindow.location.href = "/under-maintenance"
  };
  SIUpdateforStarHealthPremier(formEditData) {
    let startDate = moment(new Date).format(`DD/MM/YYYY`);
    let ages = [];
    ages[0] = moment(startDate, `DD/MM/YYYY`).diff(
      moment(formEditData.dob.value, `DD/MM/YYYY`),
      `years`
    );
    ages[1] = moment(startDate, `DD/MM/YYYY`).diff(
      moment(formEditData.adultDob1.value, `DD/MM/YYYY`),
      `years`
      );
    ages[2] = moment(startDate, `DD/MM/YYYY`).diff(
      moment(formEditData.adultDob2.value, `DD/MM/YYYY`),
      `years`);
    ages[3] = moment(startDate, `DD/MM/YYYY`).diff(
      moment(formEditData.parentDob1.value, `DD/MM/YYYY`),
      `years`);
    ages[4] = moment(startDate, `DD/MM/YYYY`).diff(
      moment(formEditData.parentDob2.value, `DD/MM/YYYY`),
      `years`);
    ages[5] = moment(startDate, `DD/MM/YYYY`).diff(
      moment(formEditData.parentDob3.value, `DD/MM/YYYY`),
      `years`);
    ages[6] = moment(startDate, `DD/MM/YYYY`).diff(
      moment(formEditData.parentDob4.value, `DD/MM/YYYY`),
      `years`);
    // let age = moment(startDate, `DD/MM/YYYY`).diff(
    //   moment(dob, `DD/MM/YYYY`),
    //   `years`
    // );
    const SIArray = [
      {
        "heading": ``,
        "items": [
          {
            "label": `5 Lakhs`,
            "value": `500000`
          },
          {
            "label": `10 Lakhs`,
            "value": `1000000`
          },
          {
            "label": `15 Lakhs`,
            "value": `1500000`
          },
          {
            "label": `20 Lakhs`,
            "value": `2000000`
          },
          {
            "label": `25 Lakhs`,
            "value": `2500000`
          },
          {
            "label": `50 Lakhs`,
            "value": `5000000`
          },
          {
            "label": `75 Lakhs`,
            "value": `7500000`
          },
          {
            "label": `1 crore`,
            "value": `10000000`
          },
          {
            "label": `2 crore`,
            "value": `20000000`
          }
        ]
      }
    ];
    let slice = false
    ages.map(items => {
        if(items > 65){
          slice = true
        }
    }) 
    let cover_options = slice == true ? SIArray[0].items.slice(0, 6) : SIArray[0].items;
    this.setState({
      policycover_options: [{heading : ``,items : cover_options}]
    });
    // this.getPolicyCover(this.state.policycover_options);
    return {
      result: true,
      errorMessages: ``,
    };
  }

  enableEMI = () => {
    switch (this.props.productId) {
      case 43:
        if( 
          this.state.formEditDataVal.PolicyPeriod && this.state.formEditDataVal.PolicyPeriod.value == `1`
        ){
          return true;
        }
        break;
      default:
        return false;
    }
  };

  // getEmiText = function(){
  //   try{
  //     const {classes} = this.props;
  //     return (
  //       <div className={classes.emiContent}>
  //         <p className={classes.cardTitle}>
  //           {this.emiINFO.prefix}
  //         </p>
  //         <p className={classNames(classes.cardTitle,classes.emiText)} onMouseOver={this.showEMIinfo}>
  //           {this.emiINFO.text}
  //         </p>
  //       </div>
  //     );
  //   }
  //   catch(exception){
  //     console.error(exception);
  //   }
  // }

  getPremiumAmount = async requestBody => {
    let quoteApi = ``,
      headers = {};
    quoteApi = `${CUSTOMER_PORTAL_API_URL}/cp/quote/calculate/product/quote`;
    
    headers = {
      clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`,
      'Content-Type': `application/json`
    };
    return await fetch(quoteApi, {
      method: `POST`,
      headers: headers,
      body: JSON.stringify(requestBody)
    }).then(res => res.json());
  };

  getRequestBodyObject = async formData => {
    let responseSum = 0;
    let QtrlyresponseSum = 0;
    let HflyresponseSum = 0;
    let requestBodyArray = [];
    let requestBody,dob,adultDob2,policyType,productId,policyPlan,pinCode;
    policyType = this.state.policy_type ? this.state.policy_type : formData[`policy_type`].value;
    policyCover = ``;
    productId = `43`;
    dob = (this.state.dob && this.state.dob)? this.state.dob : (formData && formData['dob'] && formData['dob'].value && formData['dob'].value)? formData['dob'].value : formData['adultDob1'].value
    adultDob2 = this.state.adultDob2 ? this.state.adultDob2 : formData['adultDob2'].value
    pinCode = formData.pinCode.value;
    let year = formData.PolicyPeriod.value;
    let adults = formData.adults.value ? formData.adults.value : ``;
    let kids = formData.kids.value ? formData.kids.value : formData.kids1.value ? formData.kids1.value : formData.kids2.value ? formData.kids2.value : '';
    let parents = formData.parents.value ? formData.parents.value : formData.parents1.value ? formData.parents1.value : ``;
    let parentDob1 = formData.parentDob1.value ? formData.parentDob1.value : "";
    let parentDob2 = formData.parentDob2.value ? formData.parentDob2.value : "";
    let parentDob3 = formData.parentDob3.value ? formData.parentDob3.value : "";
    let parentDob4 = formData.parentDob4.value ? formData.parentDob4.value : "";
    let policyCover=this.state.policy_cover ? this.state.policy_cover : this.props.formJson.formSteps[0].fields[0].defaultValue;
    policyPlan=``;
    requestBody = {
      productId: productId,
      name: this.state.name,
      mobNo: this.state.mobile,
      email: this.state.email,
      policyCover:policyCover,
      policyType:policyType,
      dob:dob,
      adultDob1: dob,
      adultDob2: adultDob2,
      year: year,
      adults: adults,
      kids: kids,
      parents: parents,
      parentDob1: parentDob1,
      parentDob2: parentDob2,
      parentDob3: parentDob3,
      parentDob4: parentDob4,
      pinCode: pinCode,
      checkSumRequest: md5(
        `${productId}||${dob}|${policyType}|${policyCover}|${policyPlan}|${pinCode}|quote`,
        `73q&*cvm`
      )
    };
    requestBodyArray.push(requestBody);
    let res = await this.getPremiumAmount(requestBody);
      
    if (res.response && res.response.status !== `failure`) {
      let responseQuote = res.response;
      responseSum =
          responseSum + parseInt(responseQuote.policyQuote.split(`,`).join(``));
      responseSum = this.applyCommasForAmount(responseSum);
      HflyresponseSum =
      HflyresponseSum + parseInt(responseQuote.hlflyTotalPremium.split(`,`).join(``));
      HflyresponseSum = this.applyCommasForAmount(HflyresponseSum);
    QtrlyresponseSum =
    QtrlyresponseSum + parseInt(responseQuote.qtrlyTotalPremium.split(`,`).join(``));
    QtrlyresponseSum = this.applyCommasForAmount(QtrlyresponseSum);
    this.response = {
      policyQuote: responseSum,
      hlflyTotalPremium: HflyresponseSum,
      qtrlyTotalPremium: QtrlyresponseSum,
    };
      this.setState({
        quotepaymentResponse: res,
        quoteResponse: requestBodyArray,
        premiumAmount: responseSum,
        paymentMode: `FP`,
        emiPlan: `FP`,
        policyQuote: responseSum,
        starLoading: false,
        quoteReceived:true,
        errorPremiumAmount: ``
      });
    } else {
      this.setState({
        starLoading: false,
        premiumAmount: null,
        lifePremiumAmount: null,
        quoteReceived:false,
        errorPremiumAmount: `Sorry could not fetch the premium amount`,
        errorLifePremiumAmount: `Sorry could not fetch the  premium amount and life premium amount`
      });
      return;
    }
  };

  componentDidMount() {
    let name = ``,
      email = ``,
      mobile = ``;
    let user = getSecuredLocalStorageItem(`user`);
    let accessToken = Cookies.get('customer_accessToken')
    let userInfo = Cookies.get('user')
    let authToken = ``;
    if (accessToken || user) {
      user = JSON.parse(user);
      authToken = user.token;
      if (authToken) {
        let logOutToken = getSecuredLocalStorageItem(`loggedOut${authToken}`);
        if (logOutToken) {
          authToken = ``;
        }
      }
    }
    let queryParams = decodeURIComponent(browserWindow.location.search);
    if(queryParams){
      queryParams = queryParams.split(`?`)[1];
      queryParams = queryParams.split(`&`);
      queryParams.map(param => {
        let key = param.split(`=`)[0];
        let value = param.split(`=`)[1];
        if (key === `name` && value) {
          let decodedValue = decodeURI(value);
          name = decodedValue;
        }
        else if (key === `mobile` && value) {
          let decodedValue = decodeURI(value);
          mobile = decodedValue;
        }
        else if (key === `email` && value) {
          let decodedValue = decodeURI(value);
          email = decodedValue;
        }
      });
    }
    // var base64Url = authToken.split(`.`)[1];
    // if (base64Url != undefined) {
    //   var decodedValue = JSON.parse(browserWindow.atob(base64Url));
    //   let userObj = decodedValue.jti;
    //   userObj = JSON.parse(userObj);
    //   this.setState({
    //     name: userObj.firstName ? userObj.firstName : name,
    //     email: userObj.email,
    //     mobile: userObj.mobileNo
    //   });
    // }
    if (userInfo){
      userInfo = JSON.parse(userInfo)
      email = userInfo.emailId;
      name = userInfo.firstName;
      mobile = userInfo.mobileNumber;
    }
    else if(user && user.userDetails){
      email = user.userDetails.email;
      name = user.userDetails.firstName ? user.userDetails.firstName : name,
      mobile = user.userDetails.mobileNo;
    }
    if (this.state.urlInfo === ``) {
      this.setState({
        urlInfo: browserWindow.location.href
      });
    }
    this.setState({
      productId:this.props.productId,
      name: name,
      email: email,
      mobile: mobile,
    });
  }

  componentWillUnmount() {
    this.setState(initialState);
  }
  getPolicyCover = (field,updatedoptions) => {
    const { classes } = this.props;
    const { quoteReceived } = this.state;
    let policyCoverOptions = [];
    let res = {...field, options: updatedoptions && updatedoptions.length>0 ? updatedoptions : field.options};
    let renderComponent = quoteReceived ? (
      <>
        <span key={`formstep-3`}>{res && res.label}</span>
        <GridContainer>
          {res &&
            res.options &&
            this.getOnlyRequiredSumInsureds(res.options[0].items)
              .sort((a, b) => parseInt(b.value) - parseInt(a.value))
              .map((item, index) => {
                policyCoverOptions.push({
                  display: item.label,
                  value: item.value,
                });
                return (
                  <>
                  <GridItem md={3} sm={6} key={`radio-` + index}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            this.state[res.name] === item.value ||
                            (this.state[res.name] === null &&
                              res.defaultValue === item.value)
                          }
                          onChange={() =>
                            this.handleChangeEnabled(res.name, item.value)
                          }
                          value={item.value}
                          name={res.name}
                          aria-label={item.label}
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.customRadioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={
                        item.label.includes(`$`) ? item.label : `₹` + item.label
                      }
                      className={`${classes.customLabel} ${
                        this.state[res.name] === item.value ||
                        (this.state[res.name] === null &&
                          res.defaultValue === item.value)
                          ? classes.activeLabel
                          : ``
                      }`}
                    />
                  </GridItem>
                  </>
                );
              })}
        </GridContainer>
        {this.enableEMI() ?
          <GridContainer>
            <GridItem className={classNames(classes.mTAuto,classes.mBAuto)} style={{textAlign:`center`}} md={6}>
              <span>Please Choose your payment option</span>
            </GridItem>
            <GridItem style={{textAlign:`center`}} md={6}>
              <Radio
                checked={this.state.paymentMode === `emi`}
                onChange={this.changePaymentMode}
                value="emi"
                name="payment_mode"
                aria-label="EMI"
                icon={
                  <FiberManualRecord
                    className={classes.radioUnchecked}
                  />
                }
                checkedIcon={
                  <FiberManualRecord
                    className={classes.radioChecked}
                  />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot
                }}
              />
              EMI
              <Radio
                checked={this.state.paymentMode === `FP`}
                onChange={this.changePaymentMode}
                value="FP"
                name="payment_mode"
                aria-label="Full Payment"
                icon={
                  <FiberManualRecord
                    className={classes.radioUnchecked}
                  />
                }
                checkedIcon={
                  <FiberManualRecord
                    className={classes.radioChecked}
                  />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot
                }}
              />
              Full Payment
            </GridItem>
            {this.state.paymentMode === `emi` ?
            <>
              <GridItem md={6}>
                <Card>
                  <CardBody>
                    {this.getPremiumComponent()}
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem md={6}>
                <InputLabel
                  htmlFor="product-type"
                  className={classes.selectLabel}
                >
                    Select EMI Plan
                </InputLabel>
                <Select
                  MenuProps={{className:classes.selectMenu}}
                  classes={{
                    select: classes.select
                  }}
                  style={{ display: `block` }}
                  value={this.state.emiPlan || ``}
                  onChange={this.changeEMIPlan}
                  inputProps={{
                    name: `emiPlan`,
                    id: `emiPlan`
                  }}
                >
                  {
                    emiOptionsArr.map(emiOption => {
                      return <MenuItem
                        key={emiOption.value}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={emiOption.value}
                      >
                        {emiOption.display}
                      </MenuItem>;
                    })
                  }
                  
                </Select>
              </GridItem>
            </>
              :
              <GridItem className={classNames(classes.mrAuto,classes.mlAuto)} md={6}>
                {this.getPremiumComponent()}
              </GridItem>
            }
          </GridContainer>
          :
          <GridItem className={classNames(classes.mlAuto,classes.mrAuto)} md={6}>
            {this.getPremiumComponent()}
          </GridItem>
        }
      </>
    ) : (
      <GridContainer>
        <div style={{ margin: `0 auto` }} className={classes.loadingGIF}>
          <img src={loadingGIF} />
        </div>
      </GridContainer>
    );
    this.policyCoverOptions = policyCoverOptions;
    return renderComponent;
  };

  getOnlyRequiredSumInsureds = res => {
    return res;
  };

  changeEMIPlan = event => {
    let premiumAmount = ``;
    // if (this.state.quotepaymentResponse) {
      switch (event.target.value) {
        case `FP`:
          premiumAmount = this.response.policyQuote;
          break;
        case `04`:
          premiumAmount = this.response.hlflyTotalPremium;
          break;
        case `03`:
          premiumAmount = this.response.qtrlyTotalPremium;
          break;
        default:
          premiumAmount = this.response.policyQuote;
          break;
      }
      this.setState({ emiPlan: event.target.value, premiumAmount });
    // }
  };

  render() {
    const { classes } = this.props;

    var stepsArr = [`Contact`];

    var userDetails = (
      <div>
        <CustomInput
          autoFocus
          labelText='Your Name'
          id='name'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.formControl
          }}
          inputProps={{
            required: true,
            pattern: `^[a-zA-Z. ']*$`
          }}
          error={this.state.name.search(`^[a-zA-Z. ']*$`) !== 0}
          value={this.state.name}
          onChange={this.handleInputChange}
        />
        {this.state.name.search(`^[a-zA-Z. ']*$`) !== 0 && (
          <FormHelperText id='my-helper-text' error>
            Name is not in the required format
          </FormHelperText>
        )}
        <CustomInput
          labelText='Mobile Number'
          id='mobile'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.formControl
          }}
          inputProps={{
            required: true,
            pattern: `[0-9]{10}`,
            maxLength: `10`,
            inputMode: `tel`
          }}
          error={
            this.state.mobile.length != 10 && this.state.mobile.length != 0
          }
          value={this.state.mobile}
          onChange={this.handleInputChange}
        />
        {this.state.mobile.length != 10 && this.state.mobile.length != 0 && (
          <FormHelperText id='my-helper-text' error>
            Mobile Number must be 10 digits{` `}
          </FormHelperText>
        )}
        <CustomInput
          labelText='Email'
          id='email'
          formControlProps={{
            required: true,
            fullWidth: true
          }}
          inputProps={{
            required: true,
            pattern: `[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$`,
            inputMode: `email`
          }}
          onChange={this.handleInputChange}
          value={this.state.email}
          error={
            this.state.email !== `` &&
            this.state.email.search(/^\S+@\S+\.\S+$/) !== 0
          }
        />
        {this.state.email !== `` &&
          this.state.email.search(/^\S+@\S+\.\S+$/) !== 0 && (
          <FormHelperText id='my-helper-text' error>
              Please enter valid email
          </FormHelperText>
        )}
        <div className={classes.checkboxspan} style={{ paddingBottom: `0%` }}>
          <Checkbox
            id='dndConsent'
            checked={this.state.dndConsent}
            onChange={this.handleInputChange}
            style={{ color: `#35488a`, padding: `0px` }}
            inputProps={{ 'aria-label': `secondary checkbox` }}
          />
          <p className={classes.authorize}>
            I authorize Star Health to contact me and I understand that this
            will override the DND status on my mobile number
          </p>
        </div>
      </div>
    );

    var secondStep = (
      <span key={`formstep-2`}>
        <>
          <FormProcessor
            key={`form`}
            formJson={JSON.parse(JSON.stringify(this.props.formJson))}
            formData={this.state.formEditDataVal}
            action={this.state.formEditDataVal ? `edit` : `add`}
            rendererType={`material`}
            renderStyle={`creative_tim_classic`}
            formPayload={this.props.formPayload}
            onSubmit={(formData, fields) => {
              this.handleNext(formData, fields);
            }}
          />
          {this.state.errorDup === `` ? (
            ``
          ) : (
            <div style={{ color: `red`, marginTop: `-36px` }}>
              {this.state.errorDup}
            </div>
          )}
        </>
      </span>
    );

    var thirdStep = (
      <>
      <div>
        {this.props.formJson &&
          this.props.formJson &&
          this.props.formJson.formSteps &&
          this.props.formJson.formSteps[0].fields &&
          this.props.formJson.formSteps[0].fields.map((res) => {
            return this.getPolicyCover(res,this.state.policycover_options);

          })}
      </div>
      <div style={{ textAlign: `center` }}>
        {
          this.state.paymentMode === `emi` 
          // && this.getEmiText()
        }
        {this.props.brochureUrl && this.props.brochureUrl.length === 1 && (
            <>
              <a
                href={this.props.brochureUrl[0]}
                target="_blank"
                rel="noopener noreferrer"
                key={`brochure-download-0`}
              >
                <Button simple color="primary">
                  <ReactSVG
                    src={withPrefix(`/download.svg`)}
                    style={{ margin: `0 auto` }}
                  />
                  Brochure
                </Button>
              </a>
              {this.props.productId == 24 && (
                <h4>
                  Please note that only one policy is allowed for one person
                </h4>
              )}
            </>
        )}
        {this.props.brochureUrl &&
            this.props.brochureUrl.length > 1 &&
            this.state.formResponse &&
            this.state.formResponse.policy_type &&
            this.props.type.map((res, index) => {
              return (
                this.state.formResponse.policy_type === res && (
                  <a
                    href={this.props.brochureUrl[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={`brochure-download-${index}`}
                  >
                    <Button simple color="primary">
                      <ReactSVG
                        src={withPrefix(`/download.svg`)}
                        style={{ margin: `0 auto` }}
                      />
                      Brochure
                    </Button>
                  </a>
                )
              );
            })}
        {this.props.brochureUrl &&
            this.props.brochureUrl.length > 1 &&
            this.state.formResponse &&
            this.state.formResponse.policy_plan &&
            this.props.type.map((res, index) => {
              return (
                this.state.formResponse.policy_plan === res && (
                  <a
                    href={this.props.brochureUrl[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={`brochure-download-${index}`}
                  >
                    <Button simple color="primary">
                      <ReactSVG
                        src={withPrefix(`/download.svg`)}
                        style={{ margin: `0 auto` }}
                      />
                      Brochure
                    </Button>
                  </a>
                )
              );
            })}
      </div>
    </>
    );
    var contentArr = [<span key={`content-array`}>{userDetails}</span>];

    if (this.props.formJson) {
      stepsArr.push(`Basic Info`);
      stepsArr.push(`Your Premium`);
      contentArr.push(<>{secondStep}</>);
      contentArr.push(<>{thirdStep}</>);
    }

    return (
      <span key={`customStepper`}>
        {this.state.paymentMode === `emi` 
        // && this.getEmiDebitCardBanks()
        }
        <CustomStepper
          formResponse={this.state.formResponse}
          activeStep={this.state.activeStep}
          handleFormState={this.handleFormState}
          handleBuyNow={this.handleBuyNow}
          steps={stepsArr}
          content={contentArr}
          premiumAmount={this.state.premiumAmount}
          brochure={this.props.brochure}
          ctaLabel={`Buy Now`}
        />
      </span>
    );
  }
}

Accident.propTypes = {
  brochure: PropTypes.array,
  ctaLabel: PropTypes.string,
  classes: PropTypes.object,
  buyNowUrl: PropTypes.string,
  formArr: PropTypes.object,
  query: PropTypes.string,
  planType: PropTypes.string,
  productId: PropTypes.number,
  brochureUrl: PropTypes.array,
  type: PropTypes.array,
  formJson: PropTypes.object,
  formPayload: PropTypes.object,
  productName: PropTypes.string
};
export default withStyles(wizardStyle)(Accident);
