import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
// import { Helmet } from "react-helmet";

const PremierInsuranceHighlights = ({ classes }) => {
    return (
        <GridContainer className={classes.containerDisplay}>
      {/* {process.env.ENABLE_HELMET == `true` && (
       <Helmet>
<script type="application/ld+json">
{`{ "@context": "http://schema.org",
  "@type": "Product",
  "name": "Star Health Premier Insurance Policy",
  "description": "Star Health Premier Insurance Policy designed to cover up to 1 Cr from 50Yrs and above up to any age The policy available on individual and floater basis This policy has coverage for AYUSH treatment up to sum insured, modern treatments up to 50 of sum insured, Bariatric Surgery, air ambulance, home care treatment, Outpatient Medical expenses, health check up with lots of benefits loaded",
"offers":{ 
          "@type": "AggregateOffer",
          "offerCount": "5%"
        }
   }`}
    </script>
       </Helmet>)} */}
    </GridContainer>
  );
};
PremierInsuranceHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(PremierInsuranceHighlights);