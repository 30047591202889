
const progressComponentStyle = () => ({
    section1Extra__container: {
      width: '630px',
      boxShadow: '0px 2px 8px rgba(33, 36, 60, 0.25)',
      borderRadius: '6px',
      padding: '23px',
      textAlign: 'left',
      margin: 'auto',
      "@media (max-width:750px)": {
        width: '277px'
      },
    },
    divider: { border: '0.5px solid #ACACAC', width: '100%' },
    addons__header: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#9B9B9B',
    },
    addons__headerDescription: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '21px',
      color: '#545454',
    },
    section1Extra__contentHeader: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '17px',
      color: '#9B9B9B',
    },
    benefits__tick: {
      width: '20px',
      marginRight: '4px',
      fill: '#2B498A',
    },
    benefits__points: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
      color: '#252525',
    },
    btn__child: {
      marginTop: '12px',
      marginLeft: 'auto',
      marginRight: '0',
      display: 'inline-block',
      padding: '8px 12px',
      background: '#2B498A',
      borderRadius: '4px',
      color: '#ffffff',
      fontWeight: '400',
      "&:hover": {
        background: '#2B498A'
      }
    },
    btn__childAdded: {
      marginTop: '12px',
      marginLeft: 'auto',
      marginRight: '0',
      display: 'inline-block',
      padding: '8px 12px',
      background: 'rgba(43, 73, 138, 0.21)',
      borderRadius: '4px',
      color: '#2B498A',
      "&:hover": {
        background: 'rgba(43, 73, 138, 0.21)',
        color: '#2B498A',
      }
    },
    btn__parent: {
      display: 'flex',
      fontFamily: "'Inter'",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '19px',
    },
    plusIcon: {
      width: '22px',
      marginLeft: '2px',
      marginBottom: '2px'
    },
    minusIcon: {
      width: '22px',
      marginLeft: '6px',
      marginBottom: '3px'
    },
    addonpremium: {
        color: "#2B498A",
        fontFamily: "'Roboto'",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        cssFloat: "right",
        marginTop: '-6%',
        float: 'right',
        "@media (max-width:750px)": {
          float: 'left',
        },
    },
    premium__container: {
        width: '630px',
        borderRadius: '6px',
        padding: '23px',
        textAlign: 'left',
        margin: 'auto',
        "@media (max-width:750px)": {
          width: '277px'
        },
      },
    policy_title:{
        fontFamily: "'Roboto'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#9B9B9B" 
    },
    sub_title:{
        fontFamily: "'Roboto'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#545454" 
    },
    premium_title:{
        fontFamily: "'Inter'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "17px",
        color: "#252525" 
    },
    premium_amount: {
        color: "#2B498A",
        fontFamily: "'Roboto'",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        cssFloat: "right",
        float: 'right',
        "@media (max-width:750px)": {
          float: 'left',
        },
    },
    lh:{
        lineHeight: "40px",
    }
  });
  
  export default progressComponentStyle;
  