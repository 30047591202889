import {
    container,
    mlAuto,
    mrAuto,
    title,
    description,
    main,
    mainRaised,
  } from "assets/jss/material-kit-pro-react.jsx";
  
  import sectionCards from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";
  import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
  import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";
  import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
  
  const features = function (theme) {
    return {
      container,
      mlAuto,
      mrAuto,
      title,
      main,
      ...customSelectStyle,
      ...customCheckboxRadioSwitch,
      ...modalStyle(theme),
      mainRaised,
      ...sectionCards,
      description,
      team: {
        padding: `50px 0px 30px 0px`,
      },
      textCenter: {
        textAlign: `center`,
      },
      infoArea: {
        marginBottom: `5%`,
      },
      infoAreaKavach: {
        marginBottom: `2%`,
      },
      spaceInLink: {
        marginRight: `1%`,
      },
      tableCellLeft: {
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        textAlign: "left",
      },
      containerDisplay:{
        "@media (max-width:550px)": {
          display:'contents'
        }
      },
      kavachBanner:{
      width:'100%',
      height:'100%',
      paddingTop:'29%',
      textAlign:'center',
      "@media (max-width:1130px)": {
        paddingTop:'0%',
        paddingBottom:'6%',
        float: 'none',
        
      },
      "@media (max-width:770px)": {
        paddingTop:'4%',
        paddingBottom:'11%',
      }
      },
      contentContainer:{
        width:'60%',
      float:'left',
      paddingRight:'3%',
      "@media (max-width:1130px)": {
        float:'none',
        width:'100%',
      },
      "@media (max-width:1000px)": {
        width:'100% !important',
      },
      },
      h3textStyle:{
        color:' #3C4858',
        fontWeight: '600',
        fontFamily: '"Roboto Slab", "Times New Roman", serif',
        textDecoration: 'none',
        fontSize:'1rem'
      },
      imageContainer:{
        width:'37%',
        float:'left',
        "@media (max-width:1130px)": {
          float:'none',
          width:'100%',
        },
        "@media (max-width:1000px)": {
          width:'100% !important',
        },
        },
      tableCell: {
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        marginBottom:'2%',
        textAlign: "center",
      },
      tableCellwidth: {
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        marginBottom:'2%',
        textAlign: "center",
        width:'200px'
      },
      SCCtableCellwidth: {
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        marginBottom:'2%',
        textAlign: "center",
        width:'100px'
      },
      SCIMPtableCellwidth1: {
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        marginBottom:'2%',
        textAlign: "center",
        width:'400px'
      },
      SCIMPtableCellwidth: {
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        marginBottom:'2%',
        textAlign: "center",
        width:'600px'
      },
      headCell: {
        fontSize: "15px",
        fontWeight: "bold",
        textShadow: "0px 0px 0px black",
        border: "1px solid black",
        color: "black",
        textAlign: "center",
      },
      tableCellLeftcustomWidth:{
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        textAlign: "left",
        width:'400px'
      },
      SEPtableCellLeftcustomWidth:{
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        textAlign: "left",
        width:'80px'
      },
      SEP2tableCellLeftcustomWidth:{
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        textAlign: "left",
        width:'100px'
      },
      GMCtableCellLeftcustomWidth:{
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        textAlign: "left",
        width:'200px'
      },
      SCIMPtableCellcentercustomWidth:{
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        textAlign: "center",
        width:'200px'
      },
      SCIMPtableCellcentercustomWidth1:{
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        textAlign: "center",
        width:'400px'
      },
      SCIMPtableCellLeftcustomWidth:{
        border: `1px solid black`,
        padding: "12px",
        fontSize: "14px",
        textAlign: "left",
        width:'600px'
      },
      headCellLeft: {
        fontSize: "15px",
        fontWeight: "bold",
        textShadow: "0px 0px 0px black",
        border: "1px solid black",
        color: "black",
        textAlign: "left",
      },
      headerContent: {
        border: "1px solid black !important",
        color: "black !important",
        fontWeight: "800 !important",
        fontSize: "18px !important",
      },
      root: {
        "@media (max-width:750px)": {
          width: "100%",
          overflowX: "auto",
        },
      },
      handleMarginLeft: {
        "@media (max-width:750px)": {
          marginLeft: "0% !important",
        },
      },
      handleMarginRight:{
        "@media (max-width:750px)": {
            paddingRight: '0px !important',
          }
      },
      rooWidthPosition: {
        width: "88%",
        marginLeft: "6%",
        "@media (max-width:750px)": {
          width: "100%",
          overflowX: "auto",
          marginLeft: "0%",
        },
      },
      exclusionFont: {
        "@media (max-width : 400px)": {
          fontSize: "1.0rem !important",
        },
      },
      table: {
        "@media (max-width:750px)": {
          paddingLeft: "5px",
          paddingRight: "5px",
          flex: "1 1 auto",
          maxHeight: "none",
          overFlowX: "auto",
        },
      },
      tableStyle: {
        marginLeft: "15px",
      },
      h2Title:{
        color: `#3C4858`,
        fontSize:'1.75rem',
        textDecoration: `none`,
        fontWeight: `700`,
        marginTop: `30px`,
        marginBottom: `25px`,
        minHeight: `32px`,
        fontFamily: `"Roboto Slab", "Times New Roman", serif`,
        [theme.breakpoints.down('md')]: {
        color: `#3C4858 !important`,
        textDecoration: `none !important`,
        fontWeight: `700 !important`,
        marginTop: `30px !important`,
        marginBottom: `25px !important`,
        minHeight: `32px !important`,
        fontFamily: `"Roboto Slab", "Times New Roman", serif !important`,
        fontSize:'28px !important'
      }
      },
      GMCh2Title:{
        color: `#3C4858`,
        fontSize:'1.75rem',
        textDecoration: `none`,
        fontWeight: `700`,
        marginTop: `10px`,
        marginBottom: `10px`,
        minHeight: `32px`,
        fontFamily: `"Roboto Slab", "Times New Roman", serif`,
        [theme.breakpoints.down('md')]: {
        color: `#3C4858 !important`,
        textDecoration: `none !important`,
        fontWeight: `700 !important`,
        marginTop: `30px !important`,
        marginBottom: `25px !important`,
        minHeight: `32px !important`,
        fontFamily: `"Roboto Slab", "Times New Roman", serif !important`,
        fontSize:'28px !important'
      }
      },
      kavachTitle:{
        color: `#3C4858`,
        fontSize:'32px',
        textDecoration: `none`,
        fontWeight: `700`,
        marginTop: `30px`,
        marginBottom: `25px`,
        minHeight: `32px`,
        fontFamily: `"Roboto Slab", "Times New Roman", serif`,
        [theme.breakpoints.down('md')]: {
        color: `#3C4858 !important`,
        textDecoration: `none !important`,
        fontWeight: `700 !important`,
        marginTop: `30px !important`,
        marginBottom: `25px !important`,
        minHeight: `32px !important`,
        fontFamily: `"Roboto Slab", "Times New Roman", serif !important`,
        fontSize:'28px !important'
      }
      },

      h2hTitle:{
        color: `#3C4858`,
        fontSize:'28px',
        textDecoration: `none`,
        fontWeight: `700`,
        marginTop: `30px`,
        marginBottom: `25px`,
        minHeight: `32px`,
        fontFamily: `"Roboto Slab", "Times New Roman", serif`,
        [theme.breakpoints.down('md')]: {
        color: `#3C4858 !important`,
        textDecoration: `none !important`,
        fontWeight: `700 !important`,
        marginTop: `30px !important`,
        marginBottom: `25px !important`,
        minHeight: `32px !important`,
        fontFamily: `"Roboto Slab", "Times New Roman", serif !important`,
        fontSize:'28px !important'
      }
      },
      button: {
        marginRight: theme.spacing.unit,
        padding: '4px 16px',
        borderRadius: '6px',
        textTransform: 'capitalize'
      },
      h3_customstyle:{
        color: `#3C4858`,
        fontSize:'23px',
        textDecoration: `none`,
        fontWeight: `700`,
        marginTop: `15px`,
        marginBottom: `16px`,
        minHeight: `32px`,
        fontFamily: `"Roboto Slab", "Times New Roman", serif`,
        [theme.breakpoints.down('md')]: {
        color: `#3C4858 !important`,
        textDecoration: `none !important`,
        fontWeight: `700 !important`,
        marginTop: `30px !important`,
        marginBottom: `25px !important`,
        minHeight: `32px !important`,
        fontFamily: `"Roboto Slab", "Times New Roman", serif !important`,
        fontSize:'28px !important'
      },
      
      },
      faqheader:{
        marginLeft: "26%",
        marginTop: "-20%",
        color: "white",
        fontSize:"97px",
        fontFamily:'"Roboto Slab", "Times New Roman", serif',
        "@media (max-width : 920px)": {
          marginLeft: "25%",
          fontSize: "76px"
        },
        "@media (max-width : 840px)": {
          marginLeft: "24%",
          fontSize: "67px"
        },
        "@media (max-width : 550px)": {
          marginLeft: "25%",
          fontSize: "40px"
        },
        "@media (max-width : 520px)": {
          marginLeft: "26%",
          fontSize: "31px"
        },
        "@media (max-width : 400px)": {
          marginLeft: "23%",
          fontSize: "31px"
        },
        "@media (max-width : 285px)": {
          marginLeft: "26%",
          fontSize: "20px"
        },
      },
    };
  };
  
  export default features;
  