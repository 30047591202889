import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import { Helmet } from "react-helmet";
import Accordion from "components/Accordion/Accordion.jsx";


    const faq=[
        {
            title:'What will be the premium for Senior Citizens Red Carpet Health Insurance Policy?',
            description:<h5>
            <h5>The Health insurance Premium for Senior Citizens Red Carpet Health Insurance Policy is based on the Sum Insured ranging from 1 lakh to 25 lakhs cover.</h5>
            <h5>Premium is raging from INR. 4500/- to 36,000/- Excl. Taxes</h5>
            <h5>The best option to buy 15 lakhs Sum Insured & the Premium would be around INR 29,000/-  and avail Section 80D benefit.</h5>
            </h5>,
            content:false
        },
        {
            title:'Do I need to take a pre-medical checkup for the Senior Citizens Red Carpet Health Insurance Policy?',
            description:<h5>
                <h5>
                We understand the limitations and needs of elderly people about their dietary habits. 
                </h5>
                <h5>
                Asking elderly people to visit multiple times to the diagnostics will not be appreciated.
                </h5>
                <h5>
                Pre-Insurance Medical Screening is not required for any Sum Insured opted.
                </h5>
            </h5>,
            content:true
        },
        {
            title:'Does Star Health bear the pre-medical cost for the Senior Citizens Red Carpet Health Insurance Policy?',
            description:<h5>
                <h5>The question of bearing the cost of Pre-Insurance Medical Screening does not arise, we don’t subject the elderly people to Insurance Medical Screening.</h5>
            </h5>,
            content:false
        },
        {
            title:'What is the waiting period meeting for Senior Citizens Red Carpet Health Insurance Policy?',
            description:<h5>
                <h5>There are 3 Time-Bound exclusions or Waiting Periods under this policy, namely</h5>
                <ol>
                    <li>
                    Any initial illness - 30 days ( Accidents are covered from day one)
                    </li>
                    <li>
                    Certain specific treatment - 24 months i.e.,  Cataract, Prolapse of Inter vertebral Disc, ENT, all types of management for Calculi,  Hernia, etc.
                    </li>
                    <li>
                    Pre-Existing Diseases - 12 months
                    </li>
                </ol>
            </h5>,
            content:false
        },
        {
            title:'Any Premium Discount offered of Senior Citizen?',
            description:<h5>
                <h5>A discount of 10% for lifetime Healthy Individual on Producing Medical Reports & Accepted by Star Health Insurance.</h5>
                <h5><b>If the proposer produces the following documents:</b></h5>
                <ol>
                    <li>Stress Thallium Report</li>
                    <li>BP Report</li>
                    <li>Sugar (blood & urine)</li>
                    <li>Blood urea & creatinine</li>
                </ol>
                <h5>The tests should not be before 45 days from the date of the proposal.</h5>
                <h5>We are Promoting digital (Zero-Contact) transactions</h5>
                <li>Online purchase discount of 5% for first purchase only.</li>
            </h5>,
            content:false
        },
        {
            title:'What is a co-payment?',
            description:<h5>
                <h5>A cost-sharing requirement under a health insurance policy. The insured should bear a specified percentage of admissible claims amount. The insured should bear a specified percentage of admissible claims amount. A nominal co-payment of 30% is applicable for all claims.</h5>
            </h5>,
            content:false
        },
        ] 

const SeniorCitizenHighlights = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });
    return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Health Insurance for Senior Citizen 
            </h2>
            </GridItem>
                <h5>
                Aging - a natural process, stays to be a unique challenge for all sections of our society. Individual citizens of age group more than 60 years are considered as Senior Citizens. According to the first Longitudinal Aging Study in India (LASI), two out of every three senior citizens in India are suffering from some chronic illnesses. Diseases observed to be high among the elderly age groups include Cardiovascular diseases (CVD), chronic hypertension, Diabetes, Bone and Joint diseases and more. Health care needs are one of the retirements’ biggest ‘unknowns.’ From the financial standpoint of you, the Senior Citizens, getting a standard treatment for such chronic diseases may drain your savings with the rising medical cost and makes it hard to set your retirement saving goals. Health Insurance for Senior Citizens is needed to meet their unique health requirements. Hence, get yourself immediately covered with a right and efficacious health plan that quenches your health care needs.
                </h5>
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Senior Citizens Red Carpet Health Insurance Policy
            </h2>
            </GridItem>
                <h5>
                Star Health Insurance presents the Senior Citizens Red Carpet Health Insurance Policy with a utilitarian approach towards the elderly citizens. This plan is uniquely designed for the senior citizens in the age group of 60 to 75 years.
                </h5>
                <h5>
                The policy comes with the best benefits for the Senior Citizens, which includes a coverage for a wide range of daycare procedures involved in modern treatments, significant medical interventions, pre-existing diseases from the second policy year with the availability of constant premium with a lifelong renewable option. The policy is available on an Individual/Floater Sum Insured basis. It is apt for senior citizens looking for a best pocket-friendly premium towards medical insurance for senior citizens in India. The key highlight of the policy is that no pre-insurance medical check-up is required while buying the policy. On purchasing the policy you can also avail the benefit of tax exemption under section 80D.
                </h5>
            <h5>
                <b>Benefits of Senior Citizens Red Carpet Health Insurance Policy</b>
            </h5>
            <h5>
            On purchasing the Senior Citizens Red Carpet Health Insurance Policy, one can reap abundant benefits as listed below:
            </h5>
            <GridItem>
                <ol type="a">
                <li>
                Availability of greater Sum Insured coverage up to Rs. 25 Lakhs on Individual basis and Rs. 10 lakhs to 25 lakhs on floater basis.
                </li>
                <li>
                Coverage for outpatient medical consultations at Network Hospitals.
                </li>
                <li>
                No pre-insurance medical screening is required irrespective of the person’s age and the sum insured opted.
                </li>
                <li>
                Pre-existing diseases (PED) are covered after a one-year waiting period.
                </li>
                <li>
                A broader coverage of daycare procedures/treatments and modern treatments with both cashless and reimbursement facilities in extensive wide-range of network hospitals.
                </li>
                <li>
                Availing tax benefits as per the prevailing Income Tax rules on the premium paid, eligibility for relief under Section 80-D of Income Tax Act.
                </li>
                <li>
                An added advantage of the lifetime renewability option.
                </li>
                <li>
                A 10% discount on the premium is allowed on submitting certain medical records.
                </li>
                <li>
                A 5% discount for the online purchase of the policy.
                </li>
                <li>
                Premiums can be paid on a quarterly and half-yearly basis. You can prefer to make payments on annual, biennial and triennial terms.
                </li>
                </ol>
            </GridItem>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h3>
            Red Carpet Coverage for Senior Citizen
            </h3>
            </GridItem>
            <h5>
            The Senior Citizens Red Carpet Health Insurance Policy extends its coverage on multiple aspects to support you, the senior citizens on your health care needs and thereby making it a adequate mediclaim policy for 60+.
            </h5>
            <h5>
                <b>Daycare procedures</b>
            </h5>
            <h5>
            Expenses towards all day-care procedures, surgeries and treatments that would require hospitalization of less than 24 hours are covered. 
            </h5>
            <h5>
                <b>In-patient hospitalisation expenses</b>
            </h5>
            <h5>
            Coverage for all in-patient hospitalisation expenses up to the sum insured.
            </h5>
            <h5>
                <b>Pre- and Post-hospitalisation expenses</b>
            </h5>
            <h5>
            Pre-hospitalisation expenses are covered for a maximum of 30 days before admission in the network hospitals. 
            </h5>
            <h5>
            Post-hospitalisation expenses covered for a maximum of 7% of the hospitalisation expenses incurred up to the limits as specified in the policy clause. 
            </h5>
            <h5>
                <b>Modern Treatments</b>
            </h5>
            <h5>
            Twelve modern treatments are covered comprising balloon sinuplasty, immunotherapy and stem cell therapy, up to sub-limits mentioned in the policy clause.
            </h5>
            <h5>
                <b>Out-Patient expenses</b>
            </h5>
            <h5>
            Any medical consultation expenses as an outpatient incurred in a network hospital are covered up to the limits with a limit of Rs.200 per consultation.
            </h5>
            <h5>
                <b>Annual Health Check-Ups</b>
            </h5>
            <h5>
            Payment of expenses towards cost of health check-up up to the limits for every claim-free year at network hospitals.
            </h5>
            <h5>
                <b>Pre-Existing Disease (PED)</b>
            </h5>
            <h5>
            You can claim for treatments on pre-existing conditions after 12 months from the policy start date.
            </h5>
            <h5>
                <b>Specific Disease/Illness Coverage </b>
            </h5>
            <h5>
            The policy covers some specific diseases/treatments only after a 24-months waiting period. Covers for cataract surgery up to specific limits based on sum insured options.
            </h5>
            <h5>Initial waiting period – 30 days (except for accidents).</h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Exclusions in the Policy
            </h2>
            </GridItem>
            <h5>
            The policy does not cover the treatments and conditions listed below:
            </h5>
            <GridItem>
                <ol>
                <li>
                Cosmetic or plastic surgery expenses
                </li>
                <li>
                Change-of-Gender treatment
                </li>
                <li>
                Intentional self injury
                </li>
                <li>
                Treatments necessitated due to participation in hazardous or adventure sports.
                </li>
                <li>
                Treatments for alcohol or drug abuse
                </li>
                <li>
                Congenital External Condition / Defects / Anomalies
                </li>
                </ol>
            </GridItem>
            <h5>
            Please refer to the policy clause and wording on the website to have a detailed list of such exclusions.
            </h5>
            <h2 className={classes.h2hTitle}>
            Why 60+ Elderly People should take Star Health Medical Insurance
            </h2>
            <h5>
            Star Health Insurance designs health plans aiming for senior citizens who had once built the nation. Senior Citizens Red Carpet Health Insurance Policy is a complete plan in support of the elderly community.
            </h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h5>
            There are numerous valuable benefits enjoyed by the Senior Citizens on buying the policy:
            </h5>
            </GridItem>
            <GridItem>
                <ol type="a">
                <li>
                Coverage on any outpatient medical consultations in a Network Hospital
                </li>
                <li>
                No pre-insurance medical screening is required.
                </li>
                <li>
                Pre-existing diseases (PED) are covered after a waiting period of one year.
                </li>
                <li>
                A broader coverage for daycare procedures and modern treatments.
                </li>
                </ol>
            </GridItem>
            <h5>
                <b>Dedicated Senior Citizens Claims & Grievances</b>
            </h5>
            <h5>
            Policyholders who have complaints against insurers are required to first approach the Complaints/Grievance Redressal Cell of the insurer in the link,
            </h5>
            <a
            href="https://www.starhealth.in/grievance-redressal"
            >https://www.starhealth.in/grievance-redressal 
            </a>
            <h5>Email:
            <a
            href="mailto:gro@starhealth.in">gro@starhealth.in </a>
             or 
            <a href="mailto:grievances@starhealth.in"> grievances@starhealth.in</a>
            </h5>
            <h5>
            If they do not receive a response from the insurer within a reasonable period or are dissatisfied with the response of the company, contact
            </h5>
            <h5>
            Integrated Grievance Management System(IGMS) - IRDAI Portal at <a href="https://igms.irda.gov.in">https://igms.irda.gov.in</a> for registering the complaints Email to <a href="complaints@irdai.gov.in">complaints@irdai.gov.in</a>
            </h5>
            <h5>
            And can call Toll Free No. 155255 or 1800 4254 732.
            </h5>
            <h2 className={classes.h2hTitle}>
            Tax Benefit from buying Senior Citizens Red Carpet Health Insurance Policy
            </h2>
            <h5>
            The key benefit of the policy is, theInsured person is eligible for tax benefits under Section 80-D of the Income Tax Act 1961 in respect of the premium paid by any mode other than cash.
            </h5>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Frequently Asked Questions of Outpatient Care Insurance Policy</h2>
            </GridItem>
            <div>
            <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer
                className={`${classes.container} ${classes.textCenter}`}
                >
                <GridItem
                    md={12}
                    style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                ></GridItem>
                <GridItem
                style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <Accordion active={0} collapses={AccordionArray} />
                </GridItem>
                </GridContainer>
            </div>
            </div>
        </div>
      <Helmet>
            <title>
            Health Insurance for Senior Citizen, Medical Insurance for 60+ | Star Health
            </title>
            </Helmet>
            {process.env.ENABLE_HELMET == `true` && (
            <Helmet>
           <script type="application/ld+json">
                {`
                {
                    "@context":"https://schema.org",
                    "@type":"FAQPage",
                    "mainEntity":[
                       {
                          "@type":"Question",
                          "name":"What will be the premium for Senior Citizens Red Carpet Health Insurance Policy?",
                          "acceptedAnswer":{
                             "@type":"Answer",
                             "text":"The Health insurance Premium for SCRC is based on the Sum Insured ranging from 1 lakh to 25 lakhs cover. Premium is raging from INR. 4500/- to 36,000/- Excl. Taxes\nThe best option to buy 15 lakhs Sum Insured & the Premium would be around INR 29,000/- and avail Section 80D benefit."
                          }
                       },
                       {
                          "@type":"Question",
                          "name":"Do I need to take a pre-medical checkup for the Senior Citizens Red Carpet Health Insurance Policy?",
                          "acceptedAnswer":{
                             "@type":"Answer",
                             "text":"We understand the limitations and needs of elderly people about their dietary habits. \nAsking elderly people to visit multiple times to the diagnostics will not be appreciated.\nPre-Insurance Medical Screening is not required for any Sum Insured opted."
                          }
                       },
                       {
                          "@type":"Question",
                          "name":"3.Does Star Health bear the pre-medical cost for the Senior Citizens Red Carpet Health Insurance Policy?",
                          "acceptedAnswer":{
                             "@type":"Answer",
                             "text":"The question of bearing the cost of Pre-Insurance Medical Screening does not arise, we don't subject the elderly people to Insurance Medical Screening."
                          }
                       },
                       {
                          "@type":"Question",
                          "name":"What is the waiting period meeting for Senior Citizen policy Senior Citizens Red Carpet Health Insurance Policy?",
                          "acceptedAnswer":{
                             "@type":"Answer",
                             "text":"There are 3 Time-Bound exclusions or Waiting Periods under this policy, namely\na.Any initial illness - 30 days ( Accidents are covered from day one)\nb.Certain specific treatment - 24 months  i.e.,  Cataract, Prolapse of Inter vertebral Disc, ENT, all types of management for Calculi,  Hernia, etc.\nc.Pre-Existing Diseases - 12 months"
                          }
                       },
                       {
                          "@type":"Question",
                          "name":"Any Premium Discount offered of Senior Citizen",
                          "acceptedAnswer":{
                             "@type":"Answer",
                             "text":"A discount of 10% for lifetime Healthy Individual on Producing Medical Reports & Accepted by Star Health Insurance.    \n                                                                                                                                                                      If the proposer produces the following documents:\na) Stress Thallium Report\nb) BP Report\nc) Sugar (blood & urine)\nd) Blood urea & creatinine\nThe tests should not be before 45 days from the date of the proposal.\nWe are Promoting digital (Zero-Contact) transactions                                          \n• Online purchase discount of 5% for first purchase only."
                          }
                       },
                       {
                          "@type":"Question",
                          "name":"What is a co-payment?",
                          "acceptedAnswer":{
                             "@type":"Answer",
                             "text":"A cost-sharing requirement under a health insurance policy. The insured should bear a specified percentage of admissible claims amount. The prominent USP of this plan is that, on opting a sum insured option of 15 lakhs and above, a nominal co-payment of 30% is applicable for all medical claims and an exemption from co-payment on cataract surgeries is provided."
                          }
                       }
                    ]
                 }
                `}
           </script>
           {/* <script type="application/ld+json">
{`{ "@context": "http://schema.org",
  "@type": "Product",
  "name": "Senior Citizens Red Carpet Health Insurance Policy",
  "description": "Buy Senior Citizen Health Insurance Policy that provides medical expenses incurred during pre and post hospitalization. Apply best Health Insurance for your Parents Online.",
"offers":{ 
          "@type": "AggregateOffer",
          "offerCount": "5%"
        }
}`}
</script> */}
        </Helmet>
            )}
      </GridItem>
    </GridContainer>
  );
};
SeniorCitizenHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(SeniorCitizenHighlights);