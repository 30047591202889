import {
  container,
  title,
  cardTitle,
  coloredShadow,
  description,
  mlAuto,
  mrAuto,
  infoColor,
  whiteColor,
  roseColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";

const genericCardStyle = {
  "@keyframes fadeIn": {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  },
  container,
  title,
  coloredShadow,
  cardTitle,
  mlAuto,
  mrAuto,
  description,
  blog: {
    padding: `50px 0`
  },
  cardCategory: {
    marginBottom: `10px`,
    marginTop: `10px`,
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: `relative`,
      top: `8px`,
      lineHeight: `0`
    }
  },
  onHoverEffect: {
    "&:hover": {
      boxShadow: `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4)!important`
    }
  },
  description1: {
    ...description,
    lineHeight: `1.313rem`
  },
  author: {
    display: `inline-flex`,
    "& a": {
      color: grayColor[1],
      textDecoration: `none`
    }
  },
  card: {
    marginBottom: `80px`
  },
  card4: {
    marginBottom: `60px`,
    textAlign: `center`
    // boxShadow: "none"
  },
  cardTitleAbsolute: {
    ...cardTitle,
    position: `absolute !important`,
    bottom: `15px !important`,
    left: `15px !important`,
    color: whiteColor + ` !important`,
    fontSize: `1.125rem !important`,
    textShadow: `0 2px 5px rgba(` + hexToRgb(grayColor[9]) + `, 0.5) !important`
  },
  cardTitleWhite: {
    "&, & a": {
      ...title,
      marginTop: `.625rem`,
      marginBottom: `0`,
      minHeight: `auto`,
      color: whiteColor + ` !important`
    }
  },
  cardCategorySocial: {
    marginTop: `10px`,
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: `22px`,
      position: `relative`,
      marginTop: `-4px`,
      top: `2px`,
      marginRight: `5px`
    },
    "& svg": {
      position: `relative`,
      top: `5px`
    }
  },
  cardCategorySocialWhite: {
    marginTop: `10px`,
    color: `rgba(` + hexToRgb(whiteColor) + `, 0.8)`,
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: `22px`,
      position: `relative`,
      marginTop: `-4px`,
      top: `2px`,
      marginRight: `5px`
    },
    "& svg": {
      position: `relative`,
      top: `5px`
    }
  },
  cardCategoryWhite: {
    marginTop: `10px`,
    color: `rgba(` + hexToRgb(whiteColor) + `, 0.7)`
  },
  cardCategoryFullWhite: {
    marginTop: `10px`,
    color: whiteColor
  },
  cardDescription: {
    ...description
  },
  cardDescriptionWhite: {
    color: `rgba(` + hexToRgb(whiteColor) + `, 0.8)`
  },
  authorWhite: {
    display: `inline-flex`,
    "& a": {
      color: `rgba(` + hexToRgb(whiteColor) + `, 0.8)`
    }
  },
  avatar: {
    width: `30px`,
    height: `30px`,
    overflow: `hidden`,
    borderRadius: `50%`,
    marginRight: `5px`
  },
  statsWhite: {
    color: `rgba(` + hexToRgb(whiteColor) + `, 0.8)`,
    display: `inline-flex`,
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: `relative`,
      top: `3px`,
      marginRight: `3px`,
      marginLeft: `3px`,
      fontSize: `18px`,
      lineHeight: `18px`
    },
    "& svg": {
      position: `relative`,
      top: `3px`,
      marginRight: `3px`,
      marginLeft: `3px`,
      width: `18px`,
      height: `18px`
    }
  },
  stats: {
    color: grayColor[0],
    display: `flex`,
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: `relative`,
      top: `3px`,
      marginRight: `3px`,
      marginLeft: `3px`,
      fontSize: `18px`,
      lineHeight: `18px`
    },
    "& svg": {
      position: `relative`,
      top: `3px`,
      marginRight: `3px`,
      marginLeft: `3px`,
      width: `18px`,
      height: `18px`
    }
  },
  justifyContentCenter: {
    WebkitBoxPack: `center !important`,
    MsFlexPack: `center !important`,
    justifyContent: `center !important`
  },
  textAlignCenter: {
    textAlign: `center !important`,
    alignItems: `center !important`
  },
  textAlignLeft: {
    textAlign: `left !important`,
    alignItems: `left !important`
  },
  iconWrapper: {
    color: `rgba(` + hexToRgb(whiteColor) + `, 0.76)`,
    margin: `10px auto 0`,
    width: `130px`,
    height: `130px`,
    border: `1px solid ` + grayColor[14],
    borderRadius: `50%`,
    lineHeight: `174px`,
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: `55px`,
      lineHeight: `55px`
    },
    "& svg": {
      width: `55px`,
      height: `55px`
    }
  },
  iconWrapperColor: {
    borderColor: `rgba(` + hexToRgb(whiteColor) + `, 0.25)`
  },
  iconWhite: {
    color: whiteColor
  },
  iconRose: {
    color: roseColor[0]
  },
  iconInfo: {
    color: infoColor[0]
  },
  marginTop30: {
    marginTop: `30px`
  },
  textCenter: {
    textAlign: `center`
  },
  marginBottom20: {
    marginBottom: `20px`
  },
  cardBody: {
    width: `100%`,
    "& h4": {
      overflow: `hidden`,
      textOverflow: `ellipsis`,
      whiteSpace: `nowrap`
    },
    "& div": {
      height: `52px`,
      display: `-webkit-box`,
      WebkitLineClamp: 2,
      WebkitBoxOrient: `vertical`,
      overflow: `hidden`,
      textOverflow: `ellipsis`
    }
  },
  noWrap: {
    "& div": {
      display: `-webkit-box`,
      WebkitLineClamp: 6,
      WebkitBoxOrient: `vertical`,
      overflow: `hidden`,
      textOverflow: `ellipsis`
    }
  },
  noMarginHeader: {
    marginTop: `30px !important`
  },
  paddingBottom: {
    paddingBottom: `30px !important`
  },
  noShadow: {
    boxShadow: `none !important`,
    border: `1px solid !important`,
    borderColor: `#eee !important`
  },
  italic: {
    fontStyle: `italic`
  },
  fixedHeight: {
    height: `200px`
  },
  overlay: {
    height: `100%`,
    position: `absolute`,
    width: `100%`,
    backgroundColor: `rgba(0,0,0,0.6)`,
    zIndex: `99999`,
    borderRadius: `6px`,
    opacity: 0 /* make things invisible upon start */,
    animation: `fadeIn ease-in 1`,
    animationFillMode: `forwards`,
    animationDuration: `.7s`
  },
  overlaySections: {
    position: `absolute`,
    top: `50%`,
    transform: `translate(-50%, -50%)`,
    msTransform: `translate(-50%, -50%)`,
    left: `50%`
  }
};

export default genericCardStyle;
