import React from "react";
// nodejs library that concatenates classes
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const table1 = [
  ['1', 'BABY FOOD'],
  ['2', 'BABY UTILITIES CHARGES'],
  ['3', 'BEAUTY SERVICES'],
  ['4', 'BELTS / BRACES'],
  ['5', 'BUDS'],
  ['6', 'COLD PACK / HOT PACK'],
  ['7', 'CARRY BAGS'],
  ['8', 'EMAIL / INTERNET CHARGES'],
  ['9', 'FOOD CHARGES (OTHER THAN PATIENT’S DIET PROVIDED BY HOSPITAL)'],
  ['10', 'LEGGINGS'],
  ['11', 'LAUNDRY CHARGES'],
  ['12', 'MINERAL WATER'],
  ['13', 'SANITARY PAD'],
  ['14', 'TELEPHONE CHARGES'],
  ['15', 'GUEST SERVICES'],
  ['16', 'CREPE BANDAGE'],
  ['17', 'DIAPER OF ANY TYPE'],
  ['18', 'EYELET COLLAR'],
  ['19', 'SLINGS'],
  ['20', 'BLOOD GROUPING AND CROSS MATCHING OF DONORS SAMPLES'],
  ['21', 'SERVICE CHARGES WHERE NURSING CHARGE ALSO CHARGED'],
  ['22', 'TELEVISION CHARGES'],
  ['23', 'SURCHARGES'],
  ['24', 'ATTENDANT CHARGES'],
  ['25', 'EXTRA DIET OF PATIENT (OTHER THAN THAT WHICH FORMS PART OF BED CHARGE)'],
  ['26', 'BIRTH CERTIFICATE'],
  ['27', 'CERTIFICATE CHARGES'],
  ['28', 'COURIER CHARGES'],
  ['29', 'CONVEYANCE CHARGES'],
  ['30', 'MEDICAL CERTIFICATE'],
  ['31', 'MEDICAL RECORDS'],
  ['32', 'PHOTOCOPIES CHARGES'],
  ['33', 'MORTUARY CHARGES'],
  ['34', 'WALKING AIDS CHARGES'],
  ['35', 'OXYGEN CYLINDER (FOR USAGE OUTSIDE THE HOSPITAL)'],
  ['36', 'SPACER'],
  ['37', 'SPIROMETRE'],
  ['38', 'NEBULIZER KIT'],
  ['39', 'STEAM INHALER'],
  ['40', 'ARMSLING'],
  ['41', 'THERMOMETER'],
  ['42', 'CERVICAL COLLAR'],
  ['43', 'SPLINT'],
  ['44', 'DIABETIC FOOT WEAR'],
  ['45', 'KNEE BRACES (LONG / SHORT / HINGED)'],
  ['46', 'KNEE IMMOBILIZER / SHOULDER IMMOBILIZER'],
  ['47', 'LUMBO SACRAL BELT'],
  ['48', 'NIMBUS BED OR WATER OR AIR BED CHARGES'],
  ['49', 'AMBULANCE COLLAR'],
  ['50', 'AMBULANCE EQUIPMENT'],
  ['51', 'ABDOMINAL BINDER'],
  ['52', 'PRIVATE NURSES CHARGES - SPECIAL NURSING CHARGES'],
  ['53', 'SUGAR FREE TABLETS'],
  ['54', 'CREAMS POWDERS LOTIONS (TOILETRIES ARE NOT PAYABLE, ONLY PRESCRIBED MEDICAL PHARMACEUTICALS PAYABLE)'],
  ['55', 'ECG ELECTRODES'],
  ['56', 'GLOVES'],
  ['57', 'NEBULISATION KIT'],
  ['58', 'ANY KIT WITH NO DETAILS MENTIONED [DELIVERY KIT, ORTHOKIT, RECOVERY KIT, ETC]'],
  ['59', 'KIDNEY TRAY'],
  ['60', 'MASK'],
  ['61', 'OUNCE GLASS'],
  ['62', 'OXYGEN MASK'],
  ['63', 'PELVIC TRACTION BELT'],
  ['64', 'PAN CAN'],
  ['65', 'TROLLY COVER'],
  ['66', 'UROMETER, URINE JUG'],
  ['67', 'AMBULANCE'],
  ['68', 'VASOFIX SAFETY'],

]

const ExtraprotectBenefits = ({ classes }) => {
    return ( 
        <GridContainer>
            <h5><b>Section - I</b></h5>
            <ol type="1" style={{fontWeight:'400'}}>
                <li><b>Enhanced Room Rent: </b>Room, boarding, nursing expenses all-inclusive as provided by the hospital / nursing home as per the limits given below:
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Sum insured (Rs.)</b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>10,00,000/- to 20,00,000/-
                            (as per base policy)</b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Above 20,00,000/-
                            (as per base policy)</b>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>Room rent eligibility</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Any Room(Except for suite room and above the category of suite room)</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Any Room</TableCell>
                        </TableRow>
                        </TableHead>
                     
                  </Table>
                </Paper>
                </li>
                <li>
                  <b>CLAIM GUARD (Coverage for Non-medical Items (Consumables)): </b> If there is an admissible claim under the base policy, then the expenses towards the following items will become payable.
                  <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>SI No</b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>ITEM</b>
                        </TableCell>
                        </TableRow>
                        {table1.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.SEPtableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                className={classes.SEP2tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableHead>
                     
                  </Table>
                </Paper>
                <h5>The amount payable under this clause shall be part of the Sum Insured under the Base Policy and not in addition to the same.</h5>
                </li>
                <li>
                  <b>Enhanced Limit for Modern treatments: </b>
                  The following procedures covered under the Base policy with sub-limits are covered <b>up to sum insured</b> of the base policy.
                  <Paper className={classes.root}>
                  <Table className={classes.table}>
                      <TableRow>
                            <TableCell align="center" className={classes.tableCell}>1.Uterine Artery Embolization and HIFU</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>2. Balloon Sinuplasty</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>3. Deep brain stimulation</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>4. Oral Chemotherapy</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>5. Immunotherapy - Monoclonal antibody to be given as injection</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>6. Intra Vitreal injections</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>7. Robotic surgeries</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>8. Stereotactic radio surgeries</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>9. Bronchical Thermoplasty</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>10. Vaporisation of the prostate (Green laser treatment or holmium laser treatment)</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>11. IONM - (Intra Operative Neuro Monitoring)</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>12. Stem cell theraphy: Hematopoietic stem cells for bone marrow transplant for haematological conditions</TableCell>
                        </TableRow>
                     
                  </Table>
                </Paper>
                <h5>The amount payable under this clause shall be part of the Sum Insured under the Base Policy and not in addition to the same.</h5>   
                </li>
                <li><b>Ayush Treatment: </b>Medical expenses for In-patient Hospitalization incurred on treatment under Ayurveda, Unani, Siddha and Homeopathy systems of medicines in a AYUSH Hospital is payable up to sum insured (including Cumulative bonus if available) under the Base Policy.
                <h5>
                <b>Note: </b>Yoga and naturopathy systems of treatments are excluded from the scope of coverage under AYUSH treatment.
                The amount payable under this clause shall be part of the Sum Insured under the Base Policy and not in addition to the same.
                </h5>
                </li>
                <li><b>Home care treatment: </b>Payable up to 10% of sum insured of the base policy, subject to maximum of Rs.5 lakh in a policy year, for treatment availed by the insured person at home, only for the specified conditions mentioned below, which in normal course would require care and treatment at a hospital but is actually taken at home provided that:
                <ol type="A" >
                  <li style={{marginLeft: '5%'}}>the medical practitioner advises the insured person to undergo treatment at home</li>
                  <li style={{marginLeft: '5%'}}>there is a continuous active line of treatment with monitoring of the health status by a medical practitioner for each day through the duration of the home care treatment</li>
                  <li style={{marginLeft: '5%'}}>daily monitoring chart including records of treatment administered duly signed by the treating doctor are maintained</li>
                  <li style={{marginLeft: '5%'}}>Insured can avail “Home Care Treatment” service on cashless / reimbursement basis, if availed from our network service providers given in our website “www.starhealth.in”</li>
                </ol>
                <h5 style={{marginLeft: '5%'}}><b>List of treatments / conditions covered under home care treatment:</b></h5>
                <ol>
                  <li style={{marginLeft: '5%'}}>Fever and infectious diseases which can be managed as In-patient</li>
                  <li style={{marginLeft: '5%'}}>Uncomplicated urinary tract infections but needing parenteral antibiotics</li>
                  <li style={{marginLeft: '5%'}}>Asthma and COPD-Mild Exacerbations needing Home Nebulization</li>
                  <li style={{marginLeft: '5%'}}>Acute Gastritis/Gastroenteritis</li>
                  <li style={{marginLeft: '5%'}}>I.V. Chemotherapy [Where advised by the doctor]</li>
                  <li style={{marginLeft: '5%'}}>Palliative Cancer Care requiring medical assistance</li>
                  <li style={{marginLeft: '5%'}}>Acute Vertigo</li>
                  <li style={{marginLeft: '5%'}}>Diabetic Foot and Cellulitis</li>
                  <li style={{marginLeft: '5%'}}>IVDP [Cervical and Lumbar Disc diseases]</li>
                  <li style={{marginLeft: '5%'}}>Major surgeries/Arthroplasties needing IV Antibiotics Post Discharge</li>
                  <li style={{marginLeft: '5%'}}>Care for Brain and Spinal Injury Cases Post Discharge</li>
                  <li style={{marginLeft: '5%'}}>Post CVA Care at Home after discharge</li>
                </ol>
                <h5>The amount payable under this clause shall be part of the Sum Insured under the Base Policy and not in addition to the same.</h5>
                </li>
                <li><b>Bonus Guard:</b>
                <ol type="i">
                  <li>Cumulative bonus available under base policy will not be reduced at the time of renewals unless the bonus is utilized.</li>
                  <li>On full utilization of sum insured and nil utilization of cumulative bonus accrued, such cumulative bonus so granted under the base policy will not be reduced</li>
                  <li>On full utilization of sum insured and partial utilization of cumulative bonus accrued, the cumulative bonus granted under the base policy on renewal will be the balance cumulative bonus available</li>
                  <li>On full utilization of Sum Insured and full utilization of cumulative bonus accrued, the cumulative bonus granted under the base policy on renewal will be “nil”</li>
                </ol>
                <h5><b>Premium details</b></h5>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Product Name</b>
                        </TableCell>
                       
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Young Star Insurance Policy</b>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}><b>Percentage on the Base policy premium</b></TableCell>
                            
                            <TableCell  align="center" className={classes.tableCell}>15%</TableCell>
                        </TableRow>
                        </TableHead>
                     
                  </Table>
                </Paper>

                </li>
            </ol>
            <ul>
            <li><b>General Conditions  </b>
                <ol>
                <li style={{marginLeft: '2%'}}>The Add on Cover can be purchased along with the Base Policy only (with Rs.10,00,000/- and above Sum Insured) either at Inception or during Renewal of the Base Policy.</li>
                  <li style={{marginLeft: '2%'}}>The Add on Cover shall be available only if the same is specifically mentioned in the Base Policy Schedule.</li>
                  <li style={{marginLeft: '2%'}}>Any claim under this Add on Cover will be subject to an admissible claim under the Base Policy.</li>
                  <li style={{marginLeft: '2%'}}>Wherever the benefits mentioned in the Add on Cover are already available in the Base Policy, the Add on Cover benefits shall supersede the existing benefits.</li>
                  <li style={{marginLeft: '2%'}}>The limits under the Add on Cover shall not be in addition to the limits under the Base Policy.</li>
                  <li style={{marginLeft: '2%'}}>Wherever the benefits mentioned in the Add on Cover are not available in the Base Policy, the Add on Cover benefits will get added to the existing benefits of the Base Policy.</li>
                  <li style={{marginLeft: '2%'}}>During renewal, if insured reduces the Base Policy Sum Insured to below Rs.10,00,000/-, the Add on Cover will not be available.</li>
                  <li style={{marginLeft: '2%'}}>In case if insured migrates from the existing Base Policy to any other product offered by the Company, providing of the Add on Cover is subject to the availability of Add on Cover in the migrated product. </li>
                  <li style={{marginLeft: '2%'}}>If this Add on Cover is opted, Insured cannot opt-out of the same at the time of renewal provided the Sum Insured is Rs.10,00,000/- and above.</li>
                </ol>
                </li>
                </ul>
        </GridContainer>
    );
};
ExtraprotectBenefits.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(ExtraprotectBenefits);