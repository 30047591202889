import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
// import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "components/Accordion/Accordion.jsx";
import { Helmet } from "react-helmet";


const table1 = [
    ['Type of Cover', 'Individual /Family','Individual /Family'],
    ['Sum insured Options in lakhs', '₹100000 to ₹10 lakhs','Up to ₹1 crore'],
    ['Room Rent, Boarding, Nursing Expenses', '2% of the sum insured subject to maximum of ₹5000/- per day','Customise the limits of cover'],
    ['Pre Hospitalisation', '30 days prior to the date of hospitalisation.','Customise the limits of cover'],
    ['Post Hospitalisation', '60 days after discharge from the hospital','Customise the limits of cover'],
    ['Emergency ambulance', 'Up to ₹2000 per hospitalisation','Customise the limits of cover'],
    ['AYUSH Treatment', 'Covered up to sum insured','up to 25% of the sum insured subject to a maximum of ₹25,000/- per policy period'],
    ['Daycare treatments', 'Covered','Covered'],
    ['Modern Treatments', 'up to 50% of the sum Insured.','Customise the limits of cover'],
    ['Cataract surgery', 'up to 25% of the sum insured or ₹40000 whichever is lesser, per eye in a policy year.','Customise the limits of cover'],
    
]
const table2 = [
    ['Waiver of 30 days waiting period', 'Any illness in the first 30 days from the date of policy inception will be covered'],
    ['Waiver of first year exclusion', 'Specified illness which is mentioned in the policy clause from the date of policy inception will be covered.  eg. Gall bladder stones, Kidney stones, pancreatic stones, prostrate, hernia, hydrocele etc.'],
    ['Waiver of first two-year exclusion', 'Specified illness which is mentioned in the policy clause from the date of policy inception will be covered. Eg Cataract, ENT diseases, prolapse of inter vertebral, problems related female genital organs etc.'],
    ['Waiver of Pre-existing disease exclusion', 'Expenses related to the treatment of a pre-existing Disease (PED) and its direct complications shall be covered from the date of inception of the policy '],
]

const table3 = [
    ['Migration (Provision by the Insurance Regulator)','Subject to underwriting:  Individual members, including the family members covered under an indemnity-based group health insurance policy shall have the right to migrate from such a group policy to an individual health insurance policy or a family floater policy within the same company'],
    ['Risk Covered ', 'In-patient hospitalization for 24 hours due to illness/ accidents and daycare treatments or Procedures'],
    ['Addition & Deletion',<h5 key="addition_deletion" >
        <ul>
            <h5><li style={{fontSize:'14px'}}>Any new joiner can be added along with dependants.</li></h5>
            <h5><li style={{fontSize:'14px'}}>New born child & Newly married spouse can be added</li></h5>
            <h5> <li style={{fontSize:'14px'}}>Any person leaving the organization will be deleted from the cover on Pro-rata Premium subject no claim under that insured person /s</li></h5>
        </ul>
    </h5>],
]
const faq=[
    {
        title:'Can I get group plans for my employees of any size in a Star Group Health Insurance plan?  ',
        description:<h5>Any group with 7 members or more may purchase group health insurance policy, based on the circular from IRDAI. Furthermore, it is up to the insurance companies to decide on the minimum size of groups to offer coverage. Star Health can tailor a Star Group Health Insurance that fits the needs of your employees with a group size of minimum of seven members.</h5>,
        content:false
    },
    {
        title:'What is the policy norms on enrolment of dependents in the middle of the policy year in a Star Group Health Insurance? ',
        description:<h5>Addition and deletion of Employees is possible on joining and exiting the organization. Enrollment or changes to dependent enrollment in the middle of the policy year is not allowed except for dependent due to change in marital status, newborn baby or new recruits.</h5>,
        content:false
    },
    {
        title:'Will I be able to customise the coverage and benefits of the plan that I want to offer my employees in a Star Group Health Insurance.',
        description:<h5>Of course Yes, the Star Group Health Insurance offered by Star Health allows the proposer to customise the benefits for employees according to requirements. </h5>,
        content:false
    },
    {
        title:'Who is not eligible for a Star Group Health Insurance?',
        description:<h5>
        <h5>Like all insurance plans, the Star Group Health Insurance also comes with certain limitations. The following offers an insight into who is not eligible for the Star Group Health Insurance:</h5>
        <h5>A sole entrepreneur with no employees is not eligible for the group cover.</h5>
        <h5>A self-employed small business owner with no employees is not eligible for the group cover.</h5>
        <h5>A group formed only for the purpose of availing health insurance.</h5>
        </h5>,
        content:false
    },
    {
        title:'What factors can affect my Star Group Health Insurance’s premium? ',
        description:<h5>The size of the group you are insuring is the most obvious factor impacting your Star Group Health Insurance rates. The premium of a Star Group Health Insurance is decided according to the number of employees in an organization, their age, number of dependents, occupation and cover opted. </h5>,
        content:false
    },
    {
        title:'Should we go for a pre-existing disease waiver?',
        description:<h5>It depends upon the decision of the group</h5>,
        content:false
    },
    {
        title:'How much is the premium for Star Group Health Insurance?',
        description:<h5>The cost of the premium amount depends on the number of employees, their family members that you want to cover and the type of coverage you choose for your employees. 
            Please visit our website Employee Health Insurance, Insurance Plans, Group Medical Insurance <a href="https://www.starhealth.in/">(starhealth.in)</a>to get a free quote for group medical insurance. 
            </h5>,
        content:false
    },
    {
        title:'Are health check-ups included in a Star Group Health Insurance? ',
        description:<h5>Yes, of course. Health checkups should be opted in the beginning of the policy purchase, by paying an additional premium. Health checkups can be one of the benefits of group plans. All the benefits under the plan depend on the type of group plan and coverage selected by the employer.</h5>,
        content:false
    },
    {
        title:'Is Maternity Benefit covered under a Star Group Health Insurance?',
        description:<h5>Maternity expenses mean medical expenses traceable to childbirth (including complicated deliveries and C-sections) incurred during hospitalisation and expenses related to lawful termination of pregnancy. Maternity benefits can be very beneficial for the employee with 9 months waiting period or day one cover.This is an optional cover.</h5>,
        content:false
    },
]
        

const Groupmediclaimhighlights = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });
    return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.GMCh2Title}>
            What is Group Medical Cover (GMC)? 
            </h2>
            </GridItem>
                <h5>
                A group medical cover is a policy that covers a defined group of people, usually members of a professional association, or a society or employees of a company. Group medical insurance policy will cover health and medical expenses.
                </h5>
                
             <h5>
             <b>What is a Group?</b>
             </h5>
             <h5>
             According to IRDAI, a group means a group of members who assemble together with a purpose of engaging in a common economic activity and not formed with the main purpose of availing insurance cover.
             </h5>
             <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.GMCh2Title}>
            A group can be majorly divided into two:
            </h2>
            </GridItem>
             <ol>
                <li>
                    <h3 className={classes.h3textStyle}>Non-employer- employee groups</h3>
                    <h5>They may include members of registered welfare associations, holders of credit cards issued by a specific company/ banks, customers of a particular business where insurance is offered as an add on benefit. </h5>
                </li>
                <li>
                    <h3 className={classes.h3textStyle} >Employer- employee groups</h3>
                    <h5>They may include employees of any specific registered organisation.</h5>
                </li>
                </ol>
                <h5><b>Star Health and Allied Insurance Company Limited is the health insurance specialist in issuing group mediclaim policies for existing groups. Eg. Employers- employees </b></h5>
                <h5><b>Who is a group administrator/proposer?</b></h5>
                <h5>Group Administrator / Proposer means the person/organization who has signed in the proposal form/declaration form and is named in the Policy Schedule. The person may or may not be insured under the policy.</h5>
                <h5><b>Star Health’s Group health plans for corporate</b></h5>
                <ul>
                    <li>
                        <b>Group Arogya Sanjeevani Policy, Star Health and Allied Insurance Co Ltd </b>
                    </li>
                    <li>
                        <b>Star Group Health Insurance </b>
                    </li>
                </ul>
        <div>
        <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    colSpan={2}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    PLAN
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Group Arogya Sanjeevani Policy, Star Health and Allied Insurance Co Ltd (SHAHLGP21153V012021)
                          </TableCell>
                          <TableCell
                                colSpan={2}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                STAR GROUP HEALTH INSURANCE (SHAHLGP21214V022021)
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table1.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" colSpan={2} className={classes.GMCtableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.GMCtableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={2}
                                className={classes.GMCtableCellLeftcustomWidth}>
                                    {row[2]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                        <TableCell
                        rowSpan={4}
                                colSpan={1}
                                align="center"
                                className={classes.GMCtableCellLeftcustomWidth}
                            >
                                Waiting Period 
                          </TableCell>
                            <TableCell
                                colSpan={1}
                                align="center"
                                className={classes.GMCtableCellLeftcustomWidth}
                            >
                                Pre-Existing Diseases
                          </TableCell>
                               <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.GMCtableCellLeftcustomWidth}
                            >
                                48 months of continuous coverage after inception of 1st Policy
                                </TableCell>
                          <TableCell
                                    colSpan={2}
                                    className={classes.GMCtableCellLeftcustomWidth}
                                >
                                    With or without waiting period(Waiver of waiting period is available)
                               </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                colSpan={1}
                                align="center"
                                className={classes.GMCtableCellLeftcustomWidth}
                            >
                                Certain Specific illness
                          </TableCell>
                          <TableCell
                                    colSpan={3}
                                    className={classes.GMCtableCellLeftcustomWidth}
                                >
                                    24 months of continuous coverage after inception of 1st Policy
                               </TableCell>
                            <TableCell
                                colSpan={2}
                                align="center"
                                className={classes.GMCtableCellLeftcustomWidth}
                            >
                                With or without waiting period(Waiver of waiting period is available)
                          </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                colSpan={1}
                                align="center"
                                className={classes.GMCtableCellLeftcustomWidth}
                            >
                                Certain Specific illness
                          </TableCell>
                          <TableCell
                                    colSpan={3}
                                    className={classes.GMCtableCellLeftcustomWidth}
                                >
                                    48 months of continuous coverage after inception of 1st Policy
                               </TableCell>
                            <TableCell
                                colSpan={2}
                                align="center"
                                className={classes.GMCtableCellLeftcustomWidth}
                            >
                                With or without waiting period(Waiver of waiting period is available)
                          </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                colSpan={1}
                                align="center"
                                className={classes.GMCtableCellLeftcustomWidth}
                            >
                                Any illness other than accidents
                          </TableCell>
                          <TableCell
                                    colSpan={3}
                                    className={classes.GMCtableCellLeftcustomWidth}
                                >
                                    1st 30 days after inception of 1st Policy
                               </TableCell>
                            <TableCell
                                colSpan={2}
                                align="center"
                                className={classes.GMCtableCellLeftcustomWidth}
                            >
                                With or without waiting period(Waiver of waiting period is available)
                          </TableCell>
                        </TableRow>
                        {table3.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" colSpan={2} className={classes.GMCtableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={5}
                                className={classes.GMCtableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
        <h5>Note: - The above information is only indicative. For complete details of the Terms & Conditions kindly read the policy clause before concluding a sale.</h5>
        <h5 style={{marginTop: '2%'}} ><b>Star Group Health Insurance</b></h5>
        <h5>Star Group Health Insurance is offered after customising the product to the requirement of the proposer.  The following benefits are covered under this policy subject to the sub-limits as stipulated in the policy schedule.</h5>
        <h5><b>In-patient hospitalisation expenses: </b>All expenses pertaining to in-patient hospitalisation such as Room/boarding expenses inclusive of Medical Practitioners fees, Nursing Expenses, Surgical fees, ICU charges, Anesthetist, Anesthesia, Blood, Oxygen, operation theatre charges etc. incurred during hospitalisation for a minimum period of 24 consecutive hours. </h5>
        <h5><b>Pre and Post hospitalisation Expenses: </b>Pre and post hospitalisation medical expenses up to the limits mentioned in the schedule.</h5>
        <h5><b>Daycare treatments/procedures: </b>All daycare treatments pertaining to medical treatment and/or surgical procedure which is undertaken in less than 24 hours because of technological advancement are covered. Treatments taken as an out-patient will be excluded under the scope of cover of daycare treatments/procedures. </h5>
        <h5><b>Maternity Benefits: </b>This optional cover provides coverage for medical expenses incurred for delivery either as c-section or normal delivery (inclusive of pre and post natal expenses), lawful medical termination of pregnancy during the Policy Period. The cover also extends to provide child birth related expenses from day 1 up to a specified limit as specifically stated in the Policy schedule. This coverage may be offered with or without any waiting period. The provision to waive off 9 months waiting period can also be opted under the policy. </h5>
        <h5><b>New born cover: </b>The new born baby can get the cover for medical expenses incurred as in-patient in hospital up to the floater sum insured or certain percentage of mother’s sum insured as stated in the policy schedule. This is also an optional cover. </h5>
        <h5><b>Ayush Treatment:  </b>This cover provides coverage for medical expenses for alternate methods of treatment such as homeopathy, Ayurveda, Siddha, Unani treatment provided that such treatment is administered by medical practitioner. (NABH) </h5>
        <h5><b>What waiting periods are waived off?</b></h5>
        <div style={{marginTop:'2%'}}>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableBody>
                        {table2.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                        </Table>
                </Paper>
                </div>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.GMCh2Title}>
            Features of Star Group Health Insurance at a glance
            </h2>
            </GridItem>
            <h5>Star Group health insurance is offered by the employer to its employees. </h5>
            <ul style={{listStyle:'none'}}>
                <li>
                ✓  Employees can choose to include their family members like spouse, dependent children,  parents, and parent in-laws.  
                </li>
                <li>
                ✓  Star Group Health Insurance can be issued with or without co-pay for covering elderly parents and in-laws. 
                </li>
                <li>
                ✓  The policy would provide a floater/individual sum insured to cover the medical expenses of 24 hour in-patient hospitalisation due to an illness or an accident and daycare procedures.
                </li>
                <li>
                ✓  The employee can opt an additional top-up cover to suit his requirements. 
                </li>
            </ul>
            <h5><b>Features of the Star Group Health Insurance</b></h5>
            <h5><b>Floater Benefit: </b>
            The insured can avail a floater cover and get family covered (spouse, dependent children, parents and in-laws) for the same sum insured by paying one premium amount.
            </h5>
            <h5><b>Cashless and reimbursement facility: </b>
            The insured can avail cashless facility at network hospitals and also can get reimbursement of expenses on submission of standard required documents. 
            </h5>
            <h5><b>Cover Period:  </b>1 year </h5>
            <h5><b>Eligibility:</b>Up to any age can enter this policy</h5>
            <h5><b>Group size: </b>If your company offers the insurance, you will be eligible for it. The size of the company can be as small as 7 members including family members. </h5>
            <h5><b>Waiting Period: </b>One of the biggest advantages of taking the Star Group Health Insurance cover is the waiver of the waiting periods/time bound exclusions. Usually, in a Group Arogya Sanjeevani Policy, waiting periods are applied ranging from 30 days to 4 years and delivery expenses are not covered. However, Star Group Health Insurance allows the employees to enjoy all the waivers of time bound exclusions and certain additional benefits like delivery expenses. </h5>
            <h5><b>No pre-insurance Medical Screening: In a Group Arogya Sanjeevani Policy</b>, pre-insurance screening is compulsory unlike group insurance policies can be issued without pre-insurance screening. Thereby, an aged person with pre-existing diseases can also be covered under a group insurance cover.</h5>
            <h5><b>Premium: </b>The premium charged under the policy depends upon the sum insured opted, additional covers (if opted by the Insured) and other factors like age, risk factor, city contingency, Morbidity etc.</h5>
            <h5><b>Type of policy: </b>The types of policies available are the same as those offered to regular consumers, however, the level of coverage provided may depend on the policy opted by your employer.</h5>
            <h5><b>Addition/Deletion: </b>In a Group Arogya Sanjeevani Policy, mid-term inclusion is not possible whereas a Star Group Health Insurance can allow the new joiner and their family members even during the mid of the policy on marriage and or new born baby. </h5>
            <h5><b>What we don’t pay for?</b></h5>
            <h5>We don’t pay for permanent exclusions mentioned in the policy schedule. </h5>
            <ul>
                <li>Walkers and wheelchairs, vitamins and tonics </li>
                <li>Dental Implants</li>
                <li>Congenital External Anomaly</li>
                <li>Non-payables/consumables etc. </li>
            </ul>
            <h5><b>What is corporate buffer? </b></h5>
            <h5>Corporate Buffer means additional sum insured available for the whole group. This can be availed in case of any medical emergency falling under specific diseases/illnesses after exhaustion of individual employee’s coverage.  Under the family floater cover, this benefit can be extended to the employee, spouse and children in consent with the employer.</h5>
            <h5><b>In house claim settlement: </b></h5>
            <h5><b>Cashless claim procedures: </b></h5>
            <h5>Star Health Claims Services is a hassle-free and customer-friendly process that ensures all settlements are processed in a timely manner. As your health insurance specialist, we make cashless claims available across all our network hospitals in India. </h5>
            <ul>
                <li>The Star Health website contains a list of network hospitals, including agreed network hospitals.</li>
                <li>Search from the network list from our website and locate the nearest network hospital to your residence.</li>
                <li>For planned surgery, approach the hospital prior to the date of admission who will send the completed pre-authorization form.</li>
                <li>In the pre-authorization form, you are required to furnish your contact number.</li>
                <li>If details are not complete, approval of the authorization request may get delayed.</li>
            </ul>
            <h5>Approach the insurance desk at a network hospital. Intimation can be given either through contacting us at 044 4674 5800 or e-mail us at <a  href="mailto:support@starhealth.in" >support@starhealth.in</a></h5>
            <ul>
                <li>Inform the Operator to obtain Claim No.</li>
                <li>Customer ID / Policy No</li>
                <li>Cause of Hospitalization</li>
                <li>Hospital Name</li>
                <li>Insured/Patient Name   </li>
            </ul>
            <h5>Planned Hospitalisation can be intimated between 7 to 10 days in advance and intimate for Emergency Hospitalization is within 24 hours of hospitalisation. </h5>
            <ul>
                <li>Register claim. </li>
                <li>Reach insurance desk and submit documents at the network hospital. </li>
                <li>Documents sent to Star Claims Team.</li>
                <li>Documents verified by our Claim Processing Team. </li>
                <li>If approved, the claim is settled as per the policy conditions.</li>
                <li>Payment will reach the network hospital.</li>
                <li>Pay the difference and get discharged.</li>
            </ul>
            <h5><b>Reimbursement claim procedures: </b></h5>
            <h5>In case of planned treatments, the insured gives prior intimation about the treatment to the insurer and obtains the claim number. In case of an emergency admission, the insured can avail claim number within 24 hours of hospitalisation. The insured can call the helpline 1800-425-2255 to obtain his claim number providing the required information like hospital name and patient’s name etc.   The insured can claim for a reimbursement of those expenses within 15 days from the date of discharge on submission of the necessary documents.</h5>
            <h5><b>Documents to be submitted for Reimbursement claims:</b></h5>
            <ul>
                <li>Duly completed claim form</li>
                <li>Pre-admission investigations and treatment papers</li>
                <li>Cash receipts from the hospital and chemists</li>
                <li>Cash receipts and reports for tests done</li>
                <li>Receipts from doctors, surgeons and anaesthetist</li>
                <li>Certificate from the attending doctor regarding the diagnosis</li>
                <li>Copy of PAN card, cancelled cheque or NEFT details</li>
            </ul>
            <h5>You may also reach out to our 24/7 customer care to get your queries cleared.</h5>
            <h5>Co-payment is a cost sharing requirement under a health insurance policy where the insured should bear a specified percentage of the admissible claims amount.  This co-payment feature will reduce the cost of the group insurance. </h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.GMCh2Title}>
            Whom do we recommend to buy Star Group Health Insurance?
            </h2>
            </GridItem>
            <h5>Employees are invaluable assets of any company, and employers are undertaking several initiatives to promote a healthy workforce ensuring their excellent mental and physical health that sets them up to perform well.  In the current scenario, there is a growing trend among organisations considering Health insurance as an employee benefit. </h5>
            <h5>Health Insurance is the key factor in recognising and rewarding the employees. Health insurance can also be used a retention tool where the employees are obliged to work with company for a longer period.  When the needs of the employee are taken care like the hospitalisation for his aged parents, therefore it brings satisfaction to give his best to the employer.</h5>
            <h5>We understand that you have those common questions in your mind that every HR manager or CEO of a company has. Does Group Health Insurance plan require a minimum number of employees? What is the minimum number of employees I need for Group Health Insurance plan?</h5>
            <h5>We are a small company with less than 20 employees, are we still eligible for group health insurance policy? </h5>
            <h5>Well, we want to address all of these questions. </h5>
            <h5><b>SME’s and start-ups: </b></h5>
            <h5><b>Group health insurance for startups </b>with a small team size consisting of minimum 7 members can be provided with a group medical policy. For instance, you are a new company with a count of 7 members, you could still sign up for a group health insurance plan.  Employers can take advantage of investing in group health insurance policy on two fronts.  Not only can they provide benefits to your employees but, can also help in your tax exemption under Section 37(1) of Income Tax Act. </h5>
            <h5><b>Large organisations: </b></h5>
            <h5>Corporate Social Responsibility is the continuing commitment by business to behave ethically and contribute to the welfare of the employees and their families and contribute to the welfare of the local community and society by and large.</h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.GMCh2Title}>
            How to choose the best health Insurance for your employees? 
            </h2>
            </GridItem>
            <h5>A Star Group Health Insurance helps you attract and retain the best talent. So, it’s a matter of utmost importance to select the right benefits and maximise the utility of the insurance with value added services. </h5>
            <h5>In light of these, it becomes important for employers to offer a comprehensive health insurance plan that’s relevant for their team. The right group health insurance plan is the one that is customisable to extend the best possible benefits to the employers budget. </h5>
            <h5>Before getting into the practical pointers that may help you frame the right insurance policy for your employees, it is essential to know your team well.</h5>
            <h5>Here are few pointers:</h5>
            <ul>
                <li><b>Waiting Periods - Star Group Health Insurance</b>
                <h5>Time bound exclusions/waiting periods are generally of 4 types.</h5>
                <h5>Time bound exclusions under a  Star Group Health Insurance are as follows</h5>
                <h5>Pre-existing diseases- 4 years. </h5>
                <h5>Certain specified diseases – diseases related to eye, ENT, female genital diseases etc is 2 years</h5>
                <h5>Certain specified diseases - diseases related to Hernia, Piles, stone formation etc.  is 1 year. </h5>
                <h5>Any disease acquired or contracted during the first 30 days of the policy </h5>
                <h5>The above time bound exclusions can be waived under a Star Group Health Insurance</h5>
                </li>
                <li><b>Waiting periods - Group Arogya Sanjeevani Policy, Star Health and Allied Insurance Co Ltd </b></li>
                <h5>Time bound exclusions/waiting periods are generally of 4 types.</h5>
                <h5>Time bound exclusions under a Group Arogya Sanjeevani Policy, are as follows</h5>
                <ol>
                    <li>Pre-existing diseases- 4 years
                        {/* <ol type='a' >
                            <li>Treatment for joint replacement unless arising from accident</li>
                            <li>Age-related Osteoarthritis & Osteoporosis</li>
                            <li>pre-existing Disease (PED) and its direct complications</li>
                        </ol> */}
                    </li>
                    <li>Certain specified diseases – 2 years few examples are stated below
                        <ol type='a'>
                            <li>Cataract and age related eye ailments </li>
                            <li>Benign ENT disorders</li>
                            <li>Hysterectomy</li>
                            <li>Hernia of all types</li>
                        </ol>
                    </li>
                    <li>Certain Specified diseases - 4 years few examples are stated below
                        <ol type= 'a'>
                            <li>Treatment for joint replacement unless arising from accident</li>
                            <li>Age-related Osteoarthritis & Osteoporosis</li>
                        </ol>
                    </li>
                    <li>Any disease acquired or contracted during the first 30 days of the policy</li>
                </ol>
                <li><b>Maternity coverage</b>
                <h5>The Star Group Health Insurance provides for delivery and new born baby cover as add-on benefits. It is a good choice to offer these benefits and show your support for your employees at the time of having a baby.</h5>
                </li>
                <li><b>Family coverage: </b>
                <h5>Employees will appreciate it much when you cover their family with comprehensive health insurance. It is the best benefit you can offer to show how much you value them. Of course, this gesture does come at a cost but you can always choose to customise an insurance plan to suit your employee’s needs.  Just like maternity add-ons, it makes sense to assess your team by their age group and make a well-calculated decision. For example, a group in their 20s may not require family coverage as much as a team in their mid- 30s with ageing parents and kids.  Your efforts will be useless if your employees cannot use their health insurance properly in an emergency.        </h5>
                </li>
            </ul>
            <h5><b>Benefits for employees from a Star Group Health Insurance? </b></h5>
            <h5>Today, employees look for attractive companies, those that offer more than just a paycheck. One such meaningful compensation is a Star Group Health Insurance, which has gained precedence in the current pandemic. Considering the notification issued by the Ministry of Home Affairs, to provide mandatory group health insurance plan for employees in light of the Covid- 19, corporate health insurance can offer a range of benefits for both the employer and the employee.</h5>
            <h5>Healthier employees make a more productive workforce. Especially in the event when a family member is hospitalised, employees are under a lot of stress. Let’s take a closer look at several advantages of having Star Group Health Insurance from your employer to cover your medical needs.</h5>
            <h5>As an employee, </h5>
            <h5>The benefits of a Star Group Health Insurance go further than that depending on the premium paid. </h5>
            <ul>
                <li>More than just insurance. Cover for the family. Depending on the policy, your family may be eligible to be insured as well.</li>
                {/* <li>Cover for the family. Depending on the policy, your families may be eligible to be insured as well. </li> */}
            </ul>
            <h5>As an Employer, </h5>
            <h5>With a Star Group Health Insurance, you can provide your employees with the benefits they need to remain productive.</h5>
            <ul>
                <li>A reduction in absenteeism </li>
                <li>Boosted productivity </li>
                <li>Improved employee retention </li>
                <li>Increased business productivity </li>
            </ul>
            <h5>For small and large businesses, Star Health and Allied Insurance Company Limited offers flexible and customised corporate health insurance solutions to meet your employees’ healthcare needs and help you control costs. Here is what our Star Group Health Insurance can do for your company:</h5>
            <h5><b>Cuts the cost of sick days </b></h5>
            <h5>Every employer, in every company has a challenge of reducing the number of sick leave taken by their employees. The working days are lost on sick leaves, which considerably impacts the bottom line of businesses. While you can’t control when your employees get sick, Star Group Health Insurance can provide your employees with fast access to quality healthcare, helping them return to work sooner. </h5>
            <h5><b>Helps you hire and retain staff </b></h5>
            <h5>When recruiting talent, offering company medical insurance as part of your benefits package can be the reason why a candidate chooses you over other companies. At the same time, your current employees are less likely to look elsewhere for employment because offering company private medical insurance can make them feel more valued and looked after. </h5>
            <h5><b>Increases job satisfaction </b></h5>
            <h5>Company medical insurance is one of the most wanted employee perks. When people know their employer is interested in their health and well-being by giving them access to quality private healthcare through a corporate health insurance scheme, they can experience a boost in motivation and productivity and be enticed to stay with your company for longer</h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.GMCh2Title}>
            Take care of your employees with Star Health  
            </h2>
            </GridItem>
            <h5>When you take care of your employees, anything is possible</h5>
            <h5>Our group plans are carefully designed to make sure the health and wellness of your employees is taken care of so they can focus on what matters.</h5>
            <ul>
                <li>Flexible Cover
                    <h5>For group policies, we can tailor our Star Group Health Insurance to fit your business’ needs and the level of cover required.</h5>
                </li>
                <li>Tech-friendly
                    <h5>Star health will be your tech friendly choice that will make your claim process simple and stress free.</h5>
                </li>
                <li>Admin Dashboard
                    <h5>Admin dashboard for managing your group health benefits. Our admin dashboard provides you with all the functionality you need. </h5>
                </li>
            </ul>
            <h5><b>Why choose Star Group Health Insurance with Star Health </b></h5>
            <ul style={{listStyle:'none'}}>
                <li>✓  89.9% cashless claims settled in less than 2 hours.  </li>
                <li>✓  Get cashless facility in over 13,000+ network hospitals across India. 94% of our health claims were processed within 30 days in fiscal 2021</li>
                <li>✓  In house doctors for claim settlement, without any TPA</li>
                <li>✓  Industry best claim settlement ratio</li>
            </ul>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.GMCh2Title}>
            FAQ’s 
            </h2>
            </GridItem>
            <div style={{marginTop: '-1%'}} >
            <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer
                className={`${classes.container} ${classes.textCenter}`}
                >
                <GridItem
                    md={12}
                    style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                ></GridItem>
                <GridItem
                style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <Accordion active={0} collapses={AccordionArray} />
                </GridItem>
                {process.env.ENABLE_HELMET == `true` && (
            <Helmet>
                <script type="application/ld+json">
                    {`{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "Can I get group plans for my employees of any size in a corporate health insurance plan?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Any group with 7 employees or more may purchase group health insurance policy, based on the circular from IRDAI. Furthermore, it is up to the insurance companies to decide on the minimum size of groups to offer coverage. Star Health can tailor a Group Medical Cover that fits the needs of your employees with a group size of minimum of seven employees."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is the policy norms on enrollment of dependents in the middle of the policy year in a group medical insurance?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Addition and deletion of Employees is possible on joining and exiting the organization. Enrollment or changes to dependent enrollment in the middle of the policy year is not allowed except for dependent due to change in marital status, newborn baby or new recruits."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Will I be able to customise the coverage and benefits of the plan that I want to offer my employees in a group medical policy.",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Of course Yes, the Star Group Health Insurance offered by Star Health allows the proposer to customise the benefits for employees according to requirements."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Who is not eligible for a Group medical cover?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "All insurance plans are subject to underwriting norms of the insurance company, the Group Mediclaim Coverage (GMC) also comes with certain limitations. \nThe following offers an insight into who is not eligible for the group health scheme:\nA sole entrepreneur with zero employees.\nA self-employed small business owner with no employees.\nA group formed only for the purpose of availing health insurance."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What factors can affect my corporate health plan's premium?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The size of the group you are insuring is the most obvious factor impacting your corporate medical insurance rates. The premium of a Group Health Insurance policy is decided according to the number of employees in an organization, their age, number of dependents, occupation and cover opted."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Should we go for a pre-existing disease waiver?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "It depends upon the decision of the Employer / Corporate / Group."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How much is the premium for group health insurance policy?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The cost of the premium amount depends on the number of employees, their family members that you want to cover and the type of coverage you choose for your employees."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Are health check-ups included in a group mediclaim policy?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, of course. Health checkups should be opted in the beginning / proposing the policy purchase. Health checkups can be one of the benefits of group plans. All the benefits under the plan depend on the type of group plan and coverage selected by the employer. Under the standard group medical plan, the holder can avail annual checkup covered under the policy for every block of 4 claim free years."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is Maternity Benefit covered under a Group Health Insurance Policy?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Maternity expenses imply medical expenses traceable to childbirth (including complicated deliveries and C-sections) incurred during hospitalisation and expenses related to lawful termination of pregnancy. Maternity benefits can be very beneficial for the employee with 9 months waiting period or day one cover."
                  }
                }
              ]
            }`}</script>
            </Helmet>
            )}
                </GridContainer>
            </div>
            </div>
        </div>


      </GridItem>
    </GridContainer>
  );
};
Groupmediclaimhighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(Groupmediclaimhighlights);