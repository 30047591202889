"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _datetimePicker = require("@atlaskit/datetime-picker");

var _form = require("@atlaskit/form");

var _reactFormsProcessor = require("react-forms-processor");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var AtlaskitDate = function (_React$Component) {
  _inherits(AtlaskitDate, _React$Component);

  function AtlaskitDate() {
    _classCallCheck(this, AtlaskitDate);

    return _possibleConstructorReturn(this, (AtlaskitDate.__proto__ || Object.getPrototypeOf(AtlaskitDate)).apply(this, arguments));
  }

  _createClass(AtlaskitDate, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          description = _props.description,
          disabled = _props.disabled,
          errorMessages = _props.errorMessages,
          id = _props.id,
          isValid = _props.isValid,
          name = _props.name,
          onFieldChange = _props.onFieldChange,
          onFieldFocus = _props.onFieldFocus,
          onFieldBlur = _props.onFieldBlur,
          placeholder = _props.placeholder,
          required = _props.required,
          value = _props.value,
          label = _props.label,
          autofocus = _props.autofocus;

      return _react2.default.createElement(
        _form.Field,
        {
          name: name,
          label: label,
          helperText: description,
          isRequired: required,
          isInvalid: !isValid,
          invalidMessage: errorMessages,
          validateOnBlur: false
        },
        function (_ref) {
          var fieldProps = _ref.fieldProps;
          return _react2.default.createElement(
            _react2.default.Fragment,
            null,
            _react2.default.createElement(_datetimePicker.DatePicker, _extends({}, fieldProps, {
              autoComplete: "off",
              name: name,
              placeholder: placeholder,
              onChange: function onChange(value) {
                return onFieldChange(id, value);
              },
              value: value,
              isInvalid: !isValid,
              isDisabled: disabled,
              onFocus: function onFocus() {
                return onFieldFocus(id);
              },
              onBlur: function onBlur() {
                return onFieldBlur(id);
              },
              autoFocus: autofocus
            })),
            !isValid && _react2.default.createElement(
              _form.ErrorMessage,
              null,
              errorMessages
            )
          );
        }
      );
    }
  }]);

  return AtlaskitDate;
}(_react2.default.Component);

exports.default = function (props) {
  return _react2.default.createElement(
    _reactFormsProcessor.FieldWrapper,
    props,
    _react2.default.createElement(AtlaskitDate, null)
  );
};