import React from "react";
// @material-ui/core components
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import wizardStyle from "ps-assets/jss/wizardStyle.jsx";
import CustomStepper from "ps-components/CustomStepper/CustomStepperGroup";
import FormGenerator from "ps-components/FormGenerator/FormGenerator";
import CustomInput from "components/CustomInput/CustomInput";
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from "@material-ui/core/FormHelperText";
import { wizardLMSCall, dndCall } from "util/lmsRequest";
import root from "window-or-global";
import {  browserWindow } from "util/localStorageHelper";

const initialState = {
    name: ``,
    mobile: ``,
    email: ``,
    formResponse: null,
    productId: null,
    formArr: null,
    activeStep: 0,
    dndConsent: true,
    website: ``,
    company: ``,
    industry: ``,
    employees: ``
};

class AccidentGroup extends React.Component {
    state = initialState;

    handleInputChange = (e) => {
        if (e.target.id === `name`) {
            if ((e.target.value.search(`^[a-zA-Z. ']*$`) === 0) && (e.target.value[0] != ' ')) {
                this.setState({ [e.target.id]: e.target.value });
            }
        } else if (e.target.id === `mobile`) {
            if (!isNaN(e.target.value) && e.target.value.length <= 10) {
                this.setState({ [e.target.id]: e.target.value });
            }
        }
        else if (e.target.id === `dndConsent`) {
            this.setState({
                dndConsent: this.state.dndConsent
            })
        } else this.setState({ [e.target.id]: e.target.value });
    };


    handleFormSubmit = () => {
        var { productId } = this.props;

        this.setState({
            productId: productId,
            website: this.state.formResponse.website,
            company: this.state.formResponse.company,
            industry: this.state.formResponse.industry,
            employees: this.state.formResponse.employees
        });

        let normalBuyUrl = `/customerportal`;

        // normalBuyUrl += `/normalbuy`;

        // normalBuyUrl += `?type=accident&policyType=Group${
        //   this.state.name ? `&name=${this.state.name}` : ``
        // }${this.state.mobile ? `&mobNo=${this.state.mobile}` : ``}${
        //   this.state.email ? `&email=${this.state.email}` : ``
        // }${this.state.website ? `&website=${this.state.website}` : ``} : 
        // ${this.state.company ? `&company=${this.state.company}` : ``}${
        //   this.state.industry ? `&industry=${this.state.industry}` : `` }
        //   ${this.state.employees ? `&employees=${this.state.employees}` : `` }`;

        let redirectUrl = normalBuyUrl;
        let target = `_self`;
        console.log(`buyNowUrl - `, normalBuyUrl + redirectUrl);
        root.open(redirectUrl, target);
    };

    handleFormState = (formArr, activeStep) => {
        let formSteps = null;
        if (activeStep === 1 && !browserWindow.location.href.includes(`#agent`)) {
            wizardLMSCall(
                this.state.name,
                this.state.mobile,
                this.state.email,
                this.props.productName,
                this.props.productId,
            );
        }
        if (!this.state.dndConsent) {
            dndCall(this.state.name, this.state.mobile);
        }
        if (this.state.formArr) {
            formSteps = this.state.formArr.slice();
            formSteps[0].fields = formArr;
            this.setState({ formArr: formSteps, activeStep: activeStep });
        } else {
            this.setState({ activeStep: activeStep });
        }
    };

    componentDidMount() {
        let name = ``,
            email = ``,
            mobile = ``,
            firstName = ``,
            formResponse = ``;
        let user = localStorage.getItem(`user`);
        let authToken = ``;
        if (user) {
            user = JSON.parse(user);
            authToken = user.token;
            if (authToken) {
                let logOutToken = localStorage.getItem(`loggedOut${authToken}`);
                if (logOutToken) {
                    authToken = ``;
                }
            }
        }
        let queryParams = decodeURIComponent(browserWindow.location.search);
        if(queryParams){
          queryParams = queryParams.split(`?`)[1];
          queryParams = queryParams.split(`&`);
          queryParams.map(param => {
            let key = param.split(`=`)[0];
            let value = param.split(`=`)[1];
            if (key === `name` && value) {
              let decodedValue = decodeURI(value);
              name = decodedValue;
            }
            else if (key === `mobile` && value) {
              let decodedValue = decodeURI(value);
              mobile = decodedValue;
            }
            else if (key === `email` && value) {
              let decodedValue = decodeURI(value);
              email = decodedValue;
            }
          });
        }
        var base64Url = authToken.split(`.`)[1];
        if (base64Url != undefined) {
            var decodedValue = JSON.parse(browserWindow.atob(base64Url));
            let userObj = decodedValue.jti;
            userObj = JSON.parse(userObj);
            email = userObj.email;
            firstName = userObj.firstName;
            mobile = userObj.mobileNo;
        }
        if (
            this.props.formArr &&
            this.props.formArr.formSteps &&
            this.props.formArr.formSteps[0] &&
            this.props.formArr.formSteps[0].fields
        ) {
            this.setState({
                formArr: this.props.formArr.formSteps,
                name: firstName ? firstName : name,
                email: email,
                mobile: mobile,
                formResponse: formResponse,
            });
        } else {
            this.setState({ formArr: null });
        }
        if(this.state.urlInfo === ``) {
            this.setState({
              urlInfo:browserWindow.location.href
            })
          }
          this.setState({
            productId:this.props.productId,
            name: name,
            email: email,
            mobile: mobile,
          });
    }


    render() {
        const { classes } = this.props;

        var stepsArr = [`Contact`];

        var userDetails = (
            <div>
                <CustomInput
                    autoFocus
                    labelText="Your Name"
                    id="name"
                    formControlProps={{
                        fullWidth: true,
                        required: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        required: true,
                        pattern: `^[a-zA-Z. ']*$`,
                    }}
                    error={this.state.name.search(`^[a-zA-Z. ']*$`) !== 0}
                    value={this.state.name}
                    onChange={this.handleInputChange}
                />
                {this.state.name.search(`^[a-zA-Z. ']*$`) !== 0 && (
                    <FormHelperText id="my-helper-text" error>
                        Name is not in the required format
                    </FormHelperText>
                )}
                <CustomInput
                    labelText="Mobile Number"
                    id="mobile"
                    formControlProps={{
                        fullWidth: true,
                        required: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        required: true,
                        pattern: `[0-9]{10}`,
                        maxLength: `10`,
                        inputMode: `tel`,
                    }}
                    error={
                        this.state.mobile.length != 10 && this.state.mobile.length != 0
                    }
                    value={this.state.mobile}
                    onChange={this.handleInputChange}
                />
                {this.state.mobile.length != 10 && this.state.mobile.length != 0 && (
                    <FormHelperText id="my-helper-text" error>
                        Mobile Number must be 10 digits{` `}
                    </FormHelperText>
                )}
                <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                        required: true,
                        fullWidth: true,
                    }}
                    inputProps={{
                        required: true,
                        pattern: `[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$`,
                        inputMode: `email`,
                    }}
                    onChange={this.handleInputChange}
                    value={this.state.email}
                    error={
                        this.state.email !== `` &&
                        this.state.email.search(/^\S+@\S+\.\S+$/) !== 0
                    }
                />
                {this.state.email !== `` &&
                    this.state.email.search(/^\S+@\S+\.\S+$/) !== 0 && (
                        <FormHelperText id="my-helper-text" error>
                            Please enter valid email
                        </FormHelperText>
                    )}

                <div className={classes.checkboxspan}>
                    <Checkbox
                        id="dndConsent"
                        checked={this.state.dndConsent}
                        onChange={this.handleInputChange}
                        style={{ color: '#35488a', padding: '0px' }}
                    />
                    <span className={classes.authorize}>
                        I authorize Star Health to contact me and I understand that this
                        will override the DND status on my mobile number
            </span>
                </div>
            </div>
        );

        var secondStep = (
            <span key={`formstep-2`}>
                {this.state.formArr && (
                    <FormGenerator
                        handleFormSubmit={this.handleFormSubmit}
                        fields={this.state.formArr[0].fields}
                    />
                )}
            </span>
        );

        var contentArr = [
            <span key={`content-array`}>
                {this.state.formArr && userDetails}
                {` `}
                {!this.state.formArr && (
                    <div>
                        Please visit the nearest Star Health Insurance branch for premium
                        details
                    </div>
                )}
            </span>,
        ];
        if (this.state.formArr) {
            stepsArr.push(`Basic Info`);
            contentArr.push(<>{secondStep}</>);
        }
        return (
            <>
                <span key={`customStepper`}>
                    <CustomStepper
                        productId={
                            this.state.productId ? this.state.productId : this.props.productId
                        }
                        formResponse={this.state.formResponse}
                        formValues={this.state.formArr && this.state.formArr[0].fields}
                        activeStep={this.state.activeStep}
                        handleFormState={this.handleFormState}
                        handleFormSubmit={this.handleFormSubmit}
                        steps={stepsArr}
                        content={contentArr}
                        ctaLabel={this.props.ctaLabel}
                    />
                </span>
            </>
        );
    }
}
AccidentGroup.propTypes = {
    ctaLabel: PropTypes.string,
    classes: PropTypes.object,
    buyNowUrl: PropTypes.string,
    formArr: PropTypes.object,
    query: PropTypes.string,
    planType: PropTypes.string,
    productId: PropTypes.number,
    type: PropTypes.array,
    productName: PropTypes.string,
};
export default withStyles(wizardStyle)(AccidentGroup);
