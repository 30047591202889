import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
// import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "components/Accordion/Accordion.jsx";

const table1 = [
    ['Eligibility', '5 months to 65 years'],
    ['Policy period', '1 year'],
    ['Sum Insured in Rs.', '3 lakhs and 5 lakhs'],
    ['Product type', 'Fixed benefit (lump sum) and indemnity (surgical and interventional therapy, non -surgical and non- interventional therapy)'],
    ['Co payment', '10% (applicable for 61 years & above at the time of entry)'],
    ['Geographical scope', 'Medical expenses covered if treatment taken within India'],
]


const faq=[
    {
        title:'Is Star Cancer Care Gold (Pilot Product) a fixed benefit plan?',
        description:<h5>STAR CANCER CARE GOLD (Pilot Product) is both a fixed benefit plan as well as an indemnity cover policy. A lump sum fixed benefit can be claimed for recurrence, metastasis, and / or a second malignancy unrelated to first cancer  While the policy offers indemnity coverage for accidents and diseases other than cancer and its related ailments.as Indemnity coverage does not apply to cancer and its related treatments.</h5>,
        content:false
    },
    {
        title:'What is recurrence, metastasis and second malignancy?',
        description:<h5>Hello there! It is good practice to know some of the commonly used terms on cancer. When a sign, symptom, or disease returns after remission, it is called recurrence. Metastasis is the spreading of cancer cells from their origins to other parts of the body. A second malignancy is cancer that occurs in an individual after radiation or chemotherapy treatment.</h5>,
        content:false
    },
    {
        title:'Will the plan cover all stages of cancer?',
        description:<h5>Yes certainly. This Policy covers two stages of cancer. Whether you are diagnosed with a first stage or second stage, the plan is especially devoted to covering two stages of cancer.</h5>,
        content:false
    },
    {
        title:'How many times can I avail of the lump sum benefit?',
        description:<h5>The lump sum benefit for section A (when there is a recurrence/metastasis and/or second cancer) can be availed once during the entire policy period. Upon claims for the lump sum benefit, the Coverage under section 1 ceases and will continue with coverage under section 2 and section 3A waiting period of 30 months is applicable to avail of the lump sum benefit.</h5>,
        content:false
    },
    {
        title:'What if I am diagnosed with a new type of cancer?',
        description:<h5>With this Policy, you can receive lump sum benefits if you develop a new type of cancer or second cancer, commonly called the second malignancy.</h5>,
        content:false
    },
    {
        title:'Can I change my sum insured anytime during the policy?',
        description:<h5>Unfortunately no. The sum insured option cannot be enhanced or changed during the policy term or even at renewal. We suggest you opt for a suitable sum insured option at the time of purchase.</h5>,
        content:false
    },
    {
        title:'I have not been diagnosed with cancer, will I still be eligible to buy this policy?',
        description:<h5>This health insurance is a medical policy specially dedicated to cancer patients.</h5>,
        content:false
    },
    {
        title:'Is it wise to choose cancer cover as a standalone policy instead of a Critical Illness policy? ',
        description:<h5>It is a well-known fact that cancer is on the rise in India. People who face a high risk of cancer need specialised insurance. It is recommended that you add this to your financial plan since it comes at a very affordable price and most critical illness plans may not cover the disease.</h5>,
        content:false
    },
    {
        title:'I have health insurance; do I still need an additional cancer cover?',
        description:<h5>Cancer can strike anyone at any time, and treatment can be expensive. Even quality medical plans can leave you with extra expenses. You and your family will have less worry when the time comes if you have financial support. Moreover, a cancer plan over and above your health insurance can strengthen your protection. Also, when you are diagnosed for recurrence, metastasis, and / or a second malignancy unrelated to first cancer you receive an immediate payment, ensuring that you have funds in your account to begin treatment right away. While a health insurance plan will reimburse the actual expenses on hospitalisation. Hence, both these plans complement each other and hence we recommend that you should have both</h5>,
        content:false
    },
]
const CancercareHighlights = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });
    return (
      <GridContainer className={classes.containerDisplay}>
        <GridItem
          md={11}
          sm={11}
          xs={11}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.infoArea,
            classes.handleMarginRight
          )}
        >
          <GridItem style={{ paddingLeft: "0px" }}>
            <h2 className={classes.h2hTitle}>
                Star Cancer Care Gold (Pilot Product) 
            </h2>
          </GridItem>
          <h5>
            We are guessing that you certainly want to know if buying cancer
            health insurance is worth it. Well, our answer is yes, if you are a
            cancer patient or someone diagnosed with this critical disease.
          </h5>
          <h5>
            Moreover, if you are diagnosed with cancer, your finances could take
            a hit along with your health. Here is how this health insurance Plan
            can assist you in facing challenges related to financial hardships
            in the case of cancer and its related illnesses:
          </h5>
          <h5>
            This is a mediclaim insurance policy that provides cover for
            recurrence, metastasis, and/or a second malignancy unrelated to the
            first cancer.
          </h5>
          <ul>
            <li>
              The plan covers the first stage of cancer, risk of recurrence and
              spread of cancer by offering coverage for stage two of cancer
              disease.
            </li>
            <li>
              The policy covers up to Rs. 5 lakh for patients between 5 months
              and 65 years who have been diagnosed with stages one and two of
              cancer.
            </li>
            <li>
              The plan will be beneficial, as it provides a lump sum pay-out of
              50% of the entire sum insured provided, the diagnosis after 30
              months from first inception under this policy
            </li>
          </ul>
          <h5>
            It is vital to consider this policy is in the context of growing
            treatment costs.  The recent advancements have given the most hope.
            As technology advances, cancer treatment levels have moved past
            conventional treatment methods to precision and personalized
            treatments.
          </h5>
          <h5>
            The unique feature of the policy is that there is no need for a
            prior medical check-up which means you can purchase the policy with
            previous medical records by just including your details of the
            latest treatment. Furthermore, the policy also covers surgical and
            non-surgical, as well as interventional treatment for
            non-cancer-related diseases. The policy will provide coverage for
            accidents, a benefit that is common in any standard medical
            insurance
          </h5>
          <h5>
            <b>Eligibility of the policy</b>
          </h5>
          <h5>
            This Policy is designed for people who have been diagnosed with
            stages one and two of the disease.
          </h5>
          <h5>Coverage under this Policy includes:</h5>
          <ul>
            <li>In-patient hospitalisation</li>
            <li>Pre and post hospitalisation</li>
            <li>Ambulance charges</li>
            <li>Day-care expenses</li>
          </ul>
          <h5>
            <b>Product Features</b>
          </h5>
          <Paper className={classes.root}>
            <Table
              className={classes.table}
              fixedHeader={false}
              style={{ tableLayout: "auto" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    rowSpan={1}
                    align="center"
                    className={classes.tableCellwidth + ` ` + classes.headCell}
                    // style={{minWidth:100, maxWidth:100}}
                  >
                    Subject
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableCellwidth + ` ` + classes.headCell}
                  >
                    Criteria
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table1.map((row) => (
                  <TableRow key={row[0]}>
                    <TableCell
                      align="center"
                      colSpan={1}
                      className={classes.tableCellwidth}
                    >
                      {row[0]}
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      className={classes.tableCellLeftcustomWidth}
                    >
                      {row[1]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <h2 className={classes.h2hTitle}>
            Features of Star Cancer Care Gold (Pilot Product)
          </h2>
          <h5>
            <b>Eligibility</b>
          </h5>
          <h5>
            People between the age group of 5 months and 65 years, already
            diagnosed with stage 1 or Stage 2 of cancer can avail of this
            policy.
          </h5>
          <h5>
            <b>Sum insured </b>
          </h5>
          <h5>
            The policy offers two sum-insured options which are Rs. Three lakhs
            and five lakhs. The Proposer may choose a sum insured option to suit
            their need.   
          </h5>
          <h5>
            <b>Product type</b>
          </h5>
          <h5>
            The policy provides coverage on both a lump sum/fixed benefit basis
            and an indemnity basis. 
          </h5>
          <h5>Coverage type</h5>
          <h5>
            <b>Section 1</b> - Lump sum benefit when there is recurrence,
            metastasis, and / or second malignancy (second cancer)
          </h5>
          <h5>
            <b>Section 2</b> - Indemnity coverage for Surgical and
            Interventional Therapy*
          </h5>
          <h5>
            <b>Section 3</b> - Indemnity coverage for non-surgical and
            non-interventional therapy*
          </h5>
          <h5>
            Note: *Applicable only for accidents and diseases other than cancer
            and its related ailments
          </h5>
          <h5>
            <b>Policy term</b>
          </h5>
          <h5>The policy is available for one year. </h5>
          <h5>
            <b>Co-payment</b>
          </h5>
          <h5>
            The co-payment is 10% for everyone who purchases this plan at the
            age of 61 years and above. A claim with Star Health Insurance is
            processed after the insured pays a fixed 10% of the claim, and then
            Star Health Insurance handles the remaining Up to sum
          </h5>
          <h5>
            <b>Waiting periods</b>
          </h5>
          <ol>
            <li>
              Any illness contracted within 30 days of the policy&apos;s
              inception date is not covered. This specific waiting period is
              applicable for section-2 & section -3 coverage.
            </li>
            <li>
              A waiting period of 30 months is applied for lump sum payment for
              recurrence, metastasis, or second malignancy.
            </li>
            <li>
              Specified illnesses and surgeries are covered after 24 months.
              Under Section 2 & Section 3
            </li>
            <li>
              Pre-existing diseases are covered after a waiting period of 48
              months from the policy&apos;s inception date for Section 2 &
              Section 3
            </li>
          </ol>
          <h5>
            <b>Portability</b>
          </h5>
          <h5>
            As per IRDAI guidelines regarding portability, the policyholder can
            apply to another insurer to transfer their policy. In that case,
            when the insured wants to port the plan, he can port at least 45 days before, but not earlier than 60 days before
            the policy renewal date.
          </h5>
          <h2 className={classes.h2hTitle}>
            What is covered in the Star Cancer Care Gold (Pilot Product)? 
          </h2>
          <h5>
            <b>Coverage under Section 2 and Section 3</b>
          </h5>
          <ul>
            <li>In-patient hospitalisation expenses</li>
          </ul>
          <h5>
            This Policy will cover medical expenses
            during 24 hours hospitalisation in the policy period. The plan will
            cover expenses incurred towards the following:
          </h5>
          <ul>
            <li>
              Expenses incurred on room rent (Single standard AC Room),
              boarding, and nursing
            </li>
            <li>
              Expenses incurred on anaesthesia, blood, oxygen, operation theatre
              charges, ICU charges, surgical appliances, medicines and drugs,
              diagnostic materials and X-ray, diagnostic imaging modalities,
              cost of a pacemaker, stent, and such other similar expenses
            </li>
            <li>
              Expenses towards the anaesthetist, surgeon, consultants, medical
              practitioner, specialist fees
            </li>
          </ul>
          <h5>
            <b>Pre- and post-hospitalisation expenses</b>
          </h5>
          <h5>
            This Policy covers related pre-hospitalisation
            expenses for a maximum of 30 days before admission. Also, it covers
            post-hospitalisation expenses for a maximum period of up to 60 days
            after discharge from the hospital.
          </h5>
          <h5>
            <b>Ambulance expenses</b>
          </h5>
          <h5>
            In case of requirement of emergency ambulance service to transport
            the Insured is covered up to Rs. 1500 per hospitalisation and Rs
            2000 per policy period are covered. 
          </h5>
          <h5>
            <b>Day-care treatments</b>
          </h5>
          <h5>
            The policy covers charges for all day-care procedures, surgeries,
            and treatments that require less than 24 hours of hospitalisation.
          </h5>
          <h5>
            <b>Modern treatments</b>
          </h5>
          <h5>
            The plan covers modern treatments up to the sub limits mentioned in
            the policy.
          </h5>
          <h5>
            Modern Treatments covered under this policy are
            as follows:
          </h5>
          <ul>
            <li>
              Uterine artery embolization and HIFU (High intensity focused
              ultrasound)
            </li>
            <li>Balloon sinuplasty</li>
            <li>Deep brain stimulation</li>
            <li>Intra vitreal injections</li>
            <li>Robotic surgeries</li>
            <li>Stereotactic radio surgeries</li>
            <li>Bronchial Thermoplasty</li>
            <li>Vaporisation of the prostrate</li>
            <li>ION M Intra Operative Neuro Monitoring</li>
            <li>Stem cell therapy</li>
          </ul>
          <h2 className={classes.h2hTitle}>
            Benefits of Star Cancer Care Gold (Pilot Product) 
          </h2>
          <h5>
            <b>Accident cover</b>
          </h5>
          <h5>
            The policy also provides accident cover with regular benefits like
            hospitalisation expenses arising from an accidental injury like any
            other standard medical health policy.
          </h5>
          <h5>
            <b>Pre-acceptance and medical screening</b>
          </h5>
          <h5>
            Being a first-ever insurance cover for cancer affected people, a
            unique feature of this policy is that there is no requirement for
            pre-acceptance medical check-ups. It is sufficient to submit
            previous medical records with the latest treatment information.
          </h5>
          <h5>
            <b>What is not covered?</b>
          </h5>
          <h5>
            The following is a partial listing of policy exclusions. In the
            policy, the document has listed all exclusions.
          </h5>
          <ol>
            <li>Substance abuse, self-inflicted injuries, STDs</li>
            <li>
              Any kind of surcharge, service charge, registration fees levied by
              the hospital or admission fees
            </li>
            <li>Treatments outside India</li>
            <li>
              Circumcision, gender-change surgery, cosmetic surgery and plastic
              surgery
            </li>
            <li>
              Hearing impairment correction, refractive error correction (less
              than 7.5 dioptres) and cosmetic dental surgeries
            </li>
          </ol>
          <h2 className={classes.h2hTitle}>
            How does the Star Cancer Care Gold (Pilot Product) differ from other
            plans?
          </h2>
          <h5>
            Star Cancer Care Gold (Pilot Product) is a type of cancer cover medical policy meant
            to protect those who have already been diagnosed with cancer and to
            sustain people from the financial hardships imposed by the disease.
            A significant benefit of this policy is the wide coverage that it
            provides its holders. The policy pays a lump sum upon diagnosis of
            cancer which will be useful for the direct costs of cancer
            treatment, such as hospital stays, drugs and doctor’s visits.
            Expenses for diseases other than cancer-related are also covered up
            to the limits of the policy.
          </h5>
          <h5>
            Nobody in their worst dreams would want to be affected by cancer or
            any critical illness for that matter. However, the chances of
            getting cancer have steadily increased and the World Health
            Organisation (WHO) informs us that cancer is a leading cause of
            death worldwide. As matters stand in India, around 10 lakh people
            are diagnosed with cancer every year. Furthermore, anyone can fall
            victim to this deadly disease regardless of age. But on the brighter
            side, today’s advanced technologies and treatments have brought hope
            for many.
          </h5>
          <h5>
            As the chances of getting cancer have risen substantially in recent
            decades, treatment costs have risen many fold as well. Cancer and
            its treatment play havoc on your health and can wipe out your
            finances in a single strike. Cancer patients are the ones who will
            certainly need medical and financial assistance in the future. 
          </h5>
          <h5>
            Cancer treatments are usually phased treatments, might affect the
            financial stability. Having said that, regular insurance may not be
            sufficient to cover the medical costs of cancer treatment.
          </h5>
          <h2 className={classes.h2hTitle}>
            Why is cancer insurance important?
          </h2>
          <h5>
            When a patient is diagnosed with cancer, he or she probably wants to
            see the best doctor, but what about a financial advisor? Cancer is
            quite expensive. Cancer treatment raises the following questions:
          </h5>
          <ol>
            <li>Is the person covered by health insurance?</li>
            <li>
              Does the patient have money in savings for medical expenses and
              other out-of-pocket expenses?
            </li>
          </ol>
          <h5>
            Cancer could put your finances at risk. Here are some reasons why
            cancer insurance is important:
          </h5>
          <h5>
            <b>Unprepared for Emergencies</b>
          </h5>
          <h5>
            Being stable makes a huge difference in case of a medical emergency.
            Cancer treatment can lead to debt due to the high cost. You will not
            need to dip into your savings if you have cancer insurance as a
            backup. To deal with sudden financial crises well, Star Health
            recommends buying cancer insurance. You can use the lump sum to pay
            your day-to-day treatment bills, to cover the cost of a day, or to
            do anything else you want.
          </h5>
          <h5>
            <b>Rising Treatment Costs</b>
          </h5>
          <h5>
            In light of the skyrocketing cost of cancer treatment, everyone
            should be covered to battle the financial burden associated with
            this disease. Treatment costs are affected by several factors,
            including the type, duration, and stage of cancer. Typically, a
            single chemotherapy session costs between 65k and 1 lakh. Cancer
            insurance can cover all these costs while securing your financial
            future. These factors make investing in a cancer insurance plan more
            important than ever.
          </h5>
          <h5>
            <b>Exposed to the Risk Factors</b>
          </h5>
          <h5>
            Diet and everyday life play a big part in preventing us from
            contracting the disease. Although there is no way to reliably
            determine why one person gets cancer and another does not, there are
            certain factors that do increase the risk. Some of these risk
            factors can be avoided, but others are beyond one&apos;s control.
            Risk factors include age, diet, and obesity, overexposure to
            sunlight, alcohol consumption, and tobacco use.
          </h5>
          <GridItem style={{ paddingLeft: "0px" }}>
            <h2 className={classes.h2hTitle}>FAQs on STAR CANCER CARE GOLD (Pilot Product)</h2>
          </GridItem>

          <div>
            <div className={classes.section}>
              <div className={classes.container}>
                <GridContainer
                  className={`${classes.container} ${classes.textCenter}`}
                >
                  <GridItem
                    md={12}
                    style={{ paddingLeft: "0px" }}
                    className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                  ></GridItem>
                  <GridItem
                    style={{ paddingLeft: "0px" }}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Accordion active={0} collapses={AccordionArray} />
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    );
};
CancercareHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(CancercareHighlights);