import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
// import Table from "@material-ui/core/Table";
// import { Helmet } from "react-helmet";
// import Paper from "@material-ui/core/Paper";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import { Helmet } from "react-helmet";


// const table1 = [
//     ['Dependent children', '16 days to 25 years'],
//     ['Policy period', '1 year'],
//     ['Sum Insured', '3 lakhs to 25 lakhs '],
//     ['Product type ', 'Floater'],
//     ['Discounts', <h5 key="renewal_premium2">
//         <h5 key="renewal_premium1">Purchase policy online- 5% discount</h5>
//     </h5>],
// ]

// const table2 = [
//     ['In-patient Hospitalisation', 'Up to S.I and bonus if any','Covers all in-patient hospitalisation expenses if one or more insured persons are required to be hospitalised for treatment for at least 24 hours during the policy period.'],
//     ['AYUSH treatment', 'Covered','Covers expenses for AYUSH treatments (Ayurveda, Unani, Sidha, and Homeopathy) received in a government hospital or any facility recognised by the government and/or accredited by the Quality Council of India/National Accreditation Board on Health up to the limits mentioned'],
//     ['Repatriation of Mortal Remains', 'Covered','Reimbursement up to Rs 5000 towards the cost of repatriation of mortal remains of the holder to their city of residence.'],
//     ['Compassionate Travel', 'Covered','Covers the air transport expenses Up to Rs 5000 on sum insured of 10L and above of one family member to visit a hospitalised family member towards the concerned hospital.'],
//     ['Emergency Domestic Medical Evacuation', 'Covered','Covers for any emergency medical evacuation from the hospital to another hospital within India.'],
//     ['Treatment of New born', 'Covered','Hospitalisation charges for the new-born due to any medical complications provided the mother is covered under the policy for a continuous period of 12 months without break - Lower of 10% of the S.I. or up to Rs. 50,000'],
//     ['Air Ambulance', 'Covered','Covers air ambulance services per policy period for sum insured of 5 lakhs and above. Up to 10% of S.I for 5 L S.I and above'],
//     ['Domiciliary hospitalisation', 'Covered','Coverage for medical treatment (including AYUSH) confined at home for a period exceeding three days, for an illness/disease/injury'],
//     ['Organ Donor Expenses', 'Covered','In patient hospitalization expenses incurred for organ transplantation from the Donor to the recipient insured person are payable provided the claim for transplantation is payable Lower of 10% of the S. I. or Rs 1 Lakh'],
//     ['Health Check up', 'Covered','Reimbursement of annual health check-ups available for every claim-free year at network hospitals Based on Sum insured opted'],
//     ['Recharge Benefit', 'Available','When the sum insured is exhausted/ exceeded during the policy period, additional indemnity is provided'],
//     ['Additional Sum insured for RTA', 'Available','Additional cover provided for RTA on the condition that the insured rider/pillion rider wears a helmet and travelled by a two-wheeler. S.I by 25% up to 5L'],
//     ['Assisted Reproduction Treatment', 'Available','Coverage for expenses incurred on assisted reproduction treatment for infertility after completing a waiting period of 36 months from the policy’s inception.'],
//     ]
        

const FamilyhealthoptimaHighlights = ({ classes }) => {
    return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Family Health Optima Insurance Plan
            </h2>
            </GridItem>
                <h5>
                Taking care of family needs can be economically challenging. Hence, a super saver policy keeping the expenses to a minimum yet covering the whole family can be your option.This Health Insurance Plan is appropriately priced so that you can cope with the most challenging health issues of every family member, even the youngest ones. Protect your newborn from the very start of the 16th day of birth from any medical complications.
             </h5>

             <h5>
             This plan comes with an array of USPs like Automatic Restoration available 3 times at 100% each time during the policy period, repatriation of mortal remains, compassionate travel, emergency domestic medical evacuation, organ donor expenses, recharge benefit, sum insured for road traffic accidents, assisted reproduction treatment and more.
             </h5>
        {/* <div>
        <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                18 years to 65 years
                            </TableCell>
                        </TableRow>
                        {table1.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting Periods
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-48 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24/48 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>
        <div style={{marginTop:'5%'}}>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Benefits
                                  </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Coverage limit
                                </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                >
                                   Description of cover
                         </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {table2.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellwidth}>
                                    {row[1]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellwidth}>
                                    {row[2]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                        </Table>
                </Paper>
                </div>
       */}
      </GridItem>
      {/* {process.env.ENABLE_HELMET == `true` && (
       <Helmet>
          <script type="application/ld+json">
{`{ "@context": "http://schema.org",
  "@type": "Product",
  "name": "Family Health Optima Insurance Plan",
  "description": "Family Health Insurance Optima Policy Search for Best Mediclaim Policy for Family and buy your policy online that gives protection for the entire family by paying a single premium for your plan.",
"offers":{ 
          "@type": "AggregateOffer",
          "offerCount": "5%"
        }
      }`}
    </script>
       </Helmet>)} */}
    </GridContainer>
  );
};
FamilyhealthoptimaHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(FamilyhealthoptimaHighlights);