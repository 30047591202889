import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import AddonSection1style from '../../ps-assets/jss/AddonSection1style'
import {withPrefix } from "gatsby";
import PropTypes from 'prop-types';
// import WebFont from 'webfontloader';
import Button from "components/CustomButtons/Button";
import Primary from 'components/Typography/Primary';

class AddonSection1 extends Component {
  state = {
    added: false
  };
  onAddonClick = (add) =>{
    this.props.togglePremium(add)
  }

componentDidMount () {
    if(typeof window !== 'undefined'){
        const WebFont = require('webfontloader')
        WebFont.load({
            google: {
              families: ['Inter']
            }
          });
    }
}
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section1Extra__container}>
        <div>
          <p className={classes.addons__header}>Add - Ons</p>
          <p className={classes.addons__headerDescription}>
            Get “Young Star - Extra Protect” cover at a minimal price
          </p>
         {/* {this.props.added && this.props.addonpremiumsec1 && <p className={classes.addonpremium}>₹ {this.props.addonpremiumsec1}</p>} */}
        </div>
        <hr className={classes.divider} />
        <div>
          <p className={classes.section1Extra__contentHeader}>
            Add ons includes
          </p>
          <div>
            <div>
              <img className={classes.benefits__tick} src={withPrefix("/tick.svg")}></img>
              <span className={classes.benefits__points}>
                Enhanced Room Rent
              </span>
            </div>
            <div>
              <img className={classes.benefits__tick} src={withPrefix("/tick.svg")}></img>
              <span className={classes.benefits__points}>
                Claim Guard (Consumables cover)
              </span>
            </div>
            <div>
              <img className={classes.benefits__tick} src={withPrefix("/tick.svg")}></img>
              <span className={classes.benefits__points}>
                Enhanced Limit for Modern Treatments
              </span>
            </div>
            <div>
              <img className={classes.benefits__tick} src={withPrefix("/tick.svg")}></img>
              <span className={classes.benefits__points}>
                Ayush Treatments
              </span>
            </div>
            <div>
              <img className={classes.benefits__tick} src={withPrefix("/tick.svg")}></img>
              <span className={classes.benefits__points}>
                Home care Treatment
              </span>
            </div>
            <div>
              <img className={classes.benefits__tick} src={withPrefix("/tick.svg")}></img>
              <span className={classes.benefits__points}>Bonus Guard</span>
            </div>
          </div>
        </div>
        {!this.props.added && (
          <div className={classes.btn__parent}>
                <Button color={Primary} className={classes.btn__child} onClick={() => {this.onAddonClick(true)}}>
              <span>Add</span>
              <img className={classes.plusIcon} src={withPrefix("/plus.svg")}></img>
              </Button>
           </div>
        )}
        {this.props.added && (
          <div className={classes.btn__parent}>
            <Button color={Primary} className={classes.btn__childAdded} onClick={() => this.onAddonClick(false)}>
              <span>Added</span>
              <img className={classes.minusIcon} src={withPrefix("/minus.svg")}></img>
            </Button>
          </div>
        )}
      </div>
    );
  }
}

AddonSection1.propTypes = {
    classes: PropTypes.object,
    togglePremium: PropTypes.func,
    addonpremiumsec1: PropTypes.number,
    added: PropTypes.number,
  };

export default withStyles(AddonSection1style)(AddonSection1);
