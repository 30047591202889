import React from "react";
// @material-ui/core components
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import wizardStyle from "ps-assets/jss/wizardStyle.jsx";
import CustomStepper from "ps-components/CustomStepper/CustomStepper";
import classNames from 'classnames';
import FormGenerator from "ps-components/FormGenerator/FormGenerator";
import CustomInput from "components/CustomInput/CustomInput";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "components/CustomButtons/Button.jsx";
// import Dialog from "@material-ui/core/Dialog";
// import Close from "@material-ui/icons/Close";
import root from "window-or-global";
import ReactSVG from "react-svg";
import { withPrefix } from "gatsby";
import { formatReqAndRes , getRequestData, isNullOrUndefined} from "util/RequestBodyFormatter.jsx";
import { convQuoteToInt, convQuoteToString } from "util/quoteFormatter";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import loadingGIF from "ps-assets/img/star2.gif";
import md5 from "blueimp-md5";
import { wizardLMSCall , dndCall} from "util/lmsRequest";
import { browserLocalStorage, browserWindow } from "util/localStorageHelper";
import moment from 'moment';
import { sevaApp } from 'util/LayoutUtil.jsx';
import AddonSection1 from "../Card/AddonSection1";
import Cookies from "js-cookie";

const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;
const CUSTOMERPORTAL_URL = process.env.CUSTOMERPORTAL_URL
// const CUSTOMER_PORTAL_API_URL = `https://customer.starhealth.in`;
// const CUSTOMER_PORTAL_API_URL = `https://customer-uat.starhealth.in`;
// const SIT_CUSTOMER_PORTAL_API_URL=`https://test-starbuzz.in`;

// const emiOptionsArr = [
//   {
//     display: `Half yearly EMI Plan`,
//     value: `04`
//   },
//   {
//     display: `Quarterly EMI Plan`,
//     value: `03`
//   }
// ];

const initialState = {
  name: ``,
  mobile: ``,
  email: ``,
  formResponse: null,
  productId: null,
  formArr: null,
  activeStep: 0,
  policy_cover: null,
  life_cover: null,
  premiumAmount: null,
  errorPremiumAmount: null,
  errorLifePremiumAmount: null,
  lifePremiumAmount: null,
  quoteReceived: false,
  handleScroll: false,
  emiPlan:`FP`,
  paymentMode: `FP`,
  displayEMIinfo: false,
  dndConsent:true,
  extraprotectsec1: false,
  extraprotectsec2: false,
  urlInfo: ``,
  emiOptionsArr : [
    {
      display: `Half yearly EMI Plan`,
      value: `04`
    },
    {
      display: `Quarterly EMI Plan`,
      value: `03`
    }
  ],
  deductionplan:``,
  deduction:[
    {
      display: `25000`,
      value: 25000
    },
    {
      display: `50000`,
      value: 50000
    },
    {
      display: `100000`,
      value: 100000
    }
  ],
  extraprotect: false,
  enableemiEp: false,
  epPlanssection1: [2,3,4,25],
  epPlanssection2: [2,3,4],
  addonpremiumsec1: null,
  quoteresponse: [],
};

class Wizard extends React.Component {
  state = initialState;

  handleInputChange = (e) => {
    if (e.target.id === `name`) {
      if ((e.target.value.search(`^[a-zA-Z. ']*$`) === 0) && (e.target.value[0] != " ")) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else if (e.target.id === `mobile`) {
      if (!isNaN(e.target.value) && e.target.value.length <= 10) {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
    else if (e.target.id === `dndConsent`) {
      this.setState({
        dndConsent:this.state.dndConsent
      })
    } else this.setState({ [e.target.id]: e.target.value });

    var { productId } = this.props;
    if (productId == 2 || productId == 14 ){
      this.setState({
        emiOptionsArr : [
          {
            display: `Half yearly EMI Plan`,
            value: `04`
          },
        ]
      })
    }
  };

  handleChangeEnabled = (input, value) => {
    let policy_cover = this.state.policy_cover,
      life_cover = this.state.life_cover;
    this.setState({ [input]: value, quoteReceived: false});

    if (input === `policy_cover`) policy_cover = value;
    if (input === `life_cover`) life_cover = value;
    this.getPremiumAmount(policy_cover, life_cover);
  };

  clean = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  };

  enableEMI = () => {
    switch (this.state.productId) {
      case 3:
      case 6:
      case 14:
      case 11:
      case 4:
      case 2:
      case 7:
      case 8:
      case 33:
      case 34:
      case 40:
      case 41:
        return true;
      case 25:
        if (
          parseInt(this.state.policy_cover) >= 500000 &&
          parseInt(this.state.formResponse.year) === 1 ||
          parseInt(this.state.policy_cover) >= 500000 &&
          parseInt(this.state.formResponse.year) === 2 ||
          parseInt(this.state.policy_cover) >= 500000 &&
          parseInt(this.state.formResponse.year) === 3
        ) {
          return true;
        } else {
          return false;
        }
        case 26:
          if (
            parseInt(this.state.policy_cover) > 50000
          ) {
            return true;
          } else {
            return false;
          }
      case 42:
        if( 
          parseInt(this.state.formResponse.year) === 1
        ){
          return true;
        }
        else{
          return false;
        }
      default:
        return false;
    }
  };
  
  changeAdultValueCheck = (adults,productId) => {
    if(adults == 11){
      switch(productId){
        case 26:
        case 3:
        case 29:
        case 31: 
          return true;
        default: break;
      }
    }
  return false;
}

toggleAddon = (checked) => {
  let policy_cover = this.state.policy_cover,
      life_cover = this.state.life_cover;
      if(this.state.extraprotectsec1 != checked){
        this.setState({extraprotectsec1: checked,
          enableemiEp: checked,
        quoteReceived: false},() => {this.getPremiumAmount(policy_cover, life_cover)});
      }
      else{
        this.setState({
          enableemiEp: checked},() => {this.getPremiumAmount(policy_cover, life_cover)});
      }
        // this.setState({extraprotectsec1: e.target.checked},() => this.getPremiumAmount(policy_cover, life_cover));
    // this.getPremiumAmount(policy_cover, life_cover);
}

handlefunctionone = (e) =>{
  let policy_cover = this.state.policy_cover,
      life_cover = this.state.life_cover;
      if(this.state.extraprotectsec1 != e.target.checked){
        this.setState({extraprotectsec1: e.target.checked,
          enableemiEp: true,
        quoteReceived: false},() => this.getPremiumAmount(policy_cover, life_cover));
      }
      else{
        this.setState({
          enableemiEp: true},() => this.getPremiumAmount(policy_cover, life_cover));
      }
        // this.setState({extraprotectsec1: e.target.checked},() => this.getPremiumAmount(policy_cover, life_cover));
    // this.getPremiumAmount(policy_cover, life_cover);
}
handlefunctiontwo = (e) =>{
  let policy_cover = this.state.policy_cover,
      life_cover = this.state.life_cover;
      if(e.target.checked == true){
        this.setState({extraprotectsec2: e.target.checked,enableemiEp: true,
          handleScroll: true, deductionplan: ``})
      }
      else{
        this.setState({extraprotectsec2: e.target.checked,
          enableemiEp: true,
          quoteReceived: false},() => this.getPremiumAmount(policy_cover, life_cover));
      }
    //     this.setState({extraprotectsec2: e.target.checked });
    // this.getPremiumAmount(policy_cover, life_cover);
}

changeDecuctionPlan = (e) =>{
  let policy_cover = this.state.policy_cover,
      life_cover = this.state.life_cover;
      if(this.state.extraprotectsec2){
        this.setState({deductionplan: e.target.value,
          enableemiEp: true,
          quoteReceived: false},() => this.getPremiumAmount(policy_cover, life_cover));
      }
      else{
        this.setState({extraprotectsec2: e.target.checked,
          quoteReceived: false},() => this.getPremiumAmount(policy_cover, life_cover));
      }
    //     this.setState({extraprotectsec2: e.target.checked });
    // this.getPremiumAmount(policy_cover, life_cover);
}



  handleBuyNow = async () => {
    try {
    let { buyNowUrl, planType } = this.props;
    let adults = this.state.formResponse.adults;
    let kids = this.state.formResponse.kids;
    let name = this.state.name;
    let mobNo = this.state.mobile;
    let email = this.state.email;
    let year = this.state.formResponse.year;
    let policyCoverOptions = JSON.stringify(this.policyCoverOptions);
    let { days } = this.state.formResponse;
    let {parents,parentDob1,parentDob2,parentDob3,parentDob4} = this.state.formResponse;
    if(this.changeAdultValueCheck(adults,this.state.productId)){
      adults = 1;
    }
    let suminsured = this.state.policy_cover;
    let redirectUrl = buyNowUrl;
    let user = browserLocalStorage.getItem(`user`);
    let authToken = ``;
    if (user) {
      user = JSON.parse(user);
      authToken = user.token;
      if (authToken) {
        let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
        if (logOutToken) {
          authToken = ``;
        }
      }
    }
    let postalcode = this.state.formResponse.pincode;
    let dob = this.state.formResponse.dob && this.state.formResponse.dob.replace(/\//g, `-`);
    let normalBuyUrl = `${CUSTOMERPORTAL_URL}/customerportal`;
    if (buyNowUrl && buyNowUrl.includes(`instantbuy`)) {
      normalBuyUrl +=
        this.state.formResponse.policy_type == `Floater` &&
        planType === `redcarpet`
          ? `/normalbuy`
          : `/instantbuy`;
    } else {
      normalBuyUrl += `/normalbuy`;
    }
    normalBuyUrl += `?${planType ? `type=${planType}` : ``}${
      postalcode ? `&pinCode=${postalcode}` : ``
    }${suminsured ? `&policyCover=${suminsured}` : ``}${
      dob ? `&dob=${dob.split(`-`).join(`/`)}` : ``
    }${year ? `&year=${year}` : ``}${
      this.state.formResponse.policy_plan
        ? `&policyPlan=${this.state.formResponse.policy_plan}`
        : ``
    }${
      this.state.formResponse.policy_type
        ? `&policyType=${this.state.formResponse.policy_type}`
        : ``
    }${
      this.state.formResponse.policy_option
        ? `&policyOption=${this.state.formResponse.policy_option}`
        : ``
    }${name ? `&name=${name}` : ``}${mobNo ? `&mobNo=${mobNo}` : ``}${
      email ? `&email=${email}` : ``
    }${policyCoverOptions ? `&policyCoverOptions=${policyCoverOptions}` : ``}${
      this.state.productId ? `&productId=${this.state.productId}` : ``
    }${days ? `&days=${days}` : ``}`;
    if(this.state.productId === 26 || this.state.productId === 29 || this.state.productId === 3){
      if(this.state.formResponse.policy_type == `Floater`){
        normalBuyUrl += `${
          adults ? `&adults=${adults}` : ``
        }${kids ? `&kids=${kids}` : ``}`;
        normalBuyUrl += `${
          parents ? `&parents=${parents}` : ``
        }${
          parentDob1 ? `&parentDob1=${parentDob1.replace(/\//g, `-`)}` : ``
        }${
          parentDob2 ? `&parentDob2=${parentDob2.replace(/\//g, `-`)}` : ``
        }${
          parentDob3 ? `&parentDob3=${parentDob3.replace(/\//g, `-`)}` : ``
        }${
          parentDob4 ? `&parentDob4=${parentDob4.replace(/\//g, `-`)}` : ``
        }`;
      }
      else if(this.state.productId === 3){
        normalBuyUrl += `${
          adults ? `&adults=${adults}` : ``
        }${kids ? `&kids=${kids}` : ``}`;
        normalBuyUrl += `${
          parents ? `&parents=${parents}` : ``
        }${
          parentDob1 ? `&parentDob1=${parentDob1}` : ``
        }${
          parentDob2 ? `&parentDob2=${parentDob2}` : ``
        }${
          parentDob3 ? `&parentDob3=${parentDob3}` : ``
        }${
          parentDob4 ? `&parentDob4=${parentDob4}` : ``
        }`;
      }
      else{
        if(this.state.productId === 29){
          normalBuyUrl += `${
            adults ? `&adults=${adults}` : ``
          }`;
        }
        else{
          normalBuyUrl += `&adults=1`;  
        }
        normalBuyUrl += `&parents=&kids=0`;
      }
    }
    else{
      if(this.state.formResponse.policy_type == `Individual`){
        normalBuyUrl += `&kids=`;
      }
      else{
        normalBuyUrl += `${kids ? `&kids=${kids}` : ``}`;
      }
    }
    if(this.state.productId === 29 || this.state.productId === 26 || this.state.productId === 3){
      if(this.state.formResponse.insures === "My Parents only" ||
          this.state.formResponse.insures === "My Parents-in-law only"){
        normalBuyUrl += `&parents=2`;
      }
    }else{
      normalBuyUrl += `${
        adults ? `&adults=${adults}` : ``
      }`;
    }
    if(this.state.productId === 34){
      if(this.state.formResponse.lumpSumYN){
        normalBuyUrl += `&lumpSumYN=`+this.state.formResponse.lumpSumYN;
      }
    }
    const getEMIoptions = () => {
      switch(this.state.productId){
      case 3:
      case 6:
      case 14:
      case 11:
      case 4:
      case 2:
      case 7:
      case 8:
      case 33:
      case 34:
      case 40:
      case 41:
      case 42:
        return `&emiValue=${this.state.emiPlan}&emiOptions=${JSON.stringify(this.state.emiOptionsArr)}`;
      case 25:
        if (
          parseInt(this.state.policy_cover) >= 500000 &&
          parseInt(this.state.formResponse.year) === 1 || 
          parseInt(this.state.policy_cover) >= 500000 &&
          parseInt(this.state.formResponse.year) === 2 ||
          parseInt(this.state.policy_cover) >= 500000 &&
          parseInt(this.state.formResponse.year) === 3
        ) {
          return `&emiValue=${this.state.emiPlan}&emiOptions=${JSON.stringify(this.state.emiOptionsArr)}`;
        } else {
          return `&emiValue=FP&emiOptions=${JSON.stringify(this.state.emiOptionsArr)}`;
        }
        case 26:
          if (
            parseInt(this.state.policy_cover) > 50000
          ) {
            return true;
          } else {
            return false;
          }
      default:
        return ``;
      }
    }
    normalBuyUrl += getEMIoptions();
    if (this.state.productId == 5) {
      switch (this.state.formResponse.policy_plan) {
        case `Basic`:
          normalBuyUrl += `&PolicyDays1=${this.state.formResponse.days}`;
          break;
        case `Enhanced`:
          normalBuyUrl += `&PolicyDays2=${this.state.formResponse.days}`;
          break;
      }
    }
    if(this.state.extraprotectsec1 == true){
      normalBuyUrl += `&addOnCvrSec1YN=Y`
    }
    if(this.state.extraprotectsec2 == true){
      normalBuyUrl += `&deductionExtraCover=${this.state.deductionplan}`
    }
    redirectUrl = normalBuyUrl;
    let target = `_self`;
    // if (this.state.productId == 2) {
    //   redirectUrl =
    //     buyNowUrl +
    //     `?type=${planType}&policyyear=${this.state.formResponse.year}&plan=${this.state.formResponse.policy_plan}&postalcode=${postalcode}&suminsured=${suminsured}&dob=${dob}`;
    //   target = `_blank`;
    // }
    // if (this.state.productId == 20) {
    //   redirectUrl = buyNowUrl;
    //   target = `_blank`;
    // }
    if(sevaApp()){
      redirectUrl = redirectUrl +'&sevaapp=true'
    }
    if(authToken !== ``) localStorage.setItem(`sessionalive`, true);
    root.open(redirectUrl, target);
  }
  catch(exception){
    console.log(exception);
  }
};

  getPremiumAmount = (policy_cover, life_cover) => {
    if (!this.state.policy_cover && policy_cover)
      this.setState({ policy_cover: policy_cover });
    if (!this.state.life_cover && life_cover)
      this.setState({ life_cover: life_cover });
    if (this.state.formResponse) {
      var requestBody = Object.assign({}, this.state.formResponse);
    if(this.state.productId === 29 || this.state.productId === 26 || this.state.productId === 3){
      if(this.state.formResponse.insures === "My Parents only" ||
          this.state.formResponse.insures === "My Parents-in-law only"){
        requestBody.parents = 2;
      }
    }
    if(this.state.productId === 42){
      requestBody.dob = this.state.formResponse.adultDob1;
    }
    if(this.state.productId === 40){
      requestBody.policyStartDate = moment(new Date).format(`DD/MM/YYYY`);
    }
    if(this.state.productId === 2 || this.state.productId === 25 ){
      requestBody.source = "website";
      if(this.state.extraprotectsec1 && this.state.extraprotectsec1 == true && (policy_cover > 1000000 || policy_cover == 1000000)){
        requestBody.addOnCvrSec1YN = 'Y'
      }
      if(this.state.extraprotectsec2 && this.state.extraprotectsec2 == true && (policy_cover > 1000000 || policy_cover == 1000000)){
        requestBody.deduction = this.state.deductionplan
        requestBody.deductionExtraCover = this.state.deductionplan
      }
    }
    if(this.state.productId === 4 && (this.state.extraprotectsec1 == true || this.state.extraprotectsec2 == true) ){
      requestBody.source = "website";
    }
    if(this.state.productId === 3 || this.state.productId === 4){
      if(this.state.extraprotectsec1 && this.state.extraprotectsec1 == true && (policy_cover > 1000000 || policy_cover == 1000000)){
        requestBody.addOnCvrSec1YN = 'Y'
      }
      if(this.state.extraprotectsec2 && this.state.extraprotectsec2 == true && (policy_cover > 1000000 || policy_cover == 1000000)){
        requestBody.deduction = this.state.deductionplan
        requestBody.deductionExtraCover = this.state.deductionplan
      }
    }
      if (requestBody.policy_type == `Individual`) delete requestBody.kids;
      delete requestBody.insures;
      requestBody.product_id = this.state.productId;
      requestBody.policy_cover = policy_cover ? parseInt(policy_cover) : ``;
      requestBody.name = this.state.name;
      requestBody.mobNo = this.state.mobile;
      requestBody.email = this.state.email;
      requestBody.policyStartDate = moment(new Date).format('DD/MM/YYYY');
      if(this.changeAdultValueCheck(requestBody.adults,requestBody.product_id)){
        requestBody.adults = `1`;
      }
      if (life_cover) requestBody.life_cover = parseInt(life_cover);
      let extraPremium = (this.state.extraprotect && requestBody.policy_cover < 1000000) ? 1000000 : requestBody.policy_cover;
      if((this.state.extraprotect && requestBody.policy_cover < 1000000)){
        if (this.state.policy_cover !='1000000'){
          let policyCoverExtra = '1000000'
          this.setState({ policy_cover: policyCoverExtra });
        }
      }
      requestBody.policy_cover = extraPremium;      
      let quoteApi = ``,
        headers = {};
      requestBody = formatReqAndRes(requestBody);
      quoteApi = `${CUSTOMER_PORTAL_API_URL}/cp/quote/calculate/product/quote`;
      headers = {
        clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`,
        "Content-Type": `application/json`,
      };
      fetch(quoteApi, {
        method: `POST`,
        headers: headers,
        body: JSON.stringify(getRequestData(requestBody)),
      })
        .then((res) => res.json())
        .then((res) => {
          var checksum = md5(
            `${isNullOrUndefined(res.response.policyQuote)}|${isNullOrUndefined(res.response.totalPremium)}|quote`,
            `73q&*cvm`,
          );
          if (res.response && (checksum === res.response.checkSum)) {
            res.response = formatReqAndRes(res.response);
            this.response = res.response;
            let premiumAmount = res.response.policy_quote || null,
              errorPremiumAmount = res.response.error || null,
              lifePremiumAmount = res.response.life_policy_quote || null,
              healthPremiumAmount = res.response.health_policy_quote || null;
            if (
              requestBody.policyType == `Individual` &&
              requestBody.productId == 23
            ) {
              premiumAmount = convQuoteToString(
                convQuoteToInt(premiumAmount) * parseInt(requestBody.adults)
              ).split(`₹`)[1];
            }
            if (requestBody.productId == 24 ||
                requestBody.productId == 28 ||
                (requestBody.productId === 29 && requestBody.policyType == `Individual`)) {
              if(requestBody.adults){
                premiumAmount = convQuoteToString(
                  convQuoteToInt(premiumAmount) * parseInt(requestBody.adults)
                ).split(`₹`)[1];
              }
            }
            if(this.state.enableemiEp == true && this.state.emiPlan == '04'){
              premiumAmount = this.response.hlflyTotalPremium
            }
            else if(this.state.enableemiEp == true && this.state.emiPlan == '03'){
              premiumAmount = this.response.qtrlyTotalPremium
            }
            this.setState({
              premiumAmount: premiumAmount || healthPremiumAmount,
              lifePremiumAmount: lifePremiumAmount,
              errorPremiumAmount: errorPremiumAmount,
              quoteReceived: true,
              handleScroll:false,
              emiPlan: this.state.enableemiEp ? this.state.emiPlan : `FP`,
              paymentMode: this.state.enableemiEp ? this.state.paymentMode :`FP`,
              addonpremiumsec1: this.response.addOnCvrSec1Prem,
              quoteresponse: this.response,
            });
          } else
            this.setState({
              premiumAmount: null,
              lifePremiumAmount: null,
              errorPremiumAmount: `Sorry could not fetch the premium amount`,
              errorLifePremiumAmount: `Sorry could not fetch the  premium amount and life premium amount`,
              quoteReceived: true,
              handleScroll:false,
              emiPlan:`FP`,
              paymentMode:`FP`
            });
        })
        .catch(() => {
          this.setState({
            errorPremiumAmount: `Sorry could not fetch the premium amount`,
          });
          if (this.state.life_cover) {
            this.setState({
              errorLifePremiumAmount: `Sorry could not fetch the premium amount and life premium amount`,
            });
          }
        });
    }
  };

  handleFormSubmit = (values) => {
    var { productId } = this.props;
    if(productId === 4 && values.adults === undefined){
      values.adults = 1;
      values.kids = 0;
    }
    var formResponse = Object.assign({}, values);
    this.setState({
      productId: productId,
      formResponse: formResponse,
      premiumAmount: null,
      lifePremiumAmount: null,
      policy_cover: null,
      life_cover: null,
      extraprotectsec1: false,
      extraprotectsec2: false,
      deductionplan:``,
    });
    // browserWindow.location.href = "/under-maintenance"
    if (productId == 2 || productId == 14 ){
      this.setState({
        emiOptionsArr : [
          {
            display: `Half yearly EMI Plan`,
            value: `04`
          },
        ]
      })
    }
  };

  handleFormState = (formArr, activeStep) => {
    let formSteps = null;
    if (activeStep === 1 && !browserWindow.location.href.includes(`#agent`)) {
      if(!this.state.extraprotect ){
        wizardLMSCall(
          this.state.name,
          this.state.mobile,
          this.state.email,
          this.props.productName,
          this.props.productId,
          this.state.urlInfo
        );
      }
    }
    if(!this.state.dndConsent){
      dndCall(this.state.name,this.state.mobile);
    }
    if (this.state.formArr) {
      formSteps = this.state.formArr.slice();
      formSteps[0].fields = formArr;
      this.setState({ formArr: formSteps, activeStep: activeStep,emiPlan:`04` });
    } else {
      this.setState({ activeStep: activeStep,emiPlan:`04` });
    }
  };

  componentDidMount() {
    let name = ``,
      email = ``,
      mobile = ``,
      firstName = ``;
    let user = browserLocalStorage.getItem(`user`);
    let accessToken = Cookies.get('customer_accessToken')
    let userInfo = Cookies.get('user')
    let authToken = ``;
    if (accessToken || user) {
      user ? user = JSON.parse(user) : JSON.parse(userInfo)
      authToken = accessToken ? accessToken : user.token;
      if (authToken) {
        let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
        if (logOutToken) {
          authToken = ``;
        }
      }
    }
    // var base64Url = authToken.split(`.`)[1];
    // if (base64Url != undefined) {
    //   var decodedValue = JSON.parse(browserWindow.atob(base64Url));
    //   let userObj = decodedValue.jti;
    //   userObj = JSON.parse(userObj);
    //   email = userObj.email;
    //   firstName = userObj.firstName;
    //   mobile = userObj.mobileNo;
    // }
    if (userInfo){
      userInfo = JSON.parse(userInfo)
      email = userInfo.emailId;
      firstName = userInfo.firstName;
      mobile = userInfo.mobileNumber;
    }
    else if(user && user.userDetails){
      email = user.userDetails.email;
      firstName = user.userDetails.firstName;
      mobile = user.userDetails.mobileNo;
    }
    if (
      this.props.formArr &&
      this.props.formArr.formSteps &&
      this.props.formArr.formSteps[0] &&
      this.props.formArr.formSteps[0].fields
    ) {
      const fieldArr = this.props.formArr.formSteps[0].fields.slice();
      let formResponse = {};
      let noOfChildren = -1,
        noOfAdults = -1,
        childElement,
        adultElement,
        extraprotectpolicy;
      for (let index = 0; index < fieldArr.length; index++) {
        const element = fieldArr[index];
        this.clean(element);
        if (this.props.query && this.props.query.length > 1) {
          var queryString = this.props.query.substring(
            1,
            this.props.query.length
          );
          var queryArr = queryString.split(`&`);

          for (let index = 0; index < queryArr.length; index++) {
            const queryElement = queryArr[index];
            const queryElementArr = queryElement.split(`=`);
            const key = queryElementArr[0];
            const value = queryElementArr[1];
            if (key === element.id && value) {
              element.defaultValue = value;
              if (key === `noOfChildren` && value === `0`) {
                childElement = element;
                noOfChildren = 0;
              }
              if (key === `noOfAdults` && value === `1`) {
                adultElement = element;
                noOfAdults = 1;
              }
            } else if (key === `name` && value) {
              let decodedValue = decodeURI(value);
              name = decodedValue;
            }
            else if (key === `mobile` && value) {
              let decodedValue = decodeURI(value);
              mobile = decodedValue;
            }
            else if (key === `email` && value) {
              let decodedValue = decodeURI(value);
              email = decodedValue;
            }
            else if (key === `extraprotect` && value) {
              let decodedValue = decodeURI(value);
              extraprotectpolicy = decodedValue;
            }
          }
        }
      }
    
      if (
        noOfAdults === 1 &&
        noOfChildren === 0 &&
        childElement &&
        adultElement
      ) {
        formResponse[childElement.name] = 0;
        formResponse[adultElement.name] = 1;
        childElement.visible = false;
        adultElement.visible = false;
      }

      this.setState({
        formArr: this.props.formArr.formSteps,
        name: firstName ? firstName : name,
        email: email,
        mobile: mobile,
        formResponse: formResponse,
        extraprotect: extraprotectpolicy
      });
    } else {
      this.setState({ formArr: null });
    }
    if(this.state.urlInfo === ``) {
      this.setState({
        urlInfo:browserWindow.location.href
      })
    }

    if(typeof window !== 'undefined'){
      const WebFont = require('webfontloader')
      WebFont.load({
          google: {
            families: ['Inter']
          }
        });
  }
  }

  componentWillUnmount() {
    this.setState(initialState);
  }

  componentDidUpdate() {
    if (
      this.state.activeStep === 2 &&
      this.state.premiumAmount === null &&
      this.state.errorPremiumAmount === null
    ) {
      var getDependantValue;
      var defaultPolicyCover =
        this.state.formArr &&
        this.state.formArr[2] &&
        this.state.formArr[2].fields &&
        this.state.formArr[2].fields
          .map((res) => {
            if (res.visibleWhen && res.visibleWhen[0]) {
              getDependantValue =
                this.state.formResponse &&
                this.state.formResponse[res.visibleWhen[0].field];
              if (getDependantValue === res.visibleWhen[0].is[0].value) {
                return res.defaultValue;
              }
            } else if (res.visibleWhenAll && res.visibleWhenAll.length > 0) {
              let flag = 1;
              for (let index = 0; index < res.visibleWhenAll.length; index++) {
                getDependantValue =
                  this.state.formResponse &&
                  this.state.formResponse[res.visibleWhenAll[index].field];
                if (
                  getDependantValue !== res.visibleWhenAll[index].is[0].value
                ) {
                  flag = 0;
                  break;
                }
              }

              if (flag) {
                return res.defaultValue;
              }
            } else {
              return res.defaultValue;
            }
          })
          .filter((item) => item);
      this.getPremiumAmount(defaultPolicyCover[0], defaultPolicyCover[1]);
    }
    if (this.state.activeStep !== 2 && this.state.errorPremiumAmount !== null) {
      this.setState({ errorPremiumAmount: null });
    }
    if(this.state.activeStep === 2 && this.state.enableemiEp == true){
      this.setState({
        enableemiEp: false
      })
    }
  }

  getOnlyRequiredSumInsureds = (res) => {
    const { productId } = this.props;
    // const { policy_plan, days } = this.state.formResponse;
    switch (productId) {
      // case 20:
      //   if (policy_plan == `B` && days == `0-7`) {
      //     return res.slice(0, 1);
      //   }
      //   break;
      case 3:
        if (this.state.extraprotect) {
          return res.slice(3, 8);
        }
        break;
      case 2:
        if (this.state.extraprotect) {
          if(this.state.formResponse.policy_plan == "GOLD"){
            return res.slice(3, 7);
          }
          else{
            return res.slice(5, 7);
          }
        }
        break; 
        case 4:
        if (this.state.extraprotect) {
          return res.slice(2, 9);
        }
        break; 
      case 25:
        if (this.state.extraprotect) {
          if (this.state.formResponse.policy_plan == "GOLD"&& this.state.formResponse.policy_type=="Individual"){
            return res.slice(2, 9);
          }
          else if (this.state.formResponse.policy_plan == "SILVER"&& this.state.formResponse.policy_type=="Individual"){
            return res.slice(2, 9);
          }
          else {
            return res.slice(1, 8);
        }
		}
    break;
    }
    return res;
  };

  changeEMIPlan = (event) => {
    let premiumAmount = ``;
    if(this.response){
      switch(event.target.value){
        case `FP`: premiumAmount = this.response.policy_quote;
          break;
        case `04`: premiumAmount = this.response.hlflyTotalPremium;
          break;
        case `03`: premiumAmount = this.response.qtrlyTotalPremium;
          break;
        default :  premiumAmount = this.response.policy_quote;
          break;
      }
      this.setState({emiPlan:event.target.value,premiumAmount});
    }
  }

  changeBrochurename = () => {
    const { productId } = this.props;
      switch(productId){
        case 3: 
          return 'FHO Brochure'
        case 4:
          return 'Comprehensive Brochure'
        case 2: 
          return 'Medi Classic Brochure'
        case 25: 
          return 'Young Star Brochure'
        default :  
          return ''
      }
  }

  getPremiumComponent = () => {
    const {classes} = this.props;
    return <>{this.state.premiumAmount && !this.state.lifePremiumAmount && (
      <div style={{ textAlign: `center` }}>
        <h4 className={classes.cardTitle}>
          {this.state.productId == 9
            ? `Out Patient Benfit`
            : `Premium Amount`}
        </h4>
        <h2 className={classes.amount}>₹ {this.state.premiumAmount}</h2>
        <h4 className={classes.cardTitle}>(Including Tax)</h4>
        <h6
          className={classes.cardTitle}
          style={{ textTransform: `capitalize` }}
        >
          {this.state.productId == 6
            ? `This policy is applicable only for rural segment`
            : ``}
        </h6>
      </div>
    )}</>;
  }

  changePaymentMode = async (event) => {
    try {
      event.persist();
      // let url = `${CUSTOMER_PORTAL_API_URL}/api/services/authentication/fetch/forms?formId=banklist&formType=formData`;
      // let res = await fetch(url);
      // res = await res.json();
      // this.emiINFO = res;
    if(event.target.value === `FP`)
      this.setState({paymentMode:event.target.value,emiPlan:`FP`,premiumAmount:this.response.policy_quote});
    else
      this.setState({paymentMode:event.target.value,emiPlan:`04`,premiumAmount:this.response.hlflyTotalPremium});
    }
    catch(exception){
      console.log(exception);
    }
  }

  getPolicyCover = (res) => {
    const { classes } = this.props;
    const { quoteReceived } = this.state;
    let policyCoverOptions = [];
    let renderComponent = quoteReceived ? (
      <>
        <span key={`formstep-3`}>{res && res.label}</span>
        <GridContainer>
          {res &&
            res.options &&
            this.getOnlyRequiredSumInsureds(res.options[0].items)
              .sort((a, b) => parseInt(b.value) - parseInt(a.value))
              .map((item, index) => {
                policyCoverOptions.push({
                  display: item.label,
                  value: item.value,
                });
                return (
                  <>
                  <GridItem md={3} sm={6} key={`radio-` + index}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            this.state[res.name] === item.value ||
                            (this.state[res.name] === null &&
                              res.defaultValue === item.value)
                          }
                          onChange={() =>
                            this.handleChangeEnabled(res.name, item.value)
                          }
                          value={item.value}
                          name={res.name}
                          aria-label={item.label}
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.customRadioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      // Add `₹` to all other policy selection options without '$'
                      // `$` added in Strapi to Travel plans
                      label={
                        item.label.includes(`$`) ? item.label : `₹` + item.label
                      }
                      className={`${classes.customLabel} ${
                        this.state[res.name] === item.value ||
                        (this.state[res.name] === null &&
                          res.defaultValue === item.value)
                          ? classes.activeLabel
                          : ``
                      }`}
                    />
                  </GridItem>
                  </>
                );
              })}
        </GridContainer>
        
        {this.enableEMI() ?
          <GridContainer>
            <GridItem className={classNames(classes.mTAuto,classes.mBAuto)} style={{textAlign:`center`}} md={6}>
              <span>Please Choose your payment option</span>
            </GridItem>
            <GridItem style={{textAlign:`center`}} md={6}>
              <Radio
                checked={this.state.paymentMode === `emi`}
                onChange={this.changePaymentMode}
                value="emi"
                name="payment_mode"
                aria-label="EMI"
                icon={
                  <FiberManualRecord
                    className={classes.radioUnchecked}
                  />
                }
                checkedIcon={
                  <FiberManualRecord
                    className={classes.radioChecked}
                  />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot
                }}
              />
              EMI
              <Radio
                checked={this.state.paymentMode === `FP`}
                onChange={this.changePaymentMode}
                value="FP"
                name="payment_mode"
                aria-label="Full Payment"
                icon={
                  <FiberManualRecord
                    className={classes.radioUnchecked}
                  />
                }
                checkedIcon={
                  <FiberManualRecord
                    className={classes.radioChecked}
                  />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot
                }}
              />
              Full Payment
            </GridItem>
            {this.state.paymentMode === `emi` ?
            <>
              <GridItem md={6}>
                <Card>
                  <CardBody>
                    {this.getPremiumComponent()}
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem md={6}>
                <InputLabel
                  htmlFor="product-type"
                  className={classes.selectLabel}
                >
                    Select EMI Plan
                </InputLabel>
                <Select
                  MenuProps={{className:classes.selectMenu}}
                  classes={{
                    select: classes.select
                  }}
                  style={{ display: `block` }}
                  value={this.state.emiPlan || ``}
                  onChange={this.changeEMIPlan}
                  inputProps={{
                    name: `emiPlan`,
                    id: `emiPlan`
                  }}
                >
                  {
                    this.state.emiOptionsArr.map(emiOption => {
                      return <MenuItem
                        key={emiOption.value}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={emiOption.value}
                      >
                        {emiOption.display}
                      </MenuItem>;
                    })
                  }
                  
                </Select>
              </GridItem>
            </>
              :
              <GridItem className={classNames(classes.mrAuto,classes.mlAuto)} md={6}>
                {this.getPremiumComponent()}
              </GridItem>
            }
          </GridContainer>
          :
          <GridItem className={classNames(classes.mlAuto,classes.mrAuto)} md={6}>
            {this.getPremiumComponent()}
          </GridItem>
        }
        <>
          {(!this.state.premiumAmount || this.state.error) &&
            !this.state.life_cover && (
            <div style={{ textAlign: `center`, color: `red` }}>
              {this.state.errorPremiumAmount != null &&
                  (this.state.productId == 9
                    ? this.state.errorPremiumAmount.replace(
                      `premium amount`,
                      `outpatient benfit`
                    )
                    : this.state.errorPremiumAmount)}
            </div>
          )}
        </>
      </>
    ) : (
      <GridContainer>
        <div style={{ margin: `0 auto` }} className={classes.loadingGIF}>
          <img src={loadingGIF} />
        </div>
      </GridContainer>
    );
    this.policyCoverOptions = policyCoverOptions;
    return renderComponent;
  };

  showEMIinfo = () => {
      this.setState({
        displayEMIinfo : true,
      });
  }

  // getEmiDebitCardBanks = function(){
  //   try {
  //     return <Dialog open={this.state.displayEMIinfo}>
  //       <GridItem>
  //         <GridItem style={{textAlign:`end`}}>
  //           <Close style={{cursor:`pointer`}} onClick={() => this.setState({
  //             displayEMIinfo:false
  //           })}/></GridItem>
  //         <GridItem style={{
  //           fontWeight: `bold`,
  //           textAlign: `center`,
  //           minWidth: `550px`,
  //           fontSize: `20px`,
  //           marginTop: `2%`,
  //           marginBottom: `1%`
  //         }}>{this.emiINFO.emiContent[0].title}</GridItem>
  //         <GridItem>
  //           <ul>
  //             {this.emiINFO.emiContent[0].content.split(`|`).map((bank,index) => <li key={index}>{bank}</li>)}
  //           </ul>
  //         </GridItem>
  //       </GridItem>
  //     </Dialog>;
  //   }
  //   catch(exception){
  //     console.log(`exception - `,exception);
  //   }
  // };

  // getEmiText = function(){
  //   try{
  //     const {classes} = this.props;
  //     return (
  //       <div className={classes.emiContent}>
  //         <p className={classes.cardTitle}>
  //           {this.emiINFO.prefix}
  //         </p>
  //         <p className={classNames(classes.cardTitle,classes.emiText)} onMouseOver={this.showEMIinfo}>
  //           {this.emiINFO.text}
  //         </p>
  //       </div>
  //     );
  //   }
  //   catch(exception){
  //     console.error(exception);
  //   }
  // }

  render() {
    const { classes } = this.props;

    var stepsArr = [`Contact`];
    let starExtraprotect = this.state.extraprotectsec2 && this.state.deductionplan.length==0 ? true : false;

    var userDetails = (
      <div>
        <CustomInput
          autoFocus
          labelText="Your Name"
          id="name"
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.formControl,
          }}
          inputProps={{
            required: true,
            pattern: `^[a-zA-Z. ']*$`,
          }}
          error={this.state.name.search(`^[a-zA-Z. ']*$`) !== 0}
          value={this.state.name}
          onChange={this.handleInputChange}
        />
        {this.state.name.search(`^[a-zA-Z. ']*$`) !== 0 && (
          <FormHelperText id="my-helper-text" error>
            Name is not in the required format
          </FormHelperText>
        )}
        <CustomInput
          labelText="Mobile Number"
          id="mobile"
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.formControl,
          }}
          inputProps={{
            required: true,
            pattern: `[0-9]{10}`,
            maxLength: `10`,
            inputMode: `tel`,
          }}
          error={
            this.state.mobile.length != 10 && this.state.mobile.length != 0
          }
          value={this.state.mobile}
          onChange={this.handleInputChange}
        />
        {this.state.mobile.length != 10 && this.state.mobile.length != 0 && (
          <FormHelperText id="my-helper-text" error>
            Mobile Number must be 10 digits{` `}
          </FormHelperText>
        )}
        <CustomInput
          labelText="Email"
          id="email"
          formControlProps={{
            required: true,
            fullWidth: true,
          }}
          inputProps={{
            required: true,
            pattern: `[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$`,
            inputMode: `email`,
          }}
          onChange={this.handleInputChange}
          value={this.state.email}
          error={
            this.state.email !== `` &&
            this.state.email.search(/^\S+@\S+\.\S+$/) !== 0
          }
        />
        {this.state.email !== `` &&
          this.state.email.search(/^\S+@\S+\.\S+$/) !== 0 && (
          <FormHelperText id="my-helper-text" error>
              Please enter valid email
          </FormHelperText>
        )}
        <div className={classes.checkboxspan}>
          <Checkbox
            id="dndConsent"
            checked={this.state.dndConsent}
            onChange={this.handleInputChange}
            style={{color:'#35488a',padding:'0px'}}
          /> 
            <p className={classes.authorize}> 
              I authorize Star Health to contact me and I understand that this
              will override the DND status on my mobile number
            </p>
        </div>
      </div>
    );
    var thirdStep = (
      <div>
        {this.state.productId == 25 && <p className={classes.youngstar_label}>Elevate your coverage: Young Star - Extra Protect, Now Available for Sum Insured of 10 Lakh and Above!</p>}
        {this.state.formArr &&
          this.state.formArr[2] &&
          this.state.formArr[2].fields &&
          this.state.formArr[2].fields.map((res) => {
            if (res.visibleWhen && res.visibleWhen[0]) {
              var getDependantValue =
                this.state.formResponse &&
                this.state.formResponse[res.visibleWhen[0].field];
              if (getDependantValue === res.visibleWhen[0].is[0].value) {
                return this.getPolicyCover(res);
              }
            } else if (res.visibleWhenAll && res.visibleWhenAll.length > 0) {
              let flag = 1;
              for (let index = 0; index < res.visibleWhenAll.length; index++) {
                getDependantValue =
                  this.state.formResponse &&
                  this.state.formResponse[res.visibleWhenAll[index].field];
                if (
                  getDependantValue !== res.visibleWhenAll[index].is[0].value
                ) {
                  flag = 0;
                  break;
                }
              }
              if (flag) return this.getPolicyCover(res);
            } else {
              return this.getPolicyCover(res);
            }
          })}
        {this.state.formArr &&
          this.state.formArr[2] &&
          this.state.formArr[2].fields.length === 0 && (
          <div>{this.getPolicyCover(null)}</div>
        )}
        
        {this.state.premiumAmount && this.state.lifePremiumAmount && (
          <div style={{ textAlign: `center` }}>
            <h4 className={classes.cardTitle}>Health Premium Amount</h4>
            <h2 className={classes.amount}>₹ {this.state.premiumAmount}</h2>
            <h4 className={classes.cardTitle}>(Including Tax)</h4>
          </div>
        )}
        {this.state.lifePremiumAmount && (
          <div style={{ textAlign: `center` }}>
            <h4 className={classes.cardTitle}>Life Premium Amount</h4>
            <h2 className={classes.amount}>₹ {this.state.lifePremiumAmount}</h2>
            <h4 className={classes.cardTitle}>(Including Tax)</h4>
          </div>
        )}
        {(!this.state.lifepremiumAmount || this.state.error) &&
          this.state.life_cover && (
          <div style={{ textAlign: `center`, color: `red` }}>
            {this.state.errorLifePremiumAmount}
          </div>
        )}
        {this.state.quoteReceived && (<div style={{ textAlign: `center` }}>
          {
            this.state.paymentMode === `emi` 
            // && this.getEmiText()
          }
          {this.props.brochureUrl && this.props.brochureUrl.length === 1 && (
            <>
            {this.state.extraprotect ? (
              <>
              <a
                href={this.props.brochureUrl[0]}
                target="_blank"
                rel="noopener noreferrer"
                key={`brochure-download-0`}
              >
                <Button simple color="primary">
                  <ReactSVG
                    src={withPrefix(`/download.svg`)}
                    style={{ margin: `0 auto` }}
                  />
                  {this.changeBrochurename()}
                  {/* {this.props.productId == 3 ? "FHO Brochure" : this.props.productId == 4 ? "Comprehensive Brochure" : "Young Star Insurance Policy"} */}
                </Button>
              </a>   
              {this.props.productId == 25 ? (
             <a
              href="/sites/default/files/brochure/Youngstar-Extra-Protect-Addoncover-Brochure.pdf"
              target="_blank"
              rel="noopener noreferrer"
              key={`brochure-download-0`}
             >
              <Button simple color="primary">
                <ReactSVG
                  src={withPrefix(`/download.svg`)}
                  style={{ margin: `0 auto` }}
                />
                Young Star - Extra Protect Addon Brochure
              </Button>
            </a>
            ) :
            ( <a
              href="/sites/default/files/brochure/Star-Extra-Protect-Brochure.pdf"
              target="_blank"
              rel="noopener noreferrer"
              key={`brochure-download-0`}
            >
              <Button simple color="primary">
                <ReactSVG
                  src={withPrefix(`/download.svg`)}
                  style={{ margin: `0 auto` }}
                />
                Extra Protect Brochure
              </Button>
            </a>) }
            </>
            ) : (
              !this.state.extraprotect && (
              <a
                href={this.props.brochureUrl[0]}
                target="_blank"
                rel="noopener noreferrer"
                key={`brochure-download-0`}
              >
                <Button simple color="primary">
                  <ReactSVG
                    src={withPrefix(`/download.svg`)}
                    style={{ margin: `0 auto` }}
                  />
                  Brochure
                </Button>
              </a>)
            )}
              {this.props.productId == 24 && (
                <h4>
                  Please note that only one policy is allowed for one person
                </h4>
              )}
            </>
          )}
          {this.props.brochureUrl &&
            this.props.brochureUrl.length > 1 &&
            this.state.formResponse &&
            this.state.formResponse.policy_type &&
            this.props.type.map((res, index) => {
              return (
                this.state.formResponse.policy_type === res && (
                  <a
                    href={this.props.brochureUrl[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={`brochure-download-${index}`}
                  >
                    <Button simple color="primary">
                      <ReactSVG
                        src={withPrefix(`/download.svg`)}
                        style={{ margin: `0 auto` }}
                      />
                      Brochure
                    </Button>
                  </a>
                )
              );
            })}
          {this.props.brochureUrl &&
            this.props.brochureUrl.length > 1 &&
            this.state.formResponse &&
            this.state.formResponse.policy_plan &&
            this.props.type.map((res, index) => {
              return (
                this.state.formResponse.policy_plan === res && (
                  <a
                    href={this.props.brochureUrl[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={`brochure-download-${index}`}
                  >
                    <Button simple color="primary">
                      <ReactSVG
                        src={withPrefix(`/download.svg`)}
                        style={{ margin: `0 auto` }}
                      />
                      Brochure
                    </Button>
                  </a>
                )
              );
            })}
        </div>
        )}
        {this.state.quoteReceived && (<GridItem>
        {(this.state.epPlanssection1.includes(this.props.productId)) && this.state.policy_cover>=1000000 &&
        <>
        {this.state.productId == 25 ? 
        (<AddonSection1
        togglePremium = {this.toggleAddon}
        added = {this.state.extraprotectsec1}
        addonpremiumsec1 = {this.state.addonpremiumsec1}
        />) 
        :
        (<div className={classes.checkboxspan}>
          <p style={{color: 'black'}} ><b>ADD ON COVER: Do you want <u><a href="/extra-protect" style={{color: 'black'}} >STAR EXTRA</a></u> PROTECT?</b></p>
          <Checkbox
          checked={this.state.extraprotectsec1}
            onChange={this.handlefunctionone}
                  value='extraprotect1'
                  color='primary'
                  style={{color:'#35488a',padding:'0px'}}
                />
            <p className={classes.authorize}> 
            SECTION I
            <ol>
              <li>Enhanced Room Rent</li>
              <li>Claim Guard (Consumables cover)</li>
              <li>Enhanced Limit for Modern Treatments</li>
              <li>Enhanced Limit for Ayush Treatment</li>
              <li>Home care Treatment</li>
              <li>Bonus Guard</li>
            </ol>
            </p>
        </div>)}
        </>
        }
        {(this.state.epPlanssection2.includes(this.props.productId)) && this.state.policy_cover>=1000000 &&
        <>
        <div className={classes.checkboxspan}>
          <Checkbox
            checked={this.state.extraprotectsec2}
            onChange={this.handlefunctiontwo}
                  value='extraprotect2'
                  color='primary'
                  style={{color:'#35488a',padding:'0px'}}
                />
            <p className={classes.authorize}> 
            SECTION II : Option to choose aggregate Deductible
            </p>
        </div>
        {this.state.extraprotectsec2 && (
          <GridItem md={6}>
          <InputLabel
            htmlFor="product-type"
            className={classes.selectLabel}
          >
              Choose Deductible
          </InputLabel>
          <Select
            MenuProps={{className:classes.selectMenu}}
            classes={{
              select: classes.select
            }}
            style={{ display: `block` }}
            value={this.state.deductionplan}
            onChange={this.changeDecuctionPlan}
            inputProps={{
              name: `deduction`,
              id: `deduction`
            }}
          >
            {
              this.state.deduction.map(emiOption => {
                return <MenuItem
                  key={emiOption.value}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={emiOption.value}
                >
                  {emiOption.display}
                </MenuItem>;
              })
            }
            
          </Select>
        </GridItem>
        )}
        </>
        }
        </GridItem>)}
        
      </div>
    );
    var secondStep = (
      <span key={`formstep-2`}>
        {this.state.formArr && (
          <FormGenerator
            handleFormSubmit={this.handleFormSubmit}
            fields={this.state.formArr[0].fields}
          />
        )}
      </span>
    );
    var contentArr = [
      <span key={`content-array`}>
        {this.state.formArr && userDetails}
        {` `}
        {!this.state.formArr && (
          <div style={{ margin: `0 auto` }} className={classes.loadingGIF}>
          <img src={loadingGIF} />
        </div>
        )}
      </span>,
    ];
    if (this.props.productId == '37') {
      stepsArr.push(`Message`);
      contentArr.push(<>{ 
        <span key={`formstep-2`}><div>
       Thanks for sharing your contact details, our representative will contact you shortly.
      </div></span>}</>);
    }
    if (this.state.formArr && this.state.formArr[2]) {
      stepsArr.push(`Basic Info`);
      stepsArr.push(`Your Premium`);
      contentArr.push(<>{secondStep}</>);
      contentArr.push(<>{thirdStep}</>);
    }
    return (
      <>
      {this.state.paymentMode === `emi` 
      // && this.getEmiDebitCardBanks()
      }
      <span key={`customStepper`}>
        <CustomStepper
          productId={
            this.state.productId ? this.state.productId : this.props.productId
          }
          formResponse={this.state.formResponse}
          formValues={this.state.formArr && this.state.formArr[0].fields}
          activeStep={this.state.activeStep}
          handleFormState={this.handleFormState}
          handleBuyNow={this.handleBuyNow}
          steps={stepsArr}
          content={contentArr}
          premiumAmount={this.state.premiumAmount}
          brochure={this.props.brochure}
          ctaLabel={this.props.ctaLabel}
          quoteReceived={this.state.quoteReceived}
          starExtraprotect={starExtraprotect}
          handleScroll={this.state.handleScroll}
        />
      </span>
      </>
    );
  }
}
Wizard.propTypes = {
  brochure: PropTypes.array,
  ctaLabel: PropTypes.string,
  classes: PropTypes.object,
  buyNowUrl: PropTypes.string,
  formArr: PropTypes.object,
  query: PropTypes.string,
  planType: PropTypes.string,
  productId: PropTypes.number,
  brochureUrl: PropTypes.array,
  type: PropTypes.array,
  productName: PropTypes.string,
};
export default withStyles(wizardStyle)(Wizard);
