import {
  grayColor, dangerColor
} from "assets/jss/material-kit-pro-react.jsx";

const customStepperStyle = (theme) => ({
  root: {
    backgroundColor: `transparent !important`,
    justifyContent: `center`,
    paddingTop: `60px`,
    width: `100%`
  },
  label: {
    fontSize: `10px`,
    textAlign: `center`,
    color: `rgba(68, 68, 68, 0.8)`,
    textTransform: `uppercase`
  },
  errorMsg: {
    color: dangerColor[0] + ` !important`,
    fontSize: `14px !important`
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
    label: {
      fontSize: `10px`,
      textAlign: `center`,
      color: `rgba(68, 68, 68, 0.8)`,
      textTransform: `uppercase`
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: grayColor[11],
    },
  },
  connectorLine: {
    transition: theme.transitions.create(`border-color`),
  },
  icon: {
    width: `30px`,
    height: `30px`,
    boxShadow: `0px 1px 1px 0 rgba(0, 0, 0, 0.3)`,
    backgroundColor: `#ffffff`,
    borderRadius: `50%`,
    fill: `white !important`,
    "& text": {
      fill: `black !important`
    }
  },
  active: {
    fill: `#35488a !important`,
    "& text": {
      fill: `white !important`
    }
  },
  connectorfix: {
    "@media (max-width: 320px)": {
      left: `calc(-40% + -10px) !important`
    }
  },
  container: {
    color: grayColor[0]
  },
  error: {
    color: `red`
  },
  connectorfixpadding: {
    "@media (max-width: 320px)": {
      paddingLeft: `7px !important`,
      paddingRight: `9.5px !important`
    }
  },
});


export default customStepperStyle;
