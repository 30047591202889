import React from "react";
// import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
// import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
// import Table from "@material-ui/core/Table";
import { Helmet } from "react-helmet";
// import Paper from "@material-ui/core/Paper";
// import Accordion from "components/Accordion/Accordion.jsx";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Markdown from "react-markdown";
// import CustomTable from "components/Table/Table";
// const HOSPITALCOUNT = process.env.HOSPITALCOUNT;
// const STRHOSPITALCOUNT = process.env.STRHOSPITALCOUNT;

// const table1 = [
//   ['Eligibility (for adult)', '18 – 65 years'],
//   ['Dependent Children', '91 days - 25 years'],
//   ['Policy period', '1 year/2 years/3 years'],
//   ['Installment facility for premium payment ', 'Quarterly/Half-yearly'],
//   ['Sum Insured ', '5 lakhs to 1 crore'],
//   ['Product type ', 'Individual and Floater'],
//   ['Discounts', <h5 key="renewal_premium2">
//       <h5 key="renewal_premium">2% - 10% - on Renewal Premium under Wellness Rewards Program</h5>
//       <h5 key="renewal_premium1">5% - for purchase of policy online</h5>
//   </h5>],
// ]

// const table2 = [
//   ['In-patient Hospitalisation','Up to S.I and bonus if any','Covers all in-patient hospitalisation expenses if one or more insured persons are required to be hospitalised for treatment for at least 24 hours during the policy period.'],
//   ['AYUSH treatment','Covered based on sum insured opted','Covers expenses for AYUSH treatments (Ayurveda, Unani, Sidha, and Homeopathy) received in a government hospital or any facility recognised by the government and/or accredited by the Quality Council of India/National Accreditation Board on Health up to the limits mentioned'],
//   ['Out Patient Dental/Ophthalmic Treatment','Covered based on sum insured opted','Covers expenses towards out-patient dental and ophthalmic treatment for every block of three years'],
//   ['Delivery Expenses','Covered','Covers for Delivery including Delivery by Caesarean section (including pre-natal, post-natal expenses), subject to a maximum of 2 deliveries in the entire life time of the insured person'],
//   ['Bariatric Surgery','Covered','Bariatric surgeries are covered after the completion of the waiting period of 3 years '],
//   ['Personal Accident Cover','Up to basic S.I.','Covers for accidental death and permanent disabilities caused due to accidents worldwide'],
//   ['Hospital Cash','Covered','Hospital Cash Benefit is payable for each completed day of hospitalisation for a maximum of 7 days per occurrence if an eligible hospitalisation claim is submitted.'],
//   ['Cataract Surgery','No sub-limits','Covers for cataract surgeries without any sub limits'],
// ]
    // const faq=[
    //     {
    //         title:'Does the plan cover maternity expenses?',
    //         description:"Yes. Certainly. Star Comprehensive Health Insurance Plan covers expenses incurred during normal delivery or delivery by caesarean section, where both husband and wife are covered, and if the delivery is after the waiting period mentioned in the policy. A vital benefit of the comprehensive policy is the coverage of pre-and post-natal expenses like ultrasounds, tests, doctor check-ups, follow-ups as per the Terms and conditions of the policy.",
    //         tablerows:null
    //     },
    //     {
    //         title:'Can I reduce the waiting period for pre-existing diseases in the plan?',
    //         description:"Yes. You can get a reduction in the waiting period for pre-existing diseases. This can be done with the “Buyback PED” optional cover, which reduces your waiting period from 36 months to 12 months by paying a one-time additional premium.",
    //         tablerows:null
    //     },
    //     {
    //         title:'Is there a cumulative bonus in the Star Comprehensive Policy?',
    //         description:'Yes. This health plan provides a maximum bonus equal to 100% of the basic sum insured for a claim-free year.',
    //         tablerows:null
    //     },
    //     {
    //         title:'Does the policy cover annual health check-ups?',
    //         description:'Yes, it is covered. You can avail of an annual health check-up for every claim-free year, subject to the continuous renewal of the policy.',
    //         tablerows:null
    //     },
    //     {
    //         title:'Does the plan cover personal accidents?',
    //         description:(<><h5>Yes.  Death & Permanent Disability is covered up to the sum insured for one adult specified in the policy. The policy covers the following conditions:</h5><ul><li>Accidental Death of Insured Person: If the Insured Person dies as a result of an accident within 12 calendar months of the date of the accident</li><li>Permanent Total Disablement of the Insured Person: If the insured`s mental or physical capacities are permanently impaired due to an accident, the company will pay the benefits as mentioned in the policy document. </li></ul></>),
    //         tablerows:null
    //     },
    //     {
    //         title:'What is the number of claims allowed in a policy year?',
    //         description:'There is no limit on the number of claims that can be made during a policy year. You can apply for claims until the sum insured is exhausted because the maximum limit for claim amounts is your sum insured under the policy. Even if the claims go beyond the sum insured, you can make claims up to the limits of the auto-restoration amount, as per the Terms and conditions of the policy',
    //         tablerows:null
    //     },
    //     {
    //         title:'Can I cancel my health insurance after purchasing it?',
    //         description:'Yes, cancellation can be done during the Freelook Period, i.e., within 15 days from the receipt of the policy document',
    //         tablerows:null
    //     },
    //     {
    //         title:'How can I move (port) from one health insurance policy to another?',
    //         description:'According to IRDAI guidelines, the insured individual would have the opportunity to port the policy to other health insurance products/plans offered by the company by applying for migration within 45 days before the renewal of the policy.',
    //         tablerows:null
    //     }
    // ]


        

const ComprehensiveHighlights = ({ classes }) => {
    // let AccordionArray = faq.map(category => {
    //     return {
    //       title: category.title,
    //       content: (
    //         <>
    //           <Markdown source={category.description} />
    //           {category.table && (
    //             <CustomTable
    //               tableHead={category.table.tablerows[0].tablecolumns.map(
    //                 col => {
    //                   console.log(`det ${col.details}`);
    //                   return col.details;
    //                 }
    //               )}
    //               tableData={category.table.tablerows.slice(1).map(row => {
    //                 return row.tablecolumns.map(col => {
    //                   return col.details;
    //                 });
    //               })}
    //             />
    //           )}
    //         </>
    //       )
    //     };
    //   });
    return (
        <GridContainer className={classes.containerDisplay}>
          
            {/* <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2Title}>What is a Comprehensive Policy?</h2>
            </GridItem>
            <h5>
            Health insurance policies have always been looked at as either tax-saving options or as something that requires minimal investment. After all, no one plans to fall sick, right? Very few times have they been viewed as the safety net that insurance policies truly are.  
            </h5>
                <h5>
            <h5>
            The truth is that with the changing lifestyle where we are demanding more and more of our minds and bodies without being able to take care of ourselves, a health insurance plan becomes an important safety net against stress and lifestyle-induced medical problems. 
            </h5> 
            </h5>
            <h5>
            And if a healthcare policy has to be a safety net, it should ideally provide end-to-end coverage for all planned and unplanned medical expenses. An all-encompassing policy has to cover everything, from hospitalisation expenses to pre-or post hospitalisation, or even out-patient consults and daycare treatments. And it doesn’t just end with allopathy; it ideally has to cover alternative therapies like Ayurveda, Unani, Siddha, Homeopathy, etc. Be it treatment for lifestyle-related problems like obesity or bariatric surgery, or even organ donation, a medical plan should be able to stand by the insured at all times.
            </h5>
            <h5>
            When a health insurance plan provides such wide coverage, it means it is providing comprehensive health insurance. Let’s look at the Comprehensive Health Insurance Plan by Star Health Insurance and how it’s the right choice for individuals and families looking for across-the-board protection.
            </h5>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2Title}>Coverage under Star Health Comprehensive Health Insurance Plan includes:</h2>
            </GridItem>
             <ul style={{listStyle:'none'}}>
                <li>
                ✓  In-patient hospitalization expenses
                </li>
                <li>
                ✓  Pre-and post-hospitalisation expenses
                </li>
                <li>
                ✓  Daycare procedures
                </li>
                <li>
                ✓  Domiciliary hospitalisation
                </li>
                <li>
                ✓  Ambulance expenses
                </li>
                <li>
                ✓  Daycare treatments
                </li>
                <li>
                ✓  AYUSH treatments
                </li>
                <li>
                ✓  Second medical opinion
                </li>
                <li>
                ✓  Delivery and new-born cover
                </li>
                <li>
                ✓  Organ donor expenses
                </li>
                <li>
                ✓  Bariatric surgery
                </li>
                <li>
                ✓  Personal accident cover – lumpsum benefit for death & permanent disablement
                </li>
                <li>
                ✓  Out-patient medical consultation
                </li>
                <li>
                ✓  Out-patient Dental and Ophthalmic treatments
                </li>
                <li>
                ✓  Hospital Cash Benefit
                </li>
                <li>
                ✓  Annual health check-up
                </li>
                <li>
                ✓  Wellness program
                </li>
                <li>
                ✓  TeleMedicine Service – talk to Star
                </li>
            </ul>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2Title}>Important Features of Comprehensive Health Insurance Policy:</h2>
            </GridItem> */}
            {/* <div>
        <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={4}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {table1.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={4}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting Periods
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-36 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Option to buy-back PED. Waiting periods reduced to 36 months to 12 months 
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24 months 
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents) 
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>
        <div style={{marginTop:'5%',marginBottom:'3%'}}>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Benefits
                                  </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Coverage limit
                                </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                >
                                   Description of cover
                         </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {table2.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellwidth}>
                                    {row[1]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellwidth}>
                                    {row[2]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                        </Table>
                </Paper>
                </div> */}
      
         {/* <ul>
                 <li>
                     <b>Eligibility </b> <br />
                     <div>Star Comprehensive Insurance Policy can be purchased by individuals between the age of 18 years and 65 years. The comprehensive family floater plan allows its holder to cover their family, including two adults and up to three dependent children between the ages of 3 months and 25 years. 
                     </div>
                 </li>
                 <li>
                     <b>Sum insured</b> <br />
                     <div>The plan provides sum insured options ranging from a minimum of Rs. 5 lakhs to Rs. 1 crore. 
                     </div>
                 </li>
                 <li>
                     <b>Product type</b> <br />
                     <div>Star Comprehensive Insurance Policy is an indemnity-based medical insurance policy available on both individual and family floater coverage basis.
                     </div>
                 </li>
                 <li>
                     <b>Policy term</b> <br />
                     <div>Available for - 1 year / 2 years / 3 years</div>
                 </li>
                 <li>
                     <b>Co-payment</b> <br />
                     <div>A co-payment of 10% for every claim is applicable for those who purchase this policy at the age of 61 and above. This means that every time a claim is filed with the company, the insured will be required to pay a fixed 10% of the claim, and then Star Health Insurance will process the rest of the claim.</div>
                 </li>
                 <li>
                     <b>Waiting periods</b> <br /><div>
                <div>
                ✓  Any illness contracted within 30 days of the policy`s inception (activation of the policy) is not covered. The exception is in case of an accident. 
                </div>
                <div>
                ✓  Coverage for specified illnesses/ surgeries becomes effective after 24 months.
                </div>
                <div>
                ✓  Pre-existing diseases are covered after a waiting period of 36 months from the policy`s date of activation. 
                </div>
            <b>Note:</b> 
            <div>There is an option to reduce the pre-existing disease waiting period from 3 years to 1 year, which comes under the Buy-Back Pre-Existing Diseases (PED) option.</div>
            </div>
                 </li>
                 <li>
                     <b>Portability </b> <br />
                     <div>According to IRDAI guidelines on portability, the insured person can port the policy to another insurer by applying to that insurer to port the entire policy. If any, they can port all family members at least 45 days before, but no earlier than 60 days before the policy renewal date.</div>
                 </li>
                 <li>
                     <b>Free look period </b> <br />
                     <div>A free look period of 15 days from the date of receipt of the policy is available for reviewing the policy`s terms and conditions. If the insured is not satisfied with the plan, they will have an option of cancelling the policy within the stipulated period. In such a case, the company will process the premium refund after adjusting the cost incurred during that period.</div>
                 </li>
                 <li>
                     <b>Pre-acceptance and medical screening </b> <br />
                     <div>No pre-policy medical screening is required.</div>
                 </li>
                 <li>
                     <b>Lifelong renewal option </b> <br />
                     <div>The Star Comprehensive Insurance plan offers a lifelong renewal option. If the insured renews the policy within the due date, he can enjoy continuous benefits like reduced waiting period on coverage of PED’, cumulative bonus etc. A grace period of 30 days is provided to renew the policy. This way continuity of benefits is ensured.</div>
                 </li>
            </ul>


             <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2Title}>What is covered in the Star Comprehensive Insurance Policy?</h2>
            </GridItem>
            <ul>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>In-patient hospitalisation expenses</h3>
                </GridItem>
                <h5>
                The Star Comprehensive Insurance Policy will cover hospitalisation expenses if one or more insured persons are required to be hospitalised for treatment for at least 24 hours during the policy period. The plan will cover expenses incurred towards the following:
                </h5>
                <ul  style={{listStyle:'none'}}>
                    <li>
                    +  Expenses towards room rent (Private Single AC Room), boarding, and nursing
                    </li>
                    <li>
                    +  Expenses towards anaesthesia, blood, oxygen, operation theatre charges, ICU charges, surgical appliances, medicines and drugs, diagnostic materials and X-ray, diagnostic imaging modalities, dialysis, chemotherapy, radiotherapy, cost of a pacemaker, stent, and such other similar expenses
                    </li>
                    <li>
                    +  Expenses towards the surgeon, anaesthetist, medical practitioner, consultants, specialist fees
                    </li>
                </ul>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Pre- and post-hospitalisation expenses</h3>
                </GridItem>
                <h5>
                Star Comprehensive Insurance Policy covers related pre-hospitalisation expenses for a maximum of 60 days before getting admitted. Also, it covers post-hospitalisation expenses for a maximum period of upto 90 days from the day of getting discharged from the hospital.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Domiciliary hospitalisation</h3>
                </GridItem>
                <h5>
                Star Comprehensive Insurance Policy covers medical treatment expenses (including AYUSH) for a period of more than three days for an illness/disease/injury that usually would necessitate hospitalisation but is treated at home on the advice of the attending medical practitioner. 
                </h5>
                <h5>
                The patient`s condition is that they cannot be transferred to a hospital or receive care at home due to a lack of available rooms in a hospital.
                </h5>
                </li>

                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Ambulance expenses</h3>
                </GridItem>
                <h5>
                The policy covers all road ambulance expenses. In case of requirement, air ambulance charges of up to Rs. 2.5 lakhs per hospitalisation and a maximum of up to Rs. 5 lakhs per policy year are included.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Daycare treatments</h3>
                </GridItem>
                <h5>
                The policy covers charges for all daycare procedures, surgeries, and treatments that require less than 24 hours of hospitalisation.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}> AYUSH treatments</h3>
                </GridItem>
                <h5>
                he policy covers expenses for AYUSH treatments (Ayurveda, Unani, Sidha, and Homeopathy) received in a government hospital or any facility recognised by the government and/or accredited by the Quality Council of India/National Accreditation Board on Health up to the limits mentioned.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}> Second medical opinion</h3>
                </GridItem>
                <h5>
                The Comprehensive Health Insurance Policy offers the insured the facility to obtain a second medical opinion from a doctor in the company`s network of medical practitioners.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}> Unique features of Comprehensive Health Insurance Policy</h3>
                </GridItem>
                <h5>
                Here is a list of features that are unique to the Comprehensive Health Insurance Policy. As we promise, we deliver the most comprehensive care through this policy.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}> Delivery expenses cover</h3>
                </GridItem>
                <h5>
                Normal delivery and caesarean section are covered when both husband and wife are covered in the policy. Pre- and post-natal expenses are also covered under this policy up to the limits mentioned and per the Terms and Conditions.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}> New-born cover</h3>
                </GridItem>
                <h5>
                Expenses incurred in a hospital or nursing home for treatment of the new-born for any disease, illness including any congenital disorders or accidental injuries, are covered when there is an admissible claim under Delivery Expenses. In addition, vaccination expenses for the new-born will be covered up to the limit specified in the policy document as per Terms and conditions
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}> Organ donor expenses</h3>
                </GridItem>
                <h5>
                Medical expenses towards organ transplant surgery for the donor are covered when the surgery for the recipient/ insured is admissible.
                </h5>
                <h5>
                Furthermore, any expenses incurred by the donor for complications arising out of the transplant surgery that require a redo-surgery or ICU admission will be covered as per the Terms and conditions of the Policy.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}> Bariatric surgery</h3>
                </GridItem>
                <h5>
                Bariatric / Weight Loss surgery expenses are covered after the completion of the waiting period of 3 years up to the limits mentioned in the policy.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}> Personal accident coverage</h3>
                </GridItem>
                <h5>
                The policy holder gets compensation for accidental death and permanent disabilities caused due to accidents up to the sum insured.  For a floater policy, either spouse (husband or wife) can be covered depending on who the nominee is.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}> Out-patient medical consultation</h3>
                </GridItem>
                <h5>
                Expenses incurred towards out-patient medical consultations are covered up to the limits specified for the chosen sum insured in the policy.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Out–patient Ophthalmic and Dental Consultation</h3>
                </GridItem>
                <h5>
                The policy also covers expenses towards out-patient dental and ophthalmic treatment for every block of three years up to the limits mentioned in the policy.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Hospital Cash Benefit</h3>
                </GridItem>
                <h5>
                Hospital Cash Benefit up to the limitations is payable for each completed day of hospitalisation for a maximum of 7 days per occurrence if an eligible hospitalisation claim is submitted.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Annual health check-ups </h3>
                </GridItem>
                <h5>
                On renewing the policy, policyholders will be entitled to free annual health check-ups after every claim-free year, as per the terms and conditions of the policy. 
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Wellness program </h3>
                </GridItem>
                <h5>
                The wellness program aims to start, improve, and maintain the active and healthy lifestyle of a policyholder. The Star Wellness program is a benefit for Star Comprehensive Health Plan customers. Insured persons can earn reward points by undertaking various wellness activities. These reward points can help the policyholder earn discounts on the premium at the policy`s renewal.
                </h5>
                <h5>
                The wellness activities listed are intended to assist the insured person in earning wellness reward points, which the company will track and monitor. The wellness points obtained through the wellness program can be used to get a discount on the policy premium. The Star Wellness Platform (available on Star Power app) - a digital platform is used to implement and administer this wellness program.
                </h5>
                <h5>
                The best way to know about the reward points and the various wellness activities is to get in touch with our customer care team.
                </h5>
                </li>
                </ul>
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2Title}>What is not covered?</h2>
            </GridItem>
            <h5>
            The following is a partial listing of policy exclusions. A detailed list of all exclusions is included in the policy document.
            </h5>
             <ul style={{listStyle:'none'}}>
                <li>
                +  Pre-existing medical conditions until the completion of the waiting period
                </li>
                <li>
                +  Any hospitalisation with the primary goal of obtaining diagnostic information
                </li>
                <li>
                +  Treatment outside of India 
                </li>
                <li>
                +  Circumcision, sex-change surgery, cosmetic surgery, and plastic surgery 
                </li>
                <li>
                +  Refractive error repair, hearing impairment correction, corrective and aesthetic dental surgery 
                </li>
                <li>
                +  Hazardous or adventure sports-related injuries 
                </li>
                <li>
                +  Unproven treatments 
                </li>
                <li>
                +  Venereal diseases and STDs (other than HIV)
                </li>
                <li>
                +  Nuclear weapon and war-related perils 
                </li>
                <li>
                +  Intentional self-injury 
                </li>
            </ul>

             <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2Title}>Why should you buy Comprehensive Health Insurance Policy?</h2>
            </GridItem>
                <h5>
                We understand that some people need a health cover that provides wide protection against various lifestyle diseases covers different treatment modalities, and provides one-stop coverage for the entire family. Star Health Insurance understands this need and has designed a policy to provide comprehensive health coverage on both individual and floater basis.
                </h5>
                <h5>
                Our changing lifestyle is a massive contributor to our health and wellness. Lifestyle changes have led to increasing health issues, and they take a toll on our mental wellness. On the other hand, the cost of medical treatments and hospitalisation has also shot up drastically. This is where an extensive health coverage plan steps in by providing financial security against such unexpected medical expenses.
                </h5>
                <h5>
                The Comprehensive Health Insurance Policy covers expenses related to in-patient hospitalisation, out-patient hospitalisation, ophthalmic/dental care, maternity care, all major diseases, including critical, airborne, waterborne, and vector-borne diseases and accidents, infectious diseases up to the amount of sum insured.
                </h5>

             <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2Title}>Why choose Comprehensive Health Policy from Star Health Insurance?</h2>
            </GridItem>
            <ul>
               <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Cash-less benefit from Network Hospitals </h3>
                </GridItem>
                <h5>
                We provide healthcare access from an ever-increasing list of Network Hospitals where you can opt for cash-less treatment. Our aim is that you should have easy access to a network hospital near you in times of planned or unplanned medical expenses. Star Health Insurance currently has a network of over {STRHOSPITALCOUNT} hospitals. For the complete list, visit 
                {` `}<a href='https://www.starhealth.in/wellness#networkhospitals' rel="noopener noreferrer" target='_blank'>here</a>.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Hassle-free claim process </h3>
                </GridItem>
                <h5>
                Insurance Claim Settlement processes can often be time-consuming. However, Star Health Insurance offers digital-friendly, zero-touch, quick, and convenient options that work better for you during this pandemic situation with our In-house claim settlement. 
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Instalment options for premium payment </h3>
                </GridItem>
                <h5>
                The insured person can choose to pay their premiums in instalments, such as half-yearly or quarterly mode.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Tax benefits </h3>
                </GridItem>
                <h5>
                Individual and family members below 60 years can get Rs. 25,000 deductions from taxable income, and the deductible amount can extend up to Rs. 50,000 for senior citizens on premiums paid as per Section 80D of Income Tax Act, 1961. If two family members above the age of 60 are insured under one policy, then the deductible amount extends to Rs 1 lakh. In contrast, if there is one family member below the age of 60 and his parents are also insured under the same policy, then they can avail of a deduction of Rs 75,000.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Automatic restoration </h3>
                </GridItem>
                <h5>
                As the name suggests, this plan restores your sum insured by 100% once after it has been exhausted within a calendar year due to hospitalisation related claims.
                </h5>
                </li>
                <li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h3 className={classes.h3textStyle}>Cumulative bonus </h3>
                </GridItem>
                <h5>
                A No Claim Bonus is declared after every claim-free year. In the Comprehensive Health Insurance Policy, it is 50% for Rs. 5 lakhs and 100% for sum insured of Rs 7.5 lakhs and above. The maximum bonus accumulation is up to 100% of the sum insured. Terms and conditions applicable on the accrual method.
                </h5>
                </li>
             </ul>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h3 className={classes.h3textStyle}>FREQUENTLY ASKED QUESTIONS:</h3>
            </GridItem>

            <div>
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer
              className={`${classes.container} ${classes.textCenter}`}
            >
              <GridItem
                md={12}
                style={{ paddingLeft:'0px'}}
                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
              ></GridItem>
              <GridItem
               style={{ paddingLeft:'0px'}}
                className={`${classes.mrAuto} ${classes.textCenter}`}
                xs={12}
                sm={12}
                md={12}
              >
                <Accordion active={0} collapses={AccordionArray} />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      </GridItem>
      <Helmet>
      <title>
      Comprehensive Health Insurance Plans | Comprehensive Medical Policy | Star Health
            </title>
       </Helmet> */}
       {process.env.ENABLE_HELMET == `true` && (
       <Helmet>
            {/* <script type="application/ld+json">{`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "Does the plan cover maternity expenses?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes. Certainly. Star Comprehensive Health Insurance Plan covers expenses incurred during normal delivery or delivery by caesarean section, where both husband and wife are covered, and if the delivery is after the waiting period mentioned in the policy. A vital benefit of the comprehensive policy is the coverage of pre-and post-natal expenses like ultrasounds, tests, doctor check-ups, follow-ups as per the Terms and conditions of the policy."
                }
              },
              {
                "@type": "Question",
                "name": "Can I reduce the waiting period for pre-existing diseases in the plan?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes. You can get a reduction in the waiting period for pre-existing diseases. This can be done with the “Buyback PED” optional cover, which reduces your waiting period from 36 months to 12 months by paying a one-time additional premium."
                }
              },
              {
                "@type": "Question",
                "name": "Is there a cumulative bonus in the Star Comprehensive Policy?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes. This health plan provides a maximum bonus equal to 100% of the basic sum insured for a claim-free year.  "
                }
              },
              {
                "@type": "Question",
                "name": "Does the policy cover annual health check-ups? ",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, it is covered. You can avail of an annual health check-up for every claim-free year, subject to the continuous renewal of the policy. "
                }
              },
              {
                "@type": "Question",
                "name": "5.Does the plan cover personal accidents?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes.  Death & Permanent Disability is covered up to the sum insured for one adult specified in the policy. The policy covers the following conditions:\nAccidental Death of Insured Person: If the Insured Person dies as a result of an accident within 12 calendar months of the date of the accident \nPermanent Total Disablement of the Insured Person: If the insured's mental or physical capacities are permanently impaired due to an accident, the company will pay the benefits as mentioned in the policy document. "
                }
              },
              {
                "@type": "Question",
                "name": "What is the number of claims allowed in a policy year?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "There is no limit on the number of claims that can be made during a policy year. You can apply for claims until the sum insured is exhausted because the maximum limit for claim amounts is your sum insured under the policy. Even if the claims go beyond the sum insured, you can make claims up to the limits of the auto-restoration amount, as per the Terms and conditions of the policy"
                }
              },
              {
                "@type": "Question",
                "name": "Can I cancel my health insurance after purchasing it?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": " Yes, cancellation can be done during the Freelook Period, i.e., within 15 days from the receipt of the policy document"
                }
              },
              {
                "@type": "Question",
                "name": "How can I move (port) from one health insurance policy to another?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "According to IRDAI guidelines, the insured individual would have the opportunity to port the policy to other health insurance products/plans offered by the company by applying for migration within 45 days before the renewal of the policy."
                }
              }
            ]
          }`}</script> */}
          {/* <script type="application/ld+json">
          {`{ "@context": "http://schema.org",
            "@type": "Product",
            "name": "Star Comprehensive Insurance Policy",
            "description": "Buy your Comprehensive Health Insurance Policy from us to get more benefits when compared to other insurer.",
          "offers":{ 
                    "@type": "AggregateOffer",
                    "offerCount": "5%"
                  }
      }`}
</script> */}
          {/* <meta
              name="title"
              content="Comprehensive Health Insurance Plans | Comprehensive Medical Policy | Star Health"
            />
            <meta
              name="description"
              content="Comprehensive Health Insurance plan gives complete protection for whole family. Covers all expenses, daycare procedures, accidents, maternity and new born, OPD, ambulance, annual health check up and more."
            /> */}
            </Helmet>
       )}
    </GridContainer>
  );
};
ComprehensiveHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(ComprehensiveHighlights);