import React from 'react';
import Edit from "@material-ui/icons/Edit";
import { browserWindow } from "util/localStorageHelper";
import { formatReqAndRes } from "util/RequestBodyFormatter.jsx";
import Button from "components/CustomButtons/Button";
import FormProcessor from '@ps/react-forms/processor/FormProcessor';
import PropTypes from 'prop-types';
import { browserDocument } from "util/localStorageHelper";
import productListStyle from 'ps-assets/jss/productListStyle.jsx';
import {
  withStyles
} from '@material-ui/core/styles';
import 'assets/scss/material-kit-pro-react.scss?v=1.3.0';
import FHOConversational from "forms/conversational/basic_family-health-optima.json";
import FHOConversational_payload from "forms/payload/FHO_payload.json";
import SeniorCitizenForm from "forms/conversational/basic_Senior_Citizen.json";
import SeniorCitizen_payload from "forms/payload/Senior_Citizen_payload.json";
import ComprehensiveForm from "forms/conversational/basic_Comprehensive.json";
import Comprehensive_payload from "forms/payload/Comprehensive_payload.json";
import { browserLocalStorage } from "util/localStorageHelper";
const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;
const CUSTOMERPORTAL_URL = process.env.CUSTOMERPORTAL_URL

const emiOptionsArr = [
  {
    display: `Half yearly EMI Plan`,
    value: `04`
  },
  {
    display: `Quarterly EMI Plan`,
    value: `03`
  }
];


const forms={
  "3":{
    "formJson":FHOConversational,
    "formPayload":FHOConversational_payload
},
"4":{
    "formJson":ComprehensiveForm,
    "formPayload":Comprehensive_payload
},
"7":{
    "formJson":SeniorCitizenForm,
    "formPayload":SeniorCitizen_payload
}
}



class ConversationalQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: null,
      formJson:{},
      formPayload:{},
      formResponse:{},
      formData:{},
      originalFormData:{}
         };
  }
  

  handleCallback = () => {
    browserDocument.getElementsByClassName(``);
    browserDocument.getElementsByClassName(`wbf-screen`)[0].classList.add(`active`);
    browserDocument.getElementsByClassName(`wbf-container`)[0].classList.add(`active`);
  };

  clearQuote = () => {
    this.setState({ apiResponse: null });
  };

  changeAdultValueCheck = (adults,productId) => {
    if(adults == 11){
      switch(productId){
        case 26:
        case 3:
        case 29:
        case 31: 
          return true;
        default: break;
      }
    }
  return false;
}

  handleBuyNow = () => {
    try {
    let { buyNowUrl, planType } = this.props;
    let adults = this.state.formData.formPayload.adults;
    let kids = this.state.formData.formPayload.kids;
    let name = this.state.formData.formPayload.name;
    let mobNo = this.state.formData.formPayload.mobNo;
    let email = this.state.formData.formPayload.email;
    let year = this.state.formData.formPayload.year;
    let policyCoverOptions={};
    if(this.state.formData.formPayload.productId =='7' && this.formObj && this.formObj.pages && this.formObj.pages[0].sections && this.formObj.pages[0].sections[0].subSections && this.formObj.pages[0].sections[0].subSections[0].fields){
      this.formObj.pages[0].sections[0].subSections[0].fields.map((item)=>{
          if(this.state.formData.formPayload.policyType == `Floater`){
            if(item.name=='policy_cover2'){
          policyCoverOptions=item.options
            }
          }else{
            if(item.name=='policy_cover1'){
              policyCoverOptions=item.options
                }
          }
      })
    }
    else if(this.formObj && this.formObj.pages && this.formObj.pages[0].sections && this.formObj.pages[0].sections[0].subSections && this.formObj.pages[0].sections[0].subSections[0].fields){
    this.formObj.pages[0].sections[0].subSections[0].fields.map((item)=>{
      if(item.name=='policy_cover'){
        policyCoverOptions=item.options
      }
    })
  }
    policyCoverOptions = JSON.stringify(policyCoverOptions);
    let { days } = this.state.formData.formPayload;
    let {parents,parentDob1,parentDob2,parentDob3,parentDob4} = this.state.formData.formPayload;
    if(this.changeAdultValueCheck(adults,this.state.formData.formPayload.productId)){
      adults = 1;
    }
    let suminsured = this.state.formData.formPayload.policyCover;
    let redirectUrl = buyNowUrl;
    let user = browserLocalStorage.getItem(`user`);
    let authToken = ``;
    if (user) {
      user = JSON.parse(user);
      authToken = user.token;
      if (authToken) {
        let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
        if (logOutToken) {
          authToken = ``;
        }
      }
    }
    let postalcode = this.state.formData.formPayload.pinCode;
    let dob = this.state.formData.formPayload.dob && this.state.formData.formPayload.dob.replace(/\//g, `-`);
    let normalBuyUrl = `${CUSTOMERPORTAL_URL}/customerportal`;
    if (buyNowUrl && buyNowUrl.includes(`instantbuy`)) {
      normalBuyUrl +=
        this.state.formData.formPayload.policyType == `Floater` &&
        planType == `redcarpet`
          ? `/normalbuy`
          : `/instantbuy`;
    } else {
      normalBuyUrl += `/normalbuy`;
    }
    normalBuyUrl += `?${planType ? `type=${planType}` : ``}${
      postalcode ? `&pinCode=${postalcode}` : ``
    }${suminsured ? `&policyCover=${suminsured}` : ``}${
      dob ? `&dob=${dob.split(`-`).join(`/`)}` : ``
    }${year ? `&year=${year}` : ``}${
      this.state.formData.formPayload.policyPlan
        ? `&policyPlan=${this.state.formData.formPayload.policyPlan}`
        : ``
    }${
      this.state.formData.formPayload.policyType
        ? `&policyType=${this.state.formData.formPayload.policyType}`
        : ``
    }${
      this.state.formData.formPayload.policy_option
        ? `&policyOption=${this.state.formData.formPayload.policy_option}`
        : ``
    }${name ? `&name=${name}` : ``}${mobNo ? `&mobNo=${mobNo}` : ``}${
      email ? `&email=${email}` : ``
    }${policyCoverOptions ? `&policyCoverOptions=${policyCoverOptions}` : ``}${
      this.state.formData.formPayload.productId ? `&productId=${this.state.formData.formPayload.productId}` : ``
    }${days ? `&days=${days}` : ``}`;
    if(this.state.formData.formPayload.productId === '26' || this.state.formData.formPayload.productId === '29' || this.state.formData.formPayload.productId === '3'){
      if(this.state.formData.formPayload.policyType == `Floater`){
        normalBuyUrl += `${
          adults ? `&adults=${adults}` : ``
        }${kids ? `&kids=${kids}` : ``}`;
        normalBuyUrl += `${
          parents ? `&parents=${parents}` : ``
        }${
          parentDob1 ? `&parentDob1=${parentDob1.replace(/\//g, `-`)}` : ``
        }${
          parentDob2 ? `&parentDob2=${parentDob2.replace(/\//g, `-`)}` : ``
        }${
          parentDob3 ? `&parentDob3=${parentDob3.replace(/\//g, `-`)}` : ``
        }${
          parentDob4 ? `&parentDob4=${parentDob4.replace(/\//g, `-`)}` : ``
        }`;
      }
      else if(this.state.formData.formPayload.productId === '3'){
        normalBuyUrl += `${
          adults ? `&adults=${adults}` : ``
        }${kids ? `&kids=${kids}` : ``}`;
        normalBuyUrl += `${
          parents ? `&parents=${parents}` : ``
        }${
          parentDob1 ? `&parentDob1=${parentDob1}` : ``
        }${
          parentDob2 ? `&parentDob2=${parentDob2}` : ``
        }${
          parentDob3 ? `&parentDob3=${parentDob3}` : ``
        }${
          parentDob4 ? `&parentDob4=${parentDob4}` : ``
        }`;
      }
      else{
        if(this.state.formData.formPayload.productId === '29'){
          normalBuyUrl += `${
            adults ? `&adults=${adults}` : ``
          }`;
        }
        else{
          normalBuyUrl += `&adults=1`;  
        }
        normalBuyUrl += `&parents=&kids=0`;
      }
    }
    else{
      if(this.state.formData.formPayload.policyType == `Individual`){
        normalBuyUrl += `&kids=`;
      }
      else{
        normalBuyUrl += `${kids ? `&kids=${kids}` : ``}`;
      }
    }
    if(this.state.formData.formPayload.productId === '29' || this.state.formData.formPayload.productId === '26' || this.state.formData.formPayload.productId === '3'){
      if(this.state.formData.adults === "My Parents only" ||
          this.state.formData.adults === "My Parents-in-law only"){
        normalBuyUrl += `&parents=2`;
      }
    }
    else{
      normalBuyUrl += `${
        adults ? `&adults=${adults}` : ``
      }`;
    }
    normalBuyUrl +=`&emiValue=FP&emiOptions=${JSON.stringify(emiOptionsArr)}`
    redirectUrl = normalBuyUrl;
    if(authToken !== ``) localStorage.setItem(`sessionalive`, true);
     return redirectUrl
  }
  catch(exception){
    console.log(exception);
  }
};


  parseCtaMessage = text => {
    var pattern = /\$\{(\w+_?\w+)\}/g;
    var responseString = [],
      startIndex = 0;
    text.replace(pattern, res => {
      responseString.push(
        text.substr(startIndex, text.indexOf(res) - startIndex)
      );
      startIndex = text.indexOf(res) + res.length;
      var match = res.substr(2, res.length - 3);
      var label = this.formObj.responseHelperMessage[match].label;
      var classname = this.formObj.responseHelperMessage[match].className;
      // var target = this.formObj.responseHelperMessage[match].target;
      let button = <></>;
      var url = this.formObj.responseHelperMessage[match].url;
      if (url) {
        let redirectUrl = this.handleBuyNow()
        redirectUrl=redirectUrl+`&from=conv`
        console.log(redirectUrl)
        button = (
          <a rel="noopener noreferrer" href={redirectUrl}>
            <Button round color="primary" className={classname}>
              {label}
            </Button>
          </a>
        );
      } else {
        button = (
          <Button
            round
            color="primary"
            onClick={this.handleCallback}
            className={classname}
          >
            {label}
          </Button>
        );
      }
      responseString.push(button);
    });

    return responseString;
  };
  formCover = () => {
    switch(this.state.formData.formPayload.productId){
      case '3':
        if( this.state.formData.adults=='Myself and My Spouse'){
          if (this.state.formData.formPayload.parents!=''){
            return 'Myself, My Spouse and My Parents/Parent-in laws'
          }
          else{
            return this.state.formData.adults;
          }
        }
        else{
          return this.state.formData.adults;
        }
      default:
        return this.state.formData.adults;
    }
  }
  parseHelperMessage = text => {
    const { classes } = this.props;
    const stateKeys = Object.keys(this.state.formResponse);
    var pattern = /\$\{(\w+_?\w+)\}/g;
    var responseString = [],
      startIndex = 0;
    text.replace(pattern, res => {
      responseString.push(
        text.substr(startIndex, text.indexOf(res) - startIndex)
      );
      startIndex = text.indexOf(res) + res.length;
      var match = res.substr(2, res.length - 3);
      for (let index = 0; index < stateKeys.length; index++) {
        const stateKey = stateKeys[index];
        const element = this.state.formResponse[stateKey];
        if (element && match && element.name === match) {
          responseString.push(
            <span className={classes.responseHelperText}>{element.label}</span>
          );
        }
      }
    });
    return responseString;
  };
  getResponseHelperMessage = () => {
    const responseHelperText = this.formObj.responseHelperMessage;
    let formCover=this.formCover();
    const { classes } = this.props;
      return (
        <div>
          <h4>Covering <span className={classes.CoveringStyle}>{this.state.formData && formCover} </span>
          for <span className={classes.CoveringStyle}>{this.state.formData && this.state.formData.policy_cover}</span>
            {' '}in <span className={classes.CoveringStyle}>{this.state.formData && this.state.formData.pincode}</span></h4>
          <h5
            style={{
              textAlign: `center`,
              fontWeight: `500`,
              paddingTop: `15px`,
              marginBottom: `0`
            }}
          >
           Your Premium Amount is
          </h5>
          <h2 className={classes.policy_quote}>
            ₹ {this.state.apiResponse.policy_quote}
          </h2>
          {!browserWindow.location.href.includes(`#agent`) && (
            <h4 className={classes.ctaText} style={{fontSize: '2.25rem',
              fontWeight: 500,
              fontFamily: 'Roboto Slab'}}>
              {this.parseCtaMessage(responseHelperText.ctaText)}
            </h4>
          )}
        </div>
      );
  };
  

  onSubmit = async (formData,originalFormData) => {
    var requestBody = JSON.parse(JSON.stringify(formData.formPayload))
    fetch(
      `${CUSTOMER_PORTAL_API_URL}/cp/insurance/calculate/product/quote`,
      {
        method: `POST`,
        headers: {
          "Content-Type": `application/json`
        },
        body: JSON.stringify(formatReqAndRes(requestBody))
      }
    )
      .then(res => res.json())
      .then(res => {
        res.response = formatReqAndRes(res.response);
        if (res && res.response && res.response.policy_quote) {
          this.setState({
            apiResponse: { policy_quote: res.response.policy_quote },
            formData:formData,
            originalFormData:originalFormData
          });
        } else
          this.setState({
            apiResponse: {
              error:
                (res.response && res.response.error) ||
                `Sorry could not fetch the premium amount. Please try again later`
            }
          });
      })
      .catch(() => {
        this.setState({
          apiResponse: {
            error: `Sorry could not fetch the premium amount. Please try again later`
          }
        });
      });
  };

  render() {
    let formJson=forms[this.props.data.strapiInsuranceplans.productId]['formJson']
    let formPayload=forms[this.props.data.strapiInsuranceplans.productId]['formPayload']
    this.formObj = formJson;
    return (
      <>
            {!this.state.apiResponse && formJson && formPayload && (
              <>
            <FormProcessor
            key={`form`}
            formJson={formJson}
            formData={this.state.originalFormData}
            action={'edit'}
            rendererType={`material`}
            updatePageNumber={0}
            renderStyle={`creative_tim_conversation`}
            formPayload={formPayload}
            onSubmit={this.onSubmit}
        />
        </>
        )}
          {this.state.apiResponse && this.state.apiResponse.policy_quote && (
            <div style={{ textAlign: `center` }}>
              {this.getResponseHelperMessage()}
              <Button color="primary" simple onClick={this.clearQuote}>
                <Edit /> Edit Quote
              </Button>
            </div>
          )}
          {this.state.apiResponse && this.state.apiResponse.error && (
            <div style={{ textAlign: `center` }}>
              {this.state.apiResponse.error}
              <br />
              <Button color="primary" simple onClick={this.clearQuote}>
                <Edit /> Edit Quote
              </Button>
            </div>
          )}
        </>
    );
  }
}

ConversationalQuote.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  planType: PropTypes.string,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  buyNowUrl: PropTypes.string
};

export default withStyles(productListStyle)(ConversationalQuote);