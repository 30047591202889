"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _TextField = require("@material-ui/core/TextField");

var _TextField2 = _interopRequireDefault(_TextField);

var _FormHelperText = require("@material-ui/core/FormHelperText");

var _FormHelperText2 = _interopRequireDefault(_FormHelperText);

var _index = require("../../../../core/dist/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
  return {
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      // marginLeft: theme.spacing.unit,
      // marginRight: theme.spacing.unit,
      width: 200
    },
    menu: {
      width: 200
    }
  };
};

var MaterialUiTextField = function (_React$Component) {
  _inherits(MaterialUiTextField, _React$Component);

  function MaterialUiTextField() {
    _classCallCheck(this, MaterialUiTextField);

    return _possibleConstructorReturn(this, (MaterialUiTextField.__proto__ || Object.getPrototypeOf(MaterialUiTextField)).apply(this, arguments));
  }

  _createClass(MaterialUiTextField, [{
    key: "render",
    value: function render() {
      // $FlowFixMe - HOC adds this class
      var classes = this.props.classes;

      var _props = this.props,
          description = _props.description,
          disabled = _props.disabled,
          errorMessages = _props.errorMessages,
          id = _props.id,
          isValid = _props.isValid,
          name = _props.name,
          onFieldChange = _props.onFieldChange,
          placeholder = _props.placeholder,
          required = _props.required,
          value = _props.value,
          label = _props.label,
          maximumLength = _props.maximumLength;

      return _react2.default.createElement(_TextField2.default, {
        autoComplete: "off",
        key: id,
        name: name,
        label: label,
        className: classes.textField,
        placeholder: placeholder,
        helperText: isValid ? description : errorMessages,
        disabled: disabled,
        required: required,
        error: !isValid,
        value: value,
        inputProps: {
          maxlength: maximumLength
        },
        onChange: function onChange(evt) {
          return onFieldChange(id, evt.target.value);
        }
      });
    }
  }]);

  return MaterialUiTextField;
}(_react2.default.Component);

var StyledMaterialUiTextField = (0, _styles.withStyles)(styles)(MaterialUiTextField);

exports.default = function (props) {
  return _react2.default.createElement(
    _index.FieldWrapper,
    props,
    _react2.default.createElement(StyledMaterialUiTextField, null)
  );
};