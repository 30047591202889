"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _FormControl = require("@material-ui/core/FormControl");

var _FormControl2 = _interopRequireDefault(_FormControl);

var _reactDatetime = require("react-datetime");

var _reactDatetime2 = _interopRequireDefault(_reactDatetime);

var _InputLabel = require("@material-ui/core/InputLabel");

var _InputLabel2 = _interopRequireDefault(_InputLabel);

var _index = require("../../../../core/dist/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

require("react-datetime/css/react-datetime.css");
require("./date.css");

var MaterialDate = function (_React$Component) {
  _inherits(MaterialDate, _React$Component);

  function MaterialDate() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, MaterialDate);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = MaterialDate.__proto__ || Object.getPrototypeOf(MaterialDate)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      shrink: false
    }, _this.setFlag = function (flag) {
      _this.setState({ shrink: flag });
    }, _this.getUnit = function (key) {
      switch (key) {
        case "d":
          return "days";
        case "m":
          return "months";
        case "y":
          return "year";
        default:
          break;
      }
    }, _this.getValidDate = function (compareDate) {
      var compareDateArr = compareDate.split("-");
      return _reactDatetime2.default.moment().subtract(compareDateArr[0], _this.getUnit(compareDateArr[1]));
    }, _this.valid = function (current) {
      var _this$props = _this.props,
        minValue = _this$props.minValue,
        maxValue = _this$props.maxValue;

      var validDate1 = void 0,
        validDate2 = void 0;
      if (minValue) {
        validDate1 = _this.getValidDate(minValue);
      }
      if (maxValue) {
        validDate2 = _this.getValidDate(maxValue);
      }

      var flag = void 0;
      if (validDate1 && validDate2) flag = current.isBefore(validDate1) && current.isAfter(validDate2); else if (validDate1) flag = current.isBefore(validDate1); else if (validDate2) flag = current.isAfter(validDate2); else flag = true;
      return flag;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(MaterialDate, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.props.value) {
        this.setState({ shrink: true });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var _props = this.props,
        description = _props.description,
        disabled = _props.disabled,
        errorMessages = _props.errorMessages,
        id = _props.id,
        isValid = _props.isValid,
        name = _props.name,
        onFieldChange = _props.onFieldChange,
        onFieldFocus = _props.onFieldFocus,
        onFieldBlur = _props.onFieldBlur,
        placeholder = _props.placeholder,
        required = _props.required,
        value = _props.value,
        label = _props.label,
        autofocus = _props.autofocus,
        minValue = _props.minValue;

      var viewDate = this.getValidDate(minValue);
      return _react2.default.createElement(
        _FormControl2.default,
        { autoFocus: true },
        _react2.default.createElement(
          _InputLabel2.default,
          { htmlFor: id, shrink: this.state.shrink },
          label + " *"
        ),
        _react2.default.createElement(_reactDatetime2.default, {
          color: "primary",
          timeFormat: false,
          dateFormat: "DD/MM/YYYY",
          inputProps: {
            required: true,
            readOnly: true,
            id: id,
            name: name
          },
          isValidDate: this.valid,
          onChange: function onChange(moment) {
            _this2.setFlag(true);
            return onFieldChange(id, moment.format("DD/MM/YYYY"));
          },
          viewDate: viewDate,
          closeOnSelect: true,
          value: value

        })
      );
    }
  }]);

  return MaterialDate;
}(_react2.default.Component);

exports.default = function (props) {
  return _react2.default.createElement(
    _index.FieldWrapper,
    props,
    _react2.default.createElement(MaterialDate, null)
  );
};