"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Date = require("./components/fields/Date");

var _Date2 = _interopRequireDefault(_Date);

var _FieldText = require("./components/fields/FieldText");

var _FieldText2 = _interopRequireDefault(_FieldText);

var _FieldTextArea = require("./components/fields/FieldTextArea");

var _FieldTextArea2 = _interopRequireDefault(_FieldTextArea);

var _Checkbox = require("./components/fields/Checkbox");

var _Checkbox2 = _interopRequireDefault(_Checkbox);

var _RadioGroup = require("./components/fields/RadioGroup");

var _RadioGroup2 = _interopRequireDefault(_RadioGroup);

var _Select = require("./components/fields/Select");

var _Select2 = _interopRequireDefault(_Select);

var _MultiSelect = require("./components/fields/MultiSelect");

var _MultiSelect2 = _interopRequireDefault(_MultiSelect);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var renderer = function renderer(field, onChange, onFieldFocus, onFieldBlur) {
  var id = field.id,
      type = field.type,
      label = field.label;

  switch (type) {
    case "text":
      return _react2.default.createElement(_FieldText2.default, _extends({ key: id }, field));

    case "textarea":
      return _react2.default.createElement(_FieldTextArea2.default, _extends({ key: id }, field));

    case "checkbox":
      return _react2.default.createElement(_Checkbox2.default, _extends({ key: id }, field));

    case "date":
      return _react2.default.createElement(_Date2.default, _extends({ key: id }, field));

    case "select":
      return _react2.default.createElement(_Select2.default, _extends({ key: id }, field));

    case "multiselect":
      return _react2.default.createElement(_MultiSelect2.default, _extends({ key: id }, field));

    case "radiogroup":
      return _react2.default.createElement(_RadioGroup2.default, _extends({ key: id }, field));

    default:
      return _react2.default.createElement(
        "div",
        null,
        "No mapped field"
      );
  }
};

exports.default = renderer;