import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
// import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Markdown from "react-markdown";
import Accordion from "components/Accordion/Accordion.jsx";
import CustomTable from "components/Table/Table";


const table1 = [
    ['Renewal', 'Lifelong'],
    ['Policy period', '1 year/ 2 years / 3 years'],
    ['Plan variants', 'Gold and Silver'],
    ['Sum Insured', '₹3 lakhs and ₹4 lakhs'],
    ['Co-payment', 'If age of entry is 61 yrs and above 10% to all admissible claims']
]

const table2 = [
    ['1','Applicable for accident and non-cardiac ailments', 'Applicable for accident and non-cardiac ailments'],
    ['2','Applicable for Cardiac Ailments and complications. Cover is available for both surgical intervention and medical management.', 'Applicable for Cardiac Ailments and complications. Cover available only for surgical intervention.'],
    ['3','Outpatient expenses in networked facility', 'Outpatient expenses in networked facility'],
    ['4','Personal Accident: Death only cover equal to chosen sum insured', 'Personal Accident: Death only cover equal to chosen sum insured'],
]

const faq=[
    {
        title:'What is the co-payment percentage in this policy?',
        description:"Policyholders whose age at the time of entry is 61 years and above for fresh and renewal policies. Are required to provide a co-payment of 10% at the inception and on renewal as well",
        tablerows:null
    },
    {
        title:'What is the tax benefits?',
        description:"As per Section 80D of the Income Tax Act of 1961, less than 60 yrs of age INR 25000/- & for more than 60 yrs. 50,000/-",
        tablerows:null
    },
    {
        title:'What are the sum insured options?',
        description:"The sum insured amount ₹ 3, 00,000 or ₹ 4, 00,000. This policy can be purchased for individual coverage only",
        tablerows:null
    },
    {
        title:'What is the free look period of this policy?',
        description:"The policy offers a free-look period of 15 days from the date of receipt of the policy document. However, this feature is not applicable for policy renewals.",
        tablerows:null
    },
] 

const CardiaccareHighlights = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
              <Markdown source={category.description} />
              {category.table && (
                <CustomTable
                  tableHead={category.table.tablerows[0].tablecolumns.map(
                    col => {
                      console.log(`det ${col.details}`);
                      return col.details;
                    }
                  )}
                  tableData={category.table.tablerows.slice(1).map(row => {
                    return row.tablecolumns.map(col => {
                      return col.details;
                    });
                  })}
                />
              )}
            </>
          )
        };
      });
    return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Star Cardiac Care Insurance Policy
            </h2>
            </GridItem>
                <h5>
                Cardiac ailments have been rising at an alarming rate in India. Treatment for cardiac diseases can be expensive. In this ongoing pandemic situation and the surging costs of the healthcare system have made many realize the importance of health insurance for cardiac related ailments. It’s therefore vital to have an insurance plan that financially protects you from the risk of cardiac ailments. For this very purpose, Star Health presents the Star Cardiac Care Insurance Policy, a health insurance plan for individuals who have undergone heart surgery, bypass or stenting procedures.             
                </h5>
                <h2 className={classes.h2hTitle}>
                    What Is Star Cardiac Care Insurance Policy?
                </h2>
                <h5>
                    Cardiac care insurance is one of the kind policies in the industry offering complete cover for cardiac and non-cardiac treatments. It provides health insurance for heart patients and ensures coverage for all their cardiovascular needs. It’s is a combination of cardio vascular and regular hospitalization needs.
                </h5>
                <h5>
                    This helps ease the financial burden of those in such situation of treating repeat heart ailments and provides sufficient expense coverage.
                </h5>
                <h5>
                    This policy also covers multiple claims for various heart conditions. These claims are, however, subject to the sum insured. Cardiac care insurance policy offers surgical, non-surgical treatments and benefits like cover for modern treatments, outpatient care and Personal Accident cover for death due to accident.
                </h5>
                <h2 className={classes.h2hTitle}>
                    Features and Benefits of Star Cardiac Care Insurance Policy
                </h2>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                10 years to 65 years 
                            </TableCell>
                        </TableRow>
                        {table1.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting Periods for non-cardiac ailments
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-48 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
                </Paper>
                <h2 className={classes.h2hTitle}>
                    Eligibility Criteria:
                </h2>
                <h5>
                    The policy is designed to cover people who have undergone cardiac-related ailments and also to treat those recovering from post-surgery. Anyone from the ages of 10 – 65 can purchase a Cardiac Care Insurance Policy.
                </h5>
                <h5>
                    The Star Cardiac Care Insurance policy offers the ideal health insurance for heart patients to cover their expenses if they have previously been diagnosed with cardiovascular disease or have had heart-related surgeries and other procedures. The ailments and procedures that make you eligible for cardiac care insurance include:
                </h5>
                <ul>
                    <li>Undergoing angioplasty (stenting) or by-pass surgery a maximum of 7 years before purchasing the policy</li>
                    <li>Correction of Atrial Septal Defect (ASD) or Ventricular Septal Defect (VSD).</li>
                    <li>Underlying cardiac ailments treated with RF Ablation.</li>
                    <li>Angiography performed with no further medical intervention.</li>
                </ul>
                <h5>
                    Star Cardiac Care offers coverage if you have had any of the mentioned conditions or procedures. Having cardiac ailments puts you at risk of having another heart-related issue. That is why purchasing coverage like Star Cardiac Care insurance is a good choice.
                </h5>
                <h2 className={classes.h2hTitle}>
                    Features and Benefits
                </h2>
                <h3 className={classes.h3textStyle}>
                    Policy Tenure: 
                </h3>
                <h5>
                    The policy tenure can be one year, two years or three years, and the premium can be paid quarterly, half-yearly, annually, biennially, or triennially. This provides flexibility and options for paying your premium. You get two options for the sum insured amount ₹ 3,00,000 or ₹ 4,00,000. This policy can be purchased for individual coverage only.
                </h5>
                <h3 className={classes.h3textStyle}>
                    Coverage: 
                </h3>
                <h5>
                    Section 1: Hospitalisation cover for Accidents and Non-cardiac ailments
                </h5>
                <h5>
                    Section 2: Hospitalisation cover for Cardiac Ailments
                </h5>
                <h5>
                    Section 3: Cover for Outpatient medical expenses
                </h5>
                <h5>
                    Section 4: Personal Accident cover for accident death
                </h5>
                <h3 className={classes.h3textStyle}>
                    Sum insured 
                </h3>
                <h5>
                    The plan provides sum insured options of ₹3 lakhs and ₹4 lakhs
                </h5>
                <h5>
                <span className={classes.h3textStyle}>
                    Renewal: 
                </span>
                     Star Cardiac Care insurance policy has no renewal limit and can be renewed for your lifetime.
                </h5>
                <h3 className={classes.h3textStyle}>
                    Co-payment
                </h3>
                <h5>
                Co-payment is applicable for 61 years and above. This means that every time a claim is filed with the company, the insured will be required to pay a fixed 10% of the admissible claim, and then <b>Star Health</b> Insurance will process the rest of the admissible claim.                
                </h5>
                <h3 className={classes.h3textStyle}>
                    Waiting period for non-cardiac treatments
                </h3>
                <ol>
                    <li>Any illness contracted within 30 days of the policy&apos;s date of inception is not covered. The exception is in case of an accident.</li>
                    <li>Coverage for specified illnesses/ surgeries becomes effective after 24 months.</li>
                    <li>Pre-existing diseases are covered after a waiting period of 48 months from the policy&apos;s date of inception</li>
                </ol>
                <h2 className={classes.h2hTitle}>
                    Coverage under Star Cardiac Care Insurance Policy Gold Plan and Silver Plan
                </h2>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    colSpan={1}
                                    className={classes.SCCtableCellwidth + ` ` + classes.headCell}
                                    style={{width: `50px`}}
                                >
                                    Section
                               </TableCell>
                            <TableCell
                            colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Gold Plan 
                          </TableCell>
                          <TableCell
                          colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Silver Plan
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table2.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.SCCtableCellwidth + ' ' }>
                                    {row[0]}
                                </TableCell>
                                <TableCell colSpan={3} align="center" 
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                                <TableCell colSpan={3} align="center" 
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[2]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth}>
                                Waiting period applicable for cardiac treatments is 90 days only. 
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
                </Paper>
                <h2 className={classes.h2hTitle}>
                Inclusions of Star Cardiac Care Insurance Policy
                </h2>
                <h5>
                    Cardiac insurance provides a wide range of expense coverage for cardiac as well as non-cardiac ailments. The salient feature of this policy is that it covers people who have already had a heart condition or procedure. If you have undergone a heart surgery or a procedure in the last 7 years, it is advisable to purchase a cardiac care policy because you are at greater risk of cardiac ailments.
                </h5>
                <h3 className={classes.h3textStyle}>
                    Hospitalisation expenses for non-cardiac ailment and accidents
                </h3>
                <h5>
                    Star Cardiac Care will cover hospitalisation expenses if the insured person requires to be hospitalised for treatment at the hospital for at least 24 hours during the policy period. The plan will cover expenses incurred towards the following: 
                </h5>
                <ul>
                    <li>Room rent, boarding, and nursing expenses are covered up to Rs.5000 per day. </li>
                    <li>Emergency ambulance charges up to ₹750 per hospitalisation and ₹ 1500 per policy period</li>
                    <li>Pre hospitalisation expenses for a period not exceeding 30 days prior to the date of hospitalisation</li>
                    <li>Post hospitalisation expenses for a period not exceeding 60 days after discharge from hospital. The amount payable shall not exceed the sum equivalent to 7% of the hospitalisation expenses subject to a maximum of ₹ 5000 per hospitalisation. </li>
                </ul>
                <h3 className={classes.h3textStyle}>
                    Daycare treatments/procedures
                </h3>
                <h5>
                    The policy covers expenses for all daycare procedures, surgeries, and treatments that require less than 24 hours of hospitalisation as per the policy terms.
                </h5>
                <h3 className={classes.h3textStyle}>
                Cataract treatment
                </h3>
                <h5>
                The policy covers expenses incurred for cataract treatment up to ₹30,000 entire policy period.                
                </h5>
                <h3 className={classes.h3textStyle}>
                Pre-insurance medical screening
                </h3>
                <h5>
                No pre-insurance medical screening is required. It is enough to submit previous medical records including details of the latest treatment along with the proposal form.                
                </h5>
                <h5>
                <span className={classes.h3textStyle}>Personal Accident coverage:</span> Star Health Cardiac Care Insurance provides a worldwide cover and compensates for accidental death equal to the sum insured opted for. 
                </h5>
                <h3 className={classes.h3textStyle}>
                Modern Treatments covered under the Cardiac Care Insurance Policy
                </h3>
                <h5>
                Some modern treatments are covered under Star Cardiac Insurance. The coverage details are as follows: The limits are mentioned in the Policy Clause.                
                </h5>
                <ul>
                    <li>Uterine artery embolization and HIFU (High intensity focused ultrasound)</li>
                    <li>Balloon sinuplasty</li>
                    <li>Deep brain stimulation</li>
                    <li>Oral chemotherapy</li>
                    <li>Immunotherapies - monoclonal antibody to be given as an injection</li>
                    <li>Intra vitreal injections</li>
                    <li>Robotic surgeries</li>
                    <li>Stereotactic radio surgeries</li>
                    <li>Bronchial Thermoplasty</li>
                    <li>Vaporisation of the prostrate</li>
                    <li>ION M Intra Operative Neuro Monitoring</li>
                    <li>Stem cell therapy</li>
                </ul>
                <h5>
                The following is a partial listing of policy exclusions. A detailed list of all exclusions is included in the policy document
                </h5>
                <h5>
                While Star Health Cardiac Care Policy offers comprehensive coverage for both cardiac and non-cardiac ailments, a few exclusions come under the purview of this policy. These exclusions are as follows:
                </h5>
                <ul>
                    <li>Diagnostic expenses which are not related to the current diagnosis and treatment are excluded</li>
                    <li>Expenses for custodial care and services for terminally ill patients</li>
                    <li>Gender change procedures</li>
                    <li>Cosmetic/plastic surgeries</li>
                    <li>Hospitalization or treatment due to hazardous of adventure sports/activities</li>
                    <li>Expenses for treatment arising out of any criminal activity</li>
                    <li>Expenses for treating alcohol, drugs, and other substance abuse.</li>
                    <li>Expenses for treating infertility</li>
                    <li>Maternity expenses and expenses toward miscarriage (unless due to an accident)</li>
                    <li>Treatment for sexually transmitted diseases</li>
                    <li>Bodily harm or disease arising out of a war or war-like situation</li>
                </ul>
                
                <div>
                <GridItem  style={{ paddingLeft:'0px', paddingTop: '3px' }}>
            <h3 className={classes.h3textStyle}>FREQUENTLY ASKED QUESTIONS:</h3>
            </GridItem>
            </div>
            <div>
     
      <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer
              className={`${classes.container} ${classes.textCenter}`}
            >
              <GridItem
                md={12}
                style={{ paddingLeft:'0px'}}
                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
              ></GridItem>
              <GridItem
               style={{ paddingLeft:'0px'}}
                className={`${classes.mrAuto} ${classes.textCenter}`}
                xs={12}
                sm={12}
                md={12}
              >
                <Accordion active={0} collapses={AccordionArray} />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      </GridItem>
    </GridContainer>
  );
};
CardiaccareHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(CardiaccareHighlights);