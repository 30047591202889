import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
// import { Helmet } from "react-helmet";

const WomencareHighlights = ({ classes }) => {
    return (
        <GridContainer className={classes.containerDisplay}>
      {/* {process.env.ENABLE_HELMET == `true` && (
       <Helmet>
          <script type="application/ld+json">
         {`{ "@context": "http://schema.org",
         "@type": "Product",
         "name": "Star Women Care Insurance Policy",
         "description": "Star Women Care Insurance Policy is a health insurance product that meets the requirements of women in different stages of their lives and addresses women&#x27;s specific needs at affordable premiums.",
         "offers":{ 
          "@type": "AggregateOffer",
          "offerCount": "5%"
        }
      }`}
    </script>
       </Helmet>)} */}
    </GridContainer>
  );
};
WomencareHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(WomencareHighlights);