"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Checkbox = require("@material-ui/core/Checkbox");

var _Checkbox2 = _interopRequireDefault(_Checkbox);

var _FormControlLabel = require("@material-ui/core/FormControlLabel");

var _FormControlLabel2 = _interopRequireDefault(_FormControlLabel);

var _reactFormsProcessor = require("react-forms-processor");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MaterialCheckbox = function (_React$Component) {
  _inherits(MaterialCheckbox, _React$Component);

  function MaterialCheckbox() {
    _classCallCheck(this, MaterialCheckbox);

    return _possibleConstructorReturn(this, (MaterialCheckbox.__proto__ || Object.getPrototypeOf(MaterialCheckbox)).apply(this, arguments));
  }

  _createClass(MaterialCheckbox, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          disabled = _props.disabled,
          id = _props.id,
          isValid = _props.isValid,
          name = _props.name,
          onFieldChange = _props.onFieldChange,
          value = _props.value,
          label = _props.label;

      var stringValue = value ? value.toString() : undefined;
      return (
        //$FlowFixMe
        _react2.default.createElement(_FormControlLabel2.default, {
          control: _react2.default.createElement(_Checkbox2.default, {
            key: id,
            name: name,
            disabled: disabled,
            isInvalid: !isValid,
            value: stringValue,
            checked: value,
            onChange: function onChange(evt) {
              return onFieldChange(id, evt.target.checked);
            }
          }),
          label: label
        })
      );
    }
  }]);

  return MaterialCheckbox;
}(_react2.default.Component);

exports.default = function (props) {
  return _react2.default.createElement(
    _reactFormsProcessor.FieldWrapper,
    props,
    _react2.default.createElement(MaterialCheckbox, null)
  );
};