import React from "react";
// @material-ui/core components
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import wizardStyle from "ps-assets/jss/wizardStyle.jsx";
// import CustomStepper from "ps-components/CustomStepper/CustomStepperGroup";
// import FormGenerator from "ps-components/FormGenerator/FormGenerator";
import CustomInput from "components/CustomInput/CustomInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import { wizardLMSCall } from "util/lmsRequest";
import {  browserWindow } from "util/localStorageHelper";
import Button from "components/CustomButtons/Button";
import loadingGIF from "ps-assets/img/star2.gif";
import GridContainer from "components/Grid/GridContainer";


const initialState = {
    name: ``,
    mobile: ``,
    email: ``,
    formResponse: null,
    productId: null,
    formArr: null,
    activeStep: 0,
    dndConsent: true,
    website: ``,
    industry: ``,
    employees: ``,
    urlInfo: ``,
    policy: false,
    loading: false,
    // product_Interested:'Family Health Optima Insurance Plan',
};

class Extraprotect extends React.Component {
    state = initialState;

    handleInputChange = (e) => {
        if ((e.target.id === `name`) ) {
            if (e.target.value.search(`^[a-zA-Z. ']*$`) === 0) {
              this.setState({ [e.target.id]: e.target.value });
            }
          } else if (e.target.id === `mobile`) {
            if (!isNaN(e.target.value) && e.target.value.length <= 10) {
              this.setState({ [e.target.id]: e.target.value });
            }
          }
          else if (e.target.id === `dndConsent`) {
            this.setState({
              dndConsent:this.state.dndConsent
            })
          } else this.setState({ [e.target.id]: e.target.value });
    };

    handleSubmit = async () => {
        this.setState({
            policy: true
        })
      };

    componentDidMount() {
        let name = ``,
            email = ``,
            mobile = ``,
            firstName = ``,
            formResponse = ``;
        let user = localStorage.getItem(`user`);
        let authToken = ``;
        if (user) {
            user = JSON.parse(user);
            authToken = user.token;
            if (authToken) {
                let logOutToken = localStorage.getItem(`loggedOut${authToken}`);
                if (logOutToken) {
                    authToken = ``;
                }
            }
        }
        let queryParams = decodeURIComponent(browserWindow.location.search);
        if(queryParams){
          queryParams = queryParams.split(`?`)[1];
          queryParams = queryParams.split(`&`);
          queryParams.map(param => {
            let key = param.split(`=`)[0];
            let value = param.split(`=`)[1];
            if (key === `name` && value) {
              let decodedValue = decodeURI(value);
              name = decodedValue;
            }
            else if (key === `mobile` && value) {
              let decodedValue = decodeURI(value);
              mobile = decodedValue;
            }
            else if (key === `email` && value) {
              let decodedValue = decodeURI(value);
              email = decodedValue;
            }
          });
        }
        // var base64Url = authToken.split(`.`)[1];
        // if (base64Url != undefined) {
        //     var decodedValue = JSON.parse(browserWindow.atob(base64Url));
        //     let userObj = decodedValue.jti;
        //     userObj = JSON.parse(userObj);
        //     email = userObj.email;
        //     firstName = userObj.firstName;
        //     mobile = userObj.mobileNo;
        // }
        if(user && user.userDetails){
          email = user.userDetails.email;
          firstName = user.userDetails.firstName;
          mobile = user.userDetails.mobileNo;
        }
        
        this.setState({
            name: firstName ? firstName : name,
            email: email,
            mobile: mobile,
            formResponse: formResponse,
        });
        if(this.state.urlInfo === ``) {
            this.setState({
              urlInfo:browserWindow.location.href
            })
          }
          this.setState({
            productId:this.props.productId,
            name: name,
            email: email,
            mobile: mobile,
          });
    }

    handleNavigation = async () => {
        this.setState({
            loading: true,
        })
        try {
          await wizardLMSCall(
            this.state.name,
                this.state.mobile,
                this.state.email,
                this.props.productName,
                this.props.productId,
                this.state.urlInfo,
                // this.state.product_Interested,
                null,
                null,
          );
        //   response =  response.json();

          // this.setState({
          //     loading: false
          // })
          
        } catch (exception) {
          console.error(`except - `, exception);
          this.setState({
            loading: false
        })
        }
        browserWindow.location.href = `/family-health-optima?name=${this.state.name}&mobile=${this.state.mobile}&email=${this.state.email}&extraprotect=true`
    }

    handleNavigationmci = async () => {
        this.setState({
            loading: true,
        })
        try {
          await wizardLMSCall(
            this.state.name,
                this.state.mobile,
                this.state.email,
                this.props.productName,
                this.props.productId,
                this.state.urlInfo,
                // this.state.product_Interested,
                null,
                null,
          );
        //   response =  response.json();

          // this.setState({
          //     loading: false
          // })
          
        } catch (exception) {
          console.error(`except - `, exception);
          this.setState({
            loading: false
        })
        }
        browserWindow.location.href = `/mediclassic-insurance-policy?name=${this.state.name}&mobile=${this.state.mobile}&email=${this.state.email}&extraprotect=true`
    }

    handleNavigationchi = async () => {
      this.setState({
          loading: true,
      })
      try {
        await wizardLMSCall(
          this.state.name,
              this.state.mobile,
              this.state.email,
              this.props.productName,
              this.props.productId,
              this.state.urlInfo,
              null,
              null,
        );
      } catch (exception) {
        console.error(`except - `, exception);
        this.setState({
          loading: false
      })
      }
      browserWindow.location.href = `/comprehensive-health-insurance-plan?name=${this.state.name}&mobile=${this.state.mobile}&email=${this.state.email}&extraprotect=true`
  }

  handleNavigationyip = async () => {
    this.setState({
        loading: true,
    })
    try {
      await wizardLMSCall(
        this.state.name,
            this.state.mobile,
            this.state.email,
            this.props.productName,
            this.props.productId,
            this.state.urlInfo,
            null,
            null,
      );
    } catch (exception) {
      console.error(`except - `, exception);
      this.setState({
        loading: false
    })
    }
    browserWindow.location.href = `/young-star-insurance?name=${this.state.name}&mobile=${this.state.mobile}&email=${this.state.email}&extraprotect=true`
}

    render() {
        const { classes } = this.props;

        var userDetails = (
        <>
        {!this.state.loading &&(
            <>
            {!this.state.policy && (
            <div style={{marginTop: '17%'}} className={classes.GMCform} >
               <CustomInput
          autoFocus
          labelText='Your Name'
          id='name'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.formControl
          }}
          inputProps={{
            required: true,
            pattern: `^[a-zA-Z. ']*$`
          }}
          error={this.state.name.search(`^[a-zA-Z. ']*$`) !== 0}
          value={this.state.name}
          onChange={this.handleInputChange}
        />
        {this.state.name.search(`^[a-zA-Z. ']*$`) !== 0 && (
          <FormHelperText id='my-helper-text' error>
            Name is not in the required format
          </FormHelperText>
        )}
                <CustomInput
                    labelText="Mobile Number"
                    id="mobile"
                    formControlProps={{
                        fullWidth: true,
                        required: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        required: true,
                        pattern: `[0-9]{10}`,
                        maxLength: `10`,
                        inputMode: `tel`,
                    }}
                    error={
                        this.state.mobile.length != 10 && this.state.mobile.length != 0
                    }
                    value={this.state.mobile}
                    onChange={this.handleInputChange}
                />
                {this.state.mobile.length != 10 && this.state.mobile.length != 0 && (
                    <FormHelperText id="my-helper-text" error>
                        Mobile Number must be 10 digits{` `}
                    </FormHelperText>
                )}
                <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                        required: true,
                        fullWidth: true,
                    }}
                    inputProps={{
                        required: true,
                        pattern: `[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$`,
                        inputMode: `email`,
                    }}
                    onChange={this.handleInputChange}
                    value={this.state.email}
                    error={
                        this.state.email !== `` &&
                        this.state.email.search(/^\S+@\S+\.\S+$/) !== 0
                    }
                />
                {this.state.email !== `` &&
                    this.state.email.search(/^\S+@\S+\.\S+$/) !== 0 && (
                        <FormHelperText id="my-helper-text" error>
                            Please enter valid email
                        </FormHelperText>
                    )}
                    <div className={classes.GMCbutton}>
                    <Button style={{borderRadius: '30px',backgroundColor: '#35488a'}} onClick={this.handleSubmit}>
                    Next
                    </Button>
                </div>
            </div>
            )}
            {this.state.policy && (
                <div className={classes.EPbutton}>
                <Button
                onClick={this.handleNavigationyip}
                style={{borderRadius: '30px',backgroundColor: '#35488a',marginTop: '30%'}}>
                Young Star Insurance Policy
                </Button> 
            </div>
            )}
            </>
        )}
        {this.state.loading &&(
            <GridContainer>
            <div style={{ margin: `0 auto`,marginTop: '20%' }} className={classes.loadingGIF}>
              <img src={loadingGIF} />
            </div>
          </GridContainer>
        )}
        </>
            );

        return (
            <>
                <span key={`customStepper`}>
                    {userDetails}
                </span>
            </>
        );
    }
}
Extraprotect.propTypes = {
    ctaLabel: PropTypes.string,
    classes: PropTypes.object,
    buyNowUrl: PropTypes.string,
    formArr: PropTypes.object,
    query: PropTypes.string,
    planType: PropTypes.string,
    productId: PropTypes.number,
    type: PropTypes.array,
    productName: PropTypes.string,
};
export default withStyles(wizardStyle)(Extraprotect);