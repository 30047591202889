import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
// import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Accordion from "components/Accordion/Accordion.jsx";    

const CriticalillnessBenefits = ({ classes }) => {
    return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
             
        <div>
        <Paper className={classes.root} style={{marginTop: '15px'}}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    colSpan={1}
                                    className={classes.SCIMPtableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                   List of covered Major Diseases
                               </TableCell>
                            <TableCell
                                colSpan={1}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Group Number or category
                          </TableCell>
                          <TableCell
                                colSpan={1}
                                align="center"
                                className={classes.SCIMPtableCellwidth1 + ` ` + classes.headCell}
                            >
                                Disease Group
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                                <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                                1. Cancer of Specified Severity 
                                </TableCell>
                                <TableCell align="center" rowSpan={3} colSpan={1} className={classes.SCIMPtableCellcentercustomWidth}>
                                1
                                </TableCell>
                                <TableCell align="center" rowSpan={3} colSpan={2} className={classes.SCIMPtableCellcentercustomWidth1}>
                                Cancer related
                                </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                                2. Bone Marrow Transplantation
                                </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                                3. Aplastic Anaemia 
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                                4. Myocardial Infarction 
                                </TableCell>
                                <TableCell align="center" rowSpan={9} colSpan={1} className={classes.SCIMPtableCellcentercustomWidth}>
                                2
                                </TableCell>
                                <TableCell align="center" rowSpan={9} colSpan={2} className={classes.SCIMPtableCellcentercustomWidth1}>
                                Heart related conditions
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            5. Open Chest CABG
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            6. Open Heart Replacement or Repair of Heart Valves
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            7. Primary (Idiopathic) Pulmonary Hypertension 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            8. Heart Transplantation 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            9. Surgery of Aorta
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            10. Dissecting Aortic Aneurysm 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            11. Other Serious Coronary Artery Disease 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            12. Cardiomyopathy of Specified Severity
                            </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                                13. Coma of Specified Severity 
                                </TableCell>
                                <TableCell align="center" rowSpan={16} colSpan={1} className={classes.SCIMPtableCellcentercustomWidth}>
                                3
                                </TableCell>
                                <TableCell align="center" rowSpan={16} colSpan={2} className={classes.SCIMPtableCellcentercustomWidth1}>
                                Brain & Nervous System related Conditions 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            14. Stroke Resulting in Permanent Symptoms 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            15. Permanent Paralysis of Limbs 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            16. Motor Neuron Disease with Permanent Symptoms 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            17. Multiple Sclerosis with Persisting Symptoms 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            18. Benign Brain Tumour 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            19. Loss of Speech 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            20. Major Head Trauma 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            21. Alzheimer’s Disease 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            22. Creutzfeldt-Jacob Disease (CJD) 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            23. Encephalitis 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            24. Muscular Dystrophy 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            25. Bacterial Meningitis 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            26. Brain Surgery 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            27. Progressive Supranuclear Palsy 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            28. Apallic Syndrome
                            </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                                29. Major Organ Transplantation (of lung, liver, kidney, pancreas)
                                </TableCell>
                                <TableCell align="center" rowSpan={9} colSpan={1} className={classes.SCIMPtableCellcentercustomWidth}>
                                4
                                </TableCell>
                                <TableCell align="center" rowSpan={9} colSpan={2} className={classes.SCIMPtableCellcentercustomWidth1}>
                                Major Organ & Other Conditions
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            30  Kidney Failure Requiring Regular Dialysis 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            31. Blindness 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            32. Deafness 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            33. End Stage Liver Failure
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            34. Third-Degree Burns 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            35. Fulminant Hepatitis 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            36. Systemic Lupus Erythematous with Lupus Nephritis 
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" colSpan={1} className={classes.tableCellLeftcustomWidth}>
                            37. End Stage Lung Failure
                            </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>
      </GridItem>
      <h5 style={{marginTop:'-15px'}} >2. Star <b>Wellness Program</b>: This program intends to promote, incentivize and to reward the Insured Persons’ healthy life style through various wellness activities. </h5>
        <h5><b>Special Conditions:</b></h5>
        <ul>
            <li>First diagnosis of the covered major disease should be only after 90 days of commencement of cover under this policy.</li>
            <li>Survival Period of 15 Days is applicable from date of diagnosis of such major disease.</li>
            <li>12 months waiting period shall apply between the occurence of each condition.</li>
        </ul>
        <h5><b>Exclusions:</b></h5>
        <h5>Please refer to policy terms and conditions for complete information about exclusions</h5>
        <h5><b>Instalment Facility:</b> Premium can be paid Quarterly and Half Yearly. Premium can also be paid Annually, Biennial (once in 2 years) and Triennial (once in 3 years)</h5>
    </GridContainer>
  );
};
CriticalillnessBenefits.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(CriticalillnessBenefits);