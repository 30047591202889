import {
  container,
  main,
  mainRaised,
  title,
  cardTitle,
  whiteColor,
  section,
  mrAuto,
  mlAuto,
  primaryColor,
  // grayColor
} from "assets/jss/material-kit-pro-react.jsx";

import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";

const productListStyle = (theme) => {
  return {
    ...customSelectStyle,
  // container: {
  //   color: whiteColor,
  //   ...container,
  //   zIndex: `2`
  // },
  formMargin: { 
    marginTop: `7px !important`,
    marginBottom: `15px !important`
  },
  Icon: {
    "& svg": {
      width: `100%`,
      height: `100%`,
      "& path": {
        fill: primaryColor[0]
      }
      // [theme.breakpoints.down(`xs`)]:{
      //   "& path": {
      //     fill: whiteColor
      //   },
      // }
    }
  },
  // title: {
  //   ...title,
  //   display: `inline-block`,
  //   position: `relative`,
  //   marginTop: `30px`,
  //   minHeight: `32px`,
  //   fontSize: `3rem`,
  //   textDecoration: `none`,
  //   "@media (min-width: 767px) and (max-width: 768px)": {
  //     color: whiteColor
  //   },
  //   [theme.breakpoints.down(`xs`)]: {
  //     fontSize: `2rem`,
  //     // color: "black",
  //     // marginTop: `40px !important`,
  //     marginBottom: `0px !important`
  //   }
  // },
  // summary: {
  //   color: grayColor[1],
  //   maxWidth: `600px`,
  //   "@media (min-width: 767px) and (max-width: 768px)": {
  //     color: whiteColor
  //   },
  //   [theme.breakpoints.down(`xs`)]: {
  //     fontSize: `1.1rem`
  //   }
  // },
  bannerPara: {
    fontSize:"25px",
    marginTop:"20px",
    "@media (max-width:600px)": {
      fontSize: '18px',
      marginTop: '10px'
    }
  },
  subtitle: {
    fontSsocialize: `1.313rem`,
    maxWidth: `500px`,
    margin: `10px auto 0`
  },
  // block: {
  //   color: `inherit`,
  //   padding: `0.9375rem`,
  //   fontWeight: `500`,
  //   fontSize: `12px`,
  //   textTransform: `uppercase`,
  //   borderRadius: `3px`,
  //   textDecoration: `none`,
  //   position: `relative`,
  //   display: `block`
  // },
  inlineBlock: {
    display: `inline-block`,
    padding: `0px`,
    width: `auto`
  },
  // list: {
  //   marginBottom: `0`,
  //   padding: `0`,
  //   marginTop: `0`
  // },
  // left: {
  //   float: `left!important`,
  //   display: `block`
  // },
  // right: {
  //   padding: `15px 0`,
  //   margin: `0`,
  //   float: `right`
  // },
  // icon: {
  //   width: `18px`,
  //   height: `18px`,
  //   top: `3px`,
  //   position: `relative`
  // },
  textLeft: {
    textAlign: `left`
  },
  pageHeader: {
    position: `relative`,
    height: `100vh`,
    maxHeight: `1600px`,
    backgroundPosition: `50%`,
    backgroundSize: `cover`,
    backgroundColor: `rgba(0,0,0,0.2)`,
    margin: `0`,
    padding: `0`,
    border: `0`,
    display: `flex`,
    paddingTop: `20px`,
    paddingLeft: `20px`,
    transition: `all 1s ease-in-out`,
    marginTop: `2%`,
    "&:hover": {
      backgroundColor: `transparent`
    },
    "@media (min-width: 767px) and (max-width: 768px)": {
      "&:hover": {
        backgroundColor: `rgba(0,0,0,0.2)`
      }
    },
    "@media (max-width: 1024px)": {
      backgroundPosition: `100%`,
      height: `auto !important`
    },
    "& > div:first-of-type": {
      width: `100% !important`
    },
    [theme.breakpoints.down(`md`)]: {
      marginTop: `7%`
    },
    [theme.breakpoints.down(`sm`)]: {
      paddingRight: `20px`,
      height: `auto !important`
    }
  },
  noBorder: {
    "&, &:hover": {
      borderWidth: `1px 0px 1px 0`,
      borderStyle: `solid`,
      borderColor: primaryColor[0],
      color: primaryColor[0],
      borderRadius: `0px 5px 5px 0px`,
      "@media (max-width:600px)": {
        borderWidth: `1px 1px 1px 1px`,
        borderStyle: `solid`,
        borderColor: primaryColor[0],
        color: primaryColor[0],
        borderRadius: `5px 5px 5px 5px`
      }
    }
  },
  allBorder:{
    "&, &:hover": {
      borderWidth: `1px 1px 1px 1px`,
      borderStyle: `solid`,
      borderColor: primaryColor[0],
      color: primaryColor[0],
      borderRadius: `0px 5px 5px 0px`,
      "@media (max-width:600px)": {
        borderWidth: `1px 1px 1px 1px`,
        borderStyle: `solid`,
        borderColor: primaryColor[0],
        color: primaryColor[0],
        borderRadius: `5px 5px 5px 5px`
      }
    }
  },
  noLeftBorder: {
    "&, &:hover": {
      borderWidth: `1px 1px 1px 0`,
      borderStyle: `solid`,
      borderColor: primaryColor[0],
      color: primaryColor[0],
      borderRadius: `0px 5px 5px 0px`
    }
  },
  noRightBorder: {
    "&, &:hover": {
      borderWidth: `1px 0 1px 1px`,
      borderStyle: `solid`,
      borderColor: primaryColor[0],
      color: primaryColor[0],
      borderRadius: `5px 0px 0px 5px`
    }
  },
  whiteBorder: {
    borderWidth: `1px`,
    borderStyle: `solid`,
    borderColor: `#FFFFFF`,
    color: `#FFFFFF`,
    width: `100%`
  },
  primaryBorder: {
    borderWidth: `1px`,
    borderStyle: `solid`,
    borderColor: primaryColor[0],
    color: primaryColor[0],
    "&:hover": {
      color: primaryColor[0]
    }
    // borderRadius: `5px 0px 0px 5px`
  },
  buttonPadding: {
    [theme.breakpoints.up(`sm`)]: {
      [theme.breakpoints.down(`md`)]: {
        padding: `0`
      }
    }
  },
  buttonMargin: {
    [theme.breakpoints.up(`lg`)]: {
      marginLeft: `-28px`
    },
  },
  portButtonMargin: {
    [theme.breakpoints.up(`lg`)]: {
      marginLeft: `-58px`
    }
  },
  cardStyle: {
    boxShadow: `none !important`,
    border: `1px solid #eee`,
    "@media (max-width : 470px)": {
      marginBottom: '50px !important',
    },
    "@media (max-width : 370px)": {
      marginBottom: '40px !important',
    },

  },
  leftSectionsBackground: {
    backgroundPosition: `-6%`,
    [theme.breakpoints.down(`sm`)]: {
      backgroundPosition: `-16%`
    },
    [theme.breakpoints.down(`xs`)]: {
      backgroundPosition: `-96%`
    }
  },
  rightSectionsBackground: {
    backgroundPosition: `106%`,
    [theme.breakpoints.down(`sm`)]: {
      backgroundPosition: `116%`
    },
    [theme.breakpoints.down(`xs`)]: {
      backgroundPosition: `196%`
    }
  },
  bannerText: {
    maxWidth: `700px`,
    marginTop:'0px'
  },
  exceptCorona: {
    marginTop:'28px', 
    lineHeight:'55px',
    "@media (max-width:600px)": {
      lineHeight: '40px'
    }
  },
  coronaTitle: {
    fontSize:"65px", 
    marginTop:"28px",
    marginBottom:'0px',
    "@media (max-width:600px)": {
      fontSize: `2rem`,
      marginTop:"0px",
    },
    "@media (max-width: 768px)": {
      marginTop:"0px"
      }
  },
  coronaBannerText: {
    marginTop: `8%`,
    maxWidth: `52%`,
    fontSize: `36px`,
    "@media (max-width:736px)": {
      maxWidth: `700px`
    }
  },
  coronaContent: {
    fontSize:"22px", 
    lineHeight:"30px",
    fontWeight:"150", 
    marginTop:"2px"
  },
  knowMoreButton: {
    marginTop:'40px',
    "@media (max-width:600px)": {
      marginTop:"3px",
    },
    "@media (max-width: 768px)": {
      marginTop:"3px"
      }
  },
  portPolicyButton:{
    [theme.breakpoints.down(`md`)]:{
      marginLeft:`auto`,
      marginRight:`auto`
    }
  },
  hidden:{
    display: `block`
  },

  //
    main,
    mainRaised,
    mrAuto,
    mlAuto,
    title,
    cardTitle,
    pageTitle: {
      ...title,
      textAlign: `center`,
      marginTop: `0px`,
      fontSize:`36px`
    },
    summary: {
      textAlign: `center`
    },
    twoColumnSpacing: {
      width: `50%`
    },
    textLeftfix: {
      textAlign: `left`
    },
    premiumChart: {
      ...mrAuto,
      ...mlAuto,
      width: `70%`
    },
    premiumChartText: {
      ...mrAuto,
      ...mlAuto,
      width: `62%`,
      marginTop: `2%`
    },
    textBoldfix: {
      fontWeight: `bold`
    },
    primary: {
      color: primaryColor[0]
    },
    customTitle: {
      ...cardTitle
    },
    textCenter: {
      textAlign: `center !important`
    },
    container: {
      ...container,
      zIndex: `2`
    },
    customCarousel: {
      backgroundColor: primaryColor[0],
      height: `100%`
    },
    brand: {
      "& h2, & h4": {
        color: whiteColor
      }
    },
    link: {
      textDecoration: `none`
    },
    description: {
      color: `#3c4858`,
      fontWeight: `300`,
      textAlign: `center`
    },
    section: {
      ...section,
      padding: `70px 0px`,
      "& h4$description": {
        fontSize: `1.5em`
      }
    },
    list: {
      marginBottom: `0`,
      padding: `0`,
      marginTop: `0`
    },
    // inlineBlock: {
    //   display: `inline-block`,
    //   padding: `0px`,
    //   width: `auto`
    // },
    mobileNo:{
      width: '99% !important',
      paddingLeft: '6% !important',
      paddingRight: '6% !important',
      textAlign: 'center !important',
      fontSize: '25px  !important',
      "@media (max-width: 400px)": {
        paddingLeft: '7% !important',
        paddingRight: '7% !important',
        textAlign: 'center !important',
        fontSize: '22px !important',
      },
      "@media (max-width: 350px)": {
        paddingLeft: '8% !important',
        paddingRight: '8% !important',
        textAlign: 'center !important',
        fontSize: '18px !important',
      },
    },
    policy_quote: {
      ...cardTitle,
      textAlign: `center`,
      paddingBottom: `15px`,
      marginTop: `0px !important`,
      fontFamily:'"Roboto Slab", "Times New Roman", serif',
      fontWeight: '700',
    color: '#35488a'
    },
    CoveringStyle:{
    fontFamily:'Roboto,Helvetica,Arial,sans-serif',
        fontWeight:'400',color:'#35488a'
    },
    FormPosition:{
      paddingLeft:'10%',
      paddingBottom:'2%',
      "@media (max-width: 400px)": {
        paddingLeft:'1%',
      paddingBottom:'2%',
      },
    },
    ctaText: {
      fontWeight: `500`,
      fontSize: `2.25rem`,
      fontFamily: `Roboto Slab`,
      [theme.breakpoints.down(`md`)]: {
        fontSize: `1.5rem`,
        "& button": {
          padding: `0.40625rem 1.25rem`,
          fontSize: `0.6875rem`
        }
      }
    },
    responseHelperText: {
      fontFamily: `Roboto Slab`,
      color: primaryColor[0]
    },
    left: {
      float: `left!important`,
      display: `block`
    },
    right: {
      padding: `15px 0`,
      margin: `0`,
      float: `right`
    },
    sectionTitle: {
      color: `#3C4858`
    },
    aClass: {
      textDecoration: `none`,
      backgroundColor: `transparent`
    },
    block: {
      color: `inherit`,
      padding: `0.9375rem`,
      fontWeight: `500`,
      fontSize: `12px`,
      textTransform: `uppercase`,
      borderRadius: `3px`,
      textDecoration: `none`,
      position: `relative`,
      display: `block`
    },
    card: {
      boxShadow: `none`,
      "&:hover": {
        boxShadow: `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4)`
      }
    },
    icon: {
      marginTop: `30px`,
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: `40px`,
        lineHeight: `40px`
      },
      "& svg": {
        width: `40px`,
        height: `40px`
      }
    },
    customList: {
      margin: `0px 30px 0px !important`,
      "& span": {
        paddingRight: `15px`,
        margin: 0
      },
      "& th": {
        "& span": {
          paddingRight: `0px`,
          margin: 0
        }
      },
      "& div": {
        lineHeight: `2`,
        display: `flex !important`,
        flexWrap: `nowrap !important`,
        flexDirection: `column`
      },
      "@media (max-width: 576px)": {
        marginTop: `-30px`
      },
      "@media (max-width: 830px)": {
        marginLeft: `10px !important`,
        marginRight: `10px !important`
      }
    },
    customListFlexFree: {
      margin: `0px 30px 0px !important`,
      "& span": {
        paddingRight: `15px`,
        margin: 0
      },
      "& th": {
        "& span": {
          paddingRight: `0px`,
          margin: 0
        }
      },
      "& div": {
        lineHeight: `2`,
        flexWrap: `nowrap !important`,
        flexDirection: `column`
      },
      "@media (max-width: 576px)": {
        marginTop: `-30px`
      },
      "@media (max-width: 830px)": {
        marginLeft: `10px !important`,
        marginRight: `10px !important`
      }
    },
    flexItem: {
      display: `flex`,
      flexDirection: `row !important`,
      "& p": {
        fontSize: `16px !important`
      }
    },
    cartImage: {
      position: `relative`,
      backgroundColor: primaryColor[0],
      height: `85vh`,
      padding: `40px 40px 40px 40px`,
      borderTopLeftRadius: `5px`,
      borderBottomLeftRadius: `5px`
    },
    socialIcon: {
      "& path": {
        fill: whiteColor
      }
    },
    choice: {
      backgroundColor: whiteColor,
      color: primaryColor[0],
      cursor: `pointer`,
      padding: `10px`,
      fontWeight: `500`,
      textAlign: `center`,
      border: `solid 1px ` + primaryColor[0],
      borderRadius: `6px`,
      fontSize: `14px`,
      transition: `all 0.5s ease-in`,
      "&:hover": {
        backgroundColor: primaryColor[0],
        color: whiteColor
      }
    },
    markdownContainer:{
      "& td":{
        width:`33%`
      }
    },
    emiLabel:{
      width: `100px`,
      height: `100px`,
      position: `absolute`,
      alignSelf: `flex-start`,
      top:`-6%`,
      left:`-2%`,
      zIndex:`100000`,
      [theme.breakpoints.down(`sm`)]:{
        left:`-1%`
      },
      [theme.breakpoints.down(`xs`)]:{
        left:`-2.5%`
      }
    },
    headerStyle:{
      fontSize: '36px',
      color: '#3C4858',
      fontWeight: '700',
      marginLeft: '3%',
      marginTop: '3%',
    },
    parastyle:{
      fontSize:'18px',
      color: '#3C4858',
      width: '460px',
      marginTop: '8%',
      marginLeft: '-27.5%',
      "@media (max-width : 1070px)": {
        marginLeft: '-36.5% !important',
      },
      "@media (max-width : 930px)": {
        marginLeft: '-50.5% !important',
      },
      "@media (max-width : 770px)": {
        marginLeft: '4.5% !important',
        marginTop: '2% !important'
      },
      "@media (max-width : 470px)": {
        marginLeft: '5% !important',
      },
      "@media (max-width : 370px)": {
        marginLeft: '5% !important',
      },
    },
    buynow:{
      marginTop: '22%',
      marginLeft: '-35%',
      "@media (max-width : 1050px)": {
        marginLeft: '-49% !important',
      },
      "@media (max-width : 770px)": {
        marginLeft: '-62% !important',
      },
      "@media (max-width : 600px)": {
        marginLeft: '0% !important',
        marginTop: '1% !important'
      },
      "@media (max-width : 470px)": {
        marginLeft: '0% !important',
        marginTop: '1% !important'
      },
      "@media (max-width : 370px)": {
        marginLeft: '0% !important',
        marginTop: '5% !important'
      },
    },
    portstyle:{
      marginTop: '22%',
      marginLeft: '-15px',
      "@media (max-width : 1050px)": {
        marginLeft: '0% !important',
      },
      "@media (max-width : 600px)": {
        marginTop: '-2% !important',
      },
      "@media (max-width : 370px)": {
        marginTop: '-3% !important',
      },
    },
    insurestyle:{
      "@media (max-width : 1050px)": {
        width: '125% !important',
      },
      "@media (max-width : 770px)": {
        width: '100% !important',
      },
    },
    insurestyle1:{
      "@media (max-width : 1050px)": {
        marginLeft: '-2px !important',
      },
      "@media (max-width : 770px)": {
        marginLeft: '0x !important',
      },
    },
    cardmargin:{
      "@media (max-width : 470px)": {
        marginTop: '-2% !important',
      },
      "@media (max-width : 370px)": {
        marginTop: '-7% !important',
      },
    },
    parastyleparentspage:{
      fontSize:'18px',
      color: '#3C4858',
      width: '460px',
      marginTop: '8%',
      marginLeft: '-27.5%',
      "@media (max-width : 1200px)": {
        marginLeft: '3% !important',
      },
      "@media (max-width : 930px)": {
        marginLeft: '4.5% !important',
        marginTop: '2% !important'
      },
      "@media (max-width : 570px)": {
        marginLeft: '3.5% !important',
      },
      "@media (max-width : 470px)": {
        marginLeft: '5% !important',
      },
      "@media (max-width : 370px)": {
        marginLeft: '5% !important',
      },
    },
    buynowparentspage:{
      marginTop: '22%',
      marginLeft: '-35%',
      "@media (max-width : 1050px)": {
        marginLeft: '-49% !important',
      },
      "@media (max-width : 770px)": {
        marginLeft: '-62% !important',
      },
      "@media (max-width : 600px)": {
        marginLeft: '-0% !important',
        marginTop: '1% !important',
        marginBottom: '0% !important'
      },
      "@media (max-width : 470px)": {
        marginLeft: '0% !important',
        marginTop: '1% !important'
      },
      "@media (max-width : 370px)": {
        marginLeft: '0% !important',
        marginTop: '5% !important'
      },
    },
    portstylepage:{
      marginTop: '22%',
      marginLeft: '20px',
      "@media (max-width : 1050px)": {
        marginLeft: '0% !important',
      },
      "@media (max-width : 600px)": {
        marginTop: '-2% !important',
      },
      "@media (max-width : 370px)": {
        marginTop: '-3% !important',
      },
    },
    portstyleparentspage:{
      marginTop: '22%',
      marginLeft: '-15px',
      "@media (max-width : 1050px)": {
        marginLeft: '0% !important',
      },
      "@media (max-width : 600px)": {
        marginTop: '-2% !important',
      },
      "@media (max-width : 370px)": {
        marginTop: '-3% !important',
      },
    },
    

  };
};

export default productListStyle;
