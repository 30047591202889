import React from "react";
// import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
// import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
// import Table from "@material-ui/core/Table";
// // import { Helmet } from "react-helmet";
// import Paper from "@material-ui/core/Paper";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import { Helmet } from "react-helmet";


// const table1 = [
//     ['Renewal', 'Lifelong'],
//     ['Policy period', '1 year'],
//     ['Sum Insured', '3 lakhs to 1 Crore '],
//     ['Discounts', <h5 key="renewal_premium2">
//         <h5 key="renewal_premium">2% - 10% - on Renewal Premium under Wellness Rewards Program</h5>
//         <h5 key="renewal_premium1">5% - for purchase of policy online</h5>
//     </h5>],
// ]

// const table2 = [
//     ['In-patient Hospitalisation', 'Up to S.I and bonus if any','Covers all in-patient hospitalisation expenses if one or more insured persons are required to be hospitalised for treatment for at least 24 hours during the policy period.'],
//     ['Premium Discounts', 'Available','Be in your pink of health and save on premiums with discounts earned through Star Wellness Programme.'],
//     ['Daycare Treatments/Procedures', 'Covered','Covers all daycare procedures, surgeries, and treatments that require less than 24 hours of hospitalisation.'],
//     ['Delivery Expenses', 'Covered','Covers expenses for delivery, including c- section after a waiting period of 12 months to a maximum of 2 deliveries, provided both husband and wife are covered in the policy.'],
//     ['Modern Treatment', 'Covered','Twelve modern treatments, including balloon sinuplasty, immunotherapy, and stem cell therapy, are covered. Up to sub limits'],
//     ['Hospital Cash', 'Covered','Hospital Cash Benefit is payable for each completed day of hospitalisation for a maximum of 7 days per occurrence if an eligible hospitalisation claim is submitted.'],
//     ['RTA (Road Traffic Accident)', 'Covered','Additional cover provided for RTA on the condition that the insured rider/pillion rider wears a helmet and travelled by a two-wheeler.'],
//     ]
        

const YoungstarHighlights = ({ classes }) => {
    return (
        <GridContainer className={classes.containerDisplay}>
          
            {/* <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Young Star Health Insurance Plan
            </h2>
            </GridItem>
                <h5>
                India has one of the largest populations of youngsters globally. Indian millennials are the backbone of the economic development of the country. Hence, the nation`s youth should be vibrant and energetic. With the Young Star Health Insurance Plan, you can make the best of your youthful years. This plan has been specially tailor-made for young individuals under the age of 40 who want to lead a healthy lifestyle for themselves and their families. You can get this health insurance as an individual plan or as a floater plan.
             </h5>

             <h5>
             This plan comes with a wide range of benefits such as incentive-led wellness programs, discount on renewals, the lowest waiting periods, coverage for pre and post-hospitalisation expenses, no-claim bonus, hospital cash benefits, annual check-ups, automatic restoration of the sum insured and additional basic sum insured for road traffic accidents.
             </h5> */}
        {/* <div>
        <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={2}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                18 years to 40 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Dependent Children – 3 months to 25 years
                            </TableCell>
                        </TableRow>
                        {table1.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting Periods
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-12 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 12 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>
        <div style={{marginTop:'5%'}}>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Benefits
                                  </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Coverage limit
                                </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                >
                                   Description of cover
                         </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {table2.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellwidth}>
                                    {row[1]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellwidth}>
                                    {row[2]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                        </Table>
                </Paper>
                </div> */}
      {/* </GridItem> */}
      {/* {process.env.ENABLE_HELMET == `true` && (
    <Helmet>
        <script type="application/ld+json">
{`{ "@context": "http://schema.org",
  "@type": "Product",
  "name": "Young Star Insurance Policy",
  "description": "The Young Star Policy is available to people between 18 years and 40 years. It is available on floater and individual basis. The sum insured starts from 3 lakhs and goes up to 1 crore. The policy provides an all-round cover right from a young age and comes loaded with benefits.",
"offers":{ 
          "@type": "AggregateOffer",
          "offerCount": "5%"
        }
}`}
</script>
</Helmet>)}  */}
    </GridContainer>
  );
};
YoungstarHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(YoungstarHighlights);