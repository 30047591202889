"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _select = require("@atlaskit/select");

var _select2 = _interopRequireDefault(_select);

var _reactFormsProcessor = require("react-forms-processor");

var _form = require("@atlaskit/form");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var AtlaskitSelect = function (_React$Component) {
  _inherits(AtlaskitSelect, _React$Component);

  function AtlaskitSelect() {
    _classCallCheck(this, AtlaskitSelect);

    return _possibleConstructorReturn(this, (AtlaskitSelect.__proto__ || Object.getPrototypeOf(AtlaskitSelect)).apply(this, arguments));
  }

  _createClass(AtlaskitSelect, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          description = _props.description,
          disabled = _props.disabled,
          errorMessages = _props.errorMessages,
          id = _props.id,
          isValid = _props.isValid,
          name = _props.name,
          _props$options = _props.options,
          options = _props$options === undefined ? [] : _props$options,
          placeholder = _props.placeholder,
          required = _props.required,
          value = _props.value,
          label = _props.label,
          onFieldChange = _props.onFieldChange,
          onFieldFocus = _props.onFieldFocus,
          onFieldBlur = _props.onFieldBlur,
          touched = _props.touched,
          validWhen = _props.validWhen,
          requiredWhen = _props.requiredWhen,
          autofocus = _props.autofocus,
          shouldFitContainer = _props.shouldFitContainer;

      var defaultValue = [];
      var stringValue = value ? value.toString() : undefined;
      var items = options.map(function (option) {
        var heading = option.heading,
            _option$items = option.items,
            items = _option$items === undefined ? [] : _option$items;

        return {
          label: heading,
          options: items.map(function (item) {
            if (typeof item === "string") {
              var isSelected = false;
              if (value && Array.isArray(value) && value.includes(item)) {
                isSelected = true;
              }
              var _item = {
                label: item,
                value: item
              };
              if (isSelected) {
                defaultValue.push(_item);
              }
              return _item;
            } else {
              var _isSelected = false;
              if (value && Array.isArray(value) && value.includes(item.value)) {
                _isSelected = true;
              }
              var _item2 = {
                label: item.label || item.value,
                value: item.value
              };
              if (_isSelected) {
                defaultValue.push(_item2);
              }
              return _item2;
            }
          })
        };
      });

      // We only want to show validation state if there are validation rules for the select field
      var needsValidation = validWhen && Object.keys(validWhen).length || requiredWhen && requiredWhen.length || required;
      var isInvalid = touched && needsValidation && !isValid;

      return _react2.default.createElement(
        _form.Field,
        {
          name: name,
          label: label,
          helperText: description,
          isRequired: required,
          isInvalid: isInvalid,
          invalidMessage: errorMessages
        },
        function (_ref) {
          var fieldProps = _ref.fieldProps;
          return _react2.default.createElement(
            _react2.default.Fragment,
            null,
            _react2.default.createElement(_select2.default, _extends({}, fieldProps, {
              isMulti: true,
              isSearchable: false,
              name: name,
              defaultValue: defaultValue,
              placeholder: placeholder,
              isDisabled: disabled,
              options: items,
              onChange: function onChange(value) {
                onFieldChange(id, value.map(function (item) {
                  return item.value;
                }));
              },
              onFocus: function onFocus() {
                return onFieldFocus(id);
              },
              onBlur: function onBlur() {
                onFieldFocus(id);
              },
              autoFocus: autofocus,
              isInvalid: isInvalid,
              shouldFitContainer: shouldFitContainer
            })),
            isInvalid && _react2.default.createElement(
              _form.ErrorMessage,
              null,
              errorMessages
            )
          );
        }
      );
    }
  }]);

  return AtlaskitSelect;
}(_react2.default.Component);

exports.default = function (props) {
  return _react2.default.createElement(
    _reactFormsProcessor.FieldWrapper,
    props,
    _react2.default.createElement(AtlaskitSelect, null)
  );
};