import React from "react";
// nodejs library that concatenates classes
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
// import Button from "components/CustomButtons/Button";
// import { Link } from "gatsby";

class InstalmentFacility extends React.Component {
// const InstalmentFacility = (classes, props, productId) => {
    render() {
        // let {productId, redirectUrl } = this.props;
        // console.log(productId, redirectUrl)
        let props = this.props
        
// console.log("installment",props)
    return ( 
        <GridContainer>
            <div>
                    {props && props.productId && (props.productId == "1" || props.productId == "3" || props.productId == "26" || props.productId == "37" || props.productId == "34") &&
                    <h5 style={{marginLeft: '17px', marginBottom: '-19px'}} ><b>Instalment Facility:</b> Premium can be paid <u><a target="blank" href={`/customerportal/${props && props.redirectUrl}&paymentOption=Quarterly`}>Quarterly</a></u> and <u><a target="blank" href={`/customerportal/${props.redirectUrl}&paymentOption=HalfYearly`}>Half Yearly</a></u>. Premium can also be paid Annually.
                    </h5>}

                    {props && props.productId && (props.productId == "4" || props.productId == "7" || props.productId == "11" || props.productId == "33" || props.productId == "22") &&
                    <h5 style={{marginLeft: '17px', marginBottom: '-19px'}} ><b>Instalment Facility:</b> Premium can be paid <u><a target="blank" href={`/customerportal/${props && props.redirectUrl}&paymentOption=Quarterly`}>Quarterly</a></u> and <u><a target="blank" href={`/customerportal/${props.redirectUrl}&paymentOption=HalfYearly`}>Half Yearly</a></u>. Premium can also be paid Annually, Biennial (once in 2 years) and Triennial (once in 3 years).
                    </h5>}

                    {props && props.productId && props.productId == "2" &&<h5>
                    <h5 style={{marginLeft: '17px', marginBottom: '-19px'}} ><b>Instalment Facility:</b> Premium can be paid  <u><a target="blank" href={`/customerportal/${props && props.redirectUrl}&paymentOption=HalfYearly`}>Half Yearly</a></u>.
                    <h5>Premium can also be paid Annually, Biennial (once in 2 years) and Triennial (once in 3 years).</h5>
                    </h5>
                    <br/>
                    <h5 style={{marginLeft: '16px'}} >Note: If Instalment Facility is opted for 2 year and 3 year term policies, the full premium applicable for 2 year or 3 year terms should be paid half yearly within the expiry of the first year.</h5></h5>}
                    
                    {props && props.productId && props.productId == "25" &&
                    <h5 style={{marginLeft: '17px', marginBottom: '-19px'}} ><b>Instalment Facility:</b> Premium can be paid <u><a target="blank" href={`/customerportal/${props && props.redirectUrl}&paymentOption=Quarterly`}>Quarterly</a></u> and <u><a target="blank" href={`/customerportal/${props && props.redirectUrl}&paymentOption=HalfYearly`}>Half Yearly</a></u>. Premium can also be paid Annually, Biennial (once in 2 years) and Triennial (once in 3 years).Instalment facility will be available only for the sum insured Rs.5 lacs and above.
                    </h5>}

                    {/* {props && props.productId && (props.productId != "7" || props.productId != "1" || props.productId != "3" || props.productId != "26" || props.productId != "37" || props.productId != "34" || props.productId != "4" || props.productId != "11" || props.productId != "33" || props.productId != "22" || props.productId != "2" || props.productId == "25") &&
                    <h5 style={{marginLeft: '17px', marginBottom: '-19px'}} ><b>Instalment Facility:</b> Premium can be paid <u><a target="blank" href={`/customerportal/${props && props.redirectUrl}&paymentOption=Quarterly`}>Quarterly</a></u> and <u><a target="blank" href={`/customerportal/${props && props.redirectUrl}&paymentOption=HalfYearly`}>Half Yearly</a></u>. Premium can also be paid Annually.
                    </h5>} */}

                    {props && props.productId && props.productId == "42" &&<h5>
                    <h5 style={{marginLeft: '17px', marginBottom: '-19px'}} ><b>Instalment Facility:</b> Premium can be paid <u><a target="blank" href={`/customerportal/${props.redirectUrl}&paymentOption=Quarterly`}>Quarterly</a></u> and <u><a target="blank" href={`/customerportal/${props.redirectUrl}&paymentOption=HalfYearly`}>Half Yearly</a></u>. Premium can also be paid Annually, Biennial (once in 2 years) and Triennial (once in 3 years).
                    </h5>
                    <h5>Note: Instalment facility is not available for long term (2 year and 3 year) policies</h5></h5>}
                    </div>
        </GridContainer>
    );
    }
};
InstalmentFacility.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
  productId: PropTypes.number,
  redirectUrl: PropTypes.string
};
export default withStyles(WellnessBenefitsStyle)(InstalmentFacility);