"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _MenuItem = require("@material-ui/core/MenuItem");

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _TextField = require("@material-ui/core/TextField");

var _TextField2 = _interopRequireDefault(_TextField);

var _InputAdornment = require("@material-ui/core/InputAdornment");

var _InputAdornment2 = _interopRequireDefault(_InputAdornment);

var _index = require("../../../../core/dist/index");

var _Person = require("@material-ui/icons/Person");

var _Person2 = _interopRequireDefault(_Person);

var _ChildCare = require("@material-ui/icons/ChildCare");

var _ChildCare2 = _interopRequireDefault(_ChildCare);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
  return {
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      // marginLeft: theme.spacing.unit,
      // marginRight: theme.spacing.unit,
      width: 200
    },
    menu: {
      width: 200
    }
  };
};

var MaterialUiSelect = function (_React$Component) {
  _inherits(MaterialUiSelect, _React$Component);

  function MaterialUiSelect() {
    _classCallCheck(this, MaterialUiSelect);

    return _possibleConstructorReturn(this, (MaterialUiSelect.__proto__ || Object.getPrototypeOf(MaterialUiSelect)).apply(this, arguments));
  }

  _createClass(MaterialUiSelect, [{
    key: "render",
    value: function render() {
      // $FlowFixMe - HOC adds this class
      var classes = this.props.classes;

      var _props = this.props,
          description = _props.description,
          disabled = _props.disabled,
          id = _props.id,
          isValid = _props.isValid,
          name = _props.name,
          _props$options = _props.options,
          options = _props$options === undefined ? [] : _props$options,
          errorMessages = _props.errorMessages,
          placeholder = _props.placeholder,
          required = _props.required,
          value = _props.value,
          label = _props.label,
          onFieldChange = _props.onFieldChange;

      var items = options.reduce(function (itemsSoFar, option) {
        return itemsSoFar.concat(option.items.map(function (item) {
          if (typeof item === "string") {
            return _react2.default.createElement(
              _MenuItem2.default,
              { value: item, style: { whiteSpace: 'normal' } },
              item
            );
          } else {
            return _react2.default.createElement(
              _MenuItem2.default,
              { value: item.value, style: { whiteSpace: 'normal' } },
              item.label || item.value
            );
          }
        }));
      }, []);

      return _react2.default.createElement(
        _TextField2.default,
        {
          select: true,
          autoComplete: "off",
          key: id,
          name: name,
          label: label,
          className: classes.textField,
          placeholder: placeholder,
          helperText: isValid ? description : errorMessages,
          disabled: disabled,
          required: required,
          error: !isValid,
          value: value,
          InputProps: {
            startAdornment: name === "adults" ? _react2.default.createElement(
              _InputAdornment2.default,
              { position: "start" },
              _react2.default.createElement(_Person2.default, null)
            ) : name === "kids" ? _react2.default.createElement(
              _InputAdornment2.default,
              { position: "start" },
              _react2.default.createElement(_ChildCare2.default, null)
            ) : _react2.default.createElement("span", null)
          },
          onChange: function onChange(evt) {
            return onFieldChange(id, evt.target.value);
          }
        },
        items
      );
    }
  }]);

  return MaterialUiSelect;
}(_react2.default.Component);

var StyledMaterialUiSelect = (0, _styles.withStyles)(styles)(MaterialUiSelect);

exports.default = function (props) {
  return _react2.default.createElement(
    _index.FieldWrapper,
    props,
    _react2.default.createElement(StyledMaterialUiSelect, null)
  );
};