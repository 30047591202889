import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Info from "components/Typography/Info.jsx";
import Danger from "components/Typography/Danger.jsx";
import Success from "components/Typography/Success.jsx";
import Warning from "components/Typography/Warning.jsx";
import Badge from "components/Badge/Badge.jsx";
import genericCardStyle from "ps-assets/jss/genericCardStyle.jsx";

class VerticalCard extends React.Component {
  state = {
    onHover: false
  };

  onHoverEnter = () => {
    this.setState({ onHover: true });
  };

  onHoverLeave = () => {
    this.setState({ onHover: false });
  };

  getDescription(description) {
    return typeof description == `string`
      ? description.split(`\n`).map((desc, key) => <p key={key}>{desc}</p>)
      : description;
  }

  render() {
    const {
      classes,
      title,
      subtitle,
      subtitleType,
      image,
      description,
      shareable,
      plain,
      action,
      alignItems,
      background,
      header,
      headerColor,
      onHover,
      noMarginHeader,
      noShadow,
      height,
      wrap,
      quote,
      type,
      italic,
      onHoverEffect,
      shareContent,
      displayComponent,

      ...rest
    } = this.props;
    const marginTop = rest.style && rest.style.marginTop;
    const marginBottom = rest.style && rest.style.marginBottom;
    const boxShadow = rest.style && rest.style.boxShadow;
    const defaultHeight = `52px`;
    var style = {
      height: `${height ? height : defaultHeight}`
    };
    // combinedStyles = compose(style, genericCardStyle)
    const subtTitleComponent = (
      <h4 className={classes.cardCategory} style={{fontSize:'12px', textTransform:'uppercase'}}>{subtitle}</h4>
    );
    let subTitleElement;
    switch (subtitleType) {
      case `info`:
        subTitleElement = <Info>{subtTitleComponent}</Info>;
        break;
      case `danger`:
        subTitleElement = <Danger>{subtTitleComponent}</Danger>;
        break;
      case `success`:
        subTitleElement = <Success>{subtTitleComponent}</Success>;
        break;
      case `warning`:
        subTitleElement = <Warning>{subtTitleComponent}</Warning>;
        break;
      default:
        subTitleElement = subtTitleComponent;
        break;
    }
    return (
      <div onMouseEnter={this.onHoverEnter} onMouseLeave={this.onHoverLeave}>
        <Card
          blog
          plain={plain}
          displayComponent={displayComponent}
          background={background ? true : false}
          className={`${
            alignItems === `center`
              ? classes.textAlignCenter
              : classes.textAlignLeft
          } ${noShadow ? `${classes.noShadow}` : ``} ${
            onHoverEffect ? `${classes.onHoverEffect}` : ``
          }`}
          style={{
            backgroundImage: `${background}`,
            marginTop: marginTop,
            marginBottom: marginBottom,
            boxShadow: boxShadow
          }}
        >
          <CardHeader
            image={image ? true : false}
            plain={plain}
            className={`${noMarginHeader ? `${classes.noMarginHeader}` : ``} `}
          >
            {image}
          </CardHeader>
          {quote}
          <CardBody
            plain={plain}
            background={background ? true : false}
            className={`${classes.cardBody}  ${
              wrap === false ? classes.noWrap : ``
            } ${classes.paddingBottom}`}
            style={{marginTop: '-6%'}}
          >
            {header && <Badge color={headerColor}>{header}</Badge>}
            {type !== `testimonial` && (
              <h3
                title={title}
                className={`${classes.cardTitle}              
                 ${background ? classes.cardTitleWhite : ``}`}
                 style={{fontSize:'18px'}}
              >
                {title}
              </h3>
            )}
            {subtitle && subTitleElement}
            <div
              className={`${classes.cardDescription}                        
              ${background ? classes.cardTitleWhite : ``} ${
        italic ? classes.italic : ``
      }`}
              style={style}
            >
              {this.getDescription(description)}
            </div>

            {type === `testimonial` && (
              <p
                className={`${classes.cardTitle} ${
                  background ? classes.cardTitleWhite : ``
                }`}
                style={{fontSize:"18px"}}
              >
                {title}
              </p>
            )}
          </CardBody>
          {(!onHover || !onHover.includes(`shareable`)) && shareable && (
            <CardFooter
              profile
              plain={plain}
              className={
                alignItems === `center` ? classes.justifyContentCenter : ``
              }
            >
              {shareContent}
            </CardFooter>
          )}
          {(!onHover || !onHover.includes(`action`)) && action && (
            <CardFooter
              profile
              plain={plain}
              className={
                alignItems === `center` ? classes.justifyContentCenter : ``
              }
            >
              {action}
            </CardFooter>
          )}
          {this.state.onHover && onHover && (
            <div className={classes.overlay}>
              <div className={classes.overlaySections}>
                {onHover.includes(`shareable`) && shareable && (
                  <CardFooter
                    profile
                    plain={plain}
                    className={
                      alignItems === `center`
                        ? classes.justifyContentCenter
                        : ``
                    }
                  >
                    {shareContent}
                  </CardFooter>
                )}
                {onHover.includes(`action`) && (
                  <CardFooter
                    profile
                    plain={plain}
                    className={
                      alignItems === `center`
                        ? classes.justifyContentCenter
                        : ``
                    }
                  >
                    {action}
                  </CardFooter>
                )}
              </div>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

VerticalCard.propTypes = {
  classes: PropTypes.object.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subtitleType: PropTypes.oneOf([
    `default`,
    `info`,
    `success`,
    `warning`,
    `danger`
  ]),
  image: PropTypes.node,
  action: PropTypes.node,
  shareable: PropTypes.bool,
  noShadow: PropTypes.bool,
  onHoverEffect: PropTypes.bool,
  background: PropTypes.string,
  plain: PropTypes.bool,
  onHover: PropTypes.array,
  noMarginHeader: PropTypes.bool,
  alignItems: PropTypes.oneOf([`left`, `center`]),
  header: PropTypes.string,
  headerColor: PropTypes.oneOf([
    `default`,
    `info`,
    `success`,
    `warning`,
    `danger`
  ]),
  wrap: PropTypes.bool,
  height: PropTypes.string,
  quote: PropTypes.node,
  type: PropTypes.string,
  italic: PropTypes.bool,
  shareContent: PropTypes.node,
  displayComponent: PropTypes.object
};

export default withStyles(genericCardStyle)(VerticalCard);
