import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import customStepperStyle from "ps-assets/jss/customStepperStyle.jsx";
import { Link } from "gatsby";
import root from "window-or-global";
import { browserDocument, browserWindow } from "util/localStorageHelper";

function CustomStep(props) {
  return (
    <GridItem
      md={3}
      lg={3}
      sm={3}
      xs={12}
      className={props.data.connectorfixpadding}
    >
      <Step {...props} />
    </GridItem>
  );
}

class CustomStepper extends React.Component {
  state = {
    disabled: false,
    error: ``,
  };

  handleNext = (e) => {
    e.preventDefault();

    var s = root.document.getElementById(`submitButton`);
    if (s) {
      s.click();
    }
    const { formValues } = this.props;
    if (formValues) {
      var error = null;
      const formValuesClone = formValues.slice();
      for (let index = 0; index < formValuesClone.length; index++) {
        const element = formValuesClone[index];
        const formResponseElement = root.document.getElementsByName(
          element.name
        )[0];
        if (
          element.required &&
          formResponseElement &&
         s && !s.disabled &&
          (!formResponseElement.value || !formResponseElement.value.trim())
        ) {
          error = formResponseElement;
          break;
        } else if (element && formResponseElement) {
          element.defaultValue = formResponseElement.value;
        }
      }
      if (error) {
        this.setState({ error: `*Please enter all values` });
        error.focus();
      } else if (s && s.disabled) {
        this.setState({ error: `*Please enter all values` });
      } else {
        this.setState({ error: `` });
        this.props.handleFormState(formValuesClone, this.props.activeStep + 1);
      }
    } else {
      this.setState({ error: `` });
      this.props.handleFormState(formValues, this.props.activeStep + 1);
    }
  };

  handleBack = () => {
    const { formValues } = this.props;
    this.props.handleFormState(formValues, this.props.activeStep - 1);
  };

  handleCallback = () => {
    browserDocument.getElementsByClassName(`wbf-screen`)[0].classList.add(`active`);
    browserDocument.getElementsByClassName(`wbf-container`)[0].classList.add(`active`);
  };

  componentDidUpdate = () => {
    let handleScroll =  this.props && this.props.handleScroll  && this.props.handleScroll ? true : false;
    if(this.props.activeStep === 2 && this.props.quoteReceived){
      let premiumStep_element = browserDocument.getElementById(`stepper-element`);
      if(premiumStep_element && !handleScroll){
        premiumStep_element.scrollIntoView({behavior:`smooth`,block:`start`,inline:`nearest`});
      }
    }
  }

  render() {
    const { classes, steps, content, activeStep, productId } = this.props;
     let starExtra = this.props.starExtraprotect && this.props.starExtraprotect ? false : true;
    const connector = (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          completed: classes.connectorCompleted,
          disabled: classes.connectorDisabled,
          line: classes.connectorLine,
        }}
        className={classes.connectorfix}
        style={{ left: `calc(-50% + -10px)` }}
      />
    );
    return (
      <div className={classes.container}>
        <Stepper
          style={
            activeStep === 2 ?
              {paddingTop:`75px`} :
              {paddingTop:`60px`}
          }
          id="stepper-element"
          alternativeLabel
          activeStep={activeStep}
          connector={connector}
          className={classes.root}
        >
          {steps.map((label) => (
            <CustomStep data={classes} key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.icon,
                    active: classes.active,
                    completed: classes.active,
                  },
                }}
                classes={{
                  labelContainer: classes.label,
                }}
              >
                {label}
              </StepLabel>
            </CustomStep>
          ))}
        </Stepper>
        <div style={{ padding: `24px`, margin: `0 auto` }}>
          <div>
            <form onSubmit={this.handleNext}>
              {content && content[activeStep]}
              <div className={classes.errorMsg}>{this.state.error}</div>
              <div style={{ paddingTop: `0px`, minHeight: `80px` }}>
                {activeStep !== 0 && (
                  <Button
                    round
                    variant="contained"
                    color="primary"
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  this.props.quoteReceived  &&
                  starExtra &&
                  (this.props.ctaLabel === `Buy Now` ? (
                    !browserWindow.location.href.includes(`#agent`) && (
                      <Button
                        round
                        variant="contained"
                        color="primary"
                        className={`${classes.button} sumInsuredBuyNowGA`}
                        style={{
                          float: `right`,
                          display:  (productId === 20) ? `none` : ``,
                        }}
                        disabled={!this.props.premiumAmount}
                        onClick={this.props.handleBuyNow}
                      >
                        <span className={`sumInsuredBuyNowGA`}> Buy Now</span>
                      </Button>
                    )
                  ) : this.props.ctaLabel == `Call Now` ? (
                    !browserWindow.location.href.includes(`#agent`) && (
                      <Button
                        round
                        variant="contained"
                        color="primary"
                        className={`${classes.button} clickme`}
                        onClick={this.handleCallback}
                        style={{ float: `right` }}
                      >
                        Call Now
                      </Button>
                    )
                  ) : (
                    <Link to="/locate-us">
                      <Button
                        round
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        style={{ float: `right` }}
                      >
                        Branch Office Locator
                      </Button>
                    </Link>
                  ))
                ) : (
                  <Button
                    disabled={this.state.disabled}
                    round
                    variant="contained"
                    color="primary"
                    className={`${classes.button} ${activeStep==0 ? `contactDetailNextGa` :`basicInfoNextGA`}`}
                    style={{ float: `right` }}
                    type="submit"
                  >
                  <span className={activeStep==0 ? `contactDetailNextGa` :`basicInfoNextGA`}> {`Next`}</span>  
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CustomStepper.propTypes = {
  classes: PropTypes.object,
  formValues: PropTypes.array,
  handleFormState: PropTypes.func,
  activeStep: PropTypes.number,
  steps: PropTypes.array,
  content: PropTypes.array,
  ctaLabel: PropTypes.string,
  premiumAmount: PropTypes.object,
  handleBuyNow: PropTypes.func,
  handleCallNow: PropTypes.func,
  buyNowUrl: PropTypes.string,
  productId: PropTypes.number,
  quoteReceived: PropTypes.bool,
  starExtraprotect: PropTypes.bool,
  handleScroll : PropTypes.bool
};

CustomStep.propTypes = {
  data: PropTypes.object,
};

export default withStyles(customStepperStyle)(CustomStepper);