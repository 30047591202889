import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
// import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
// import Accordion from "components/Accordion/Accordion.jsx";    

class Womencarebenefits extends React.Component {
// const Womencarebenefits = ({ classes }) => {
    render() {
        let {classes} = this.props;
        let props = this.props
    return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
             
        <div style={{marginLeft: '-2%'}} >
            <li>Room, Boarding, Nursing Expenses all inclusive as provided by the Hospital / Nursing Home as per the limits given below;</li>
            <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Sum Insured (Rs.)</b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Limit</b>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>5,00,000/-</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Upto 1% of Sum Insured per day</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>10,00,000/-,15,00,000/-, 20,00,000/- and 25,00,000/-</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Any Room(except suite or above category)</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>50,00,000/- and 1,00,00,000/-</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Any Room</TableCell>
                        </TableRow>
                        </TableHead>
                     
                  </Table>
                </Paper>
            <li><b>Star Mother Cover :</b> If the insured person is a child age less than 12 years, the Company will provide for expenses up to <b>Single Private A/c room</b> for stay of the mother in the hospital provided insured child is under treatment in ICU</li>
            <li>Surgeon, Anesthetist, Medical Practitioner, Consultants, Specialist Fees , Anesthesia, Blood, Oxygen, Operation theatre charges, ICU charges</li>
            <li>All day care procedures are covered</li>
            <li>Coverage for <b>Non-medical Items</b> available <u><a href="https://uat.starhealth.in/sites/default/files/non-medical-womencare.pdf" >click here</a></u> to view details</li>
            <li>Road Ambulance charges for transportation of Insured Person covered subject to an admissible Hospitalisation Claim.</li>
            <li><b>Air Ambulance</b> charges up to 10% of the Sum Insured per year is payable</li>
            <li>Pre-Hospitalization expenses up to 60 days prior to admission in the hospital.</li>
            <li>Post- Hospitalization expenses incurred up to 90 days after discharge from the hospital.</li>
            <li><b>Organ Donor Expenses</b> In patient hospitalization expenses incurred for organ transplantation from the Donor to the Recipient Insured Person are payable provided claim for transplantation is payable.</li>
            <li><b>AYUSH Treatment:</b> In patient Hospitalizations Expenses incurred for treatment of diseases / illness / accidental injuries by system of medicines other than allopathic up to the Sum Insured per year.</li>
            <li><b>Bariatric Surgery:</b>
            <ul>
                <li>Expenses incurred on hospitalization for bariatric surgical procedure and its complications thereof are payable upto the limits mentioned, during the policy period.</li>
                <li>The maximum limit of Rs.2,50,000/- and Rs.5,00,000/- are inclusive of pre-hospitalization and post hospitalization expenses.</li>
                </ul></li>
            <li><b>Coverage for Modern Treatment:</b> Please <u><a href="https://uat.starhealth.in/sites/default/files/modern-treatment/Modern-Treatment-Women-Care-Insurance-Policy.pdf" >Click here</a></u></li>
            <li>Benefit/Premium illustration for Individual and Floater basis please <u><a href="https://uat.starhealth.in/sites/default/files/Benefit-Illustration/Women-Care-Insurance-Policy.pdf" >Click here</a></u></li>
            <li><b>Automatic Restoration of Sum Insured:</b> There shall be automatic restoration of the Sum Insured once by 100%</li>
            <li><b>Cumulative Bonus:</b> In respect of a claim free year of Insurance, the insured would be entitled to benefit of bonus of 20% of the expiring Sum Insured from the second year onwards. The maximum allowable bonus shall not exceed 100%.</li>
            <li><b>Shared accommodation:</b> If the Insured person occupies, a shared accommodation during in-patient hospitalization, then sum of Rs.2,000/- per day subject to a maximum of 7 days(per hospitalization) will be payable for each continuous and completed period of 24 hours of stay in such shared accommodation.</li>
            <li><b>Rehabilitation and Pain Management:</b> The company will pay the medical expenses for Rehabilitation and Pain Management up to the sub-limit (or) maximum up to 10% of the sum insured whichever is less, per policy year.</li>
            <li><b>Assisted Reproduction Treatment:</b> The Company will reimburse medical expenses incurred on Assisted Reproduction Treatment, where indicated, for sub-fertility with waiting period of 36 months from the date of first inception of this policy covered upto the limits for each policy year.</li>
            <div style={{ marginLeft: '30px' }}>
            <ul><b>(a)	Surrogacy Cover:</b>
            <br></br>
            <p>The company will indemnify the inpatient hospitalization expenses covering post-partum delivery complications for a period of 36 months incurred for the Surrogate mother up to the sub-limits specified under “Assisted Reproduction Treatment”. The cover will commence from the date of initiation of the treatment/procedure.</p>
            <p>The company will pay lump sum amount in case of “Miscarriage due to Accident” for surrogate mother as specified under the coverage “Miscarriage due to Accident” and waiting period mentioned under this cover is not applicable.</p>
            <b>(b)	Oocyte Donor cover:</b>
            <p>The company will indemnify the inpatient hospitalization expenses for the complications arising out of Assisted Reproductive Treatment Procedures, for the Oocyte donor up to the sub-limits specified under “Assisted Reproduction Treatment” for a period of 12 months. The cover will commence from the date of initiation of the treatment/procedure</p>
            <b>Applicable for Surrogacy Cover and Oocyte Donor Cover:</b>
            <p>i.	The Proposal for insurance has to be made 30 days before the embryo transfer for the surrogate mother and /or 30 days before ovarian stimulation for oocyte donor.</p>
            <p>ii.	Proposer has to be one of the intending couple.</p>
            <p>iii. <b>	For Surrogate Mother:</b> After completion of 36 months period, Surrogacy cover will be excluded for the Surrogate Mother and the coverage under Section II (except Surrogacy cover) will continue.</p>
            <p>iv.	<b>For Oocyte Donor:</b> After completion of 12 months period, Oocyte Donor cover will be excluded for the Oocyte Donor and the coverage under Section II (except Oocyte cover) will continue.</p>
            </ul></div>
            <li><b>Ante-Natal Care (Pregnancy Care) :</b> Company pays the expenses for Ante-natal care after confirmation of Pregnancy, incurred as an Out-patient up to the limits per policy year.</li>
            <li><b>In Utero Fetal Surgery/Repair :</b> The Company will pay the expenses incurred for listed In Utero Fetal Surgeries and Procedures subject to waiting period. However, this waiting period will not apply for treatment related to congenital disease/defects for the new born.</li>
            <li><b>Voluntary Sterilization Expenses :</b> The Company will pay the expenses incurred for Voluntary Sterilization (Tubectomy / Vasectomy), after a waiting period of 24 months from the date of first inception of this policy, provided if Insured is a Married Person and his/ her age is 22 years and above.<ul><li>Note: Expenses incurred for Reversal of Sterilization are not payable.</li></ul></li>
            <li><b>Miscarriage due to Accident:</b> The Company will pay the lumpsum amount upto the limits towards miscarriage arising due to accident subject to a waiting period.</li>
            <li><b>Delivery Expenses :</b> Expenses for a Delivery including Delivery by Caesarean section (including pre-natal and post natal expenses) up-to the limits per delivery is payable subject to waiting period.</li>
            <li><b>Hospitalization expenses for treatment of New Born Baby.</b></li>
        <ul>
            <li>
            <b>Birth of New born baby during the policy year:</b> In-patient hospitalization expenses (Including Congenital Internal and External defects/anomalies) are covered from day 1 subject to a maximum of 25% of the sum insured 
            </li>
            <li><b>In the subsequent year (on payment of applicable premium for New born):</b> In-patient hospitalization expenses (Including Congenital Internal and External defects/anomalies) are covered up to 100% of the sum insured.</li>
            <li><b>Vaccination Expenses :</b> Vaccination expenses for the new born baby are payable up to the 12 months from the birth of the new born. The Company’s liability for vaccination is as per the limits mentioned in the table below;</li>
        <Paper className={classes.root} style={{marginTop: '15px'}}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                    <TableRow key='suminsured'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Sum Insured (Rs.)
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                Limit (Rs.)
                            </TableCell>
                        </TableRow>
                        <TableRow key='sum'>
                        <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            5,00,000/- and 10,00,000/-
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                2500
                            </TableCell>
                        </TableRow>
                        <TableRow key='above'>
                        <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            15,00,000/- and above 
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                3500
                            </TableCell>
                        </TableRow>
                </Table>
            </Paper>
            <li><b>Metabolic Screening</b> Test for New Born is payable once for each new born subject to a limit of Rs.3,500/-</li>
            <li><b>Pediatrician Consultation</b> up to 4 consultations per year are payable up to 12 year of age subject to policy is in force. The Company’s liability for each consultation is up to Rs.500/-.</li>
        </ul>
        <li>Expenses of <b>Medical Consultations as an Outpatient</b> up to the limits are payable.</li>
        <h5>Note :
            <ol>
                <li>This benefit is available only for female insured person</li>
                <li>Unlimited Gynecologist Consultation through Star tele health app is available</li>
            </ol>
        </h5>
        <li><b>Preventive Health Check Up:</b> Expenses incurred towards cost of preventive  health check-up for the tests payable up to the limits for each policy year.</li>
        <li><b>Star Wellness Program:</b> This program intends to promote, incentivize and to reward the Insured Persons’ healthy life style through various wellness activities. The following table shows the discount on premium available under the Wellness Program:</li>
        <Paper className={classes.root} style={{marginTop: '15px'}}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                    <TableRow key='suminsured'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Wellness Points Earned
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                Discount in Premium
                            </TableCell>
                        </TableRow>
                        <TableRow key='sum'>
                        <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            200 to 350
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                2%
                            </TableCell>
                        </TableRow>
                        <TableRow key='above'>
                        <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            351 to 600
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                5%
                            </TableCell>
                        </TableRow>
                        <TableRow key='above'>
                        <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            601 to 750
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                7%
                            </TableCell>
                        </TableRow>
                        <TableRow key='above'>
                        <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            751 to 1000
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                10%
                            </TableCell>
                        </TableRow>
                </Table>
            </Paper>
            <ul>
            <li>
                <h5>
                <b>Optional Cover  (Lump sum on diagnosis of Cancer): (Available on payment of additional premium and shown in the policy schedule)</b> If the insured person shall contract Cancer as a first incidence, then Company will pay the sum insured stated in the Schedule as lump-sum. This optional cover is available only for female insured persons who is aged between 91 days to 65 years. Available once in lifetime. The available sum insured options under this benefit cover  5/10/15/20 and 25 lacs only.

                </h5>
                <h5>
                <b>Waiting Period:</b> An initial waiting period of 180 days is applicable from the date of commencement of this policy. In case of enhancement of sum insured this waiting period shall apply afresh to the extent of sum insured increase
                </h5>
                </li>
                <li><b>Waiting Period:</b>
                <ul>
                    <li><b>Pre-Existing Diseases:</b> 24 months</li>
                    <li><b>Specified disease / procedure waiting period:</b> 24 months</li>
                    <li><b>Initial waiting period:</b> 30 days</li>
                </ul></li>
                {props && props.redirectUrl ? 
                (<>
                <li><b>Instalment Facility:</b> Premium can be paid <u><a target="blank" href={`/customerportal/${props && props.redirectUrl}&paymentOption=Quarterly`}>Quarterly</a></u> and <u><a target="blank" href={`/customerportal/${props && props.redirectUrl}&paymentOption=HalfYearly`}>Half Yearly</a></u>. Premium can also be paid Annual, Biennial (once in 2 years) and Triennial (once in 3 years).</li></>)
                :
                (<><li><b>Instalment Facility:</b> Premium can be paid Quarterly and Half Yearly. Premium can also be paid Annual, Biennial (once in 2 years) and Triennial (once in 3 years).</li>
                </>)}
            </ul>
            </div>
      </GridItem>
      
    </GridContainer>
  );}
};
Womencarebenefits.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
  redirectUrl: PropTypes.string
};
export default withStyles(WellnessBenefitsStyle)(Womencarebenefits);