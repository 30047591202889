import React from "react";
// import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
// import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
// import Table from "@material-ui/core/Table";
// import { Helmet } from "react-helmet";
// import Paper from "@material-ui/core/Paper";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import { Helmet } from "react-helmet";


// const table1 = [
//     ['Renewal', 'Lifelong'],
//     ['Policy period', '1 year/ 2 years / 3 years'],
//     ['Sum Insured', '3 lakhs to 25 lakhs '],
//     ['Discounts', '5% - for purchase of policy online'],
// ]

// const table2 = [
//     ['In-patient Hospitalisation', 'Up to S.I and bonus if any','Covers all in-patient hospitalisation expenses if one or more insured persons are required to be hospitalised for treatment for at least 24 hours during the policy period.'],
//     ['AYUSH Treatments', 'Covered','Covers expenses for AYUSH treatments (Ayurveda, Unani, Sidha, and Homeopathy) received in a government hospital or any facility recognised by the government and/or accredited by the Quality Council of India/National Accreditation Board on Health. up to the limits mentioned Up to certain limits based on sum insured opted'],
//     ['New Born Baby Cover', 'Covered','Hospitalisation charges for the new-born due to any medical complications provided the mother is covered under the policy for a continuous period of 12 months without break Lower of 10% of the B.S.I. or up to Rs 50000'],
//     ['Domiciliary Hospitalisation', 'Covered','Coverage for medical treatment (including AYUSH) confined at home for a period exceeding three days, for an illness/disease/injury'],
//     ['Modern Treatment', 'Covered','Twelve modern treatments, including balloon sinuplasty, immunotherapy, and stem cell therapy, are covered. Up to sub limits mentioned in the policy clause'],
//     ['RTA', 'Additional S.I by 50% ','Additional cover provided for RTA on the condition that the insured rider/pillion rider wears a helmet and travelled by a two-wheeler.'],
//     ['Super Restoration', 'Available for gold','Upon partial/full utilisation of the limit of coverage, get 100% of the basic sum insured,       restored under the Gold variant. Up to 100% of S.I.'],
//     ['Automatic Restoration', 'Up to 200% of S.I.','200% of sum insured post exhaustion of limit of cover (Basic Sum Insured + Cumulative Bonus Sum Insured)'],
//     ]
        

const MediclassicHighlights = ({ classes }) => {
    return (
        <GridContainer className={classes.containerDisplay}>
          
            {/* <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Medi Classic Insurance Policy (Individual)
            </h2>
            </GridItem>
                <h5>
                The Medi Classic Insurance Policy (Individual) is a well-balanced health insurance plan that provides ample benefits for individuals to manage their medical expenses. This uniquely designed health plan covers from 16 days to 65 years of age, with no age capping for renewals after 65 years. This plan exclusively covers organ donor expenses up to sum insured, AYUSH treatments, domiciliary hospitalisation, modern treatments and some more.
             </h5> */}
        {/* <div>
        <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={2}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                16 days to 65 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Individual (1A)  A= Adult
                            </TableCell>
                        </TableRow>
                        {table1.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting Periods
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-48 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>
        <div style={{marginTop:'5%'}}>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Benefits
                                  </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Coverage limit
                                </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                >
                                   Description of cover
                         </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {table2.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellwidth}>
                                    {row[1]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellwidth}>
                                    {row[2]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                        </Table>
                </Paper>
                </div> */}
      {/* </GridItem> */}
      {/* {process.env.ENABLE_HELMET == `true` && (
       <Helmet>
          <script type="application/ld+json">
{`{ "@context": "http://schema.org",
  "@type": "Product",
  "name": "Medi Classic Insurance Policy",
  "description": "Medi Classic Insurance Policy (Individual) offers cover for an individual from 16th day child to 65 years of age. This policy has features like 200% automatic restoration, 100% super restoration and is much more beneficial to the policyholder.",
"offers":{ 
          "@type": "AggregateOffer",
          "offerCount": "5%"
        }

}`}
</script>
       </Helmet>)} */}
    </GridContainer>
  );
};
MediclassicHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(MediclassicHighlights);