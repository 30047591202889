import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
// import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";


const table2 = [
    ['Location of Centre','World Travel Assist - USA','World Travel Assist - Europe','World Travel Assist – Singapore','Heritage Health Insurance TPA Pvt.Ltd. - India'],
    ['Address','W.T.A. TRAVEL SERVICES, INC. 1571 Sawgrass Corporate Pkwy Suite 110 Sunrise, Fl, 33323-2862, U.S.A.','W.T.A. TRAVEL SERVICES, INC. Calle Barcas 2, Planta 2, Oficina 302, Regus, 46002, Valencia. Spain','W.T.A. TRAVEL SERVICES, INC. 75 Kim Yan Road Singapore 239372','Heritage Health Insurance TPA Pvt.Ltd. Champion Building, Gr.Flr, 15, Parsi Panchayat Road, Andheri (E), Mumbai - 400069. India.'],
    ['Toll – Free Number for Assistance','+1 877 822 7386','+34 900 804 116',<td key="no3">+65 3163 2058 <br/>+65 8001302100</td>,'1800 22 4004 (Toll Free within India)'],
    ['Other Telephone Numbers','+1 305 328 3897','+34 911 881 617','+65 315 80234','+91-22-61273891 (24 hrs) +91-22-61273892/ 93'],
    ['Fax Number(s)','+1 877 395 1784','+34 915 932 577',' ','+91-22-6127 3890'],
    ['E-Mail ID','assistance@wtabyhas.com','assistance@wtabyhas.com','assistance@wtabyhas.com','heritagehealthomp@bajoria.in'],
    ['Website','www.wta-heritage.com','www.wta-heritage.com','www.wta-heritage.com','www.wta-heritage.com'],
    ['Jurisdiction','North, Central & South America','Europe, UK & Ireland, Africa, Pakistan','Singapore','South & South East Asia, Middle East, Australia & New Zealand'],
]

const CriticalillnessBenefits = ({ classes }) => {
    return (
        <GridContainer className={classes.containerDisplay}>
            <div style={{marginTop:'1%'}}>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableBody>
                        {table2.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[2]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[3]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[4]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                        </Table>
                </Paper>
                </div>
          
      <h5 style={{marginTop:'15px'}} >NOTE: Renewal premium, terms and conditions are subject to change with prior approval from IRDAI.</h5>
        
    </GridContainer>
  );
};
CriticalillnessBenefits.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(CriticalillnessBenefits);