import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import { rows1 } from "./CovidChargesTableData";

const table1 = [
  ["200 to 350", "2%"],
  ["351 to 600", "5%"],
  ["601 to 750", "7%"],
  ["751 to 1000", "10%"],
];

const table4 = [
  "Complete Haemogram Test",
  "Blood Sugar (Fasting Blood Sugar (FBS) + Postprandial (PP) [or] HbA1c)",
  "Lipid profile (Total cholesterol, HDL, LDL, Triglycerides, Total Cholesterol / HDL Cholesterol Ratio)",
  "Serum Creatinine",
];
const table9 = [
  ["Completed Online Health Risk Assessment (HRA)", "50"],
  [
    "Submitted Health Check-Up Report (one test results are not within normal values)",
    "150",
  ],
  ["Participated in Walkathon ", "100"],
  ["Attended to Yoga Classes", "100"],
  [
    "Achieved 10,000 average number of steps per day during the policy year",
    "200",
  ],
  ["Ramesh accepted the Weight management program and reached 23 BMI", "100"],
  ["Ramesh has completed De-stress & Mind Body Healing Program", "125"],
];
const table10 = [
  ["Completed Online Health Risk Assessment (HRA)", "50", "50"],
  ["Submitted Health Check-Up Report", "200", "200"],
  ["Participation in Walkathon", "100", "100"],
  ["Attended to Tai Chi Classes", "100", "-"],
  ["Achieved 10,000 average number of steps per day during the policy year", "200", "200"],
  [
    "Submitted his fitness success story",
    "50",
    "50",
  ],
  [
    " Managed Hypertension through Chronic management program",
    "250",
    "250",
  ],
];

const ComprehensiveWellnessBenefits = ({ classes }) => {
  return (
    <GridContainer>
      <GridItem
        md={8}
        sm={8}
        xs={8}
        className={classNames(
          classes.mrAuto,
          classes.mlAuto,
          classes.infoArea,
          classes.handleMarginLeft
        )}
      >
        <GridItem style={{ textAlign: "center" }}>
          <h2>Star Wellness Program</h2>
        </GridItem>
        <h5>
        This program intends to promote, incentivize and to reward the Insured Persons’ healthy life style through various wellness activities. The wellness activities as mentioned below are designed to help the Insured person to earn wellness reward points which will be tracked and monitored by the Company. The wellness points earned by the Insured Person(s) under the wellness program, can be utilized to get discount in premium. This Wellness Program is enabled and administered online through Star Wellness Platform (digital platform).
        </h5>
        <h5>
          <b>Note:</b> The Wellness Activities mentioned in the table below (from Serial Number 1 to 5) are applicable for the Insured person(s) aged 18 years and above only.
        </h5>
        <h5>
          <b>
            The following table shows the discount on renewal premium available
            under the Wellness Program:
          </b>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    rowSpan={1}
                    className={classes.tableCell + ` ` + classes.headCell}
                  >
                    Wellness Points Earned
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    align="center"
                    className={classes.tableCell + ` ` + classes.headCell}
                  >
                    Discount in Premium
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table1.map((row) => (
                  <TableRow key={row[0]}>
                    <TableCell align="center" className={classes.tableCell}>
                      {row[0]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row[1]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </h5>

        <div style={{ paddingLeft: `2%` }}>
          <div>
            <h5>
              <b>
                The wellness services and activities are categorized as below:
              </b>
            </h5>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      className={classes.tableCell + ` ` + classes.headCell}
                    >
                      Sr. No.
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      align="center"
                      className={classes.tableCell + ` ` + classes.headCell}
                    >
                      Activity
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      align="center"
                      className={classes.tableCell + ` ` + classes.headCell}
                    >
                      Maximum number of Wellness Points that can be earned under
                      each activity in a policy year
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* row1 */}
                  <TableRow>
                    <TableCell
                      rowSpan={3}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>1.</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Manage and Track Health</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      a) Online Health Risk Assessment (HRA)
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>50</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      b) Preventive Risk Assessment
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>200</b>
                    </TableCell>
                  </TableRow>
                  {/* row2 */}
                  <TableRow>
                    <TableCell
                      rowSpan={3}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>2.</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Affinity to Wellness</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      a) Participating in Walkathon, Marathon, Cyclothon and
                      similar activities
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>100</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      b) Membership in a health club (for 1 year or more)
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>100</b>
                    </TableCell>
                  </TableRow>
                  {/* row3 */}
                  <TableRow>
                    <TableCell align="center" className={classes.tableCell}>
                      <b>3.</b>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellLeft}>
                      <b>Stay Active</b> – If the Insured member achieves the
                      step count target on mobile app
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <b>200</b>
                    </TableCell>
                  </TableRow>
                  {/* row4 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>4(a).</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Weight Management Program</b> (for the Insured who is
                      Overweight / Obese)
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>100</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>4(b).</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>
                        Sharing Insured Fitness Success Story through adoption
                        of Star Wellness Program
                      </b>{" "}
                      (for the Insured who is <b>not</b> Overweight / Obese)
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>50</b>
                    </TableCell>
                  </TableRow>
                  {/* row5 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>5(a).</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Chronic Condition Management Program</b> (for the
                      Insured who is suffering from Chronic Condition/s -
                      Diabetes, Hypertension, Cardiovascular Disease or Asthma)
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>250</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>5(b).</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>
                        On Completion of De-Stress & Mind Body Healing Program
                      </b>{" "}
                      (for the Insured who is not suffering from Chronic
                      Condition/s - Diabetes, Hypertension, Cardiovascular
                      Disease or Asthma)
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>125</b>
                    </TableCell>
                  </TableRow>
                  {/* row */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    ></TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Additional Wellness Services</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    ></TableCell>
                  </TableRow>
                  {/* row6 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>6.</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Virtual Consultation Service</b>
                    </TableCell>
                    <TableCell
                      rowSpan={8}
                      align="center"
                      className={classes.tableCell}
                    ></TableCell>
                  </TableRow>
                  {/* row7 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>7.</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Medical Concierge Services</b>
                    </TableCell>
                  </TableRow>
                  {/* row8 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>8.</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Period & Fertility Tracker</b>
                    </TableCell>
                  </TableRow>
                  {/* row9 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>9.</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Digital Health Vault</b>
                    </TableCell>
                  </TableRow>
                  {/* row10 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>10.</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Wellness Content</b>
                    </TableCell>
                  </TableRow>
                  {/* row11 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>11.</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Health Quiz & Gamification</b>
                    </TableCell>
                  </TableRow>
                  {/* row12 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>12.</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Post-Operative Care</b>
                    </TableCell>
                  </TableRow>
                  {/* row13 */}
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      <b>13.</b>
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCellLeft}
                    >
                      <b>Discounts from Network Providers</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </div>
        </div>
        <ol>
          <li>
            <div style={{ paddingLeft: `2%` }}>
              <h5 style={{ fontWeight: "bold" }}>
                <b>Manage and Track Health:</b>
              </h5>
            </div>
            <div>
              <div>
                <p>
                  <b>
                    <u>(a)Completion of Health Risk Assessment (HRA):</u>
                  </b>
                </p>
                The Health Risk Assessment (HRA) questionnaire is an online tool for evaluation of health and quality of life of the Insured. It helps the Insured to introspect his/ her personal lifestyle. The Insured can log into his/her account on the website{" "}
                <b>www.starhealth.in</b> and complete the HRA questionnaire. The Insured can undertake this once per policy year.
                <h5>
                <b>On Completion of online HRA questionnaire</b>, the Insured
                earns <b>50 wellness points</b>.
                </h5>
              </div>
            </div>
            <div>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableCell + ` ` + classes.headCell}
                      ></TableCell>
                      <TableCell
                        className1={classes.tableCell + ` ` + classes.headCell}
                        style={{
                          border: "1px solid black",
                          color: "black",
                          fontWeight: "800",
                          fontSize: "18px",
                        }}
                      >
                        <b>Initiative</b>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        <b>Wellness Points</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        rowSpan={2}
                        align="center"
                        className={classes.tableCell}
                      >
                        <b>a.</b>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        <b>
                          Participating in Walkathon, Marathon, Cyclothon and
                          similar activities
                        </b>
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        align="center"
                        className={classes.tableCell}
                      >
                        <b>100</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className={classes.tableCell}>
                        - On submission of BIB Number along with the details of
                        the entry ticket taken to participate in the event.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        rowSpan={1}
                        align="center"
                        className={classes.tableCell}
                      >
                        <b>b.</b>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        Membership in a health club (for 1 year or more) - In a
                        Gym / Yoga Centre / Zumba Classes / Aerobic Exercise/
                        Sports Club/ Pilates Classes/ Swimming / Tai Chi/
                        Martial Arts / Gymnastics/ Dance Classes
                      </TableCell>
                      <TableCell
                        rowSpan={1}
                        align="center"
                        className={classes.tableCell}
                      >
                        <b>100</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className={classes.tableCell}>
                        <b>Note:</b>
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="center"
                        className={classes.tableCell}
                      >
                        In case if Insured is not a member of any health club,
                        he/she should join into club within 3 months from the
                        date of the policy risk commencement date. Insured
                        person should submit the health club membership.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
            <h5><b>Note:</b>To get the wellness points mentioned under HRA, the Insured has to complete the entire HRA within one month from the time he/she started HRA Activity.</h5>
            <div>
              <div>
                <p>
                  <b>
                    <u>(b) Preventive Risk Assessment:</u>
                  </b>
                </p>
                The Insured can also earn wellness points by undergoing
                diagnostic / preventive tests during the policy year. These
                tests should include the four mandatory tests mentioned below.
                Insured can take these tests at any diagnostic centre at
                Insured’s own expenses.
                <ul style={{ listStyle: "disc" }}>
                  <li>
                    If all the results of the submitted test reports are within
                    the normal range, <b>Insured earns 200 wellness points.</b>
                  </li>
                  <li>
                    If the result of any one test is not within the normal range
                    as specified in the lab report,{" "}
                    <b>Insured earns 150 wellness points.</b>
                  </li>
                  <li>
                    If two or more test results are not within the normal range,{" "}
                    <b>Insured earns 100 wellness points only.</b>
                  </li>
                </ul>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          className={
                            classes.tableCell + ` ` + classes.headCellLeft
                          }
                        >
                          <b>
                            List of mandatory tests under Preventive Risk
                            Assessment
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {table4.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            className={classes.tableCellLeft}
                          >
                            <span style={{ paddingLeft: "3%" }}>
                              {index + 1}.{" "}
                            </span>
                            {` `}{" "}
                            <span style={{ paddingLeft: "3%" }}>{row}</span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
                <div>
                  <b>Note:</b> These tests reports should be submitted together
                  and within 30 days from the date of undergoing such Health
                  Check-Up.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div style={{ paddingLeft: `2%` }}>
              <h5>
                <b>Affinity towards wellness:</b> Insured earns wellness points
                for undertaking any of the fitness and health related activities
                as given below.
              </h5>
              <h5>
                <b>
                  <u>List of Fitness Initiatives and Wellness points:</u>
                </b>
              </h5>
            </div>
          </li>
          <li>
            <div style={{ paddingLeft: `2%` }}>
              <h5 style={{ fontWeight: "bold" }}>
                <b>
                  Stay Active: Insured earns wellness points on achieving the
                  step count target on star mobile application as mentioned
                  below:
                </b>
              </h5>
            </div>
            <div>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className1={
                          classes.tableCell + ` ` + classes.headCellLeft
                        }
                        style={{
                          border: "1px solid black",
                          color: "black",
                          fontWeight: "800",
                          fontSize: "18px",
                        }}
                      >
                        <b>Average number of steps per day in a policy year</b>
                      </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + ` ` + classes.headCellLeft
                        }
                      >
                        <b>Wellness Points</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        If the average number of steps per day in a policy year
                        are between - 5000 and 7999
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        <b>100</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        If the average number of steps per day in a policy year
                        are between - 8000 and 9999
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        <b>150</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        If the average number of steps per day in a policy year
                        are - 10000 and above
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        <b>200</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        align="center"
                        className={classes.tableCellLeft}
                      >
                        <p>
                          <b>Note:</b>
                        </p>
                        <ul>
                          <li>
                            First month and last month in each policy year will
                            not be taken into consideration for calculation of
                            average number of steps per day under{" "}
                            <b>Stay Active.</b>
                          </li>
                          <li>
                            The mobile app must be downloaded within 30 days of
                            the policy risk start date to avail this benefit.
                          </li>
                          <li>
                            The average step count completed by an Insured
                            member would be tracked on star wellness mobile
                            application.
                          </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </li>
          <li>
            <div style={{ paddingLeft: `2%` }}>
              <h5 style={{ fontWeight: "bold" }}>
                <b>(a) Weight Management Program:</b>
              </h5>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                This Program will help the Insured persons with Over Weight and
                Obesity to manage their Body Mass Index (BMI) through the
                empanelled wellness experts who will guide the Insured in losing
                excess weight and maintain their BMI.
                <ul>
                  <li>
                    On acceptance of the Weight Management Program,{" "}
                    <b>Insured earns 50 wellness points.</b>
                  </li>
                  <li>
                    An additional <b>50 wellness points will be awarded</b> in
                    case if the results are achieved and maintained as mentioned
                    below.
                  </li>
                </ul>
                <div>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Sr. No.</b>
                        </TableCell>
                        <TableCell
                          className1={
                            classes.tableCell + ` ` + classes.headCell
                          }
                          style={{
                            border: "1px solid black",
                            color: "black",
                            fontWeight: "800",
                            fontSize: "18px",
                          }}
                        >
                          <b>Name of the Ailment</b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Values to be submitted</b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Criteria to get the Wellness points</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" className={classes.tableCell}>
                          1.
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          Obesity (If BMI is above 29)
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          Height & Weight (to calculate BMI)
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          Achieving and maintaining the BMI between 18 and 29
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className={classes.tableCell}>
                          2.
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          Overweight (If BMI is between 25 and 29)
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          Height & Weight (to calculate BMI)
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          Reducing BMI by <b>two points</b> and maintaining the
                          same BMI in the policy year
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          - Values (for BMI) shall be submitted for every 2
                          months (<b>up to 5 times in each policy year</b>)
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
                </div>
              </li>
            </ul>
            <ul style={{ listStyle: "none",marginLeft: `-3%`}}>
              <li>
                <b>(b)</b> Incase if the Insured is not Overweight / Obese, the
                Insured can submit his/her{" "}
                <b>
                  Fitness Success Story with us, on how the Insured Started /
                  Improved /Maintaining his/her “Active/Healthy Life Style”
                  through adoption of Star Wellness Activities.
                </b>
              </li>
              <li>
                {" "}
                On submission of the Fitness Success Story through adoption of
                Star Wellness Activities, Insured earns <b>50</b> wellness
                points.
              </li>
            </ul>
          </li>

          <li>
            <div style={{ paddingLeft: `2%` }}>
              <h5 style={{ fontWeight: "bold" }}>
                <b>(a). Chronic Condition Management Program:</b>
              </h5>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                This Program will help the Insured suffering from{" "}
                <b>Diabetes, Hypertension</b>{" "}
                to track their health through the empanelled wellness experts
                who will guide the insured in maintaining/ improving the health
                condition.
                <ul style={{ listStyle: "disc" }}>
                  <li>
                    On acceptance of the Chronic Condition Management Program,{" "}
                    <b>Insured earns 100 wellness points</b>
                  </li>
                  <li>
                    The Insured has to submit the test result values for every 3
                    months maximum up to 3 times in a policy year
                  </li>
                  <li>
                    If the test result values are within +/- 10% range of the
                    values given below, for at least 2 times in a policy year,{" "}
                    <b>an additional 150 wellness points will be awarded.</b>
                  </li>
                  <li>
                    These tests reports to be submitted within 1 month from the
                    date of undergoing the Health Check-Up
                  </li>
                </ul>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Sr. No. </b>
                        </TableCell>
                        <TableCell
                          className1={
                            classes.tableCell + ` ` + classes.headCell
                          }
                          style={{
                            border: "1px solid black",
                            color: "black",
                            fontWeight: "800",
                            fontSize: "18px",
                          }}
                        >
                          <b>Name of the Ailment </b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>Test to be submitted</b>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          <b>
                            Values Criteria to get the additional Wellness
                            points
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          rowSpan={2}
                          align="center"
                          className={classes.tableCell}
                        >
                          <b>1.</b>
                        </TableCell>
                        <TableCell
                          rowSpan={2}
                          align="center"
                          className={classes.tableCell}
                        >
                          Diabetes(Insured can submit either HbA1c test value
                          (or) Fasting Blood Sugar (FBS) Range & Postprandial
                          test value)
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          HbA1c
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          ≤ 6.5
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className={classes.tableCell}>
                          Fasting Blood Sugar (FBS) Range and Postprandial test
                          value
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          100 to 125 mg/dl below 160 mg/dl
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          rowSpan={1}
                          align="center"
                          className={classes.tableCell}
                        >
                          <b>2.</b>
                        </TableCell>
                        <TableCell
                          rowSpan={1}
                          align="center"
                          className={classes.tableCell}
                        >
                          Hypertension
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          Measured with - BP apparatus
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          Systolic Range - 110 to 140 mmHg Diastolic Range - 70
                          to 90 mmHg
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </li>
              <li style={{marginLeft: `-5%`}} >
                <b>(b).</b> In case if the Insured is not suffering from Chronic
                Condition/s (Diabetes, Hypertension, Cardiovascular Disease or
                Asthma) he/she can opt for{" "}
                <b>“De-Stress & Mind Body Healing Program”.</b> This program
                helps the Insured to reduce stress caused due to internal
                (self-generated) & external factors and increases the ability to
                handle stress.
                <ul style={{ listStyle: "none" }}>
                  <li>
                    - On acceptance of De-stress & Mind Body Healing Program
                    Insured earns <b>50</b> wellness points.
                  </li>
                  <li>
                    - On completion of De-stress & Mind Body Healing Program
                    Insured earns an additional <b>75</b>
                    wellness points.
                  </li>
                </ul>
                <p>
                  <b>Note:</b> This is a 10 weeks program which insured needs to
                  complete without any break.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <b>Virtual Consultation Service: ‘Medical Consultation’</b> is
            available through our in-house Medical Practitioners/Empanelled
            Service providers round the clock to the insured through an online
            portal, mobile application as a chat service, voice call or a call
            back service. Consultations including on <b>‘Diet & Nutrition’</b>{" "}
            and <b>‘Second Medical Opinion’</b> is available.
          </li>
          <li>
            <b>Medical Concierge Services:</b> The Insured can also contact
            Star Health to avail the following services:
            <ul style={{ listStyle: "none" }}>
              <li>
                - Emergency assistance information such as nearest ambulance /
                hospital / blood bank etc.
              </li>
            </ul>
          </li>
          <li>
            <b>Period & Fertility Tracker:</b> The online easy tracking program
            helps every woman with their period health and fertility care. The
            program gives access to trackers for period and ovulation which maps
            out cycles for months. This helps in planning for conception
            prevention and tracks peak ovulation if planning pregnancy.
          </li>
          <li>
            <b>Digital Health Vault:</b> A secured Personal Health records
            system for Insured to store/access and share health data with
            trusted recipients. Using this portal, Insured can store their
            health documents (prescriptions, lab reports, discharge summaries
            etc.), track health data add family members.
          </li>
          <li>
            <b>Wellness Content:</b> The wellness portal provides rich
            collection of health articles, blogs, tips and other health and
            wellness content. The contents have been written by experts drawn
            from various fields. Insured will benefit from having one single and
            reliable source for learning about various health aspects and
            incorporating positive health changes.
          </li>
          <li>
            <div style={{ paddingLeft: `0%` }}>
              <b>Health Quiz & Gamification:</b>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                - The wellness portal provides a host of Health & Wellness
                Quizzes. The wellness quizzes are geared towards helping the
                Insured to be more aware of various health choices.
              </li>
              <li>
                - Gamification helps in creating fun and engaging health &
                wellness experiences. It helps to create a sense of achievement
                in users and increases motivation levels.
              </li>
            </ul>
          </li>
          <li>
            <b>Post Operative Care:</b> It is done through follow up phone calls
            (primarily for surgical cases) for resolving their medical queries.
          </li>
          <li>
            <b>Discounts from Network Providers:</b> The Insured can avail
            discounts on the services offered by our network providers which
            will be displayed in our website.
          </li>
        </ol>
        <div style={{ paddingLeft: `2%` }}>
          <h5 style={{ fontWeight: "bold" }} className={classes.exclusionFont}>
            <b>Terms and conditions under wellness activity</b>
          </h5>
        </div>
        <div>
          <ul>
            <li>
              Any information provided by the Insured in this regard shall be
              kept confidential.
            </li>
            <li>
              There will not be any cash redemption against the wellness reward
              points.
            </li>
            <li>
              Insured should notify and submit relevant documents, reports,
              receipts etc for various wellness activities within 1 month of
              undertaking such activity/test.
            </li>
            <li>
              No activity, report, document, receipt can be submitted in the
              last month of each policy year.
            </li>
            <li>
              For services that are provided through empanelled service
              provider, Star Health is only acting as a facilitator; hence would
              not be liable for any incremental costs or the services.
            </li>
            <li>
              All medical services are being provided by empanelled health care
              service provider. We ensure full due diligence before empanelment.
              However Insured should consult his/her doctor before
              availing/taking the medical advices/services. The decision to
              utilize these advices/services is solely at Insured person’s
              discretion.
            </li>
            <li>
              We reserve the right to remove the wellness reward points if found
              to be achieved in unfair manner.
            </li>
            <li>
              Star Health, its group entities, or affiliates, their respective
              directors, officers, employees, agents, vendors, are not
              responsible or liable for, any actions, claims, demands, losses,
              damages, costs, charges and expenses which a Member claims to have
              suffered, sustained or incurred, by way of and / or on account of
              the Wellness Program.
            </li>
            <li>
              Services offered are subject to guidelines issued by IRDAI from
              time to time.
            </li>
          </ul>
        </div>
        <div>
          <div style={{ marginTop: "2%" }}>
            <p>
              <b>
                <u>ILLUSTRATION OF BENEFITS:</u>
              </b>
            </p>
          </div>
          <div style={{ paddingLeft: `2%` }}>
            <div>
              <h5>
                <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                  <b>
                    {" "}
                    Lets look how the Insured can avail discount on premium
                    through the “Star Wellness Program”
                  </b>
                </p>
              </h5>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableRow}>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        className={
                          classes.tableCell + ` ` + classes.headCellLeft
                        }
                        style={{ fontWeight: "normal" }}
                      >
                        <b>Scenario – 1</b>
                        <p>
                        A 24 year old Individual Ramesh buys Star Critical Illness Multipay Insurance Policy on 15th July, 2021 with Sum Insured of 25 Lacs, let’s understand how he can earn Wellness Points by doing different wellness activities. Ramesh has declared that his Body Mass Index (BMI) as 25. Ramesh enrolled under the Star Wellness Program and completed the following <b>wellness activities.</b>
                        </p>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        <b>Sr.No</b>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        <b>
                          Name of the wellness activity taken up during the
                          policy year
                        </b>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        <b>Wellness Points Earned</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table9.map((row, index) => (
                      <TableRow key={row[0]}>
                        <TableCell align="left" className={classes.tableCell}>
                          {index + 1}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[0]}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[1]}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                      ></TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <b>Total Number of Wellness Points earned</b>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <b>825</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        align="left"
                        className={classes.tableCellLeft}
                      >
                        <b>
                          Based on the number of Wellness Points earned Ramesh
                          is eligible to get 10% discount on renewal premium.
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </div>
          <div style={{ marginTop: "2%" }}>
            <p>
              <b>
                <u>ILLUSTRATION OF BENEFITS:</u>
              </b>
            </p>
          </div>
          <div style={{ paddingLeft: `2%` }}>
            <div>
              <h5>
                <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                  <b>
                    {" "}
                    Lets look how the Insured can avail discount on premium
                    through the “Star Wellness Program”
                  </b>
                </p>
              </h5>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableRow}>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        className={
                          classes.tableCell + ` ` + classes.headCellLeft
                        }
                        style={{ fontWeight: "normal" }}
                      >
                        <b>Scenario – 2</b>
                        <p>
                        A 35 year old Individual Umesh buys Star Critical Illness Multipay Insurance Policy for two year period, with Sum Insured of 20 lacs, let’s understand how he can earn Wellness Points by doing different wellness activities. He is suffering from Hypertension. Umesh enrolled under the Star Wellness Program and completed the following{" "}
                          <b>wellness activities.</b>
                        </p>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        <b>Sr.No</b>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        <b>
                        Name of the wellness activity taken up during the policy year
                        </b>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        <b>Wellness Points Earned in the First Year</b>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        <b>Wellness Points Earned in the Second Year</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table10.map((row, index) => (
                      <TableRow key={row[0]}>
                        <TableCell align="left" className={classes.tableCell}>
                          {index + 1}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[0]}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[1]}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row[2]}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                      ></TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <b>Total Number of Wellness Points earned</b>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <b>950</b>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <b>850</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        align="left"
                        className={classes.tableCellLeft}
                      >
                        <b>
                        Total Number of Wellness Points earned by Umesh = 1800 (950+850)
                        <br/>
                        Calculation of Wellness Points as per two year policy condition =  900 (1800/2)
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        align="left"
                        className={classes.tableCellLeft}
                      >
                        <b>
                        Based on the number of Wellness Points earned, Umesh is eligible to get 10% discount on renewal premium
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </div>
          </div>
      </GridItem>
    </GridContainer>
  );
};
ComprehensiveWellnessBenefits.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(ComprehensiveWellnessBenefits);
