import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
// import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "components/Accordion/Accordion.jsx";
import { Helmet } from "react-helmet";


const table1 = [
    ['Eligibility', '18-65 years, thereafter with lifelong renewals'],
    ['Policy Term', '1 year'],
    ['Sum Insured (in Lakhs)', '2, 3, 4, 5, 10'],
    ['Product type', 'Individual'],
    ['Geographical scope', 'Covered in Republic of India, Payable in Indian Rupees'],
    ['Family Discounts', <h5 key="modern_treatment">
    <h5 key="modern_treatment1" style={{fontSize:'14px',fontWeight:'400'}}>5% for up to 2 members</h5>
    <h5 key="modern_treatment2" style={{fontSize:'14px',fontWeight:'400'}}>10% for more than 2 members</h5></h5>],
]

const faq=[
    {
        title:'Why should I buy a critical illness policy if I already have a health cover?',
        description:<h5>A basic medical insurance plan <b>covers only the hospitalization expenses</b> and pre and post-hospitalisation expenses. It may not be sufficient to cover the medical costs incurred for critical illness treatments. a critical illness health insurance plan pays a lump-sum payment in the event of diagnosis of any listed critical illness. The lump sum payment becomes very handy. Example:- With the onset of stroke, the insured might require additional expenses for recovery from stroke like patient care assistance, physiotherapy etc,</h5>,
        content:false
    },
    {
        title:'How does the Star Criticare Plus Insurance Policy work?',
        description:<h5>The Star Criticare Plus Policy keeps paying you for all hospitalisation medical expenses and on the first diagnosis of critical illness. A lump-sum compensation is payable on an admissible claim made for critical illness specified under the policy. Once the critical illness payment is made, the cover for critical illness ceases and the policy continues for general hospitalisation. However, there is a waiting period of 90 days for critical illnesses.</h5>,
        content:false
    },
    {
        title:'Is there a medical test that I need to undergo to enroll myself in the policy?',
        description:<h5>The policy requires a pre-insurance medical screening to get enrolled.</h5>,
        content:false
    },
    {
        title:'Is there a waiting period for covering critical illnesses?',
        description:<h5>Yes, there is a waiting period of 90 days for major/critical illnesses.</h5>,
        content:false
    },
    {
        title:'Is the free-look option available in this policy?',
        description:<h5>Yes. A free-look period of 15 days from the date of receipt of policy document. this is facilitated to review the policy terms and conditions.</h5>,
        content:false
    },
    {
        title:'What is the entry age for people who are opting for a critical illness plan?',
        description:<h5>The entry age for Star Criticare Plus Insurance Policy is between 18-65 years. Beyond 65 years, only renewals are allowed for lifelong.</h5>,
        content:false
    },
    {
        title:'What is the minimum and maximum sum insured of Star Criticare Plus Insurance policy?',
        description:<h5>The plan provides a sum insured option ranging from a minimum of Rs. 2 lakhs and Rs. 10 lakhs. However, the maximum sum insured is limited to Rs 2 lakhs for people between 60 and 65 years, and coverage is bound to the limits specified in the policy.</h5>,
        content:false
    },
    {
        title:'Are Pre-existing diseases covered under this policy?',
        description:<h5>Yes, Pre-existing diseases and specified illnesses are also covered after 48 months and 24 months respectively under indemnity section of Star Criticare Plus Insurance Policy. </h5>,
        content:false
    },
    {
        title:'Is it necessary for me to buy a critical illness cover along with health insurance?',
        description:<h5>Yes, as a health insurance specialist, we suggest you have critical insurance in place to avoid major critical illnesses deterring your normal life if such situations arise. Just like you have regular health insurance, a critical illness plan acts as an additional benefit and financial aid if anything worse were to happen. Hence, adding the Star Criticare plus Insurance Policy to your health insurance portfolio becomes all the more important given the rising cost of health care.</h5>,
        content:false
    },
    {
        title:'Are delivery expenses covered under this policy?',
        description:<h5>As this Policy is designed for Critical illness Expenses due to delivery and infertility-related treatments are not covered under this insurance policy.</h5>,
        content:false
    },
]
        

const CriticcareplusHighlights = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });
    return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            STAR CRITICARE PLUS INSURANCE POLICY
            </h2>
            </GridItem>
                <h5>
                We all fall sick at some point in life. Whether it might be a simple cold or a more severe condition, regular doctor visits are bound to occur. However, some illnesses take a toll on our lives both in terms of health and finances. Such situations might require timely treatment and care that could burn a hole in your pocket.
                </h5>
             <h5>
                Critical diseases like cancer, stroke, etc., are increasing in incidence. Unfortunately, even the young aren’t spared as there is a growing prevalence of these diseases in younger populations. What’s alarming is that the cases of cancer and cancer-led deaths have gone up in the last 10 years. But, let us not get worried or alarmed as there are things that we can do to mitigate the effects of these diseases and illnesses.
             </h5>
             <h5>
                Although switching to a healthy lifestyle can help reduce the likelihood of these diseases, it is imperative to have a health insurance plan so that you and your family are financially protected if the worse were to happen and be prepared for any complex medical treatment in the future. And we hope that you do not need to use it even if you have purchased one.
            </h5>
             <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            What is critical illness insurance?
            </h2>
            </GridItem>
             <h5>A sudden financial crisis would be a burden for you and your family. A critical insurance policy gives you cover for life-threatening critical illnesses like cancer, irreversible paraplegia, irreversible quadriplegia and a few more. Few insurers give a lump-sum payment on the onset of life-threatening critical illnesses. With a critical illness insurance cover, you can easily handle the medical expenses while getting quality treatment.  Once the policy is purchased, you can also utilise the cover for general hospitalisation.</h5>
             <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Coverage under Star Criticare Plus Insurance Policy
            </h2>
            </GridItem>
             <ol>
                <li>
                In-patient hospitalisation expenses
                </li>
                <li>
                Cover for listed critical illness conditions
                </li>
                <li>
                Pre and post-hospitalisation expenses
                </li>
                <li>
                Emergency ambulance expenses
                </li>
                <li>
                Modern treatments
                </li>
                <li>
                AYUSH treatments
                </li>
                <li>
                Tele-Medicine Service - ‘Talk to Star’
                </li>
                </ol>
        <div>
        <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Product Features
            </h2>
            </GridItem>
        <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={1}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table1.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={1}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Features of Star Criticare Plus Insurance Policy
            </h2>
            </GridItem>
                <h5>
                <b>Eligibility</b>
                <br />
                <h5>
                This Policy can be purchased by individuals between the ages of 18 years and 65 years.
                </h5>
                </h5>
                <h5>
                <b>Sum insured</b>
                <br />
                <h5>
                The plan provides sum insured options ranging from a minimum of Rs. 2 lakhs to Rs. 10 lakhs. However, the maximum sum insured is limited to Rs 2 lakhs for people between 60 and 65 years, and coverage is bound to the limits specified in the policy.
                </h5>
                </h5>
                <h5>
                <b>Product type</b>
                <br />
                <h5>
                This Policy is a benefit based policy which also has indemnity cover. This policy is available on an individual basis.
                </h5>
                </h5>
                <h5>
                <b>Co-payment</b>
                <br />
                <h5>
                A co-payment of 30% for every claim is applicable for those who purchase this policy at the age of 61 and above. This means that every time a claim is filed with the company, the insured will be required to pay a fixed 30% of the admissible claim, and then Star Health Insurance will process the rest of the claim.
                </h5>
                </h5>
                <h5>
                <b>Waiting Periods</b>
                <br />
                <h5>
                <ul>
                    <li>Illness contracted within 30 days of the policy’s date of inception is not covered, except for accidents.</li>
                    <li>Pre-existing diseases are covered after a waiting period of 48 months from the date of inception of the first policy without any break.</li>
                    <li>Coverage for specified illnesses/surgeries becomes effective after 24 months.</li>
                    <li>The waiting period for critical illnesses is 90 days.</li>
                </ul>
                </h5>
                </h5>
                <h5>
                <b>Portability</b>
                <br />
                <h5>
                According to IRDAI guidelines on portability, the insured person can port the policy to another insurer by applying to that insurer to port the entire policy. They can port at least 45 days before, but no earlier than 60 days before the policy renewal date.
                <br/>
                Note:- This policy, is issued on individual basis so the word all family members does not make sense.
                </h5>
                </h5>
                <h5>
                <b>Free look period</b>
                <br />
                <h5>
                A free look period of 15 days from the date of receipt of the policy document is available for reviewing the policy’s terms and conditions. If the insured is not satisfied with the plan, they will have an option of cancelling the policy within the stipulated period. In such a case, the company will process the premium refund after adjusting the cost incurred during that period.
                </h5>
                </h5>
                <h5>
                <b>Pre-acceptance and medical screening</b>
                <br />
                <h5>
                Pre-insurance medical screening is required as per Star underwriting norms.
                </h5>
                </h5>
                <h5>
                <b>Lifelong renewal option</b>
                <br />
                <h5>
                This Policy offers a lifelong renewal option. If the insured renews the policy within the due date, he can enjoy continuous benefits like a reduced waiting period on coverage of PED,. A grace period of 30 days is provided to renew the policy. By renewing the policy on time, an insured can enjoy the continuity benefits.
                </h5>
                </h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            What is covered in the Star Criticare Plus Insurance Policy?
            </h2>
            </GridItem>
            <h5><b>Indemnity and/or Lump Sum</b></h5>
            <h5>
            The Star Criticare Plus Insurance Policy will cover hospitalisation expenses if the insured person is hospitalised for in-patient treatment for at least 24 hours due to illness/injury. In addition, a lump-sum payment on the first diagnosis of critical illness will be payable. For example: paying a premium for sum insured of Rs. 10 lakhs, then the coverage for critical illness will be 10 lakhs lump-sum and 10 lakhs in-patient hospitalisation.
            </h5>
            <ul>
                <li>Expenses towards room rent, boarding, and nursing up to 2% of sum insured or a maximum of Rs. 4000 per day. (which is lower)</li>
                <li>Expenses towards anesthesia, blood, oxygen, operation theatre charges, ICU charges, surgical appliances, medicines and drugs, diagnostic materials and X-ray, diagnostic imaging modalities, dialysis, chemotherapy, radiotherapy, cost of a pacemaker, stent, and such other similar expenses.</li>
                <li>Expenses towards the surgeon, anesthetist, medical practitioner, consultants, specialist fees.</li>
            </ul>
            <h5><b>Major illnesses covered</b></h5>
            <h5>The following major illnesses/diseases occurring for the first time are covered under the policy:</h5>
            <ol>
                <li>Cancer</li>
                <li>Chronic kidney disease</li>
                <li>Brain tumor</li>
                <li>Major organ transplantation </li>
                <li>Cerebro-Vascular stroke-causing Hemiplegia</li>
                <li>Acute Myocardial Infarction (Left Ventricular Ejection Fraction of &lt; 25%)</li>
                <li>Established irreversible coma</li>
                <li>Established irreversible paraplegia</li>
                <li>Established irreversible quadriplegia</li>
            </ol>
            <h5><b>Note:</b> The holder can avail lump-sum compensation for a major illness only once during their lifetime. </h5>
            <h5><b>Pre- and post-hospitalisation expenses</b></h5>
            <h5>Star Criticare Insurance Policy covers related pre-hospitalisation expenses for a maximum of 30 days before getting admitted. Also, it covers post-hospitalisation expenses paid out as lump sum up to 7% of the hospitalisation (excluding room charges) subject to a maximum of Rs 5000.</h5>
            <h5><b>Emergency ambulance expenses</b></h5>
            <h5>The policy covers ambulance charges for emergency transportation to the hospital up to Rs 750 per hospitalisation, with an overall limit of Rs 1500 per policy period.</h5>
            <h5><b>Modern treatments</b></h5>
            <h5>The Star Criticare Plus Policy covers modern treatments such as balloon sinuplasty, robotic surgeries, intravitreal injections, etc. The coverage limit for each treatment differs according to the sum insured opted under the policy.</h5>
            <h5><b>AYUSH treatments</b></h5>
            <h5>The policy covers expenses for AYUSH treatments (Ayurveda, Unani, Sidha, and Homeopathy) received in any Ayush Day care / Hospital. They can avail coverage up to 25% of the sum insured subject up to a maximum of Rs 25000 per policy period.</h5>
            <h5><b>Accidents covered</b></h5>
            <h5>The policyholder will get coverage for accidents due to injuries from day one without any waiting period. All in-patient hospitalisation expenses related to accidental injuries are covered.</h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Unique features of Star Criticare Plus Insurance policy
            </h2>
            </GridItem>
            <ol type="a" >
                <li><b>Lump-sum payment benefit</b>
                <h5>This Policy provides lump-sum payments <b>on the first diagnosis of critical illness after a waiting period of 90 days</b></h5></li>
                <li><b>Cover until policy expiry</b>
                <h5>On payment of lump-sum of critical illness cover,<b> this policy cannot be renewed. However, the insured person will be offered another individual indemnity policy.</b></h5></li>
            </ol>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            What is not covered?
            </h2>
            </GridItem>
            <h5>
            <b>The following is a partial listing of policy exclusions. Refer the policy document for complete list of exclusions</b>
            </h5>
            <ul>
                <li>Any hospital admission primarily for investigation diagnostic purpose</li>
                <li>Pregnancy, infertility, congenital external disease/defects</li>
                <li>Domiciliary treatment, treatment outside India</li>
                <li>Circumcision, gender-change surgery, cosmetic surgery, and plastic surgery</li>
                <li>Refractive error correction (less than 7.5 Diopters), hearing impairment correction, corrective & cosmetic dental surgeries</li>
                <li>Substance abuse, Intentional self-injury, war, terrorism, civil war or breach of law, Hazardous sports</li>
                <li>Naturopathy treatment</li>
                <li>Any service charge, surcharge, admission fees, registration fees levied by the hospital.</li>
            </ul>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Why should you buy Critical Illness Insurance?
            </h2>
            </GridItem>
            <h5>
            Life-threatening conditions like cancer, stroke, liver disease, and kidney diseases, require a critical illness plan to avoid the financial burden, mitigate the losses and have peace of mind.
            </h5>
            <h5>
            Our evolving lifestyles and cultures are making us prey to non-communicable critical health issues. In such a case, a basic Mediclaim policy may not be sufficient to cover all medical costs, especially in the case of a critical illness which requires long-term treatment and substantial financial expenditure
            </h5>
            <h5>
            This financial upheaval can be supported by a particular financial protection plan like the Critical Illness Plan. We recommend you purchase a critical illness plan along with a health insurance plan. It is advisable to buy a critical illness policy at an early age as health risks are less, and you can avail the cover.
            </h5>
            <h5>
            The benefits of <b>Critical Illness Plans/Policies are</b> manifold. With critical illness plans, you not only avail of good quality treatment but also get spared from shouldering heavy financial burdens yourself during difficult times.
            </h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Why choose the Star Criticare Plus Insurance Policy from Star Health Insurance Company? 
            </h2>
            </GridItem>
            <ol>
                <li><b>Cash-less benefit from Network Hospitals</b>
                <h5>We provide health care service from an ever-increasing list of Network Hospitals where you can opt for cash-less treatment. Our aim is that you should have easy access to a network hospital near you in times of planned or unplanned medical expenses. Star Health Insurance currently has a network of over 13,000+ hospitals. For the complete list, <a href="/network-hospitals">visit here</a> </h5></li>
                <li><b>Hassle-free claim process</b>
                <h5>Insurance Claim Settlement processes can often be time-consuming. However, Star Health Insurance offers digital-friendly, zero-touch, quick, and convenient options that work better for you during this pandemic situation with our In-house claim settlement.</h5></li>
                <li><b>Tax benefits</b>
                <h5>Individuals and family members below 60 years can get Rs. 25,000 deductions from taxable income, and the deductible amount can extend up to Rs. 50,000 for senior citizens on premiums paid as per Section 80D of Income Tax Act, 1961. If two family members above the age of 60 are insured under one policy, then the deductible amount extends to Rs 1 lakh. In contrast, if one family member below the age of 60 and his parents are also insured under the same policy, they can avail of a deduction of Rs 75,000.</h5></li>
            </ol>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            FAQ’S
            </h2>
            </GridItem>
            <div>
            <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer
                className={`${classes.container} ${classes.textCenter}`}
                >
                <GridItem
                    md={12}
                    style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                ></GridItem>
                <GridItem
                style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <Accordion active={0} collapses={AccordionArray} />
                </GridItem>
                </GridContainer>
            </div>
            </div>
        </div>

      </GridItem>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet>
           <script type="application/ld+json">
            {`
            {
                "@context":"https://schema.org",
                "@type":"FAQPage",
                "mainEntity":[
                   {
                      "@type":"Question",
                      "name":"Why should I buy a critical illness policy if I already have a health cover?",
                      "acceptedAnswer":{
                         "@type":"Answer",
                         "text":"A basic medical insurance plan covers only the hospitalization expenses and pre & post-hospitalisation expenses. It may not be enough to cover the medical costs incurred for critical illness treatments. In contrast, a critical illness health insurance plan pays a lump-sum payment in the event of diagnosis of any listed critical illness"
                      }
                   },
                   {
                      "@type":"Question",
                      "name":"How does the Star Criticare Plus Insurance Policy work?",
                      "acceptedAnswer":{
                         "@type":"Answer",
                         "text":"The Star Criticare Plus Policy keeps paying you for all hospitalisation medical expenses and on the first diagnosis of critical illness. A lump-sum compensation is payable on an admissible claim made for critical illness specified under the policy. Once the critical illness payment is made, the cover for critical illness ceases and the policy continues for general hospitalisation. However, there is a waiting period of 90 days for critical illnesses"
                      }
                   },
                   {
                      "@type":"Question",
                      "name":"Is there a medical test that I need to undergo to enroll myself in the policy?",
                      "acceptedAnswer":{
                         "@type":"Answer",
                         "text":"The policy requires a pre-insurance medical screening to get enrolled."
                      }
                   },
                   {
                      "@type":"Question",
                      "name":"Is there a waiting period for covering critical illnesses?",
                      "acceptedAnswer":{
                         "@type":"Answer",
                         "text":"Yes, there is a waiting period of 90 days for major/critical illnesses."
                      }
                   },
                   {
                      "@type":"Question",
                      "name":"Is the free-look option available in this policy?",
                      "acceptedAnswer":{
                         "@type":"Answer",
                         "text":"Yes. A free-look period of 15 days is facilitated to review the policy terms and conditions by the insured from the date of receipt of policy document."
                      }
                   },
                   {
                      "@type":"Question",
                      "name":"What is the entry age for people who are opting for a critical illness plan?",
                      "acceptedAnswer":{
                         "@type":"Answer",
                         "text":"The entry age for Star Criticare Plus Insurance Policy is between 18-65 years. Beyond 65 years, only renewals are allowed for lifelong."
                      }
                   },
                   {
                      "@type":"Question",
                      "name":"What is the minimum and maximum sum insured of Star Criticare Plus Insurance policy?",
                      "acceptedAnswer":{
                         "@type":"Answer",
                         "text":"The plan provides a sum insured option ranging from a minimum of Rs. 2 lakhs and Rs. 10 lakhs. However, the maximum sum insured is limited to Rs 2 lakhs for people between 60 and 65 years, and coverage is bound to the limits specified in the policy."
                      }
                   },
                   {
                      "@type":"Question",
                      "name":"Are Pre-existing diseases covered under this policy?",
                      "acceptedAnswer":{
                         "@type":"Answer",
                         "text":"Yes, Pre-existing diseases and specified illnesses are also covered after 48 months and 24 months respectively under indemnity section of Star Criticare Plus Insurance Policy."
                      }
                   },
                   {
                      "@type":"Question",
                      "name":"Is it necessary for me to buy a critical illness cover along with health insurance?",
                      "acceptedAnswer":{
                         "@type":"Answer",
                         "text":"Yes, as a health insurance specialist, we suggest you have critical insurance in place to avoid major critical illnesses deterring your normal life if such situations arise. Just like you have regular health insurance, a critical illness plan acts as an additional benefit and financial aid if anything worse were to happen. Hence, adding the Star Criticare Plus Insurance Policy to your health insurance portfolio becomes all the more important given the rising cost of health care."
                      }
                   },
                   {
                      "@type":"Question",
                      "name":"Are delivery expenses covered under this policy?",
                      "acceptedAnswer":{
                         "@type":"Answer",
                         "text":"As this Policy is designed for Critical illness Expenses due to delivery and infertility-related treatments are not covered under this insurance policy."
                      }
                   }
                ]
             }
            `}
           </script>
           {/* <script type="application/ld+json">
{`{ "@context": "http://schema.org",
  "@type": "Product",
  "name": "Star Criticare Plus Insurance Policy",
  "description": "Buy Critical Illness Insurance Policy that provides hospitalization expenses along with critical illness benefit. Now it is easy to buy Critical Illness Insurance Policy online."
}`}
</script> */}
        </Helmet>)}
    </GridContainer>
  );
};
CriticcareplusHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(CriticcareplusHighlights);