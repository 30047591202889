"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _Radio = require("@material-ui/core/Radio");

var _Radio2 = _interopRequireDefault(_Radio);

var _RadioGroup = require("@material-ui/core/RadioGroup");

var _RadioGroup2 = _interopRequireDefault(_RadioGroup);

var _FormControlLabel = require("@material-ui/core/FormControlLabel");

var _FormControlLabel2 = _interopRequireDefault(_FormControlLabel);

var _FormControl = require("@material-ui/core/FormControl");

var _FormControl2 = _interopRequireDefault(_FormControl);

var _FormLabel = require("@material-ui/core/FormLabel");

var _FormLabel2 = _interopRequireDefault(_FormLabel);

var _index = require("../../../../core/dist/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
  return {
    root: {
      display: "flex"
    },
    formControl: {
      margin: theme.spacing.unit * 3
    },
    group: {
      margin: theme.spacing.unit + "px 0"
    }
  };
};

var MaterialUiRadioGroup = function (_React$Component) {
  _inherits(MaterialUiRadioGroup, _React$Component);

  function MaterialUiRadioGroup() {
    _classCallCheck(this, MaterialUiRadioGroup);

    return _possibleConstructorReturn(this, (MaterialUiRadioGroup.__proto__ || Object.getPrototypeOf(MaterialUiRadioGroup)).apply(this, arguments));
  }

  _createClass(MaterialUiRadioGroup, [{
    key: "render",
    value: function render() {
      // $FlowFixMe - HOC adds this class
      var classes = this.props.classes;

      var _props = this.props,
          disabled = _props.disabled,
          id = _props.id,
          isValid = _props.isValid,
          name = _props.name,
          _props$options = _props.options,
          options = _props$options === undefined ? [] : _props$options,
          placeholder = _props.placeholder,
          required = _props.required,
          value = _props.value,
          label = _props.label,
          onFieldChange = _props.onFieldChange;


      var items = options.reduce(function (itemsSoFar, option) {
        return itemsSoFar.concat(option.items.map(function (item) {
          if (typeof item === "string") {
            return _react2.default.createElement(_FormControlLabel2.default, { value: item, control: _react2.default.createElement(_Radio2.default, null), label: item });
          } else {
            return _react2.default.createElement(_FormControlLabel2.default, {
              value: item.value,
              control: _react2.default.createElement(_Radio2.default, null),
              label: item.label || item.value
            });
          }
        }));
      }, []);

      return _react2.default.createElement(
        _FormControl2.default,
        { component: "fieldset", required: required },
        _react2.default.createElement(
          _FormLabel2.default,
          { component: "legend" },
          label
        ),
        _react2.default.createElement(
          _RadioGroup2.default,
          {
            "aria-label": label,
            name: name,
            value: value,
            onChange: function onChange(evt) {
              onFieldChange(id, evt.target.value);
            },
            id: id
          },
          items
        )
      );
    }
  }]);

  return MaterialUiRadioGroup;
}(_react2.default.Component);

var StyledMaterialUiRadioGroup = (0, _styles.withStyles)(styles)(MaterialUiRadioGroup);

exports.default = function (props) {
  return _react2.default.createElement(
    _index.FieldWrapper,
    props,
    _react2.default.createElement(StyledMaterialUiRadioGroup, null)
  );
};