"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _chevronDown = require("@atlaskit/icon/glyph/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronRight = require("@atlaskit/icon/glyph/chevron-right");

var _chevronRight2 = _interopRequireDefault(_chevronRight);

var _crossCircle = require("@atlaskit/icon/glyph/cross-circle");

var _crossCircle2 = _interopRequireDefault(_crossCircle);

require("./Expander.css");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Chevron = function (_PureComponent) {
  _inherits(Chevron, _PureComponent);

  function Chevron() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Chevron);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Chevron.__proto__ || Object.getPrototypeOf(Chevron)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isFocused: false,
      isHovered: false
    }, _this.handleClick = function () {
      if (_this.props.onExpandToggle) {
        _this.props.onExpandToggle();
      }
    }, _this.handleMouseOver = function () {
      _this.setState({ isHovered: true });
    }, _this.handleMouseOut = function () {
      _this.setState({ isHovered: false });
    }, _this.handleFocus = function () {
      _this.setState({ isFocused: true });
    }, _this.handleBlur = function () {
      _this.setState({ isFocused: false });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Chevron, [{
    key: "render",
    value: function render() {
      var isExpanded = this.props.isExpanded;
      // const { isFocused, isHovered } = this.state;

      var iconProps = {
        size: "medium"
        //   primaryColor: isHovered || isFocused ? iconColorFocus : iconColor
      };
      return _react2.default.createElement(
        "span",
        null,
        _react2.default.createElement(
          "button",
          {
            type: "button"
            //   aria-controls={ariaControls}
            , onClick: this.handleClick,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onMouseOver: this.handleMouseOver,
            onMouseOut: this.handleMouseOut
          },
          isExpanded ? _react2.default.createElement(_chevronDown2.default, _extends({ label: "Collapse" }, iconProps)) : _react2.default.createElement(_chevronRight2.default, _extends({ label: "Expand" }, iconProps))
        )
      );
    }
  }]);

  return Chevron;
}(_react.PureComponent);

var Expander = function (_Component) {
  _inherits(Expander, _Component);

  function Expander() {
    var _ref2;

    var _temp2, _this2, _ret2;

    _classCallCheck(this, Expander);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp2 = (_this2 = _possibleConstructorReturn(this, (_ref2 = Expander.__proto__ || Object.getPrototypeOf(Expander)).call.apply(_ref2, [this].concat(args))), _this2), _this2.state = {
      isExpanded: false
    }, _temp2), _possibleConstructorReturn(_this2, _ret2);
  }

  _createClass(Expander, [{
    key: "render",
    value: function render() {
      var _this3 = this;

      var isExpanded = this.state.isExpanded;
      var _props = this.props,
          children = _props.children,
          label = _props.label,
          remove = _props.remove;


      return _react2.default.createElement(
        "div",
        { className: "expander" },
        _react2.default.createElement(Chevron, {
          key: "chevron",
          isExpanded: isExpanded,
          onExpandToggle: function onExpandToggle() {
            return _this3.setState(function (prevState) {
              return {
                isExpanded: !prevState.isExpanded
              };
            });
          }
        }),
        _react2.default.createElement(
          "span",
          { className: "label" },
          label
        ),
        _react2.default.createElement(_crossCircle2.default, { onClick: remove }),
        _react2.default.createElement(
          "div",
          {
            className: "content",
            style: { display: isExpanded ? "block" : "none" }
          },
          children
        )
      );
    }
  }]);

  return Expander;
}(_react.Component);

exports.default = Expander;