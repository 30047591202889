import React from 'react';
// @material-ui/core components
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import FormProcessor from '@ps/react-forms/processor/FormProcessor';
import wizardStyle from 'ps-assets/jss/wizardStyle.jsx';
import CustomStepper from 'ps-components/CustomStepper/CustomStepperAcci';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import GridItem from 'components/Grid/GridItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import root, { console, parseInt } from 'window-or-global';
import ReactSVG from 'react-svg';
import accidentRelation from './AccidentRelation';
import { withPrefix } from 'gatsby';
import { getSecuredLocalStorageItem } from 'util/localStorageHelper.js';
import { wizardLMSCall, dndCall } from 'util/lmsRequest';
import GridContainer from 'components/Grid/GridContainer';
import classNames from 'classnames';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Radio from '@material-ui/core/Radio';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { browserWindow } from "util/localStorageHelper.js";
import { sevaApp } from 'util/LayoutUtil.jsx';
import Cookies from "js-cookie";

const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;
var md5 = require(`blueimp-md5`);
const CUSTOMERPORTAL_URL = process.env.CUSTOMERPORTAL_URL

const emiOptionsArr = [
  {
    display: `Half yearly EMI Plan`,
    value: `04`
  },
  {
    display: `Quarterly EMI Plan`,
    value: `03`
  }
];

const initialState = {
  name: ``,
  mobile: ``,
  email: ``,
  formResponse: null,
  productId: null,
  formArr: null,
  activeStep: 0,
  policy_cover: null,
  life_cover: null,
  quoteResponse: ``,
  premiumAmount: null,
  errorPremiumAmount: null,
  errorLifePremiumAmount: null,
  lifePremiumAmount: null,
  validForm: true,
  policyQuote: ``,
  formEditDataVal: {},
  sumInsured: [],
  repository: [],
  errorDup: ``,
  scheme: ``,
  formPayload: null,
  starLoading: true,
  dndConsent: true,
  emiPlan: `FP`,
  paymentMode: `FP`,
  displayEMIinfo: false,
  urlInfo: ``
};

class Accident extends React.Component {
  state = initialState;

  enableEMI = () => {
    switch (this.props.productId) {
      case 37:
        return true;
      default:
        return false;
    }
  };

  changePaymentMode = async event => {
    try {
      event.persist();
      // let url = `${CUSTOMER_PORTAL_API_URL}/api/services/authentication/fetch/forms?formId=banklist&formType=formData`;
      // let res = await fetch(url);
      // res = await res.json();
      // this.emiINFO = res;
      if (event.target.value === `FP`)
        this.setState({
          paymentMode: event.target.value,
          emiPlan: `FP`,
          premiumAmount: this.response.policyQuote
        });
      else
        this.setState({
          paymentMode: event.target.value,
          emiPlan: `04`,
          premiumAmount: this.response.hlflyTotalPremium
        });
    } catch (exception) {
      console.log(exception);
    }
  };

  handleDropdownChange = (index, change, e) => {
    let words = [``, `two`, `three`, `four`, `five`];
    let tempFormPayload = JSON.parse(JSON.stringify(this.state.formPayload));
    tempFormPayload.insured[index].policyCover = e.target.value;
    let tempFormEditData = JSON.parse(
      JSON.stringify(this.state.formEditDataVal)
    );
    if (change === `SELF`) {
      tempFormEditData[`insureds_0_sumInsured`].value = e.target.value;
    } else if (change === `SPOUSE`) {
      tempFormEditData[`insureds_1_sumInsuredtwo`].value = e.target.value;
    } else {
      tempFormEditData[`insureds_2_sumInsured` + words[index]].value =
        e.target.value;
    }

    let tempRepo = this.state.repository.slice(0);
    tempRepo[index] = e.target.value;
    this.setState({
      repository: tempRepo.slice(0),
      errorMsg: false,
      formPayload: tempFormPayload,
      formEditDataVal: tempFormEditData
    });
    this.getPremiumAmountAcci(tempFormPayload);
  };

  // setSumInsured = (formData, submitformData) => {
  //   let relationship = {
  //     REL_MED_01: `SELF`,
  //     REL_MED_03: `SPOUSE`,
  //     REL_MED_15: `SON 1`,
  //     REL_MED_16: `SON 2`,
  //     REL_MED_17: `SON 3`,
  //     REL_MED_18: `DAUGHTER 1`,
  //     REL_MED_19: `DAUGHTER 2`,
  //     REL_MED_20: `DAUGHTER 3`
  //   };
  //   let sumInsured = [];
  //   let repositorySum = [];
  //   if (formData.scheme.value !== `Self`) {
  //     let words = [``, `two`, `three`, `four`, `five`];
  //     for (let iter = 0; iter < words.length; iter++) {
  //       let insuredKey = `insureds_1_relationship_id` + words[iter];
  //       let insured = formData[insuredKey];
  //       if (insured && insured.value) {
  //         if (insured.value === `REL_MED_01`) {
  //           sumInsured.push({
  //             [relationship[formData[insuredKey].value]]:
  //               formData.insureds_0_sumInsured.options,
  //             value: formData.insureds_0_sumInsured.value
  //           });
  //           repositorySum.push(formData.insureds_0_sumInsured.value);
  //         } else if (
  //           insured.value === `REL_MED_03` ||
  //           insured.value === `REL_MED_04`
  //         ) {
  //           sumInsured.push({
  //             [relationship[formData[insuredKey].value]]:
  //               formData.insureds_1_sumInsuredtwo.options,
  //             value: formData.insureds_1_sumInsuredtwo.value
  //           });
  //           repositorySum.push(formData.insureds_1_sumInsuredtwo.value);
  //         } else {
  //           let sumInsuredKey = `insureds_2_sumInsured` + words[iter];
  //           sumInsured.push({
  //             [relationship[formData[insuredKey].value]]:
  //               formData[sumInsuredKey].options,
  //             value: formData[sumInsuredKey].value
  //           });
  //           repositorySum.push(formData[sumInsuredKey].value);
  //         }
  //       }
  //     }
  //   } else {
  //     sumInsured.push({
  //       [relationship[formData.insureds_0_relationship_id.value]]:
  //         formData.insureds_0_sumInsured.options,
  //       value: formData.insureds_0_sumInsured.value
  //     });
  //     repositorySum.push(formData.insureds_0_sumInsured.value);
  //   }

  //   this.setState({
  //     sumInsured: sumInsured,
  //     formPayload: submitformData.formPayload,
  //     repository: repositorySum
  //   });
  //   this.getPremiumAmountAcci(submitformData.formPayload);
  // };

  applyCommasForAmount = amount => {
    amount = amount.toString();
    amount = amount.split(`,`).join(``);
    if (amount.length > 3) {
      const newAmount = amount.split(``);
      newAmount.splice(-3, 0, `,`);
      amount = newAmount.join(``);
      if (amount.length > 6) {
        const newAmount = amount.split(``);
        newAmount.splice(-6, 0, `,`);
        amount = newAmount.join(``);
      }
      return amount;
    }
    return amount;
  };

  handleInputChange = e => {
    if (e.target.id === `name`) {
      if ((e.target.value.search(`^[a-zA-Z. ']*$`) === 0) && (e.target.value[0] != " ")) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else if (e.target.id === `mobile`) {
      if (!isNaN(e.target.value) && e.target.value.length <= 10) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else if (e.target.id === `dndConsent`) {
      this.setState({
        dndConsent: this.state.dndConsent
      });
    } else this.setState({ [e.target.id]: e.target.value });
  };

  handleChangeEnabled = (input, value) => {
    let policy_cover = this.state.policy_cover,
      life_cover = this.state.life_cover;
    this.setState({ [input]: value });

    if (input === `policy_cover`) policy_cover = value;
    if (input === `life_cover`) life_cover = value;
    this.getPremiumAmount(policy_cover, life_cover);
  };

  clean = obj => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  };

  sumInsuredDisplay = () => {
    const { classes } = this.props;
    let SI = [];
    for (let opt of this.state.sumInsured) {
      let idx = 0;
      let options = [];
      let output = ``;
      let option = ``;
      let optionVal = opt[Object.keys(opt)[0]];
      for (let iter = 0; iter < optionVal.length; iter++) {
        option = (
          <MenuItem value={optionVal[iter].value} key={idx}>
            {optionVal[iter].display}
          </MenuItem>
        );
        options.push(option);
        idx++;
      }
      let change = Object.keys(opt)[0];
      let selectElement = (
        <FormControl
          className={classes.formControl}
          style={{ width: `100%`, marginBottom: `20px` }}>
          <InputLabel htmlFor='repository'>
            {change + ` SUM INSURED`}
          </InputLabel>
          <Select
            value={this.state.repository[this.state.sumInsured.indexOf(opt)]}
            onChange={e => {
              this.handleDropdownChange(
                this.state.sumInsured.indexOf(opt),
                change,
                e
              );
            }}
            inputProps={{
              name: `repository`,
              id: `repository`
            }}>
            {options}
          </Select>
        </FormControl>
      );
      output = (
        <>
          <GridItem style={{ paddingTop: `4px` }}>{selectElement}</GridItem>
        </>
      );
      SI.push(output);
    }
    return SI;
  };

  handleBuyNow = () => {
    let user = getSecuredLocalStorageItem(`user`);
    let insuredArray = this.setStateObject();
    let insured1 = insuredArray && insuredArray[0];
    let insured2 = insuredArray && insuredArray[1];
    let insured3 = insuredArray && insuredArray[3];
    let insured4 = insuredArray && insuredArray[4];
    let insured5 = insuredArray && insuredArray[5];
    let authToken = ``;
    if (user) {
      user = JSON.parse(user);
      authToken = user.token;
      if (authToken) {
        let logOutToken = getSecuredLocalStorageItem(`loggedOut${authToken}`);
        if (logOutToken) {
          authToken = ``;
        }
      }
    }

    let type = this.props.productId == 37 ? `saralBhima` : `accident`;
    let productId = this.props.productId == 37 ? `32` : `17`;
    let normalBuyUrl = `${CUSTOMERPORTAL_URL}/customerportal`;

    normalBuyUrl += `/normalbuy`;

    normalBuyUrl += `?type=${type}&policyType=Individual${
      this.state.name ? `&name=${this.state.name}` : ``
    }${this.state.mobile ? `&mobNo=${this.state.mobile}` : ``}${
      this.state.email ? `&email=${this.state.email}` : ``
    }${
      this.state.scheme ? `&scheme=${this.state.scheme}` : ``
    }${`&productId=${productId}`}${`&insured1=${insured1}`}${`&insured2=${insured2}`}${`&insured3=${insured3}`}${`&insured4=${insured4}`}${`&insured5=${insured5}`}`;

    const getEMIoptions = () => {
      switch (this.props.productId) {
        case 37:
          return `&emiValue=${this.state.emiPlan}&emiOptions=${JSON.stringify(
            emiOptionsArr
          )}`;
        default:
          return ``;
      }
    };
    normalBuyUrl += getEMIoptions();

    let redirectUrl = normalBuyUrl;
    let target = `_self`;

    if(sevaApp()){
      redirectUrl= redirectUrl + '&sevaapp=true'
    }

    console.log(`buyNowUrl - `, redirectUrl);
    if (authToken !== ``) localStorage.setItem(`sessionalive`, true);
    root.open(redirectUrl, target);
  };

  setStateObject = () => {
    // let insured1, insured2, insured3, insured4, insured5;
    let insuredsData = this.state.quoteResponse;
    let item = 0;
    let insuredArray = [];
    insuredsData.map(insureds => {
      item = item + 1;
      let policyFor = accidentRelation.filter(item => {
        return insureds.policyFor === item.relationshipName;
      });
      let insured =
        policyFor &&
        policyFor[0].premiaRelationshipCode +
          `+` +
          insureds.monthlyIncome +
          `+` +
          insureds.year +
          `+` +
          insureds.riskGroup +
          `+` +
          insureds.medicalExpensesExtension +
          `+` +
          insureds.hospitalCashCover +
          `+` +
          insureds.homeConvalescenceCover +
          `+` +
          insureds.paTableA +
          `+` +
          insureds.paTableB +
          `+` +
          insureds.paTableC;
      insuredArray.push(insured);
    });
    return insuredArray;
  };

  // getPremiumAmountAcci = async formData => {
  //   let body = formData;
  //   let accidentRelation = {
  //     REL_MED_01: `SELF`,
  //     REL_MED_03: `SPOUSE-EMPLOYED`,
  //     REL_MED_04: `SPOUSE-UNEMPLOYED`,
  //     REL_MED_15: `SON 1`,
  //     REL_MED_16: `SON 2`,
  //     REL_MED_17: `SON 3`,
  //     REL_MED_18: `DAUGHTER 1`,
  //     REL_MED_19: `DAUGHTER 2`,
  //     REL_MED_20: `DAUGHTER 3`
  //   };
  //   let responseSum = 0;
  //   let healthPremiumAmount;
  //   let flag = false;
  //   this.setStateObject(body.insured);
  //   for (let object of body.insured) {
  //     if (object.policyCover && object.riskGroup) {
  //       this.setState({
  //         starLoading: true
  //       });
  //       flag = true;
  //       if (
  //         object.policyFor === `REL_MED_03` ||
  //         object.policyFor === `SPOUSE`
  //       ) {
  //         if (object.monthlyIncome > 0) {
  //           object.policyFor = `SPOUSE-EMPLOYED`;
  //         } else {
  //           object.policyFor = `SPOUSE-UNEMPLOYED`;
  //         }
  //       } else {
  //         if (
  //           object.policyFor === `REL_MED_01` ||
  //           object.policyFor === `REL_MED_15` ||
  //           object.policyFor === `REL_MED_16` ||
  //           object.policyFor === `REL_MED_17` ||
  //           object.policyFor === `REL_MED_18` ||
  //           object.policyFor === `REL_MED_19` ||
  //           object.policyFor === `REL_MED_20`
  //         )
  //           object.policyFor = accidentRelation[object.policyFor];
  //       }
  //       let quoteApi = `${CUSTOMER_PORTAL_API_URL}/api/portal/insurance/calculate/product/quote`;
  //       let headers = {
  //         clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`,
  //         'Content-Type': `application/json`
  //       };
  //       var res = await fetch(quoteApi, {
  //         method: `POST`,
  //         headers: headers,
  //         body: JSON.stringify(object)
  //       });
  //       res = await res.json();
  //       if (res.response) {
  //         let responseQuote = res && res.response && res.response.policyQuote;
  //         responseSum =
  //           responseSum + parseInt(responseQuote.split(`,`).join(``));
  //         let errorPremiumAmount = res.response.error || null;
  //         let lifePremiumAmount = res.response.life_policy_quote || null;
  //         healthPremiumAmount = res.response.health_policy_quote || null;
  //         this.setState({
  //           lifePremiumAmount: lifePremiumAmount,
  //           errorPremiumAmount: errorPremiumAmount
  //         });
  //       } else
  //         this.setState({
  //           premiumAmount: null,
  //           lifePremiumAmount: null,
  //           errorPremiumAmount: `Sorry could not fetch the premium amount`,
  //           errorLifePremiumAmount: `Sorry could not fetch the  premium amount and life premium amount`
  //         });
  //     }
  //   }
  //   responseSum = this.applyCommasForAmount(responseSum);
  //   if (flag) {
  //     this.setState({
  //       premiumAmount: responseSum || healthPremiumAmount,
  //       policyQuote: responseSum,
  //       starLoading: false
  //     });
  //   }
  // };

  handleFormSubmit = values => {
    var { productId } = this.props;
    var formResponse = Object.assign({}, this.state.formResponse);
    if (formResponse)
      formResponse = {
        ...formResponse,
        ...values
      };
    else
      formResponse = {
        ...values
      };
    this.setState({
      productId: productId,
      formResponse: formResponse,
      premiumAmount: null,
      lifePremiumAmount: null,
      policy_cover: null,
      life_cover: null
    });
  };

  handleFormState = activeStep => {
    this.setState({ activeStep: activeStep });
  };

  duplicateCheck(array) {
    let rel = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].policyFor != ``) rel.push(array[i].policyFor);
    }
    let relIn = rel.length;
    rel = rel.filter(function(item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    });
    if (relIn !== rel.length) {
      return true;
    }
    return false;
  }

  // onChangeHandler = async formData => {
  //   let flag = false;
  //   let res;
  //   let responseSum = 0;
  //   const objKeys = Object.keys(formData);
  //   for (let index = 0; index < objKeys.length; index++) {
  //     const element = formData[objKeys[index]];
  //     if (
  //       element.isVisible === true &&
  //       (element.value === undefined || element.value === ``)
  //     ) {
  //       break;
  //     }
  //   }
  //   let words = [``, `two`, `three`, `four`, `five`];
  //   let requestBodyArray = [];
  //   if (formData.schemeIndividual.value && formData.policy_period.value) {
  //     for (
  //       let iter = 0;
  //       iter < parseInt(formData.schemeIndividual.value);
  //       iter++
  //     ) {
  //       let policyFor = accidentRelation.filter(item => {
  //         return (
  //           formData[`insureds_0_relationship_id` + words[iter]].value ===
  //           item.premiaRelationshipCode
  //         );
  //       });
  //       let validFlag1 = true,
  //         validFlag3 = true;
  //       if (
  //         formData[`Tableb1` + words[iter]].isVisible &&
  //         !formData[`Tableb1` + words[iter]].isValid
  //       ) {
  //         validFlag1 = false;
  //       }
  //       if (
  //         formData[`Tableb3` + words[iter]].isVisible &&
  //         !formData[`Tableb3` + words[iter]].isValid
  //       ) {
  //         validFlag3 = false;
  //       }
  //       let tableValues =
  //         parseInt(formData[`Tableb1` + words[iter]].value) +
  //         parseInt(formData[`Tableb2` + words[iter]].value) +
  //         parseInt(formData[`Tableb3` + words[iter]].value);
  //       let monthlyIncome =
  //         iter === 0
  //           ? formData.insureds_0_gainIncome.value
  //           : policyFor[0] && policyFor[0].relationshipCategory === `A`
  //           ? formData[`insureds_1_gainIncome` + words[iter]].value
  //             ? formData[`insureds_1_gainIncome` + words[iter]].value
  //             : 0
  //           : formData[`insureds_2_gainIncome` + words[iter]].value
  //           ? formData[`insureds_2_gainIncome` + words[iter]].value
  //           : 0;
  //       if (
  //         formData[`insureds_0_relationship_id` + words[iter]].isValid &&
  //         formData[`insureds_0_relationship_id` + words[iter]].value &&
  //         formData[`insureds_0_relationship_id` + words[iter]].value !=
  //           `Select` &&
  //         formData[`insureds_0_riskGroup` + words[iter]].isValid &&
  //         formData[`insureds_0_riskGroup` + words[iter]].value &&
  //         formData[`insureds_0_riskGroup` + words[iter]].value != `Select` &&
  //         formData[`changeTableSplitYesorno` + words[iter]].value !== '' &&
  //         validFlag3 &&
  //         validFlag1 &&
  //         tableValues != 0
  //       ) {
  //         if (monthlyIncome === 0 || monthlyIncome >= 2500) {
  //           flag = true;
  //         } else flag = false;
  //       } else {
  //         flag = false;
  //         break;
  //       }
  //     }
  //     if (flag) {
  //       this.setState({
  //         scheme: formData.schemeIndividual.value
  //       });
  //       for (
  //         let iter = 0;
  //         iter < parseInt(formData.schemeIndividual.value);
  //         iter++
  //       ) {
  //         let requestBody = {};
  //         let policyFor = accidentRelation.filter(item => {
  //           return (
  //             formData[`insureds_0_relationship_id` + words[iter]].value ===
  //             item.premiaRelationshipCode
  //           );
  //         });
  //         let monthlyIncome =
  //           iter === 0
  //             ? formData.insureds_0_gainIncome.value
  //             : policyFor[0] && policyFor[0].relationshipCategory === `A`
  //             ? formData[`insureds_1_gainIncome` + words[iter]].value
  //               ? formData[`insureds_1_gainIncome` + words[iter]].value
  //               : 0
  //             : formData[`insureds_2_gainIncome` + words[iter]].value
  //             ? formData[`insureds_2_gainIncome` + words[iter]].value
  //             : 0;
  //         requestBody.productId = `17`;
  //         requestBody.name = this.state.name;
  //         requestBody.mobNo = this.state.mobile;
  //         requestBody.email = this.state.email;
  //         requestBody.policyFor = policyFor[0] && policyFor[0].relationshipName;
  //         requestBody.monthlyIncome = monthlyIncome;
  //         requestBody.year = formData.policy_period.value;
  //         requestBody.riskGroup =
  //           `RG_` + formData[`insureds_0_riskGroup` + words[iter]].value;
  //         requestBody.medicalExpensesExtension =
  //           formData[`insureds_0_medicalExpensesExtension` + words[iter]]
  //             .isChecked == true
  //             ? true
  //             : false;
  //         requestBody.hospitalCashCover =
  //           formData[`insureds_0_hospitalCash` + words[iter]].isChecked == true
  //             ? true
  //             : false;
  //         requestBody.homeConvalescenceCover =
  //           formData[`insureds_0_homeConvalescence` + words[iter]].isChecked ==
  //           true
  //             ? true
  //             : false;
  //         requestBody.paTableA = formData[`Tableb1` + words[iter]].value;
  //         requestBody.paTableB = formData[`Tableb2` + words[iter]].value;
  //         requestBody.paTableC = formData[`Tableb3` + words[iter]].value;
  //         requestBody.policyType = `Individual`;
  //         requestBody.policyCover =
  //           parseInt(formData[`Tableb1` + words[iter]].value) +
  //           parseInt(formData[`Tableb2` + words[iter]].value) +
  //           parseInt(formData[`Tableb3` + words[iter]].value);
  //         let policyPlan = ``;
  //         let dob = ``;
  //         let pincode = ``;
  //         requestBody.checkSumRequest = md5(
  //           `${requestBody.productId}||${dob}|${requestBody.policyType}|${requestBody.policyCover}|${policyPlan}|${pincode}|quote`,
  //           `73q&*cvm`
  //         );
  //         requestBodyArray.push(requestBody);
  //       }
  //     }
  //   } else {
  //     flag = false;
  //   }
  //   if (
  //     requestBodyArray.length === parseInt(formData.schemeIndividual.value) &&
  //     JSON.stringify(this.state.quoteResponse) !==
  //       JSON.stringify(requestBodyArray)
  //   ) {
  //     this.setState({
  //       quoteResponse: requestBodyArray
  //     });
  //     let item = 0;
  //     requestBodyArray.map(async requestBody => {
  //       res = await this.getPremiumAmount(requestBody);
  //       if (res.response && res.response.status !== `failure`) {
  //         item = item + 1;
  //         let responseQuote = res.response.policyQuote;
  //         responseSum =
  //           responseSum + parseInt(responseQuote.split(`,`).join(``));
  //         if (item === requestBodyArray.length) {
  //           responseSum = this.applyCommasForAmount(responseSum);
  //           this.setState({
  //             premiumAmount: responseSum,
  //             policyQuote: responseSum,
  //             starLoading: false,
  //             errorPremiumAmount: ``
  //           });
  //         }
  //       } else {
  //         this.setState({
  //           starLoading: false,
  //           premiumAmount: null,
  //           lifePremiumAmount: null,
  //           errorPremiumAmount: `Sorry could not fetch the premium amount`,
  //           errorLifePremiumAmount: `Sorry could not fetch the  premium amount and life premium amount`
  //         });
  //         return;
  //       }
  //     });
  //   } else {
  //     this.setState({
  //       starLoading: true
  //     });
  //   }
  // };

  handleFormState = activeStep => {
    if (activeStep === 1 && !browserWindow.location.href.includes(`#agent`)) {
      wizardLMSCall(
        this.state.name,
        this.state.mobile,
        this.state.email,
        this.props.productName,
        this.props.productId,
        this.state.urlInfo
      );
    }
    if (!this.state.dndConsent) {
      dndCall(this.state.name, this.state.mobile);
    }
    this.setState({ activeStep: activeStep });
  };

  tableSumCheck = submitformData => {
    let words = [``, `two`, `three`, `four`, `five`];
    let tableSum = 0;
    for (let iter = 0; iter < words.length; iter++) {
      let tableinsuredSum =
        (submitformData[`Tableb1` + words[iter]]
          ? parseInt(submitformData[`Tableb1` + words[iter]])
          : 0) +
        (submitformData[`Tableb2` + words[iter]]
          ? parseInt(submitformData[`Tableb2` + words[iter]])
          : 0) +
        (submitformData[`Tableb3` + words[iter]]
          ? parseInt(submitformData[`Tableb3` + words[iter]])
          : 0);
      tableSum = tableSum + tableinsuredSum;
    }
    if (tableSum > 10000000) {
      return false;
    } else {
      return true;
    }
  };

  handleNext = (submitformData, formData) => {
    let isDup = this.duplicateCheck(submitformData.formPayload.insured);
    let isSumCheck = this.tableSumCheck(submitformData);
    if (isDup === true) {
      let errorMessage = `Duplicate relationship found.`;
      this.setState({
        errorDup: errorMessage
      });
      return false;
    } else {
      this.setState({
        errorDup: ``
      });
    }
    if (!isSumCheck) {
      let errorMessage = `Total sum insured across insurers cannot exceed 1 Crore`;
      this.setState({
        errorDup: errorMessage
      });
      return false;
    } else {
      this.setState({
        errorDup: ``
      });
    }
    this.getRequestBodyObject(formData, submitformData);
    let subSections;
    let sectionTwo;
    subSections = JSON.parse(JSON.stringify(this.props.formJson)).pages[0]
      .sections[0].subSections;
    sectionTwo = JSON.parse(JSON.stringify(this.props.formJson)).pages[0]
      .sections[1].subSections;
    subSections = subSections.concat(sectionTwo);

    const formEditData = {};
    for (const subSection of subSections) {
      const fieldsArr = [];
      const dialogues = subSection.fields;
      for (const dialogue of dialogues) {
        fieldsArr.push(dialogue);
      }

      const keys = Object.keys(submitformData);
      let value = ``;

      let words = [`two`, `three`, `four`, `five`];

      for (const field of fieldsArr) {
        let flag = 0;
        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];
          if (
            submitformData[key] !== undefined ||
            submitformData[key] !== `formPayload` ||
            submitformData[key] !== `outputTemplate`
          ) {
            for (let iter = 0; iter < words.length; iter++) {
              if (key.indexOf(words[iter]) !== -1) {
                value = words[iter];
                break;
              } else {
                value = ``;
              }
            }

            if (
              field.name + value === key &&
              (field.isVisible || submitformData[key])
            ) {
              flag = 1;
              formEditData[key] = {};
              formEditData[key].value = submitformData[key];
              formEditData[key].isVisible = true;
              formEditData[key].isDisplayed = true;
              formEditData[key].isVisited = true;
              formEditData[key].isValid = true;
              if (field.type === `checkbox`)
                formEditData[key].isChecked = submitformData[key];

              if (field.type === `select`) {
                formEditData[key].options = field.options;
              }
              if (field.name + value === `schemeIndividual`) {
                field.actionMetadata.currCount = parseInt(
                  submitformData.schemeIndividual
                );
                formEditData[key].actionMetadata = field.actionMetadata;
              }
            }
          }
        }

        if (flag === 0) {
          formEditData[field.name] = {};
          formEditData[field.name].value = null;
          formEditData[field.name].isVisible = false;
          formEditData[field.name].isDisplayed = false;
          formEditData[field.name].isVisited = true;
          formEditData[field.name].isValid = false;
        }
      }
    }
    this.setState({
      error: ``,
      formEditDataVal: formEditData,
      scheme: submitformData[`scheme`]
    });
    this.handleFormState(this.state.activeStep + 1);
  };

  getPremiumAmount = async requestBody => {
    let quoteApi = ``,
      headers = {};
    quoteApi = `${CUSTOMER_PORTAL_API_URL}/cp/quote/calculate/product/quote`;
    headers = {
      clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`,
      'Content-Type': `application/json`
    };
    return await fetch(quoteApi, {
      method: `POST`,
      headers: headers,
      body: JSON.stringify(requestBody)
    }).then(res => res.json());
  };

  getRequestBodyObject = async formData => {
    let responseSum = 0;
    let mnthlySum = 0;
    let qtrlySum = 0;
    let hflySum = 0;
    let item = 0;
    let requestBodyArray = [];
    let maxInd = parseInt(formData.schemeIndividual.value);
    let words = [``, `two`, `three`, `four`, `five`];
    let policyPlan = ``;
    let dob = ``;
    let pincode = ``;
    let policyType = `Individual`;
    let policyCover = ``;
    let productId = this.props.productId == `37` ? `32` : `17`;
    console.log(this.props.productId);
    for (let iter = 0; iter < maxInd; iter++) {
      let policyFor = accidentRelation.filter(itemStatus => {
        return (
          formData[`insureds_0_relationship_id` + words[iter]].value ===
          itemStatus.premiaRelationshipCode
        );
      });

      let requestBody = {
        productId: productId,
        name: this.state.name,
        mobNo: this.state.mobile,
        email: this.state.email,
        policyFor: policyFor && policyFor[0] && policyFor[0].relationshipName,
        monthlyIncome: formData[`insureds_0_gainIncome` + words[iter]].value
          ? formData[`insureds_0_gainIncome` + words[iter]].value
          : formData[`insureds_1_gainIncome` + words[iter]].value
          ? formData[`insureds_1_gainIncome` + words[iter]].value
          : 0,
        riskGroup: `RG_` + formData[`insureds_0_riskGroup` + words[iter]].value,
        year: formData[`policy_period`].value,
        medicalExpensesExtension:
          formData[`insureds_0_medicalExpensesExtension` + words[iter]] &&
          formData[`insureds_0_medicalExpensesExtension` + words[iter]]
            .isChecked === true
            ? true
            : false,
        hospitalCashCover:
          formData[`insureds_0_hospitalCash` + words[iter]] &&
          formData[`insureds_0_hospitalCash` + words[iter]].isChecked === true
            ? true
            : false,
        homeConvalescenceCover:
          formData[`insureds_0_homeConvalescence` + words[iter]] &&
          formData[`insureds_0_homeConvalescence` + words[iter]].isChecked ===
            true
            ? true
            : false,

        ttdCover:
          formData[`insureds_0_ttd` + words[iter]] &&
          formData[`insureds_0_ttd` + words[iter]].isChecked === true
            ? true
            : false,
        educationGrantCover:
          formData[`insureds_0_educationGrantCover` + words[iter]] &&
          formData[`insureds_0_educationGrantCover` + words[iter]].isChecked ===
            true
            ? true
            : false,
        hospitialisationCover:
          formData[`insureds_0_hospitialisationCover` + words[iter]] &&
          formData[`insureds_0_hospitialisationCover` + words[iter]]
            .isChecked === true
            ? true
            : false,
        paTableA: formData[`Tableb1` + words[iter]].value,
        paTableB: formData[`Tableb2` + words[iter]].value,
        paTableC: formData[`Tableb3` + words[iter]].value,
        checkSumRequest: md5(
          `${productId}||${dob}|${policyType}|${policyCover}|${policyPlan}|${pincode}|quote`,
          `73q&*cvm`
        )
      };
  //   }
  // }
      requestBodyArray.push(requestBody);
      let res = await this.getPremiumAmount(requestBody);
      if (res.response && res.response.status !== `failure`) {
        item = item + 1;
        let responseQuote = res.response;
        responseSum =
          responseSum + parseInt(responseQuote.policyQuote.split(`,`).join(``));
        if (responseQuote.hlflyTotalPremium) {
          hflySum =
            hflySum +
            parseInt(responseQuote.hlflyTotalPremium.split(`,`).join(``));
          qtrlySum =
            qtrlySum +
            parseInt(responseQuote.qtrlyTotalPremium.split(`,`).join(``));
        }
        if (item === maxInd) {
          responseSum = this.applyCommasForAmount(responseSum);
          if (mnthlySum > 0) {
            mnthlySum = this.applyCommasForAmount(mnthlySum);
            hflySum = this.applyCommasForAmount(hflySum);
            qtrlySum = this.applyCommasForAmount(qtrlySum);
          }
          this.response = {
            policyQuote: responseSum,
            hlflyTotalPremium: hflySum,
            qtrlyTotalPremium: qtrlySum
          };
          this.setState({
            scheme: maxInd,
            quoteResponse: requestBodyArray,
            premiumAmount: responseSum,
            policyQuote: responseSum,
            starLoading: false,
            errorPremiumAmount: ``
          });
        }
      } else {
        this.setState({
          starLoading: false,
          premiumAmount: null,
          lifePremiumAmount: null,
          errorPremiumAmount: `Sorry could not fetch the premium amount`,
          errorLifePremiumAmount: `Sorry could not fetch the  premium amount and life premium amount`
        });
        return;
      }
    }
    // for(let iter = 0;)
    // if (requestBody.policy_type == `Individual`) delete requestBody.kids;
    // let quoteApi = ``,
    //   headers = {};
    // quoteApi = `${CUSTOMER_PORTAL_API_URL}/api/portal/insurance/calculate/product/quote`;
    // headers = {
    //   clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`,
    //   'Content-Type': `application/json`
    // };
    // return await fetch(quoteApi, {
    //   method: `POST`,
    //   headers: headers,
    //   body: JSON.stringify(requestBody)
    // }).then(res => res.json());
    // if (res.response) {
    //   let responseQuote = res && res.response && res.response.policyQuote;
    //   responseSum = responseSum + parseInt(responseQuote.split(`,`).join(``));
    //   let errorPremiumAmount = res.response.error || null;
    //   let lifePremiumAmount = res.response.life_policy_quote || null;
    //   healthPremiumAmount = res.response.health_policy_quote || null;
    //   this.setState({
    //     lifePremiumAmount: lifePremiumAmount,
    //     errorPremiumAmount: errorPremiumAmount
    //   });
    // } else
    //   this.setState({
    //     premiumAmount: null,
    //     lifePremiumAmount: null,
    //     errorPremiumAmount: `Sorry could not fetch the premium amount`,
    //     errorLifePremiumAmount: `Sorry could not fetch the  premium amount and life premium amount`
    //   });
    // responseSum = this.applyCommasForAmount(responseSum);
    // if (flag) {
    //   this.setState({
    //     premiumAmount: responseSum || healthPremiumAmount,
    //     policyQuote: responseSum,
    //     starLoading: false
    //   });
    // }
  };

  componentDidMount() {
    let name = ``;
    let user = getSecuredLocalStorageItem(`user`);
    let accessToken = Cookies.get('customer_accessToken')
    let userInfo = Cookies.get('user')
    let email = ``;
   let  mobile = ``;
    let authToken = ``;
    if (accessToken || user) {
      user = JSON.parse(user);
      authToken = user.token;
      if (authToken) {
        let logOutToken = getSecuredLocalStorageItem(`loggedOut${authToken}`);
        if (logOutToken) {
          authToken = ``;
        }
      }
    }
    let queryParams = decodeURIComponent(browserWindow.location.search);
    
    if(queryParams){
      queryParams = queryParams.split(`?`)[1];
      queryParams = queryParams.split(`&`);
      queryParams.map(param => {
        let key = param.split(`=`)[0];
        let value = param.split(`=`)[1];
        if (key === `name` && value) {
          let decodedValue = decodeURI(value);
          name = decodedValue;
        }
        else if (key === `mobile` && value) {
          let decodedValue = decodeURI(value);
          mobile = decodedValue;
        }
        else if (key === `email` && value) {
          let decodedValue = decodeURI(value);
          email = decodedValue;
        }
      });
    }
    // var base64Url = authToken.split(`.`)[1];
    // if (base64Url != undefined) {
    //   var decodedValue = JSON.parse(browserWindow.atob(base64Url));
    //   let userObj = decodedValue.jti;
    //   userObj = JSON.parse(userObj);
    //   this.setState({
    //     name: userObj.firstName ? userObj.firstName : name,
    //     email: userObj.email,
    //     mobile: userObj.mobileNo
    //   });
    // }
    if (userInfo){
      userInfo = JSON.parse(userInfo)
      email = userInfo.emailId;
      name = userInfo.firstName;
      mobile = userInfo.mobileNumber;
      console.log("cookie",userInfo,mobile)
    }
    else if(user && user.userDetails){
      email = user.userDetails.email;
      name = user.userDetails.firstName;
      mobile = user.userDetails.mobileNo;
    }
    if (this.state.urlInfo === ``) {
      this.setState({
        urlInfo: browserWindow.location.href
      });
      }
    this.setState({
      productId:this.props.productId,
      name: name,
      email: email,
      mobile: mobile,
    });
  }

  componentWillUnmount() {
    this.setState(initialState);
  }

  getOnlyRequiredSumInsureds = res => {
    const { productId } = this.props;
    const { policy_plan, days } = this.state.formResponse;
    switch (productId) {
      case 20:
        if (policy_plan == `B` && days == `0-7`) {
          return res.slice(0, 1);
        }
        break;
    }
    return res;
  };

  changeEMIPlan = event => {
    let premiumAmount = ``;
    if (this.response) {
      switch (event.target.value) {
        case `FP`:
          premiumAmount = this.response.policy_quote;
          break;
        case `04`:
          premiumAmount = this.response.hlflyTotalPremium;
          break;
        case `03`:
          premiumAmount = this.response.qtrlyTotalPremium;
          break;
        default:
          premiumAmount = this.response.policy_quote;
          break;
      }
      this.setState({ emiPlan: event.target.value, premiumAmount });
    }
  };

  render() {
    const { classes } = this.props;

    var stepsArr = [`Contact`];

    var userDetails = (
      <div>
        <CustomInput
          autoFocus
          labelText='Your Name'
          id='name'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.formControl
          }}
          inputProps={{
            required: true,
            pattern: `^[a-zA-Z. ']*$`
          }}
          error={this.state.name.search(`^[a-zA-Z. ']*$`) !== 0}
          value={this.state.name}
          onChange={this.handleInputChange}
        />
        {this.state.name.search(`^[a-zA-Z. ']*$`) !== 0 && (
          <FormHelperText id='my-helper-text' error>
            Name is not in the required format
          </FormHelperText>
        )}
        <CustomInput
          labelText='Mobile Number'
          id='mobile'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.formControl
          }}
          inputProps={{
            required: true,
            pattern: `[0-9]{10}`,
            maxLength: `10`,
            inputMode: `tel`
          }}
          error={
            this.state.mobile.length != 10 && this.state.mobile.length != 0
          }
          value={this.state.mobile}
          onChange={this.handleInputChange}
        />
        {this.state.mobile.length != 10 && this.state.mobile.length != 0 && (
          <FormHelperText id='my-helper-text' error>
            Mobile Number must be 10 digits{` `}
          </FormHelperText>
        )}
        <CustomInput
          labelText='Email'
          id='email'
          formControlProps={{
            required: true,
            fullWidth: true
          }}
          inputProps={{
            required: true,
            pattern: `[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$`,
            inputMode: `email`
          }}
          onChange={this.handleInputChange}
          value={this.state.email}
          error={
            this.state.email !== `` &&
            this.state.email.search(/^\S+@\S+\.\S+$/) !== 0
          }
        />
        {this.state.email !== `` &&
          this.state.email.search(/^\S+@\S+\.\S+$/) !== 0 && (
            <FormHelperText id='my-helper-text' error>
              Please enter valid email
            </FormHelperText>
          )}
        <div className={classes.checkboxspan} style={{ paddingBottom: '0%' }}>
          <Checkbox
            id='dndConsent'
            checked={this.state.dndConsent}
            onChange={this.handleInputChange}
            style={{ color: '#35488a', padding: '0px' }}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <p className={classes.authorize}>
            I authorize Star Health to contact me and I understand that this
            will override the DND status on my mobile number
          </p>
        </div>
      </div>
    );

    var secondStep = (
      <span key={`formstep-2`}>
        <>
          <FormProcessor
            key={`form`}
            formJson={JSON.parse(JSON.stringify(this.props.formJson))}
            formData={this.state.formEditDataVal}
            action={this.state.formEditDataVal ? `edit` : `add`}
            rendererType={`material`}
            renderStyle={`creative_tim_classic`}
            formPayload={this.props.formPayload}
            // onChangeHandler={fd => {
            //   this.onChangeHandler(fd);
            // }}
            onSubmit={(formData, fields) => {
              this.handleNext(formData, fields);
            }}
          />
          {this.state.errorDup === `` ? (
            ``
          ) : (
            <div style={{ color: `red`, marginTop: `-36px` }}>
              {this.state.errorDup}
            </div>
          )}
        </>
      </span>
    );

    var thirdStep = (
      <>
        {this.state.starLoading ? (
          <div
            style={{
              marginLeft: `auto`,
              marginRight: `auto`,
              textAlign: `center`
            }}>
            <img src={require(`ps-assets/img/star2.gif`)} />
          </div>
        ) : (
          <div>
            {this.enableEMI() ? (
              <GridContainer>
                <GridItem
                  className={classNames(classes.mTAuto, classes.mBAuto)}
                  style={{ textAlign: `center` }}
                  md={6}>
                  <span>Please Choose your payment option</span>
                </GridItem>
                <GridItem style={{ textAlign: `center` }} md={6}>
                  <Radio
                    checked={this.state.paymentMode === `emi`}
                    onChange={this.changePaymentMode}
                    value='emi'
                    name='payment_mode'
                    aria-label='EMI'
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot
                    }}
                  />
                  EMI
                  <Radio
                    checked={this.state.paymentMode === `FP`}
                    onChange={this.changePaymentMode}
                    value='FP'
                    name='payment_mode'
                    aria-label='Full Payment'
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot
                    }}
                  />
                  Full Payment
                </GridItem>
                {this.state.paymentMode === `emi` ? (
                  <>
                    <GridItem md={6}>
                      <Card>
                        <CardBody>
                          {this.state.premiumAmount && (
                            <div style={{ textAlign: `center` }}>
                              <h4 className={classes.cardTitle}>
                                Premium Amount
                              </h4>
                              <h2 className={classes.amount}>
                                ₹ {this.state.premiumAmount}
                              </h2>
                              <h4 className={classes.cardTitle}>
                                (Including Tax)
                              </h4>
                              <h6
                                className={classes.cardTitle}
                                style={{ textTransform: `capitalize` }}>
                                {this.state.productId == 6
                                  ? `This policy is applicable only for rural segment`
                                  : ``}
                              </h6>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem md={6}>
                      <InputLabel
                        htmlFor='product-type'
                        className={classes.selectLabel}>
                        Select EMI Plan
                      </InputLabel>
                      <Select
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{
                          select: classes.select
                        }}
                        style={{ display: `block` }}
                        value={this.state.emiPlan || ``}
                        onChange={this.changeEMIPlan}
                        inputProps={{
                          name: `emiPlan`,
                          id: `emiPlan`
                        }}>
                        {emiOptionsArr.map(emiOption => {
                          return (
                            <MenuItem
                              key={emiOption.value}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={emiOption.value}>
                              {emiOption.display}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </GridItem>
                  </>
                ) : (
                  <GridItem
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                    md={6}>
                    {this.state.premiumAmount && (
                      <div style={{ textAlign: `center` }}>
                        <h4 className={classes.cardTitle}>Premium Amount</h4>
                        <h2 className={classes.amount}>
                          ₹ {this.state.premiumAmount}
                        </h2>
                        <h4 className={classes.cardTitle}>(Including Tax)</h4>
                        <h6
                          className={classes.cardTitle}
                          style={{ textTransform: `capitalize` }}>
                          {this.state.productId == 6
                            ? `This policy is applicable only for rural segment`
                            : ``}
                        </h6>
                      </div>
                    )}
                  </GridItem>
                )}
              </GridContainer>
            ) : (
              <>
                {this.state.premiumAmount && (
                  <div style={{ textAlign: `center` }}>
                    <h4 className={classes.cardTitle}>Premium Amount</h4>
                    <h2 className={classes.amount}>
                      ₹ {this.state.premiumAmount}
                    </h2>
                    <h4 className={classes.cardTitle}>(Including Tax)</h4>
                    <h6
                      className={classes.cardTitle}
                      style={{ textTransform: `capitalize` }}>
                      {this.state.productId == 6
                        ? `This policy is applicable only for rural segment`
                        : ``}
                    </h6>
                  </div>
                )}
              </>
            )}
            {this.state.errorPremiumAmount && (
              <div style={{ textAlign: `center`, color: `red` }}>
                {this.state.errorPremiumAmount}
              </div>
            )}
            <div style={{ textAlign: `center` }}>
              {this.props.brochureUrl && this.props.brochureUrl.length === 1 && (
                <a
                  href={this.props.brochureUrl[0]}
                  target='_blank'
                  rel='noopener noreferrer'
                  key={`brochure-download-0`}>
                  <Button simple color='primary'>
                    <ReactSVG
                      src={withPrefix(`/download.svg`)}
                      style={{ margin: `0 auto` }}
                    />
                    Brochure
                  </Button>
                </a>
              )}
              {this.props.brochureUrl &&
                this.props.brochureUrl.length > 1 &&
                this.state.formResponse &&
                this.state.formResponse.policy_type &&
                this.props.type.map((res, index) => {
                  return (
                    this.state.formResponse.policy_type === res && (
                      <a
                        href={this.props.brochureUrl[index]}
                        target='_blank'
                        rel='noopener noreferrer'
                        key={`brochure-download-${index}`}>
                        <Button simple color='primary'>
                          <ReactSVG
                            src={withPrefix(`/download.svg`)}
                            style={{ margin: `0 auto` }}
                          />
                          Brochure
                        </Button>
                      </a>
                    )
                  );
                })}
              {this.props.brochureUrl &&
                this.props.brochureUrl.length > 1 &&
                this.state.formResponse &&
                this.state.formResponse.policy_plan &&
                this.props.type.map((res, index) => {
                  return (
                    this.state.formResponse.policy_plan === res && (
                      <a
                        href={this.props.brochureUrl[index]}
                        target='_blank'
                        rel='noopener noreferrer'
                        key={`brochure-download-${index}`}>
                        <Button simple color='primary'>
                          <ReactSVG
                            src={withPrefix(`/download.svg`)}
                            style={{ margin: `0 auto` }}
                          />
                          Brochure
                        </Button>
                      </a>
                    )
                  );
                })}
            </div>
          </div>
        )}
      </>
    );
    var contentArr = [<span key={`content-array`}>{userDetails}</span>];

    if (this.props.formJson) {
      stepsArr.push(`Basic Info`);
      stepsArr.push(`Your Premium`);
      contentArr.push(<>{secondStep}</>);
      contentArr.push(<>{thirdStep}</>);
    }

    return (
      <span key={`customStepper`}>
        <CustomStepper
          formResponse={this.state.formResponse}
          activeStep={this.state.activeStep}
          handleFormState={this.handleFormState}
          handleBuyNow={this.handleBuyNow}
          steps={stepsArr}
          content={contentArr}
          premiumAmount={this.state.premiumAmount}
          brochure={this.props.brochure}
          ctaLabel={`Buy Now`}
        />
      </span>
    );
  }
}

Accident.propTypes = {
  brochure: PropTypes.array,
  ctaLabel: PropTypes.string,
  classes: PropTypes.object,
  buyNowUrl: PropTypes.string,
  formArr: PropTypes.object,
  query: PropTypes.string,
  planType: PropTypes.string,
  productId: PropTypes.number,
  brochureUrl: PropTypes.array,
  type: PropTypes.array,
  formJson: PropTypes.object,
  formPayload: PropTypes.object,
  productName: PropTypes.string
};
export default withStyles(wizardStyle)(Accident);
