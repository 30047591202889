import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Accordion from "components/Accordion/Accordion.jsx";
import { Helmet } from "react-helmet";


    const faq=[
        {
            title:'What expenses are covered in Diabetic Insurance?',
            description:<h5>In the Diabetes Safe Insurance Policy, all the expenses of hospitalizations about the treatment of Diabetic and non-Diabetic conditions are covered.</h5>,
            content:false
        },
        {
            title:'What are the waiting periods in this plan?',
            description:
            <h5><ol type="a" >
                <li>
                The initial waiting period under this plan is 30 days (except for accidents).
                </li>
                <li>
                The waiting period for PEDs is 48 months. 
                </li>
                <li>
                For specific diseases, it is 24 months.
                </li>
                </ol>
                <h5>
                Under Plan A - Diabetic Complications are covered from Day 1 for Type I and Type II Diabetes.
                </h5>
                <h5>
                Under Plan B - Diabetic Complications are covered after 12 months for Type I and Type II Diabetes.
                </h5>
                </h5>,
            content:true
        },
        {
            title:'Does this plan cover other illnesses too?',
            description:<h5>Yes. This plan covers other illnesses too.
            </h5>,
            content:false
        },
        {
            title:'Are there any sub limits in this plan?',
            description:
            <h5>
                Sub limits are available for Cataract, CVD and modern treatments. Refer to the policy clause for more details.
            </h5>,
            content:false
        },
        {
            title:'What are the different variants available in this plan? ',
            description:
            <h5>
                Plan A and Plan B are the two variants available in this plan.
            </h5>,
            content:false
        },
        {
            title:'Who can buy this policy?',
            description:
            <h5>
                Any person of the age group from 18-65 years diagnosed with Diabetes is eligible to buy this policy.
            </h5>,
            content:false
        },
        ]

const DiabetrssafeHighlights = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });
    return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Health Insurance for Diabetic mellitus
            </h2>
            </GridItem>
                <h5>
                The majority of the population is not aware of Diabetes and co-morbid factors. Diabetes Mellitus or Diabetes is a lifestyle disorder that can often trigger other severe diseases, and it is also known to be a primary cause of heart attacks and can be fatal.
             </h5>

             <h5>
             “Over 77 million people have Diabetes in India”- Reported by Medical news today Dated:- 28-July-2021,
             </h5>
             <h5>
             Contrary to popular belief, Diabetes is more often becoming common among the younger population as well. There are two types of Diabetes: Type I and Type II Diabetes.
             </h5>
             <h5>
             Early detection and constant surveillance are necessary to manage the lifestyle problems that cause Diabetes. With proper medical care, you’ll be able to not only control your sugar levels but also lead a healthy life. However, with extensive medical treatments, huge bills come into the picture, which is why you may need Health Insurance for Diabetic patients. Star Health Insurance offers a Diabetes Safe Insurance Policy, a specialized plan designed for people with a history of diabetes.
             </h5>
             <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Diabetes Safe Insurance Policy
            </h2>
            </GridItem>
            <h5>
            As is evident from its name, Diabetes Safe Insurance Policy will cover you against all expenses incurred on hospitalisation for treatment of Type I or Type II Diabetes and its complications. The plan provides coverage on an individual and family floater basis that requires 24 hours of hospitalisation.
            </h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            What are the Conditions/Eligibility?
            </h2>
            </GridItem>
            <h5>
            Anyone with Type I or Type II Diabetes in the age group of 18 to 65 years will be considered eligible to buy this insurance plan.
            </h5>
            <h5>
            Let us take a deeper look into what the Diabetes Safe Insurance Policy covers.
            </h5>
            <h5>
            With this Policy, you get two plan options - Plan A and Plan B. 
            </h5>
            <h5><b>Plan A - Section 1</b></h5>
            <h5 style={{marginLeft: "5%"}} >
            Diabetes Safe Insurance Policy - Plan A requires a Pre-Insurance Screening. The plan allows you to file a claim for all medical expenses arising out of hospitalisation and complications caused by Diabetes up to the Sum Insured
            </h5>
            <h5 style={{marginLeft: "5%"}} >
            Plan A offers cover for diabetes complications such as Cardio Vascular System, Renal System, Diseases of eye, Diabetic Peripheral Vascular Diseases and Foot Ulcer from day 1.
            </h5>
            <h5><b>Plan B - Section 1</b></h5>
            <h5 style={{marginLeft: "5%"}} >
            Diabetes Safe Insurance Policy Plan B does not require a Pre-Insurance Screening. All in-patient hospitalisation expenses arising out of diabetes during the policy period will be covered up to the Sum Insured
            </h5>
            <h5 style={{marginLeft: "5%"}} >
            In Plan B, medical expenses relating to any Cardio Vascular System, Renal System, Diseases of the eye, Foot Ulcer, Diabetic Peripheral Vascular Diseases and other complications of diabetes are covered after a waiting period of 12 months.
            </h5>
            <h5><b>Section 2</b></h5>
            <h5 style={{marginLeft: "5%"}} >
            All in-patient hospitalization expenses for Non-Diabetic complications are covered in both the plans up to the Sum Insured. Under this policy, the waiting period for pre-existing conditions is 48 months, for specific diseases it is 24 months and the initial waiting period for the policy is 30 days except for accidents.
            </h5>
            <h5 style={{marginLeft: "5%"}} >
            We request you to choose the right plan option depending on your health care requisite.
            </h5>
            <h5>
                <b>Some coverage in common under both the plans are,</b>
            </h5>
            <GridItem>
                <ol type="a">
                <li>
                Both the plans can be bought on individual as well as on a floater basis, which covers you and your spouse provided either of you is a diabetic. Lifelong renewal is available
                </li>
                <li>
                The maximum Sum Insured Option is ₹10,00,000 in this policy.
                </li>
                <li>
                Both plans will cover your daycare medical expenses and modern treatment (up to sub-limits).
                </li>
                <li>
                Cataract surgeries are covered up to specific limits based on the Sum Insured opted.
                </li>
                <li>
                Organ donor expenses for kidney transplantation is allowed.
                </li>
                <li>
                Dialysis expenses are covered 
                </li>
                <li>
                The chosen Insured person has worldwide cover for Accidental Death.
                </li>
                </ol>
            </GridItem>
            <h5>
            <b>Other coverage under for Plan A and Plan B</b>
            </h5>
            <GridItem>
                <ol>
                <li>
                Room (Single Standard A/C room), Boarding and Nursing Expenses
                </li>
                <li>
                Surgeon, Anesthetist, Medical Practitioner, Consultants, Specialist fees
                </li>
                <li>
                Emergency ambulance charges up to a sum of ₹2,000 per policy period
                </li>
                <li>
                Pre-hospitalisation Expenses incurred for a period not exceeding 30 days before the date of hospitalization.
                </li>
                <li>
                Post-hospitalisation Expenses incurred up to 60 days after discharge from the hospital are payable up to 7% of the hospitalization expenses subject to a maximum of Rs. 5,000/- per hospitalization. 
                </li>
                <li>
                Fasting and Post Postprandial and HbA1C tests incurred at any networked facility is covered once every six months up to Rs. 750/- per event up to Rs. 1500/- per policy period. Outpatient expenses like medical consultation, other diagnostics, medicines and drugs are covered up to the specific limits per policy period.
                </li>
                <li>
                ICU charges
                </li>
                <li>
                Blood and oxygen expenses
                </li>
                <li>
                Medicinal expenses
                </li>
                <li>
                Automatic restoration of the Basic Sum Insured by 100% immediately upon exhaustion of the basic sum insured, once during the policy period.
                </li>
                <li>
                Amputation claims up to 10% of the Sum Insured.
                </li>
                </ol>
            </GridItem>
            <h5>
                <b>Exclusions</b>
            </h5>
            <GridItem>
                <h5>Here are some of the common reasons why your claims could get rejected by the Star Health Diabetes Safe Insurance Policy:</h5>
                <ol>
                <li>
                Hospitalisation expenses during the waiting period for that particular disorder.
                </li>
                <li>
                Obesity related medical procedures  Hospitalisation expenses for Investigation & Evaluation
                </li>
                <li>
                Treatment relating to sterility and infertility.
                </li>
                <li>
                Self-intentional injury.
                </li>
                <li>
                Cost of spectacles, contact lens and any dental treatments.
                </li>
                </ol>
            </GridItem>
            <h5>
            For more details on the risk factors, terms and conditions, please read the policy wordings before concluding sale
            </h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            Reasons to Choose Diabetes Safe Insurance Policy
            </h2>
            </GridItem>
            <GridItem>
                <h5>Covers hospitalization expenses other than complications of Diabetes also</h5>
                <ol>
                <li>
                Ailments like Cardiovascular Disease, Diseases of the renal system, Diseases of the eye and foot ulcers are covered with no waiting periods in Plan A and a waiting period in Plan B.
                </li>
                <li>
                Automatic restoration of entire Sum Insured by 100% upon exhaustion of the basic Sum Insured as once during the policy period.
                </li>
                <li>
                The premium paid is eligible for a relief under section 80D of the Income Tax Act, 1961.
                </li>
                <li>
                No co-payment at the time of claim 
                </li>
                </ol>
            </GridItem>
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>FAQs</h2>
            </GridItem>

            <div>
            <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer
                className={`${classes.container} ${classes.textCenter}`}
                >
                <GridItem
                    md={12}
                    style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                ></GridItem>
                <GridItem
                style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <Accordion active={0} collapses={AccordionArray} />
                </GridItem>
                </GridContainer>
            </div>
            </div>
        </div>
        {process.env.ENABLE_HELMET == `true` && (
            <Helmet>
                <script type="application/ld+json">
                    {`{
                    "@context":"https://schema.org",
                    "@type":"FAQPage",
                    "mainEntity":[
                        {
                            "@type":"Question",
                            "name":"What expenses are covered in Diabetic Insurance?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"In the Diabetes Safe Insurance Policy, all the expenses of hospitalizations about the treatment of Diabetic and non-Diabetic conditions are covered."
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"What are the waiting period in this plan?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"a.The initial waiting period under this plan is 30 days (except for accidents).
                    b.The waiting period for PEDs is 48 months. 
                    c.For specific diseases, it is 24 months.
                    Under Plan A - Diabetic Complications are covered from Day 1 for Type I and Type II Diabetes.
                    Under Plan B - Diabetic Complications are covered after 12 months for Type I and Type II Diabetes."
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"Does this plan cover other illnesses too?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"Yes. This plan covers other illnesses too."
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"3.Are there any sub limits in this plan?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"Sub limits are available for Cataract, CVD and modern treatments. Refer to the policy clause for more details."
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"What are the different variants available in this plan?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"Plan A and Plan B are the two variants available in this plan."
                            }
                        },
                        {
                            "@type":"Question",
                            "name":"Who can buy this policy?",
                            "acceptedAnswer":{
                                "@type":"Answer",
                                "text":"Any person of the age group from 18-65 years diagnosed with Diabetes is eligible to buy this policy."
                            }
                        }
                    ]
                    }`}
                </script>
                {/* <script type="application/ld+json">
{`{ "@context": "http://schema.org",
  "@type": "Product",
  "name": "Diabetes Safe Insurance Policy",
  "description": "Diabetes Health Insurance Policy that covers critical illnesses and death for Type 2 Diabetics. Buy Health Insurance for your Diabetes Patients, just a click away to get a free Quote and Apply Online Today!",
"offers":{ 
          "@type": "AggregateOffer",
          "offerCount": "5%"
        }
}`}
</script> */}
            </Helmet>
        )}
      </GridItem>
    </GridContainer>
  );
};
DiabetrssafeHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(DiabetrssafeHighlights);