import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "components/Accordion/Accordion.jsx";


const table1 = [
    ['Renewal', 'Lifelong'],
    ['Policy period', '1 year'],
    ['Sum Insured', 'Rs 25000 to 1 lakh '],
    ['Discounts', <h5 key="renewal_premium2">
        <p key="renewal_premium">Renewal discount  – 25% on the premium  after every block of 2 continuous claim free years</p>
        <p key="renewal_premium1">5% - for purchase of policy online</p>
    </h5>],
]

const table2 = [
    ['Out Patient Consultation', 'Up to S.I and bonus if any','Covers Outpatient expenses incurred at any networked facility in India.'],
    ['Non-allopathic treatment expenses', 'Covered','Covers non-allopathic treatment for those inclined to AYUSH treatments up to sum insured.'],
    ['Diagnostics, physiotherapy and pharmacy expenses', 'Covered','Get your expenses covered incurred on diagnostics, physiotherapy and pharmacy at any networked facility in India.'],
    ['Dental and ophthalmic treatment expenses', 'Covered','Dental and Ophthalmic treatment expenses arising out of accident incurred at any Networked Facility in India is covered.'],
    ]
        
const faq=[
    {
        title:'Can I avail of tax deductions on health insurance plans with OPD cover?',
        description:<h5>Premiums Paid to the OPD Policies an  Insured Person is eligible for relief under Section 80-D of the IT Act in respect of the premium paid by any mode other than cash.</h5>,
        content:false
    },
    {
        title:'What are outpatient expenses?',
        description:<h5>Outpatient Consultation expenses incurred at any Networked Facility in India. Out-patient care incurred under Ayurveda, Unani, Siddha and Homeopathy systems of medicines in any institute recognized by the Government of India. Medical expenses coverage for Diagnostics, Physiotherapy and Pharmacy. Dental treatment expenses to a natural tooth or teeth arising out of accidents incurred at any Networked Facility in India as an Outpatient. Ophthalmic Treatment expenses arising out of accidental injuries incurred at any Networked Facility in India</h5>,
        content:true
    },
    {
        title:'What are the sum insured options available under the Out Patient Care Insurance Policy?',
        description:<h5>
            <h5>
            Plans:- Silver Plan / Gold Plan / Platinum Plan
            </h5>
            <h5>
            Cover is available on Individual and Floater Basis with sum insured options of Rs.25,000, Rs.50,000,  Rs.75,000, Rs.1,00,000.
            </h5>
        </h5>,
        content:false
    },
    {
        title:'What is the waiting period for OPD health insurance policy?',
        description:
        <h5>
            <h5>
            <b>Silver Plan:</b> Coverage under the policy after the expiry of 48 months for any pre-existing disease is subject to the same being declared at the time of application and accepted by Insurer. 
            </h5>
            <h5>
            <b>Gold Plan:</b> Coverage under the policy after the expiry of 24 months for any pre-existing disease is subject to the same being declared at the time of application and accepted by Insurer. 
            </h5>
            <h5>
            <b>Platinum Plan:</b> Coverage under the policy after the expiry of 12 months for any pre-existing disease is subject to the same being declared at the time of application and accepted by Insurer.
            </h5>
        </h5>,
        content:false
    },
    ]
const OutpatientcareHighlights = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });
    return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h1 className={classes.h2hTitle}>
            STAR OUT PATIENT CARE INSURANCE POLICY
            </h1>
            <h2 className={classes.h2hTitle}>
            Health Insurance with OPD cover
            </h2>
            </GridItem>
                <h5>
                Buying a health insurance policy is usually not on the top of our priority list. It is often looked at as an unnecessary expense. Rarely do we understand the safety that health insurance policies offer. As humans, we certainly don’t expect to be admitted for major illnesses/diseases and often feel we don’t need a health insurance policy. But unfortunately, we do fall sick. These conditions would have certainly taken you to a clinic or a hospital from the common cold to the cough to diarrhea or allergies. 
             </h5>

             <h5>
             Did you know that OPD expenses in India account for over 60% of total health care costs? Yes, you read that right. And while paying Rs 500 per consultation may not seem like a lot, the cumulative expenses incurred over the year certainly cannot be taken for granted.
             </h5>
             <h5>
             There could be various reasons for spending on health care, and one way to mitigate these expenses is by buying an insurance policy. You need not be hospitalised or require an in-patient stay to avail of the benefits provided by the policy.
             </h5>
             <h5>
             Keeping all this in mind, we have devised the Star Out Patient Care Insurance Policy. This policy will help you overcome OPD costs, so you need not stress and worry if you happen to fall sick.
             </h5>
             <h5>
             While you may need to be admitted to a hospital for some conditions, most illnesses do not require hospitalisation and treatments for such ailments fall under outpatient care. Outpatient care involves all treatment that a patient received without being admitted to the hospital.    Eg. Consultation fees, vitamin supplements, antibiotics.
             </h5>
             <h5>
             Just imagine the amount of money you could save on most common outpatient procedures like tests, scans, having a consultation in hospital with a specialist or getting a tooth filled at a dentist’s clinic, etc.
             </h5>
             <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>
            What is the Out Patient care policy?
            </h2>
            <h5>Star Out Patient Care Insurance Policy is a specific mediclaim policy only for outpatient procedures. It pays for all outpatient consultation, including doctor fees, diagnostic tests, pharmacy bills, physiotherapy, non-allopathic treatments, dental treatments, eye treatments, and other therapeutic procedures.</h5>
            <h5>Star Out Patient Care Insurance Policy is available for individuals within 18-50 years on both  individual or family floater basis. Dependent children, up to 25 years of age, can be covered under either parent. This health insurance provides coverage for a maximum of six members in a family with a policy term of one-year duration. The minimum sum insured under this health policy is Rs 25000, and the maximum sum insured is Rs 1 lakh, and policy holders   may opt for variants like – Silver, Gold, and Platinum plans.</h5>   
            </GridItem>
            <h2 className={classes.h2hTitle}>
            Coverage under Star Out Patient Care Insurance Policy
            </h2>
            <GridItem>
                <ul>
            <li>
            Out-patient medical consultation
            </li>
            <li>
            Non-allopathic treatment expenses
            </li>
            <li>
            Diagnostics, physiotherapy and pharmacy expenses
            </li>
            <li>
            Dental and ophthalmic treatment expenses
            </li>
            </ul>
            </GridItem>
        <div>
        <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={2}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                18 years to 50 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Dependent Children – 31st day to 25 years
                            </TableCell>
                        </TableRow>
                        {table1.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting Periods
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED- 48/24/12 months (silver/Gold/Platinum respectively)
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>
        <div style={{marginTop:'5%'}}>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Benefits
                                  </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCellLeft}
                                >
                                    Coverage limit
                                </TableCell>
                                <TableCell
                                    colSpan={1}
                                    align="center"
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                >
                                   Description of cover
                         </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {table2.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[2]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                        </Table>
                </Paper>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h2 className={classes.h2hTitle}>
                Eligibility criteria to avail OPD benefit
                </h2>
                </GridItem>
                    <li>
                    Anyone between the age group of 18 and 50 years can avail of this medical insurance. The holder can choose to opt for a family floater plan in which it is possible to cover Self, spouse and dependent children between the age of 31 days and 25 years.
                </li>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h2 className={classes.h2hTitle}>
                Features of Star Out Patient Care Insurance Policy 
                </h2>
                </GridItem>
                <ul>
                <li>
                <b>Sum insured  </b>
                </li>
                <h5>
                Star Out Patient Care Insurance Policy provides sum insured options ranging from  ₹25000 , ₹50,000, ₹75,000 and ₹ 1 lakh under all the plans – Silver, Gold, and Platinum.
                </h5>
                <li>
                <b>Product type  </b>
                </li>
                <h5>
                Star Out Patient Care Insurance Policy is available on both individual and family floater coverage basis.
                </h5>
                <li>
                 <b>Policy term</b>
                </li>
                <h5>
                The tenure of the Star Out Patient Care Insurance Policy is 1 year and is renewable for a lifetime.
                </h5>
                <li>
                <b>Variants</b>
                </li>
                <h5>
                Star Out Patient Care Insurance Policy has three different plans, with varied benefits on the waiting periods. They are the Silver, Gold and Platinum plans.
                </h5>
                <li>
                <b>Waiting periods</b>
                </li>
                <h5>
                Except in the case of an accident, any disease developed within 30 days of the policy’s inception is not covered.
                </h5>
                <ol>
                <li>
                <b>Silver plan: </b>Pre-existing diseases are covered after 48 months from the Date of inception of the policy.
                </li>
                <li>
                <b>Gold plan:  </b>Pre-existing diseases are covered after a waiting period of 24 months from the Date of inception of the policy.
                </li>
                <li>
                <b>Platinum plan: </b>Pre-existing diseases are covered after a waiting period of 12 months from the Date of inception of the policy.
                </li>
                </ol>
                <li>
                <b>Lifelong renewal option  </b>
                </li>
                
                <h5>
                The Star Out Patient Care Insurance Policy offers a lifelong renewal option. If the insured renews the policy, within the due date, he can enjoy continuity of benefits like a reduced waiting period on coverage of PED’s and cumulative bonus. A grace period of 30 days is provided to renew the policy and for continuity benefits. The insured is also eligible for a discount of 25% of the premium after every two continuous claim-free years.
                </h5>
                </ul>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h2 className={classes.h2hTitle}>
                What is covered in the Star Out Patient Care Insurance Policy? 
                </h2>
                </GridItem>
                <h5>
                Star Out Patient Care Insurance Policy covers out-patient consultation and treatment expenses incurred at any network hospital in India within limits specified in the policy. The OPD treatments that are covered are:
                </h5>
                <ul>
                <li>
                <b>Out-patient medical consultation</b>
                </li>
                <h5>
                Avail coverage for all out-patient consultation expenses in any of our 14000+ network hospitals in the country.
                </h5>
                <li>
                <b>Non-allopathic treatment expenses</b>
                </li>
                <h5>
                The Star Out Patient Care Insurance Policy covers non-allopathic treatment, such as Ayurveda, Unani, Sidha and Homeopathy systems of medicines (AYUSH) in any institute recognised by the Indian government and at any centers accredited by the quality council of the National Accreditation Board on Health.
                </h5>
                <li>
                <b>Diagnostics, physiotherapy and pharmacy expenses</b>
                </li>
                <h5>
                The policy covers expenses incurred due to diagnostics, physiotherapy and pharmacy expenses in any of the 12,000+ Network Hospitals of Star Health Insurance.
                </h5>
                <li>
                <b>Dental and ophthalmic treatment expenses </b>
                </li>
                
                <h5>
                Dental check-ups or treatment sought at any network institute throughout India is financed. In addition, ophthalmic (eye) treatments, consultations and related surgeries are also covered.
                </h5>
                </ul>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h2 className={classes.h2hTitle}>
                Difference between Daycare treatment and OPD treatment
                </h2>
                </GridItem>
                <h5>
                <b>Day Care Treatments:</b>
                </h5>
                <h5>
                Generally, in order to make a claim against your health insurance, you must be hospitalized for a minimum of 24 hours. However, certain treatments are no longer require 24 hours of hospitalisation as they used to be due to technological advancements. In the past, for example, a patient receiving a cataract operation would be required to stay in the hospital for several days after surgery. As a result of technological advancement, surgery can now be performed on the same day as the patient is discharged from the hospital.
                </h5>
                <h5>
                Such type of treatment falls under the policy definition of covered treatment if it is included in your policy.
                </h5>
                <h5>
                <b>OPD Treatments:</b>
                </h5>
                <h5>
                Outpatient Department, or OPD, treatment refers to the situation in which a patient may visit a doctor or medical practitioner for the purpose of seeking advice, tests, X-rays, investigations, diagnostics physiotherapy etc.
                </h5>
                <h5>
                It might seem that Day Care and OPD treatments are similar because they involve a shorter amount of time. 
                </h5>
                <h5>
                Hospitalization is the most significant difference between the two. A Day Care procedure, even if it takes less time, is performed in a hospital and only then you will be able to claim your medical expenses under a Day Care treatment. Treatments for OPD do not require hospitalisation. The nature of OPD treatment is that it is possible to get the treatment without being admitted to a hospital or clinic.
                </h5>
                <h5>
                A root canal treatment is a good example to understand the same. A root canal can be done either in a hospital or clinic without actually being admitted, and therefore falls under the OPD category. A dental surgery done in the event of an accident, however, may result in a day care treatment.
                </h5>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h2 className={classes.h2hTitle}>
                Why should you buy an Out Patient Care Insurance Policy?
                </h2>
                </GridItem>
                <h5>
                The cost of medical care is rising at an unabated pace in India, and it makes health insurance a necessity than a choice. OPD treatments have become very normal today. Who isn’t visiting a doctor for fever, blood sugar test, ECG, x-rays, or frequenting a family physician or a consultant? 
                </h5>
                <h5>
                Usually, outpatient treatments come as an add-on to existing policies or benefit from accompanying a standard policy because every person has their requirements and needs when choosing a health insurance policy that suits their pocket. By having an OP cover and an in-patient cover, the person is completely covered.  On the contrary, even a healthy person is more likely to visit a doctor for medical conditions, which are not serious enough for hospitalisation, for instance, tooth filling or a few appointments with your general practitioner. As age advances, regular health checkups become a part of life. If you are a person taking regular health check-ups, such expenses are covered under OP care. Unfortunately, the cost of dental treatments, diagnostic tests, periodic doctor consultation, preventive check-ups and medicines can range anywhere from a thousand to 1 lakh. Having said that, the outpatient cover will be more apt for a healthy person and for anyone who frequently visits the hospitals and clinics, and the expenses you incur no longer need to be paid from your pocket.
                </h5>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h2 className={classes.h2hTitle}>
                Inclusions of Star Out Patient Care Insurance Policy
                </h2>
                </GridItem>
                <h5>
                Here is a list of features that are unique to the Star Out Patient Care Insurance Policy
                </h5>
                <ul>
                <li>
                The plan covers all dental and ophthalmic treatment expenses, including expenses arising from accidental injuries at any Star Health network medical facility in India.
                </li>
                <li>
                You can enjoy a discount of 25% of the premium after every block of two continuous claim-free years which means you will be given a discount of 25% on your premium when no claims are made for two successive years.
                </li>
                <li>
                You can claim outpatient expenses on pre-existing diseases from the 2nd year (12 months waiting period), and you can submit multiple claims within the same year until the limit is reached.
                </li>
                </ul>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h2 className={classes.h2hTitle}>
                Exclusions of Star Out Patient Care Insurance Policy
                </h2>
                </GridItem>
                <h5>
                The following is a partial listing of policy exclusions. A detailed list of all exclusions is included in the policy document.
                </h5>
                <GridItem>
                    <ul>
                <li>
                Pre-existing diseases before their designated waiting periods
                </li>
                <li>
                Obesity and weight management consultations
                </li>
                <li>
                Cosmetic surgery
                </li>
                <li>
                Treatment necessitated due to participation as a professional in hazardous or adventure sports
                </li>
                <li>
                Treatment-related to alcohol and drug addiction
                </li>
                <li>
                Treatment for infertility and gender change surgery
                </li>
                <li>
                Pregnancy wellness, miscarriage and related complications
                </li>
                <li>
                Self intentional injury and sexually transmitted diseases
                </li>
                <li>
                Injuries due to war, rioting, and acts of violence
                </li>
                <li>
                Vaccination
                </li>
                <li>
                Treatments that have been taken out of India 
                </li>
                </ul>
                </GridItem>
                <GridItem  style={{ paddingLeft:'0px'}}>
                <h2 className={classes.h2hTitle}>
                Why choose Star Out Patient Care Insurance Policy?
                </h2>
                </GridItem>
                <ul>
                <li>
                    <b>Cash-less facility at Network Hospitals</b>
                <h5>
                We provide access to an ever-increasing network of hospitals where you can opt for cashless facility. Our aim is that you should have easy access to a network hospital near you in times of planned or unplanned medical expenses. Star Health Insurance  has a network of over 14,000+ hospitals. For the complete list, <span><a href="https://starhealth.in/wellness#networkhospitals">visit here</a></span>.
                </h5>
                </li>
                <li>
                <b>Online discount </b>
                <h5>
                You can enjoy a 5% discount for direct online purchases from <a href="/">starhealth.in</a>   . Renewal discount  – 25% on the premium after every block of 2 continuous claim free years
                </h5>
                </li>
                <li>
                <b>Hassle-free claim process</b>
                <h5>
                Insurance processes can often be long and document-heavy. However, Star Health Insurance offers digital-friendly, zero-touch, quick and convenient options that work better for you during this pandemic situation.
                </h5>
                </li>
                <li>
                <b>Tax benefits </b>
                <h5>
                Individual and family members below 60 years can get Rs. 25,000 deductions from taxable income, and the deductible amount can extend up to Rs. 50,000 for senior citizens on premiums paid as per Section 80D of Income Tax Act, 1961. If two family members above the age of 60 are insured under one policy, then the deductible amount extends to Rs 1 lakh. In contrast, if there is one family member below the age of 60 and his parents are also insured under the same policy, then they can avail of a deduction of Rs 75,000.
                </h5>
                </li>
                </ul>
                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>FAQs</h2>
            </GridItem>

            <div>
            <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer
                className={`${classes.container} ${classes.textCenter}`}
                >
                <GridItem
                    md={12}
                    style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                ></GridItem>
                <GridItem
                style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <Accordion active={0} collapses={AccordionArray} />
                </GridItem>
                </GridContainer>
            </div>
            </div>
        </div>
      <Helmet>
            <title>
            Buy OPD Cover Health Insurance Plan in India
            </title>
            </Helmet>
            {process.env.ENABLE_HELMET == `true` && (
            <Helmet>
            <meta
              name="title"
              content="Buy OPD Cover Health Insurance Plan in India"
            />
            </Helmet>
            )}
                </div>
        {process.env.ENABLE_HELMET == `true` && (
        <Helmet>
           <script type="application/ld+json">
               {`
                {
                    "@context":"https://schema.org",
                    "@type":"FAQPage",
                    "mainEntity":[
                       {
                          "@type":"Question",
                          "name":"Can I avail of tax deductions on health insurance plans with OPD cover?",
                          "acceptedAnswer":{
                             "@type":"Answer",
                             "text":"Premiums Paid to the OPD Policies an  Insured Person is eligible for relief under Section 80-D of the IT Act in respect of the premium paid by any mode other than cash."
                          }
                       },
                       {
                          "@type":"Question",
                          "name":"What are outpatient expenses?",
                          "acceptedAnswer":{
                             "@type":"Answer",
                             "text":"Outpatient Consultation expenses incurred at any Networked Facility in India. Out-patient care incurred under Ayurveda, Unani, Siddha and Homeopathy systems of medicines in any institute recognized by the Government of India. Medical expenses coverage for Diagnostics, Physiotherapy and Pharmacy. Dental treatment expenses to a natural tooth or teeth arising out of accidents incurred at any Networked Facility in India as an Outpatient. Ophthalmic Treatment expenses arising out of accidental injuries incurred at any Networked Facility in India"
                          }
                       },
                       {
                          "@type":"Question",
                          "name":"What are the sum insured options available under the Outpatient Care Insurance Policy?",
                          "acceptedAnswer":{
                             "@type":"Answer",
                             "text":"Plans:- Silver Plan / Gold Plan / Platinum Plan\nCover is available on Individual and Floater Basis with sum insured options of Rs.25,000, Rs.50,000,  Rs.75,000, Rs.1,00,000."
                          }
                       },
                       {
                          "@type":"Question",
                          "name":"What is the waiting period for OPD health insurance policy?",
                          "acceptedAnswer":{
                             "@type":"Answer",
                             "text":"Silver Plan: Coverage under the policy after the expiry of 48 months for any pre-existing disease is subject to the same being declared at the time of application and accepted by Insurer. \nGold Plan: Coverage under the policy after the expiry of 24 months for any pre-existing disease is subject to the same being declared at the time of application and accepted by Insurer.\nPlatinum Plan: Coverage under the policy after the expiry of 12 months for any pre-existing disease is subject to the same being declared at the time of application and accepted by Insurer."
                          }
                       }
                    ]
                 }
               `}
           </script>
           {/* <script type="application/ld+json">
            {`{ "@context": "http://schema.org",
            "@type": "Product",
            "name": "Star Out Patient Care Insurance Policy",
            "description": "Star Out Patient Care Insurance Policy covers outpatient consultation (OPD) charges incurred at any network hospitals all over India. Covers non-allopathic treatment expenses, diagnostics, physiotherapy, and pharmacy expenses.",
            "offers":{ 
                    "@type": "AggregateOffer",
                    "offerCount": "5%"
                    }
            }`}
</script> */}
        </Helmet>)}    
      </GridItem>
    </GridContainer>
  );
};
OutpatientcareHighlights.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(OutpatientcareHighlights);