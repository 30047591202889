import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import { rows1 } from "./CovidChargesTableData";

const table1 = [
    ["200 to 350","2%"],
    ["351 to 600","5%"],
    ["601 to 750","7%"],
    ["751 to 1000","10%"]
]

const table2 = [
    ["Self, Spouse","1:1"],
    ["Self, Spouse and Dependent Children (up to 18 years)","1:1:0:0:0"],
    ["Self, Spouse and Dependent Children (aged above 18 years)","2:2:1:1:1"]
]

const table3 = [
    ["1.",["Manage and Track Health","a) Online Health Risk Assessment (HRA)","b) Preventive Risk Assessment"],[" ","50","200"]],
    ["2.",["Affinity to Wellness","a) Participating in Walkathon, Marathon, Cyclothon and similar activities","b) Membership in a health club (for 1 year or more) "],[" ","100","100"]],
    ["3.",["Stay Active – If the Insured member achieves the step count target on mobile app"],["200"]],
    ["4.",["a) Weight Management Program (for the Insured who is Overweight / Obese)","b) Sharing Insured Fitness Success Story through adoption of Star Wellness Program   (for the Insured who is not Overweight / Obese)"],["100","50"]],
    ["5.",["a) Chronic Condition Management Program (for the Insured who is suffering from Chronic Condition/s - Diabetes, Hypertension, Cardiovascular Disease or Asthma)","b) On Completion of De-Stress & Mind Body Healing Program (for the Insured who is not suffering from Chronic Condition/s - Diabetes, Hypertension, Cardiovascular Disease or Asthma)"],["250","125"]],
    ["",["Additional Wellness Services"],[" "]],
    ["6",["Star Tele-health services: "],[""]],
    ["7",["Medical Concierge Services"],[""]],
    ["8",["Digital Health Vault"],[""]],
    ["9",["Wellness Content"],[""]],
    ["10",["Post-Operative Care"],[""]],
    ["11",["Discounts from Network Providers"],[]],
]

const table4 = [
    ["1.Complete Haemogram Test"],
    ["2.Blood Sugar (Fasting Blood Sugar (FBS) + Postprandial (PP) [or] HbA1c)"],
    ["3.Lipid profile (Total cholesterol, HDL, LDL, Triglycerides, Total Cholesterol / HDL Cholesterol Ratio)"],
    ["4.Serum Creatinine"]
]

const table5 = [
    ["1.","Obesity (If BMI is above 29)","Height & Weight (to calculate BMI)","Achieving and maintaining the BMI between 18 and 29"],
    ["2.","Overweight (If BMI is between 25 and 29)","Height & Weight (to calculate BMI)","Reducing BMI by two points and maintaining the same BMI in the policy year"],
]


const table7 = [
    ["1.","Completed Online Health Risk Assessment (HRA)","50","50"],
    ["2.","Submitted Health Check-Up Report","200","200"],
    ["3.","Participation in Marathon","100","0"],
    ["4.","Attended to Gym","100","100"],
    ["5.","Achieved 10,000 average number of steps per day during the policy year","200","200"],
    ["6.",<p key="6">Suresh accepted the Weight management program and reached 27 BMI <br/> Lakshmi accepted the Weight management program and reached 23 BMI</p>,"100","100"],
    ["7.",<p key="7">Suresh Managed Diabetes & Hypertension through Chronic Condition Management Program <br/> Lakshmi has completed De-stress & Mind Body Healing Program</p>,"250","125"],
 
 ]


const HealthPremierWellnessBenefits = ({ classes }) => {
    return (
      <GridContainer>
        <GridItem
            md={8}
            sm={8}
            xs={8}
            className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.infoArea,
            classes.handleMarginLeft
            )}
        >
            <h5><b>Star Wellness Program:</b> This program intends to promote, incentivize and to reward the Insured Persons&apos; healthy life style through various wellness activities. The wellness activities as mentioned below are designed to help the Insured person to earn wellness reward points which will be tracked and monitored by the Company. The wellness points earned by the Insured Person(s) under the wellness program, can be utilized to get discount in premium.</h5>
            <h5>This Wellness Program is enabled and administered online through Star Wellness Platform through Star Health Customer Mobile app    ‘Star Power’ and through ‘Star Health Customer Portal’. (digital platform). 
            <br/><b>Note:</b> The Wellness Activities mentioned in the table below (from Serial Number 1 to 5) are applicable for the Insured person(s) aged 18 years and above only. The following table shows the discount on premium available under the Wellness Program:</h5>
            <Paper className={classes.root}>
                <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                    <TableCell
                        rowSpan={1}
                        className={classes.tableCell + ` ` + classes.headCell}
                    >
                        Wellness Points Earned
                    </TableCell>
                    <TableCell
                        colSpan={1}
                        align="center"
                        className={classes.tableCell + ` ` + classes.headCell}
                    >
                        Discount in Premium
                    </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {table1.map((row) => (
                    <TableRow key={row[0]}>
                        <TableCell align="center" className={classes.tableCell}>
                        {row[0]}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                        {row[1]}
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </Paper>
            <h5>*In case of floater policy the weightage is given as per the following table;</h5>
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                        <TableCell
                            rowSpan={1}
                            className={classes.tableCell + ` ` + classes.headCell}
                        >
                            Family Size
                        </TableCell>
                        <TableCell
                            colSpan={1}
                            align="center"
                            className={classes.tableCell + ` ` + classes.headCell}
                        >
                            Weightage
                        </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table2.map((row) => (
                        <TableRow key={row[0]}>
                            <TableCell align="center" className={classes.tableCell}>
                            {row[0]}
                            </TableCell>
                            <TableCell align="center" className={classes.tableCell}>
                            {row[1]}
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </Paper>
                <h5>Note:In case of two year policy, total number of wellness points earned in two year period will be divided by two.</h5>
                <h5>*Please refer the Illustrations to understand the calculation of discount in premium, weightage and the calculation.</h5>
                <h5>The wellness services and activities are categorized as below:</h5>
                <Paper className={classes.root}>
                <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                    <TableCell
                        rowSpan={1}
                        className={classes.tableCell + ` ` + classes.headCell}
                    >
                        Sr.No
                    </TableCell>
                    <TableCell
                        colSpan={1}
                        align="center"
                        className={classes.tableCell + ` ` + classes.headCell}
                    >
                        Activity
                    </TableCell>
                    <TableCell
                        colSpan={1}
                        align="center"
                        className={classes.tableCell + ` ` + classes.headCell}
                    >
                        Maximum number of Wellness  Points that can be earned under each activity in a policy year
                    </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {table3.map((row) => 
                    (row[1].map((column, index) => (
                    <TableRow key={row[0]}>
                        {index == 0 && <TableCell align="center" rowSpan={row[1].length} className={classes.tableCell}>
                        {row[0]}
                        </TableCell>}
                        <TableCell align="center" rowSpan={1} className={classes.tableCellLeft}>
                            {column}
                        </TableCell>
                        <TableCell align="center" rowSpan={1} className={classes.tableCell}>
                        {row[2][index]}
                        </TableCell>
                    </TableRow>
                    ))))}
                </TableBody>
                </Table>
            </Paper>
            <ol>
          <li>
            <h5>Manage and Track Health:</h5>
            <h5>a) Completion of Health Risk Assessment (HRA): <br/>The Health Risk Assessment (HRA) questionnaire is an online tool for evaluation of health and quality of life of the Insured. It helps the Insured to introspect his/ her personal lifestyle. The Insured can log into his/her account on the website www.starhealth.in and complete the HRA questionnaire. The Insured can undertake this once per policy year.
            <br/>On Completion of online HRA questionnaire, the Insured earns 50 wellness points.
            <br/>Note: To get the wellness points mentioned under HRA, the Insured has to complete the entire HRA within one month from the time he/she started HRA Activity.</h5>
            <h5>b) Preventive Risk Assessment: <br/>The Insured can also earn wellness points by undergoing diagnostic / preventive tests during the policy year. These tests should include the four mandatory tests mentioned below. Insured can take these tests at any diagnostic centre at Insured’s own expenses. 
            <br/><ul>
              <li>If all the results of the submitted test reports are within the normal range, Insured earns 200 wellness points.</li>
              <li>If the result of any one test is not within the normal range as specified in the lab report, Insured earns 150 wellness points.</li>
              <li>If two or more test results are not within the normal range, Insured earns 100 wellness points only.</li>
            </ul>
            Note: These tests reports should be submitted together and within 30 days from the date of undergoing such Health Check-Up.
            </h5>
                <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        rowSpan={1}
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        List of mandatory tests under Preventive Risk Assessment
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table4.map((row) => (
                      <TableRow key={row[0]}>
                        <TableCell align="center" className={classes.tableCellLeft}>
                          {row[0]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
            </Paper>
          </li>
          <br/>
          <li>
            Affinity towards wellness: Insured earns wellness points for undertaking any of the fitness and health related activities as given below.List of Fitness Initiatives and Wellness points:
                
                <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        rowSpan={1}
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        align="center"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Initiative
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        align="center"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Wellness Points
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    
                      <TableRow>
                        <TableCell align="center" rowSpan={2} className={classes.tableCell}>
                        a.
                        </TableCell>
                        <TableCell align="center" rowSpan={1} className={classes.tableCellLeft}>
                        Participating in Walkathon, Marathon, Cyclothon and similar activities
                        </TableCell>
                        <TableCell align="center" rowSpan={2} className={classes.tableCell}>
                        100
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" rowSpan={1} className={classes.tableCellLeft}>
                        On submission of BIB Number along with the details of the entry ticket taken to participate in the event. 
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" rowSpan={1} className={classes.tableCell}>
                        b.
                        </TableCell>
                        <TableCell align="center" rowSpan={1} className={classes.tableCellLeft}>
                        Membership in a health club (for 1 year or more) - In a Gym / Yoga Centre / Zumba Classes / Aerobic Exercise/ Sports Club/ Pilates Classes/ Swimming / Tai Chi/ Martial Arts / Gymnastics/ Dance Classes
                        </TableCell>
                        <TableCell align="center" rowSpan={1} className={classes.tableCell}>
                        100
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" colSpan={3} className={classes.tableCellLeft}>
                        Note: In case if Insured is not a member of any health club, he/she should join into club within 3 months from the date of the policy risk commencement date. Insured person should submit the health club membership.                        </TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
            </Paper>       
          </li>
          <br/>
          <li>
          Stay Active: Insured earns wellness points on achieving the step count target on star mobile application as mentioned below:
          <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={1}
                        align="center"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Average number of steps per day in a policy year
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        align="center"
                        className={classes.tableCell + ` ` + classes.headCell}
                      >
                        Wellness Points
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    
                      <TableRow>
                        <TableCell align="center" rowSpan={1} className={classes.tableCellLeft}>
                        <ul><li>If the average number of steps per day in a policy year are between -  5000 and 7999</li></ul>
                        </TableCell>
                        <TableCell align="center" rowSpan={1} className={classes.tableCell}>
                        100
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" rowSpan={1} className={classes.tableCellLeft}>
                        <ul><li>If the average number of steps per day in a policy year are between -  8000 and 9999</li></ul>                        </TableCell>
                        <TableCell align="center" rowSpan={1} className={classes.tableCell}>
                        150
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" rowSpan={1} className={classes.tableCellLeft}>
                        <ul><li>If the average number of steps per day in a policy year are -  10000 and above</li></ul>  
                        </TableCell>
                        <TableCell align="center" rowSpan={1} className={classes.tableCell}>
                        200
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" colSpan={3} className={classes.tableCellLeft}>
                        Note:<ul>
                        <li>First month and last month in each policy year will not be taken into consideration for calculation of average number of steps per day under Stay Active. </li>
                        <li>The mobile app must be downloaded within 30 days of the policy risk start date to avail this benefit.      </li>
                        <li>The average step count completed by an Insured member would be tracked on star wellness mobile application.  </li>
                        </ul>
                        </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
            </Paper>
          </li>
          <br/>
          <li>
            Weight Management Program: 
            <h5>a)This Program will help the Insured persons with Over Weight and Obesity to manage their Body Mass Index (BMI) through the empanelled wellness experts who will guide the Insured in losing excess weight and maintain their BMI. </h5>
            <ul>
              <li>
              On acceptance of the Weight Management Program, Insured earns 50 wellness points.
              </li>
              <li>
              An additional 50 wellness points will be awarded in case if the results are achieved and maintained as mentioned below.
              </li>
                    <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          rowSpan={1}
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          Sr.No.
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="center"
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          Name of the Ailment
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="center"
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          Values to be submitted
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="center"
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          Criteria to get the Wellness points
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {table5.map((row) => (
                        <TableRow key={row[0]}>
                          <TableCell align="center" className={classes.tableCell}>
                            {row[0]}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell}>
                            {row[1]}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell}>
                            {row[2]}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell}>
                            {row[3]}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableCell align="center" colSpan={4} className={classes.tableCellLeft}>
                       Values (for BMI) shall be submitted for every 2 months (up to 5 times in each policy year)
                      </TableCell>
                    </TableBody>
                  </Table>
              </Paper>
            </ul>
            <h5>b) Incase if the Insured is not Overweight / Obese, the Insured can submit his/her Fitness Success Story through adoption of Star Wellness Activities with us. On submission of the Fitness Success Story through adoption of Star Wellness Activities, Insured earns 50 wellness points.</h5>          
          </li>
          <li>
          Chronic Condition Management Program:
          <h5>a)This Program will help the Insured suffering from Diabetes, Hypertension, Cardiovascular Disease or Asthma to track their health through the empanelled wellness experts who will guide the insured in maintaining/ improving the health condition. </h5>
          <ul>
            <li>On acceptance of the Chronic Condition Management Program, Insured earns 100 wellness points.</li>
            <li>The Insured has to submit the test result values for every 3 months maximum up to 3 times in a policy year.</li>
            <li>If the test result values are within +/- 10% range of the values given below, for at least 2 times in a policy year, an additional 150 wellness points will be awarded.</li>
            <li>
            These tests reports to be submitted within 1 month from the date of undergoing the Health Check-Up
            <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          rowSpan={1}
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          Sr.No.
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="center"
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          Name of the Ailment
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="center"
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          Test to be submitted
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="center"
                          className={classes.tableCell + ` ` + classes.headCell}
                        >
                          Values Criteria to get the additional Wellness points
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          rowSpan={3}
                          align="center"
                          className={classes.tableCell}
                        >
                          1.
                        </TableCell>
                        <TableCell
                          rowSpan={3}
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Diabetes(Insured can submit either HbA1c test value
                          (or) Fasting Blood Sugar (FBS) Range and Postprandial
                          test value)
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          HbA1c
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          ≤ 6.5
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Fasting Blood Sugar (FBS) Range
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          100 to 125 mg/dl
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Postprandial test value
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          below 160 mg/dl
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          rowSpan={1}
                          align="center"
                          className={classes.tableCell}
                        >
                          2.
                        </TableCell>
                        <TableCell
                          rowSpan={1}
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Hypertension
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Measured with - BP apparatus
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Systolic Range - 110 to 140 mmHg Diastolic Range - 70
                          to 90 mmHg
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          rowSpan={2}
                          align="center"
                          className={classes.tableCell}
                        >
                          3.
                        </TableCell>
                        <TableCell
                          rowSpan={2}
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Cardiovascular Disease
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          LDL Cholesterol
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          100 to 159 mg/dl
                        </TableCell>
                      </TableRow>
                      <TableRow>
                      <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Total Cholesterol / HDL Cholesterol Ratio
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          ≤ 4.0
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          rowSpan={1}
                          align="center"
                          className={classes.tableCell}
                        >
                          4.
                        </TableCell>
                        <TableCell
                          rowSpan={1}
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          Asthma
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          PFT (Pulmonary Function Test)
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellLeft}
                        >
                          FEV1 (PFC) is 75% or more FEV1/ FVC is 70% or more
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    {/* <TableBody>
                      {table6.map((row) => (
                        <TableRow key={row[0]}>
                          <TableCell align="center" className={classes.tableCell}>
                            {row[0]}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell}>
                            {row[1]}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell}>
                            {row[2]}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell}>
                            {row[3]}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody> */}
                  </Table>
              </Paper>
            </li>
          </ul>
          <h5>b)In case if the Insured is not suffering from Chronic Condition/s (Diabetes, Hypertension, Cardiovascular Disease or Asthma) he/she can opt for “De-Stress & Mind Body Healing Program”. This program helps the Insured to reduce stress caused due to internal (self-generated) & external factors and increases the ability to handle stress.</h5>
          <ul>
            <li>On acceptance of De-stress & Mind Body Healing Program Insured earns 50 wellness points.</li>
            <li>On completion of De-stress & Mind Body Healing Program Insured earns an additional 75 wellness points.</li>
          </ul>
          <h5>Note: This is a 10 weeks program which insured needs to complete without any break. </h5>
          </li>
          <li>
          <h5><b>Star Tele-health Services:</b> Insured can consult with the In-house Medical Practitioners between 8.00 am and 10.00 pm, who can help the Insured by providing Medical advice, Second Medical Opinion and consultation on Diet & Nutrition through Voice Call, Video Call & Online Chat provided in our Mobile App.</h5>
          </li>
          <li>
          <h5>Medical Concierge Services: The Insured can also contact Star Health to avail the following services:-  Emergency assistance information such as nearest ambulance / hospital / blood bank etc.</h5>
          </li>
          <li>
          <h5>Digital Health Vault: A secured Personal Health records system for Insured to store/access and share health data with trusted recipients. Using this portal, Insured can store their health documents (prescriptions, lab reports, discharge summaries etc.), track health data add family members.</h5>
          </li>
          <li>
          <h5>Wellness Content: The wellness portal provides rich collection of health articles, blogs, tips and other health and wellness content. The contents have been written by experts drawn from various fields. Insured will benefit from having one single and reliable source for learning about various health aspects and incorporating positive health changes.</h5>
          </li>
          <li>
          <h5>Post Operative Care: It is done through follow up phone calls (primarily for surgical cases) for resolving their medical queries.  </h5>
          </li>
          <li>
          <h5>Discounts from Network Providers: The Insured can avail discounts on the services offered by our network providers which will be displayed in our website.
            <br/> Terms and conditions under wellness activity</h5>
            <ul>
              <li>Any information provided by the Insured in this regard shall be kept confidential.</li>
              <li>There will not be any cash redemption against the wellness reward points.</li>
              <li>Insured should notify and submit relevant documents, reports, receipts etc for various wellness activities within 1 month of undertaking such activity/test.</li>
              <li>For services that are provided through empanelled service provider, Star Health is only acting as a facilitator; hence would not be liable for any incremental costs or the services.</li>
              <li>All medical services are being provided by empanelled health care service provider. We ensure full due diligence before empanelment. However Insured should consult his/her doctor before availing/taking the medical advices/services. The decision to utilize these advices/services is solely at Insured person’s discretion.</li>
              <li>We reserve the right to remove the wellness reward points if found to be achieved in unfair manner.</li>
              <li>Star Health, its group entities, or affiliates, their respective directors, officers, employees, agents, vendors, are not responsible or liable for, any actions, claims, demands, losses, damages, costs, charges and expenses which a Member claims to have suffered, sustained or incurred, by way of and / or on account of the Wellness Program.</li>
              <li>Services offered are subject to guidelines issued by IRDAI from time to time.</li>
            </ul>
          </li>
          </ol>
          <h5><b><u>ILLUSTRATION OF BENEFITS:</u></b></h5>
        <h5><b>Lets look how the Insured can avail discount on premium through the “Star Wellness Program”</b></h5>
        <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableBody>
                      <TableRow>
                            <TableCell align="center" colSpan={4} className={classes.tableCellLeft}>
                            <b>Scenario – 1</b><br/>
                            A 50 year old Individual Suresh and his wife Lakshmi along with their two dependent children (aged below 18 yrs)  buy a <b>Star Health Premier Insurance Policy</b> with Sum Insured 20 Lacs, let’s understand how they can earn <b>Wellness Points</b> under the Floater Policy. Suresh has declared that he is suffering from Diabetes & Hypertension. Suresh has declared his Body Mass Index (BMI) as 30 & Lakshmi has declared her BMI as 25
                            Suresh and Lakshmi enrolled under the Star wellness program and completed the following <b>wellness activities</b>.
                            </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={1}
                        className={classes.tableCell + ` ` + classes.headCell}>
                        Sr. No
                        </TableCell>
                        <TableCell colSpan={1}
                        align="center"
                        className={classes.tableCell + ` ` + classes.headCell}>
                        Name of the wellness activity taken up during the policy year
                        </TableCell>
                        <TableCell colSpan={1}
                        align="center"
                        className={classes.tableCell + ` ` + classes.headCell}>
                        Wellness Points Earned by Suresh
                        </TableCell>
                        <TableCell colSpan={1}
                        align="center"
                        className={classes.tableCell + ` ` + classes.headCell}>
                        Wellness Points Earned by Lakshmi
                        </TableCell>
                      </TableRow>
                      {table7.map((row) => (
                        <TableRow key={row[0]}>
                          <TableCell align="center" className={classes.tableCell} style={{fontWeight: `bold`}}>
                            {row[0]}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCellLeft}>
                            {row[1]}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell} style={{fontWeight: `bold`}}>
                            {row[2]}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell} style={{fontWeight: `bold`}}>
                            {row[3]}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                          <TableCell align="center" className={classes.tableCell} style={{fontWeight: `bold`}}>
                          
                          </TableCell>
                          <TableCell align="center" className={classes.tableCellLeft} style={{fontWeight: `bold`}}>
                          Total Number of Wellness Points earned
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell} style={{fontWeight: `bold`}}>
                          1000
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell} style={{fontWeight: `bold`}}>
                          775
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell align="center" className={classes.tableCell} style={{fontWeight: `bold`}}>
                          
                          </TableCell>
                          <TableCell align="center" className={classes.tableCellLeft} style={{fontWeight: `bold`}}>
                          No of wellness points based upon weightage - 1:1
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell} style={{fontWeight: `bold`}}>
                          500<br/>
                          (1000X1/2)
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell} style={{fontWeight: `bold`}}>
                          388<br/>
                          (775X1/2)
                          </TableCell>
                      </TableRow>
                      <TableRow>
                            <TableCell align="center" colSpan={4} className={classes.tableCellLeft}>
                            <b>Total Number of Wellness Points earned by Suresh and Lakshmi = 888 (500+388)
                            Based on the no of Wellness Points earned, Suresh & Lakshmi are eligible to get 10% discount on renewal premium</b>
                            </TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
            </Paper> 
        </GridItem>
      </GridContainer>
    )
}

HealthPremierWellnessBenefits.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    pageContext: PropTypes.object,
  };


export default withStyles(WellnessBenefitsStyle)(HealthPremierWellnessBenefits);